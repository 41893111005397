import React, { Fragment, useState, useEffect } from "react";
import { Col, CardBody, Row, Card } from "reactstrap";
import { Btn, H2, H3, H6, H4, LI, P, UL } from "../../../AbstractElements";
import { Subscribe } from "../../../Constant";
import * as queries from "../../../graphql/queries";
import { toast } from "react-toastify";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

//price_1NMAALIeIZdDV3cdQR2JtkOL
const BillingPlan = (props) => {
  const [subscriptionsplansData, setSubscriptionsplansData] = useState([]);

  // async function onSubmit() {
  //   const { error } = await stripePromise.redirectToCheckout({
  //     lineItems: [
  //       {
  //         price: "price_1NMAALIeIZdDV3cdQR2JtkOL",
  //         quantity: 1,
  //       },
  //     ],
  //     mode: "subscription",
  //     successUrl: `http://localhost:3000/login`,
  //     cancelUrl: `http://localhost:3000/register`,
  //     customerEmail: props.values.Email,
  //   });
  //   console.warn(error.message);
  // }
  // const onSubmit = async (data) => {
  //   console.log(data);
  //   props.values.paymentId = data.id;
  //   localStorage.setItem("data", JSON.stringify(props.values));
  //   const stripe = loadStripe(stripePromise);
  //   await stripe.checkout.sessions.create({
  //     // Remove the payment_method_types parameter
  //     // to manage payment methods in the Dashboard
  //     payment_method_types: ["card"],
  //     line_items: [
  //       {
  //         price_data: {
  //           // The currency parameter determines which
  //           // payment methods are used in the Checkout Session.
  //           currency: "aud",
  //           product_data: {
  //             name: "T-shirt",
  //           },
  //           unit_amount: 10,
  //         },
  //         quantity: 1,
  //       },
  //     ],
  //     mode: "payment",
  //     success_url: "http://localhost:3000/thankyou",
  //     cancel_url: "http://localhost:3000/paymentfail",
  //   });
  // stripe
  //   .redirectToCheckout({
  //     lineItems: [{ price: "price_1NMAALIeIZdDV3cdQR2JtkOL", quantity: 1 }],
  //     mode: "payment",
  //     successUrl: `http://localhost:3000/thankyou`,
  //     cancelUrl: `http://localhost:3000/paymentfail`,
  //     customerEmail: props.values.Email,
  //   })
  //   .then(function (result) {
  //     localStorage.setItem("paymentResult", result);
  //     // const updateUser = await client.graphql(
  //     //       {queries:mutations.updateUserDetails, {
  //     //         input: { id: props.values.UserId, paymentId: bilId },
  //     //       })
  //     //     );
  //     //     console.log(updateUser);
  //     //     toast.success("Plan " + data.title + " has been Choosen Succesfully!..", {
  //     //       position: toast.POSITION.TOP_CENTER,
  //     //     });
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
  // };
  async function onSubmit(data) {
    try {
      if (data.id === "5") {
        window.open("https://knowyourasset.com.au/contact/", "_blank");
        window.location.reload("https://electrical.knowyourasset.com.au/");
      } else {
        console.log(data);
        props.values.paymentsData = JSON.stringify(data);
        props.values.PaymentId = data.id;
        localStorage.setItem("data", JSON.stringify(props.values));

        props.nextStep();
        const bilId = data.id;
        //const updateUser = await client.graphql(
        //  {queries:mutations.updateUserDetails, {
        //    input: { id: props.values.UserId, paymentId: bilId },
        //  })
        //);
        //console.log(updateUser);
        toast.success(
          "Plan " + data.title + " has been Choosen Succesfully!..",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
      //if (data.title === "Lite") {
      //  window.location.href = "https://buy.stripe.com/aEU8AwelYg2adLWfYY?client_reference_id="+props.values.UserId;
      //} else if (data.title === "Tier1") {
      //  window.location.href = "https://buy.stripe.com/dR6040cdQ7vEbDOaEF?client_reference_id="+props.values.UserId;
      //} else if (data.title === "Tier2") {
      //  window.location.href = "https://buy.stripe.com/00g9EA0v89DM6ju146?client_reference_id="+props.values.UserId;
      //} else if (data.title === "Tier3") {
      //  window.location.href = "https://buy.stripe.com/cN29EAcdQ17g4bm4gj?client_reference_id="+props.values.UserId;
      //} else if (data.title === "Corporate") {
      //  window.location.href = "https://buy.stripe.com/aEU184a5IeY60Za5ko?client_reference_id="+props.values.UserId;
      //}
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
  //----------------------------------
  useEffect(() => {
    fetchSubscriptionList();
    async function fetchSubscriptionList() {
      await client
        .graphql({
          query: queries.listSubscriptionLimits,
          authMode: "API_KEY",
          variables: {
            limit: 10000,
          },
        })
        .then((response) => {
          let setdata = [];
          let plansData = [];
          setdata = response.data.listSubscriptionLimits.items;
          // setsubscriptiondata(response.data.listSubscriptionLimits.items);
          setdata.map((item) => {
            let storage1Text =
              item.subscriptionId === "5"
                ? "If your company or organisation has a number of assets well in excess of our current packages, click below to discuss a tailored package to suit."
                : (item.MinAsset === null ? 0 : item.MinAsset) +
                  "-" +
                  item.AssetLimit;
            let props1Text = item.subscriptionId === "5" ? "" : "Assets";
            plansData.push({
              id: item.subscriptionId,
              prize: "$" + item.Price,
              title: item.SubscriptionName,
              month: "/month",
              storage1: storage1Text,
              props1: props1Text,
              // storage2: item.SiteLimit,
              storage2: "Unlimited",
              props2: "Sites",
              storage3: item.SiteUserLimit,
              // props3: 'SiteUserLimit',
              props3: "Users",
              // storage4: item.SPUserLimit,
              // props4: 'SP User Limit'
              // storage5: '15',
              // props5: 'Domains',
            });
          });
          //local bill plan
          // plansData.push({
          //   id: "667uyg76gjhgjhjhghbhj8767889989",
          //   prize: "$1",
          //   title: "Developer Plan",
          //   month: "/month",
          //   storage1: "1",
          //   props1: "1",
          //   storage2: "Unlimited",
          //   props2: "Sites",
          //   storage3: "1",
          //   props3: "Users",
          // });
          setSubscriptionsplansData(plansData);
          console.log("data of the subscription list--");
          console.log(setdata);
        })
        .catch(() => {
          console.log("Error i Fetching Subscription Details");
        });
    }
  }, []);
  //----------------------------------

  return (
    <Fragment>
      <H4>Choose Your Subscription Plan</H4>
      <CardBody className="pricing-content pricing-col">
        <Row>
          {subscriptionsplansData.map((item) => {
            return (
              <Col sm="6" xl="3" className="xl-50 box-col-6" key={item.id}>
                <Card className="pricing-block text-center">
                  <div className="pricing-header">
                    <H2>{item.title}</H2>
                    <div className="price-box">
                      <div>
                        <H3>{item.prize}</H3>
                        <P>{item.month}</P>
                      </div>
                    </div>
                  </div>
                  <div className="pricing-list">
                    <UL attrUL={{ className: "simple-list pricing-inner" }}>
                      <LI value="Plan1" name="plan1">
                        <H6>
                          {item.storage1}
                          <span>{item.props1}</span>
                        </H6>
                      </LI>
                      {item.id !== "5" ? (
                        <>
                          <LI value="Plan2" name="plan2">
                            <H6>
                              {item.storage2}
                              <span>{item.props2}</span>
                            </H6>
                          </LI>
                          <LI value="Plan3" name="plan3">
                            <H6>
                              {item.storage3}
                              <span>{item.props3}</span>
                            </H6>
                          </LI>
                        </>
                      ) : (
                        ""
                      )}
                      {/* <LI value="Plan4" name="plan4">
                        <H6>
                          {item.storage4}
                          <span>{item.props4}</span>
                        </H6>
                      </LI> */}
                    </UL>
                    <Btn
                      attrBtn={{
                        color: "primary",
                        size: "lg",
                        onClick: () => onSubmit(item),
                      }}
                    >
                      {item.id !== "5" ? Subscribe : "Click Here"}
                    </Btn>
                    <br></br>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </CardBody>
    </Fragment>
  );
};
export default BillingPlan;
