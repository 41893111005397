import React, { Fragment, useState, useEffect } from "react";
import { Btn, H5, H3, H6, LI, P, UL } from "../../AbstractElements";
import {
  Col,
  Container,
  Form,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  CardBody,
  Card,
  CardHeader,
  Media,
  Table,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  S3_BUCKET,
  REGION,
  CATEGORIES_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
} from "../../Config/Config";
import Papa from "papaparse";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import AWS from "aws-sdk";
import { Link } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { DistributionBox } from "../../models";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const XLSX = require("xlsx");
AWS.config.update({ region: REGION });
const dynamoDb = new AWS.DynamoDB({
  region: REGION,
  accessKeyId: ACCESSKEYID,
  secretAccessKey: SECRETACCESSKEY,
  apiVersion: "2012-08-10",
});

const DistrbutionImport = (props) => {
  const [fileData, setFileData] = useState([]);
  const [fileHeader, setFileHeader] = useState(null);
  const [selectedTable, setSelectedTable] = useState("");
  const [tableColumns, setTableColumns] = useState([]);
  const [tableColumnLength, setTableColumnLength] = useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const [siteDetailsNew, setSiteDetailsNew] = useState([]);
  const [assetOwners, setAssetOwner] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserGroup = localStorage.getItem("groups");

  useEffect(() => {
    console.log(props);
    const getSiteDetails = async () => {
      await client
        .graphql({
          query: queries.listSiteDetails,
          variables: {
            filter: {
              status: {
                eq: "Active",
              },
              assetownerID: {
                eq: LoginUserDetails.id,
              },
            },
            limit: 2000000,
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          setSiteDetails(result.data.listSiteDetails.items);
        })
        .catch((error) => {
          console.log(error);
          setSiteDetails([]);
        });
    };
    async function fetchSiteDetailsSU() {
      await client
        .graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: LoginUserDetails.id,
            limit: 100000,
          },
        })
        .then(async (dataDetails) => {
          const siteDetails =
            dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
          if (siteDetails.length > 0) {
            const promises = siteDetails.map((item) => {
              return client.graphql({
                query: queries.getSiteDetails,
                variables: {
                  id: item.sitedetailsID,
                },
                authMode: "API_KEY",
              });
            });
            Promise.all(promises).then((results) => {
              console.log(results);
              if (results.length > 0) {
                const siteDetail = results.map((items) => {
                  if (items.data.getSiteDetails !== null) {
                    return items.data.getSiteDetails;
                  }
                });
                console.log(siteDetail);
                setSiteDetails(siteDetail);
              } else {
                setIsLoading(false);
                setSiteDetails([]);
              }
            });
          } else {
            setIsLoading(false);
            setSiteDetails([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
    const getAssetOwnerDetails = async () => {
      await client
        .graphql({
          query: queries.listAssetOwners,
          variables: {
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: 2000000,
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          setAssetOwner(result.data.listAssetOwners.items);
        })
        .catch((error) => {
          console.log(error);
          setAssetOwner([]);
        });
    };
    const getSiteDetailsById = async () => {
      var siteFilterArray = [];
      props.values.siteDetailsImport.forEach((item) => {
        siteFilterArray.push({
          siteId: { eq: item.id },
        });
      });
      await client
        .graphql({
          query: queries.listSiteDetails,
          variables: {
            filter: {
              filter: { or: siteFilterArray },
            },
            limit: 2000000,
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          setSiteDetailsNew(result.data.listDistributionBoxes.items);
        })
        .catch((error) => {
          console.log(error);
          setSiteDetailsNew([]);
        });
    };
    if (LoginUserGroup === "site_user") {
      fetchSiteDetailsSU();
    } else {
      getSiteDetails();
    }
    getAssetOwnerDetails();
    if (props.values.siteDetailsImport !== "") {
      getSiteDetailsById();
    }
  }, [props]);

  let dbColumn = ["distributionBoardName", "siteName", "location"];
  const handleFileUpload = (event) => {
    try {
      setIsLoading(true);
      const file = event.target.files[0];
      if (file !== undefined) {
        const extension = file.name.split(".").pop().toLowerCase();

        if (extension === "csv") {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
              console.log(result);
              const rows = result.data.map((row) => Object.values(row));
              const headers = Object.keys(result.data[0]);
              var matchHeaderWithColumn = true;
              headers.map((item) => {
                if (!dbColumn.includes(item)) {
                  matchHeaderWithColumn = false;
                }
              });
              console.log(headers);
              console.log(rows);
              console.log(matchHeaderWithColumn);
              if (!matchHeaderWithColumn) {
                setFileData([]);
                setFileHeader([]);
                setTableColumnLength([]);
                setErrorMessage(!errorMessage);
              } else {
                setFileData(
                  rows.filter((o) => !Object.keys(o).every((k) => !o[k]))
                );
                setFileHeader(headers);
                setTableColumnLength(headers);
                if (errorMessage) {
                  setErrorMessage(!errorMessage);
                }
              }
              setIsLoading(false);
            },
            error: (error) => {
              console.log(error);
              setIsLoading(false);
            },
          });
        } else if (extension === "xls" || extension === "xlsx") {
          const reader = new FileReader();
          const jsonOpts = {
            header: 1,
            defval: null,
            raw: false,
            dateNF: "yyyy-m-d",
            blankrows: false,
          };
          reader.onload = (event) => {
            let data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            data = XLSX.utils.sheet_to_json(worksheet, jsonOpts);
            console.log(data);
            const headers = data.splice(0, 1)[0];
            console.log(headers);
            var matchHeaderWithColumn = true;
            headers.map((item) => {
              if (!dbColumn.includes(item)) {
                matchHeaderWithColumn = false;
              }
            });
            console.log(headers);
            console.log(matchHeaderWithColumn);
            if (!matchHeaderWithColumn) {
              setFileData([]);
              setFileHeader([]);
              setTableColumnLength([]);
              setErrorMessage(!errorMessage);
            } else {
              setFileData(
                data.filter((o) => !Object.keys(o).every((k) => !o[k]))
              );
              setFileHeader(headers);
              setTableColumnLength(headers);
            }
          };
          reader.readAsArrayBuffer(file);
          setIsLoading(false);
        } else {
          alert("Unsupported file format");
          setIsLoading(false);
        }
      } else {
        toast.info("File not selected", {
          position: toast.POSITION.TOP_CENTER,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const [dbIds, setDbIds] = useState([]);

  const handleSave = async () => {
    // try {
    //   await DataStore.save(
    //     new DistributionBox({
    //       distributionBoardName: "New title DB",
    //       sitedetailsID: "e346f816-5260-4d65-8b20-386f88d76ced",
    //       location: "ssdsds",
    //       status: "Active",
    //     })
    //   );
    // } catch (error) {
    //   console.log(error);
    // }
    setIsLoading(true);
    if (tableColumnLength.length === 0) {
      toast.error("Please choose to import the selected file.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
      setIsLoading(false);
      return;
    }

    const insetArray = [];
    tableColumnLength.map((val) => {
      insetArray.push({ col: val, data: val });
    });

    console.log(insetArray);

    if (fileData && fileData.length > 0) {
      let db = [];
      let successCount = 0;
      let errorCount = 0;
      let dataCount = 0;
      let errorData = [];
      let csvArray = {};
      fileData.map(async (row, i) => {
        dataCount = dataCount + 1;
        const columnDetails = {};
        let distributionBoxName = "";
        let siteDetail = "";
        insetArray.forEach((itemData) => {
          if (row[0] !== null && row[1] !== null) {
            columnDetails["status"] = "Active";
            if (itemData.col === "siteName") {
              var siteId = siteDetails.filter((site) => {
                return (
                  site.siteName.toLowerCase() ===
                  row[fileHeader.indexOf(itemData.data)].toLowerCase().trim()
                );
              });
              console.log(siteId);
              if (siteId.length > 0) {
                siteDetail = siteId[0].id;
                columnDetails["sitedetailsID"] = siteId[0].id;
              }
            } else {
              if (itemData.col === "distributionBoardName") {
                if (
                  row[fileHeader.indexOf(itemData.data)] !== null &&
                  row[fileHeader.indexOf(itemData.data)]
                    .toLowerCase()
                    .trim() !== "unknown"
                ) {
                  columnDetails["distributionBoxName"] =
                    row[fileHeader.indexOf(itemData.data)].trim();
                  distributionBoxName =
                    row[fileHeader.indexOf(itemData.data)].trim();
                } else {
                  columnDetails["distributionBoxName"] = "";
                }
              } else {
                columnDetails[itemData.col] =
                  row[fileHeader.indexOf(itemData.data)] !== null
                    ? row[fileHeader.indexOf(itemData.data)].trim()
                    : "";
              }
            }
          }
        });
        console.log("columnDetails", columnDetails);
        if (distributionBoxName !== "" && siteDetail != "") {
          await client
            .graphql({
              query: mutations.createDistributionBox,
              variables: { input: columnDetails },
              authMode: "API_KEY",
            })
            .then(async (result1) => {
              //--updating locatStoare--
              const addedsite = await client.graphql({
                query: queries.getDistributionBox,
                variables: {
                  id: result1.data.createDistributionBox.id,
                },
                authMode: "API_KEY",
              });
              if (addedsite.data.getDistributionBox !== null) {
                if (localStorage.getItem("siteDB") !== null) {
                  let activeDB = JSON.parse(
                    base64.decode(localStorage.getItem("siteDB"))
                  );
                  console.log(activeDB);
                  activeDB.push(addedsite.data.getDistributionBox);
                  console.log(activeDB);
                  localStorage.setItem(
                    "siteDB",
                    base64.encode(JSON.stringify(activeDB))
                  );
                }
              }
              //---
              successCount = successCount + 1;
              console.log("dataCount", dataCount);
              console.log("createDistributionBox", result1);
              db.push(result1.data.createDistributionBox.id);
              setDbIds(db);
              console.log("i", i);
              if (fileData.length === successCount + errorCount) {
                props.values.dbDetailsImport = dbIds;
                if (errorCount > 0 && successCount > 0) {
                  toast.error(
                    successCount +
                      " rows imported successfully, with " +
                      errorCount +
                      " rows failed to import.Check the Error File in Downloads.",
                    {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: false,
                    }
                  );
                }
                if (errorCount === 0 && successCount > 0) {
                  toast.success(successCount + " rows imported successfully", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                }
                if (errorCount > 0 && successCount === 0) {
                  toast.error(
                    "Error while importing, please check the data and import again.Check the Error File in Downloads.",
                    {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: false,
                    }
                  );
                }
                if (errorCount > 0) {
                  try {
                    const csv = Papa.unparse(errorData);
                    // Create a Blob containing the CSV data
                    const csvBlob = new Blob([csv], {
                      type: "text/csv",
                    });
                    // Create a URL for the Blob
                    const csvUrl = URL.createObjectURL(csvBlob);
                    // Create an invisible link element to trigger the download
                    const link = document.createElement("a");
                    link.href = csvUrl;
                    link.download = "dbImportErrorData.csv";
                    link.click();
                    // Clean up by revoking the URL to release resources
                    URL.revokeObjectURL(csvUrl);
                    // const link = document.createElement("a");
                    // document.body.appendChild(link);
                    // let csvContent = tableColumnLength.join(";") + "\n";
                    // csvContent += errorData.map((e) => e.join(";")).join("\n");
                    // const encodedUri =
                    //   "data:text/csv;charset=utf-8," +
                    //   encodeURIComponent(csvContent);

                    // link.setAttribute("href", encodedUri);
                    // link.setAttribute("download", "dbImportErrorData.csv");

                    // link.click();
                  } catch (error) {
                    console.log(error);
                  }
                }
                setFileData([]);
                setFileHeader([]);
                setTableColumnLength([]);
                setTableColumns([]);
                setIsLoading(false);
                props.nextStep();
              }
            })
            .catch((error) => {
              errorCount = errorCount + 1;
              csvArray["siteName"] = row[0];
              csvArray["distributionBoardName"] = row[1];
              csvArray["location"] = row[2];
              csvArray["error"] = error;
              errorData.push(csvArray);
              if (fileData.length === successCount + errorCount) {
                props.values.dbDetailsImport = dbIds;
                if (errorCount > 0 && successCount > 0) {
                  toast.error(
                    successCount +
                      " rows imported successfully, with " +
                      errorCount +
                      " rows failed to import.Check the Error File in Downloads.",
                    {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: false,
                    }
                  );
                }
                if (errorCount === 0 && successCount > 0) {
                  toast.success(successCount + " rows imported successfully", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                }
                if (errorCount > 0 && successCount === 0) {
                  toast.error(
                    "Error while importing, please check the data and import again.Check the Error File in Downloads.",
                    {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: false,
                    }
                  );
                }
                if (errorCount > 0) {
                  try {
                    const csv = Papa.unparse(errorData);
                    // Create a Blob containing the CSV data
                    const csvBlob = new Blob([csv], {
                      type: "text/csv",
                    });
                    // Create a URL for the Blob
                    const csvUrl = URL.createObjectURL(csvBlob);
                    // Create an invisible link element to trigger the download
                    const link = document.createElement("a");
                    link.href = csvUrl;
                    link.download = "dbImportErrorData.csv";
                    link.click();
                    // Clean up by revoking the URL to release resources
                    URL.revokeObjectURL(csvUrl);
                    // const link = document.createElement("a");
                    // document.body.appendChild(link);
                    // let csvContent = tableColumnLength.join(";") + "\n";
                    // csvContent += errorData.map((e) => e.join(";")).join("\n");
                    // const encodedUri =
                    //   "data:text/csv;charset=utf-8," +
                    //   encodeURIComponent(csvContent);

                    // link.setAttribute("href", encodedUri);
                    // link.setAttribute("download", "dbImportErrorData.csv");

                    // link.click();
                  } catch (error) {
                    console.log(error);
                  }
                }
                setFileData([]);
                setFileHeader([]);
                setTableColumnLength([]);
                setTableColumns([]);
                setIsLoading(false);
              }
              console.log(error);
            });
        } else {
          csvArray["siteName"] = row[0];
          csvArray["distributionBoardName"] = row[1];
          csvArray["location"] = row[2];
          csvArray["error"] = "Wrong Site / Missing DB Name";
          errorData.push(csvArray);
          console.log(errorData);
          errorCount = errorCount + 1;
          if (fileData.length === successCount + errorCount) {
            setIsLoading(false);
            toast.error(
              "Please check the data before importing.Check the Error File in Downloads.",
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false,
              }
            );
            try {
              const csv = Papa.unparse(errorData);
              // Create a Blob containing the CSV data
              const csvBlob = new Blob([csv], {
                type: "text/csv",
              });
              // Create a URL for the Blob
              const csvUrl = URL.createObjectURL(csvBlob);
              // Create an invisible link element to trigger the download
              const link = document.createElement("a");
              link.href = csvUrl;
              link.download = "dbImportErrorData.csv";
              link.click();
              // Clean up by revoking the URL to release resources
              URL.revokeObjectURL(csvUrl);
              // const link = document.createElement("a");
              // document.body.appendChild(link);
              // let csvContent = tableColumnLength.join(";") + "\n";
              // csvContent += errorData.map((e) => e.join(";")).join("\n");
              // const encodedUri =
              //   "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);

              // link.setAttribute("href", encodedUri);
              // link.setAttribute("download", "dbImportErrorData.csv");

              // link.click();
            } catch (error) {
              console.log(error);
            }
          }
        }
      });
    } else {
      setIsLoading(false);
      toast.error("No Data To Save", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
    }
  };
  const handleExport = (tblName) => {
    console.log(tblName);
    var elt = document.getElementById(tblName);
    var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
    return XLSX.writeFile(wb, tblName + ".xlsx");
  };

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <CardHeader>
              <Row>
                <div className="u-pearl done col-4">
                  <span className="u-pearl-number">1</span>
                  <span className="u-pearl-title">Site Details Import</span>
                </div>
                <div className="u-pearl current col-4">
                  <span className="u-pearl-number">2</span>
                  <span className="u-pearl-title">
                    Distribution Board Import
                  </span>
                </div>
                {/* <div className="u-pearl col-4">
                  <span className="u-pearl-number">3</span>
                  <span className="u-pearl-title">Category Import</span>
                </div> */}
                <div className="u-pearl col-4">
                  <span className="u-pearl-number">3</span>
                  <span className="u-pearl-title">Logbooks Import</span>
                </div>
              </Row>
              <Row>
                <Col md="4"></Col>
                <Col md="4">
                  <span className="d-block" style={{ textAlign: "center" }}>
                    Import Distribution Board
                    <a
                      class="downloadLink"
                      onClick={() => handleExport("dbTable")}
                    >
                      {" "}
                      Template
                    </a>
                  </span>

                  <Table
                    className="table-striped  table-de"
                    style={{ display: "none" }}
                    id="dbTable"
                  >
                    <thead className="custom-table-head">
                      <tr>
                        <th>siteName</th>
                        <th>distributionBoardName</th>
                        <th>location</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Balga Senior High School</td>
                        <td>Markham Block South DB11A</td>
                        <td>Perth</td>
                      </tr>
                      <tr>
                        <td>Balga Senior High School</td>
                        <td>GYM DB17</td>
                        <td>Perth</td>
                      </tr>
                      <tr>
                        <td>Balga Senior High School</td>
                        <td>Library DB5</td>
                        <td>Perth</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="3">
                  <Link to={`${process.env.PUBLIC_URL}/sites`}>All Sites</Link>
                </Col>
                <Col md="9"></Col>
              </Row>
              <Row>
                <Col md="5">
                  <input
                    type="file"
                    className="form-control"
                    accept=".csv,.xls,.xlsx"
                    onChange={handleFileUpload}
                  />
                </Col>
                {fileData.length > 0 && (
                  <Col md="3">
                    <span>Total records in file - {fileData.length}.</span>
                  </Col>
                )}
                <Col md="4" className="text-end">
                  <Btn attrBtn={{ color: "primary", onClick: props.nextStep }}>
                    Skip
                  </Btn>
                  <Btn attrBtn={{ color: "primary", onClick: handleSave }}>
                    Import Distribution Board Details
                  </Btn>
                </Col>
              </Row>
              <Row>
                <Col>
                  {errorMessage && (
                    <span className="txt-danger">
                      Data Columns are not matching the sample import template.
                      Please check the sample import template by downloading.
                    </span>
                  )}
                </Col>
              </Row>
              {fileData.length > 0 && (
                <Row>
                  <Col md="12">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr key={"header"}>
                          {tableColumnLength.map((key) => (
                            <th>{key}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {fileData.map((item, i) => (
                          <tr key={i}>
                            {Object.values(item).map((val) => (
                              <td>{val}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
  return <div>{isLoading ? <LoadingSpinner /> : renderList}</div>;
};

export default DistrbutionImport;
