import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { H1, H4, H6, Btn, P } from "../../AbstractElements";
import { Container, Row, CardHeader, Col, Card, Form, Label } from "reactstrap";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as queries from "../../graphql/queries";
import awsExports from "../../aws-exports";
import { SiteContext } from "../../App";
import { aocontext } from "../../App";
import Select from "react-select";
import DatePicker from "react-datepicker";

import TestingReports from "./TestingReports";
import RepairReports from "./RepairReports";
import LogBookReports from "./LogBookReports";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

var base64 = require("base-64");

const GenerateReports = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [sites, setSites] = useState([]);
  const [distributionBox, setDistributionBox] = useState([]);
  const [distributionBoxFilter, setDistributionBoxfilter] = useState([]);
  const [formData, setFormData] = useState([]);
  const [dataModel, setDataModel] = useState("");
  const [db, setDb] = useState("");
  const [dbsearch, setDbsearch] = useState("");
  const [siteId, setSiteId] = useState("");
  const [siteIdsearch, setSiteIdsearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [siteDetail, setSiteDetail] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
    LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];
  const [assetTemplate, setAssetTemplate] = useState([]);
  const [collection, setCollection] = useState([]);
  const LoginUserGroup = localStorage.getItem("groups");
  const [reportType, setReportType] = useState("");
  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const { selectedAssetOwnerGlobal, handleAssetOwner } = useContext(aocontext);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  //console.log(selectedAssetOwnerGlobal);

  useEffect(() => {
    console.log(userPermission);
    console.log(LoginUserDetails.id);
    if (state.state) {
      getTemplateData(state.state.category);
    }

    if (LoginUserGroup === "admin") {
      getSites().then(() => setLoading(false));
    }
    if (localStorage.getItem("site") === null && sites.length === 0) {
      LoginUserGroup === "site_user"
        ? fetchSiteDetailsSU()
        : LoginUserGroup === "electrician"
        ? fetchSiteDetailsSP()
        : fetchSiteDetailsAO(LoginUserDetails.id);
    } else {
      let activeSites = JSON.parse(base64.decode(localStorage.getItem("site")));
      setSites(activeSites.filter((item) => item.status === "Active"));
      setCollection(activeSites.filter((item) => item.status === "Active"));
    }
    if (
      localStorage.getItem("siteDB") === null &&
      (distributionBox.length === 0 || distributionBoxFilter.length === 0)
    ) {
      getDistributionBox();
    } else {
      setDistributionBox(
        JSON.parse(base64.decode(localStorage.getItem("siteDB")))
      );
      setDistributionBoxfilter(
        JSON.parse(base64.decode(localStorage.getItem("siteDB")))
      );
    }
  }, [selectedAssetOwnerGlobal]);

  useEffect(() => {
    setSiteId(selectedSiteGlobal);
  }, [selectedSiteGlobal]);

  async function getTemplateData(category) {
    await client
      .graphql({
        query: queries.listAssetTemplates,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
          filter: {
            categoriesID: {
              eq: category.id,
            },
          },
        },
      })
      .then((result) => {
        console.log(result);
        if (result.data.listAssetTemplates.items.length > 0) {
          let idArray = [];
          result.data.listAssetTemplates.items.map((item) => {
            idArray.push(item.id);
          });
          setAssetTemplate(idArray);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getSites() {
    await client
      .graphql({
        query: queries.listSiteDetails,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
          filter: {
            status: {
              eq: "Active",
            },
          },
        },
      })
      .then((result) => {
        console.log(result);
        setCollection(result.data.listSiteDetails.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getDistributionBox() {
    //--
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listDistributionBoxes,
        variables: {
          limit: 100000,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.listDistributionBoxes.items;
        nextToken = result.data.listDistributionBoxes.nextToken;
        console.log(
          "first token count",
          result.data.listDistributionBoxes.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listDistributionBoxes.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listDistributionBoxes,
              variables: {
                limit: 100000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listDistributionBoxes.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.listDistributionBoxes.items.length);
              if (results.data.listDistributionBoxes.items.length > 0) {
                assets.push(results.data.listDistributionBoxes.items);
              }
            })
            .catch((error) => {
              console.log(error);
              setDistributionBox([]);
              setDistributionBoxfilter([]);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setDistributionBox(mergeAssets);
          setDistributionBoxfilter(mergeAssets);
        }
      })
      .catch((error) => {
        console.log(error);
        setDistributionBox([]);
        setDistributionBoxfilter([]);
      });
    {
      /*
    await client.graphql({
      query: queries.listDistributionBoxes,
      authMode: "API_KEY",
      variables: {
        limit: 10000,
      },
    })
      .then((result) => {
        console.log(result);
        setDistributionBox(result.data.listDistributionBoxes.items);
        setDistributionBoxfilter(result.data.listDistributionBoxes.items);
      })
      .catch((error) => {
        console.log(error);
      });
    */
    }
  }

  useEffect(() => {
    if (siteId) {
      const filteredDistributionBox = distributionBox.filter(
        (item) => item.sitedetailsID === siteId
      );
      setDistributionBoxfilter(filteredDistributionBox);
      setSiteDetail(sites.filter((item) => item.id === siteId));
    } else {
      setSiteDetail([]);
      setDistributionBoxfilter([distributionBox]);
    }
  }, [siteId, distributionBox, sites]);

  const onSubmit = async (data) => {
    if (siteId !== "") {
      if (db !== "") {
        setDataModel("");
        try {
          console.log(dateRange[0]);
          console.log(dateRange[1]);
          console.log(data);
          data.assetTemp = assetTemplate;
          data.sites = siteId;
          data.distributionBox = db;
          data.categoryId = state.state.category.id;
          data.categoryName = state.state.category.categoryName;
          data.dateRangee = selectedOption;
          data.startDate = dateRange[0];
          data.endDate = dateRange[1];
          setFormData(data);
          setDataModel(data.dataModel);
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log("DB is not selected");
      }
    } else if (db !== "") {
      if (siteId !== "") {
        setDataModel("");
        try {
          console.log(data);
          data.assetTemp = assetTemplate;
          data.sites = siteId;
          data.distributionBox = db;
          data.dateRange = selectedOption;
          setFormData(data);
          setDataModel(data.dataModel);
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log("site is not selected");
      }
    } else {
      console.log("site needs to be selected");
    }
  };

  // --- assetowner
  async function fetchSiteDetailsAO(aoID) {
    const collectArray = [];
    await client
      .graphql({
        query: queries.getAssetOwner,
        authMode: "API_KEY",
        variables: {
          id: LoginUserDetails.id,
          limit: 10000,
        },
      })
      .then((dataDetails) => {
        const AOcompanyNames = dataDetails.data.getAssetOwner;
        client
          .graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: aoID,
              limit: 10000,
            },
            authMode: "API_KEY",
          })
          .then((result) => {
            console.log(result);
            const siteDetail = result.data.siteDetailsByAssetownerID.items;
            setCollection(
              siteDetail.filter((item) => item.status === "Active")
            );
            if (siteDetail.length > 0) {
              siteDetail.map((site) => {
                collectArray.push({
                  id: site.id,
                  unitNumber: site.unitNumber,
                  suburb: site.suburb,
                  state: site.state,
                  createdAt: site.createdAt,
                  companyName: AOcompanyNames.companyName,
                  status: site.status,
                });
              });
            }
            console.log(collectArray);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((errors) => {
        console.log(errors);
      });
  }

  // --site user --
  async function fetchSiteDetailsSU() {
    await client
      .graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: LoginUserDetails.id,
          limit: 100000,
        },
      })
      .then((dataDetails) => {
        const siteDetails =
          dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
        if (siteDetails.length > 0) {
          client
            .graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: 100000,
              },
            })
            .then((result) => {
              console.log(result);
              const siteDetail = result.data.listSiteDetails.items.filter(
                (item) => {
                  const _matchingAssetTemplate = siteDetails?.find(
                    (site) => site.sitedetailsID === item?.id
                  );
                  if (_matchingAssetTemplate) {
                    return item;
                  }
                }
              );
              setCollection(
                siteDetail.filter((item) => item.status === "Active")
              );
              console.log(siteDetail);
              const collectArray = [];
              siteDetail.map((site) => {
                client
                  .graphql({
                    query: queries.getCompanyDetails,
                    variables: {
                      id: site.companydetailsID,
                      limit: 10000,
                    },
                    authMode: "API_KEY",
                  })
                  .then((dataDetails) => {
                    console.log(dataDetails);
                    const comp = dataDetails.data.getCompanyDetails.companyName;
                    collectArray.push({
                      id: site.id,
                      unitNumber: site.unitNumber,
                      suburb: site.suburb,
                      state: site.state,
                      createdAt: site.createdAt,
                      companyName: comp,
                      siteName: site.siteName,
                      status: site.status,
                    });
                    console.log(collectArray);
                    //setSiteDetails(collectArray);
                    //setCollection(collectArray);
                  })
                  .catch((error1) => {
                    console.log(error1);
                  });
              });
            })
            .catch((error2) => {
              console.log(error2);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //-- electrician
  async function fetchSiteDetailsSP() {
    await client
      .graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: LoginUserDetails.id,
          limit: 100000,
        },
      })
      .then((dataDetails) => {
        const siteDetails =
          dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
        if (siteDetails.length > 0) {
          client
            .graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: 100000,
              },
            })
            .then((result) => {
              console.log(result);
              const siteDetail = result.data.listSiteDetails.items.filter(
                (item) => {
                  const _matchingAssetTemplate = siteDetails?.find(
                    (site) => site.sitedetailsID === item?.id
                  );
                  if (_matchingAssetTemplate) {
                    return item;
                  }
                }
              );
              const data = siteDetail.filter(
                (item) => item.assetownerID === selectedAssetOwnerGlobal
              );
              console.log(data);
              const filterdata = selectedAssetOwnerGlobal ? data : siteDetail;
              console.log(filterdata);
              setCollection(
                filterdata.filter((item) => item.status === "Active")
              );
              console.log(siteDetail);
              const collectArray = [];
              filterdata.map((site) => {
                client
                  .graphql({
                    query: queries.getCompanyDetails,
                    variables: {
                      id: site.companydetailsID,
                      limit: 10000,
                    },
                    authMode: "API_KEY",
                  })
                  .then((dataDetails) => {
                    console.log(dataDetails);
                    const comp = dataDetails.data.getCompanyDetails.companyName;
                    collectArray.push({
                      id: site.id,
                      unitNumber: site.unitNumber,
                      suburb: site.suburb,
                      state: site.state,
                      createdAt: site.createdAt,
                      companyName: comp,
                      siteName: site.siteName,
                      status: site.status,
                    });
                    console.log(collectArray);
                    //setSiteDetails(collectArray);
                    //setCollection(collectArray);
                  })
                  .catch((error1) => {
                    console.log(error1);
                  });
              });
            })
            .catch((error2) => {
              console.log(error2);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // reset
  const handleReset = () => {
    //document.getElementsByName("sites")[0].value = "";
    setSiteId("");
    setSiteIdsearch("");
    setSelectedOption("");
    setDbsearch("");
    setDb("");
    setDateRange([null, null]);
    //document.getElementsByName("distributionBox")[0].value = "";
    document.getElementsByName("dataModel")[0].value = "";
    document.getElementsByName("warranty")[0].value = "";
    document.getElementsByName("date")[0].value = "";
    //document.getElementsByName("testResult")[0].value = "";
    const testResultElement = document.getElementsByName("testResult")[0];
    if (testResultElement) {
      testResultElement.value = "";
    }
    console.log(document.getElementsByName("warranty")[0].value);
    reset({
      warranty: "",
    });
    setDataModel("");
    // document.getElementsByName("assets")[0].value = "";
    setFormData({});
  };

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/reports/catList`);
  };

  const optionsSitesOpen = [
    { value: "", label: "Choose Site" },
    { value: "allSites", label: "All Sites" },
    ...collection.map((item) => ({
      value: item.id,
      label:
        item.siteName +
        " " +
        "(" +
        (item.unitNumber ? item.unitNumber : "") +
        ")",
    })),
  ];

  const optionDB = [
    { value: "", label: "Choose Distribution Boards" },
    { value: "allDistribution", label: "All Distribution Boards" },
    ...distributionBoxFilter.map((item) => ({
      value: item.id,
      label: item.distributionBoxName,
    })),
  ];

  const handleSiteOpen = (event) => {
    const selectedsite = event.value;
    setSiteIdsearch(event);
    setDbsearch("");
    setSiteId(event.value);
  };

  const handleDB = (event) => {
    const selectedDB = event.value;
    setDbsearch(event);
    setDb(event.value);
  };

  const handleReportType = (event) => {
    setReportType(event.target.value);
  };

  //-----------------------------------------Display------------------------------------------
  return (
    <Fragment>
      <Container fluid={true}>
        <H1>
          Generate Reports For{" "}
          {state.state && state.state.category.categoryName}
        </H1>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <CardHeader>
                <Row>
                  <Col md="12" className="text-end">
                    <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>
                      <i className="fa fa-chevron-left"></i> Back
                    </Btn>
                  </Col>
                </Row>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col md="4">
                      <Label>Choose Sites*</Label>
                      {selectedSiteGlobal ? (
                        <select
                          className="form-select"
                          onChange={handleSiteOpen}
                          disabled={selectedSiteGlobal ? true : false}
                          value={
                            selectedSiteGlobal
                              ? selectedSiteGlobal
                              : siteIdsearch
                          }
                          menuPlacement="auto"
                          menuPosition="fixed"
                        >
                          <option value="">All Sites</option>
                          {optionsSitesOpen.map((site, index) => (
                            <option value={site.value} key={index}>
                              {site.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <Select
                          options={optionsSitesOpen}
                          isSearchable={true}
                          value={siteIdsearch}
                          placeholder="Choose Site"
                          onChange={handleSiteOpen}
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    </Col>

                    <Col md="4">
                      <Label>Choose Distribution Board*</Label>
                      <Select
                        options={optionDB}
                        isSearchable={true}
                        value={dbsearch}
                        placeholder="Choose Distribution Board"
                        onChange={handleDB}
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </Col>
                    <Col md="4">
                      <Label>Choose Report For*</Label>
                      <select
                        className="form-control"
                        placeholder="Choose Report For"
                        name="dataModel"
                        {...register("dataModel", { required: true })}
                        onChange={handleReportType}
                      >
                        <option value="">Choose Report For...</option>
                        <option value="LogBook">LogBook</option>
                        <option value="Repair">Repair</option>
                        <option value="Testing">Testing</option>
                      </select>
                      <input
                        type="hidden"
                        value={siteDetail}
                        name="siteDetail"
                        {...register("siteDetail", { required: false })}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Label>Warranty</Label>
                      <select
                        className="form-control"
                        name="warranty"
                        placeholder="Choose Warranty"
                        {...register("warranty", { required: false })}
                      >
                        <option value="">Select Warranty</option>
                        <option value="InWarranty">In Warranty</option>
                        <option value="OutWarranty">Out Warranty</option>
                      </select>
                    </Col>
                    <Col md="3">
                      <Label>Date Filter</Label>
                      <select
                        className="form-control"
                        name="date"
                        placeholder="Choose Test Result"
                        {...register("date", { required: false })}
                        value={selectedOption}
                        onChange={handleOptionChange}
                      >
                        <option value="30">30 Days</option>
                        <option value="60">60 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="Custom">Custom</option>
                      </select>
                    </Col>
                    {selectedOption === "Custom" && (
                      <Col xs="3" className="pe-0">
                        <DatePicker
                          className="form-control digits"
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(d) => {
                            setDateRange(d);
                            console.log(d);
                            console.log(startDate);
                            console.log(endDate);
                          }}
                          isClearable={true}
                          placeholderText="Enter Date Range"
                          dateFormat="dd-MM-yyyy"
                        />
                      </Col>
                    )}
                    <Col md="3">
                      {reportType === "Testing" ? (
                        <>
                          <Label>Test Result</Label>
                          <select
                            className="form-control"
                            name="testResult"
                            placeholder="Choose Test Result"
                            {...register("testResult", { required: false })}
                          >
                            <option value="">Select Test Result</option>
                            <option value="pass">Pass</option>
                            <option value="fail">Fail</option>
                            <option value="replace">Replace</option>
                            <option value="repair">Repair</option>
                          </select>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col md="3">
                      <Label></Label>
                      <Btn attrBtn={{ color: "primary", onClick: handleReset }}>
                        Reset
                      </Btn>
                      <Btn
                        attrBtn={{
                          color: "primary",
                          disabled: loading ? loading : loading,
                        }}
                      >
                        {!loading ? "Generate Reports" : "Loading..."}
                      </Btn>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12"></Col>
                  </Row>
                  <Row>
                    <Col md="12"></Col>
                  </Row>
                  <Row>
                    <Col md="12"></Col>
                  </Row>
                  <Row>
                    <Col md="12"></Col>
                  </Row>
                  <Row>
                    <Col md="12"></Col>
                  </Row>
                  <Row>
                    <Col md="12"></Col>
                  </Row>
                  <Row>
                    <Col md="12"></Col>
                  </Row>
                  <Row>
                    <Col md="12"></Col>
                  </Row>
                </Form>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        {/* <div className="card-block row"> */}
        {dataModel === "LogBook" && <LogBookReports data={formData} />}
        {dataModel === "Testing" && <TestingReports data={formData} />}
        {dataModel === "Repair" && <RepairReports data={formData} />}
        {/* </div> */}
      </Container>
    </Fragment>
  );
};
export default GenerateReports;
