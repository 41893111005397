import React, { Fragment, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Btn, H5, H3, H6, LI, P, UL } from "../../../AbstractElements";
import {
  Col,
  Form,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  CardBody,
  Card,
  CardHeader,
  Media,
  Table,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ScrollBar from "react-perfect-scrollbar";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import awsExports from "../../../aws-exports";
import AWS from "aws-sdk";
import {
  S3_BUCKET,
  REGION,
  CATEGORIES_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
} from "../../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const AddCategories = (props) => {
  const [serviceGroup, setServiceGroup] = useState([]);
  const [formDetails, setFormDetails] = useState([]);
  const [formIds, setFormIds] = useState([]);
  const [catId, setCatId] = useState();
  const [maxNum, setmaxNum] = useState();

  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

  const [isEnable, setisEnable] = useState(true);
  const enableButton = (id) => {
    console.log(id);
    id === 0 ? setisEnable(true) : setisEnable(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const digits_count = (n) => {
    var count = 0;
    if (n >= 1) ++count;
    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }
    return count;
  };

  async function onSubmit(formData) {
    await client
      .graphql({
        query: mutations.createCategories,
        variables: {
          input: {
            categoryName: formData.categoryName,
            servicegroupID: formData.serviceGroup,
            assetNumberPrefix: formData.assetNumberPrefix,
            formDescription: formData.formDescription,
            formIds: formIds,
            imageURL: ProfilePic,
            categoryNumber: "CAT" + catId + maxNum,
            status: "Active",
          },
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        console.log(result);
        const addedsite = await client.graphql({
          query: queries.getCategories,
          variables: {
            id: result.data.createCategories.id,
          },
          authMode: "API_KEY",
        });
        if (addedsite.data.getCategories !== null) {
          let activeDB = JSON.parse(
            base64.decode(localStorage.getItem("category"))
          );
          console.log(activeDB);
          activeDB.push(addedsite.data.getCategories);
          console.log(activeDB);
          localStorage.setItem(
            "category",
            base64.encode(JSON.stringify(activeDB))
          );
        }
        toast.success("Category Saved Successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        props.categoriesData();
        props.toggler();
      });
  }

  useEffect(() => {
    console.log(props);
    async function fetchFormDetailsData() {
      await client
        .graphql({
          query: queries.listFormDetails,
          authMode: "API_KEY",
          variables: {
            limit: 10000,
          },
        })
        .then((result) => {
          console.log(result);
          const formDetails = result.data.listFormDetails.items;
          console.log(formDetails);
          const filteredFormDetails = formDetails.filter(
            (item) => item.formType !== "Asset Template"
          );
          setFormDetails(filteredFormDetails);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function fetchServiceGroups() {
      await client
        .graphql({
          query: queries.listServiceGroups,
          authMode: "API_KEY",
          variables: {
            limit: 10000,
          },
        })
        .then((result) => {
          console.log(result);
          const serviceGroups = result.data.listServiceGroups.items;
          console.log(serviceGroups);
          setServiceGroup(serviceGroups);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    async function fetchCategoriesData() {
      await client
        .graphql({
          query: queries.listCategories,
          authMode: "API_KEY",
          variables: {
            limit: 10000,
          },
        })
        .then((response) => {
          const categoriesDetails = response.data.listCategories.items;
          let idArry = [];
          let maxnumber = 0;
          categoriesDetails.map((item) => {
            if (isNaN(Number(item.categoryNumber.replace(/^\D+/g, "")))) {
              idArry.push(1);
            } else {
              idArry.push(Number(item.categoryNumber.replace(/^\D+/g, "")));
            }
          });
          maxnumber = Math.max(...idArry);
          if (idArry.length != 0) {
            if (maxnumber !== 0 && maxnumber !== null) {
              const res = digits_count(Number(maxnumber + 1));
              if (res === 1) {
                setCatId("000");
                setmaxNum(maxnumber + 1);
              } else if (res === 2) {
                setCatId("00");
                setmaxNum(maxnumber + 1);
              } else if (res === 3) {
                setCatId("0");
                setmaxNum(maxnumber + 1);
              } else if (res === 4) {
                setCatId("");
                setmaxNum(maxnumber + 1);
              }
            } else {
              setCatId("000");
              setmaxNum(1);
            }
          } else {
            setCatId("000");
            setmaxNum(1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchFormDetailsData();
    fetchServiceGroups();
    fetchCategoriesData();
  }, []);

  const getFormIds = (e) => {
    if (e.target.checked) {
      console.log(e.target.id);
      const fId = [...formIds];
      fId.push(e.target.id);
      setFormIds(fId);
    } else {
      console.log(formIds.indexOf(e.target.id));
    }
  };

  //-------------- Image Upload -------------------------------------------------------
  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const [ProfilePic, setProfilePic] = useState([]);
  const [downloadedFile, setdownloadedFile] = useState(null);
  const [selectedImg, setselectedImg] = useState(null);

  const onSelectFile = async (e) => {
    const file = e.target.files[0];
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `public/${CATEGORIES_FOLDER}/${file.name}`,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(evt);
        const filedetails = [];
        filedetails.push({ fileName: file.name, type: file.type });
        setProfilePic(JSON.stringify(filedetails));
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };
  // image retrive
  const getImage = (filename) => {
    var params = {
      Bucket: S3_BUCKET,
      Key: `public/${CATEGORIES_FOLDER}/${filename}`,
    };
    var promise = myBucket.getSignedUrlPromise("getObject", params);
    promise.then(
      function (url) {
        setdownloadedFile(url);
        setselectedImg(url);
      },
      function (err) {
        console.log(err);
      }
    );
  };
  //-------------------------------------------------------------------------
  function getfilename(path) {
    const files = JSON.parse(path);
    if (files.length > 0) {
      var newfilename = files[0].fileName;

      getImage(newfilename);
    }
  }

  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggler}
        size={props.size}
        centered
      >
        <ModalHeader toggle={props.toggler}>Add Category</ModalHeader>
        <ModalBody className={props.bodyClass}>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col md="6 mb-3">
                <Label>Category Name</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Category Name"
                  name="categoryName"
                  {...register("categoryName", { required: true })}
                />
                <span>
                  {errors.categoryName && "Please Enter Category Name."}
                </span>
              </Col>
              <Col md="6 mb-3">
                <Label>Service Group</Label>
                <select
                  className="form-control"
                  placeholder="Service Group"
                  name="serviceGroup"
                  {...register("serviceGroup", { required: true })}
                >
                  <option value="">Choose Service Group</option>
                  {serviceGroup
                    .filter((item) => item.status === "Active")
                    .map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.serviceName}
                      </option>
                    ))}
                </select>
                <span>
                  {errors.serviceGroup && "Please Select Service Group."}
                </span>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label>Prefix Text</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Prefix Text"
                  name="assetNumberPrefix"
                  {...register("assetNumberPrefix", { required: true })}
                />
                <span>
                  {errors.assetNumberPrefix && "Please Enter Prefix Text."}
                </span>
              </Col>
              <Col md="6 mb-3">
                <Label>Form Description</Label>
                <input
                  type="textarea"
                  className="form-control"
                  placeholder="Form Description"
                  name="formDescription"
                  {...register("formDescription", { required: false })}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label>Profile Picture</Label>
                <Input
                  type="file"
                  className="form-control"
                  name="profilePic"
                  onChange={onSelectFile}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12 mb-3">
                <Label>Include Forms</Label>
                <ScrollBar
                  className="vertical-scroll ps-container ps-theme-default ps-active-y"
                  style={{
                    height: "300px",
                    border: "1px solid #efefef",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  <UL>
                    {formDetails.map(
                      (item, id) => (
                        <LI key={id}>
                          <input
                            type="checkbox"
                            id={item.id}
                            name={item.id}
                            value={item.id}
                            // checked={checkedState[id]}
                            onChange={(e) => getFormIds(e)}
                            className="checkbox_animated"
                          />
                          <label htmlFor={`custom-checkbox-${id}`}>
                            {item.formName}
                          </label>
                        </LI>
                      )
                      // <Label className="d-block" for={item.id}>
                      //   <input
                      //     className="checkbox_animated"
                      //     id={item.id}
                      //     type="checkbox"
                      //     value={item.id}
                      //     onChange={e => getFormIds(e)} />
                      // </Label>
                    )}
                  </UL>
                </ScrollBar>
              </Col>
            </Row>
            <Btn attrBtn={{ color: "primary" }}>Save</Btn>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
export default AddCategories;
