import { useState, useRef, useEffect, useCallback } from "react";
import * as queries from "../../graphql/queries";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});


const useInfiniteLoading = ({ sites }) => {
  const [items, setItems] = useState([]);
  const pageToLoad = useRef(
    new URLSearchParams(window.location.search).get("page") || 1
  );
  const initialPageLoaded = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserDetailsId =
    LoginUserDetails.hasOwnProperty("id") && LoginUserDetails.id !== null
      ? LoginUserDetails.id
      : [];
  const nextTokenRef = useRef(null);
  const isLoading = useRef(false);
  const getItems = async () => {
    if (isLoading.current || !hasMore) return;
    isLoading.current = true;
    try {
      {
        /*
        const response = await client.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetailsId,
          limit: 100,
          nextToken: nextTokenRef.current,
        },
        authMode: "API_KEY",
      });
      const data = response.data.siteDetailsByAssetownerID;
      nextTokenRef.current = data.nextToken;
      setItems(prevItems => [...prevItems, ...data.items]);
      setHasMore(data.nextToken !== null);
      isLoading.current = false;
      */
      }
      let assets1 = [];
      let count = 0;
      let countnexttoken = 0;
      sites.map(async (item) => {
        count = count + 1;
        await client
          .graphql({
            query: queries.logBooksBySitedetailsID,
            variables: {
              sitedetailsID: item.id,
              limit: 100,
              nextToken: nextTokenRef.current,
            },
            authMode: "API_KEY",
          })
          .then(async (result) => {
            console.log(result);
            const data = result.data.logBooksBySitedetailsID;
            nextTokenRef.current = data.nextToken;
            setItems((prevItems) => [...prevItems, ...data.items]);
            setHasMore(data.nextToken !== null);
            isLoading.current = false;
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      isLoading.current = false;
    }
  };
  useEffect(() => {
    getItems();
  }, []);
  return {
    items,
    loadMoreItems: getItems,
    hasMore,
  };
};
export default useInfiniteLoading;
