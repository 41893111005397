import React, { Fragment, useState, useEffect } from "react";
import * as queries from "../../graphql/queries";
import { toast } from "react-toastify";
import {
  CardBody,
  CardHeader,
  Card,
  Row,
  Col,
  Media,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import TestingList from "./Testing/index";
import RepairList from "./Repair/index";
import LogBookList from "./LogBook/index";
import { H4 } from "../../AbstractElements";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const FormsListingContain = () => {
  const [tabs, setTabs] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [category, setCategory] = useState([])
  const [loadingData, setLoadingdData] = useState([]);
  const [assetId, setAssetId] = useState(0);
  const state = useLocation();
  const [Large, setLarge] = useState(false);
  const LargeModaltoggle = () => setLarge(!Large);

  useEffect(() => {
    console.log(state);
    if (state.state) {
      setAssetId(state.state.assetId);
      setCategoryId(state.state.catId);
      getCategory(state.state.catId);
    }
  }, [state.state]);

  async function getCategory(catId) {
    await client
      .graphql({
        query: queries.listCategories,
        variables: {
          filter: {
            id: {
              eq: catId,
            },
          },
          limit: 10000,
        },
      })
      .then((result) => {
        const categoryDetails = result.data.listCategories.items;
        if (categoryDetails.length > 0) {
          setCategory(categoryDetails);
          getFormDetails(categoryDetails[0].formIds);
        }
      })
      .catch((error) => {
        console.log(error);
        // toast.error("error loading AssetTemplate -> "+error.message,{
        //     position: toast.POSITION.TOP_CENTER
        // });
      });
  }

  async function getFormDetails(formIds) {
    const tabNames = [];
    formIds.map(async (id) => {
      console.log(id);
      await client
        .graphql({
          query: queries.listFormDetails,
          variables: {
            filter: {
              id: {
                eq: id,
              },
            },
            limit: 10000,
          },
        })
        .then((result) => {
          const formDetails = result.data.listFormDetails.items;
          tabNames.push({
            name: formDetails[0].formType,
            fields: formDetails[0].formFields,
          });
          console.log(tabNames);
          setTabs(tabNames);
        })
        .catch((error) => {
          console.log(error);
          // toast.error("error loading AssetTemplate -> "+error.message,{
          //     position: toast.POSITION.TOP_CENTER
          // });
        });
    });
  }

  const [primarycolorTab, setprimarycolorTab] = useState(0);

  return (
    <Col>
      <Card>
        <CardHeader>
          <Link className="text-end"></Link>
        </CardHeader>
        <CardBody>
          <Nav className="nav-primary mb-1" tabs>
            {tabs.map((tabName, index) => (
              <NavItem key={index}>
                <NavLink
                  className={primarycolorTab === index ? "active" : ""}
                  onClick={() => setprimarycolorTab(index)}
                >
                  {tabName.name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={primarycolorTab}>
            {tabs.map((tabName, index) => (
              <TabPane className="fade show" tabId={index} key={index}>
                <br></br>
                <Row>
                  <Col md="12">
                    {tabName.name === "LogBook" && (
                      <LogBookList
                        assetId={assetId}
                        fields={tabName.fields}
                        catId={categoryId}
                      />
                    )}
                    {tabName.name === "Testing" && (
                      <TestingList
                        assetId={assetId}
                        fields={tabName.fields}
                        catId={categoryId}
                      />
                    )}
                    {tabName.name === "Repair" && (
                      <RepairList
                        assetId={assetId}
                        fields={tabName.fields}
                        catId={categoryId}
                      />
                    )}
                  </Col>
                </Row>
              </TabPane>
            ))}
          </TabContent>
        </CardBody>
      </Card>
    </Col>
    // <Fragment>
    //     <Card>
    //         <CardBody>
    //             <Row>
    //                 {
    //                     category.map((item) =>
    //                         <Col md="12" key={item.id}>
    //                             <Card className="o-hidden">
    //                                 <CardBody>
    //                                     <Media className="static-widget">
    //                                     <br></br>
    //                                     <Media body><H4 id="status" attrH6={{ className: 'font-roboto' }}>{item.categoryName}</H4><br></br>
    //                                         {getFormDetails(item.formIds)}
    //                                     </Media>
    //                                     </Media>
    //                                 </CardBody>
    //                             </Card>
    //                         </Col>
    //                     )
    //                 }
    //             </Row>
    //         </CardBody>
    //     </Card>
    // </Fragment>
  );
};
export default FormsListingContain;
