import { H4, Image } from "../../../AbstractElements";
import React from "react";
import { useState } from "react";
import { Col, Row, Card, CardHeader, CardBody, Container } from "reactstrap";
import { useLocation } from "react-router-dom";
import imggao from "../../../assets/images/logo/KYASP logo.png";
import SiteDetails from "../Registration/SiteDetails";
import BillingPlan from "../Registration/BillingPlan";
import RegisterFrom from "../../Pages/Auth/RegisterFrom";
import CompanyDetailsSP from "./CompanyDetails";
import Aosp from "./PersonalDetails";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const AoSpRegistration = () => {
  const { state } = useLocation();
  const [pageData, setPageData] = useState({
    step: 1,
    aoPic: "",
    CompanyId: "",
    CompanyName: "",
    CompanyLogo: "",
    ManagerName: "",
    BusinessNumber: "",
    CompanyAddress: "",
    MobileNumber: "",
    EmailManager: "",
    FirstName: "",
    LastName: "",
    MObileAssetOwner: "",
    SiteIds: "",
    siteName: "",
    UnitNumber: "",
    distributionBoxName: "",
    distributionBoxLocation: "",
    AssetOwnerId: "",
    PaymentId: "",
    Aomail: "",
    UserId: "",
    siteDetails: [],
    Email: "",
    compEmail: "",

    firstName: "",
    lastName: "",
    spemail: "",
    spmobileNumber: "",
    splicence: "",
    spProfilepic: "",
    CompanyNameSP: "",
    businessNumber: "",
    companyaddress: "",
    licencenumb: "",
    phone: "",
    website: "",
    EC: "",
    FAX: "",
    logo: "",
    CompanyIdSelected: "",
    companySelected: "",
  });
  const nextStep = () => {
    const {
      step,
      CompanyId,
      aoPic,
      CompanyName,
      CompanyLogo,
      ManagerName,
      BusinessNumber,
      MobileNumber,
      EmailManager,
      CompanyAddress,
      FirstName,
      LastName,
      MObileAssetOwner,
      SiteIds,
      siteName,
      UnitNumber,
      distributionBoxName,
      distributionBoxLocation,
      AssetOwnerId,
      Aomail,
      PaymentId,
      UserId,
      siteDetails,
      Email,
      firstName,
      lastName,
      spemail,
      spmobileNumber,
      splicence,
      spProfilepic,
      userDetails,
      CompanyNameSP,
      businessNumber,
      companyaddress,
      licencenumb,
      compEmail,
      phone,
      website,
      EC,
      FAX,
      logo,
      CompanyIdSelected,
      companySelected,
    } = pageData;
    setPageData({
      step: step + 1,
      CompanyId: CompanyId,
      aoPic: aoPic,
      CompanyName: CompanyName,
      CompanyLogo: CompanyLogo,
      ManagerName: ManagerName,
      BusinessNumber: BusinessNumber,
      MobileNumber: MobileNumber,
      EmailManager: EmailManager,
      CompanyAddress: CompanyAddress,
      FirstName: FirstName,
      LastName: LastName,
      MObileAssetOwner: MObileAssetOwner,
      SiteIds: SiteIds,
      siteName: siteName,
      UnitNumber: UnitNumber,
      distributionBoxName: distributionBoxName,
      distributionBoxLocation: distributionBoxLocation,
      AssetOwnerId: AssetOwnerId,
      Aomail: Aomail,
      PaymentId: PaymentId,
      UserId: UserId,
      siteDetails: siteDetails,
      Email: Email,
      firstName: firstName,
      lastName: lastName,
      spemail: spemail,
      spmobileNumber: spmobileNumber,
      splicence: splicence,
      spProfilepic: spProfilepic,
      CompanyId: CompanyId,
      CompanyNameSP: CompanyNameSP,
      userDetails: userDetails,
      businessNumber: businessNumber,
      companyaddress: companyaddress,
      PaymentId: PaymentId,
      licencenumb: licencenumb,
      phone: phone,
      website: website,
      EC: EC,
      FAX: FAX,
      logo: logo,
      compEmail: compEmail,
      companySelected: companySelected,
      CompanyIdSelected: CompanyIdSelected,
    });
  };
  // Go to prev step
  const prevStep = () => {
    const {
      step,
      CompanyId,
      aoPic,
      CompanyName,
      CompanyLogo,
      ManagerName,
      BusinessNumber,
      MobileNumber,
      EmailManager,
      CompanyAddress,
      FirstName,
      LastName,
      MObileAssetOwner,
      SiteIds,
      siteName,
      compEmail,
      UnitNumber,
      distributionBoxName,
      distributionBoxLocation,
      AssetOwnerId,
      PaymentId,
      Aomail,
      UserId,
      siteDetails,
      Email,
      firstName,
      lastName,
      spemail,
      spmobileNumber,
      splicence,
      spProfilepic,
      userDetails,
      CompanyNameSP,
      businessNumber,
      companyaddress,
      licencenumb,
      phone,
      website,
      EC,
      FAX,
      logo,
      CompanyIdSelected,
      companySelected,
    } = pageData;
    setPageData({
      step: step - 1,
      CompanyId: CompanyId,
      aoPic: aoPic,
      CompanyName: CompanyName,
      CompanyLogo: CompanyLogo,
      ManagerName: ManagerName,
      BusinessNumber: BusinessNumber,
      MobileNumber: MobileNumber,
      EmailManager: EmailManager,
      CompanyAddress: CompanyAddress,
      FirstName: FirstName,
      LastName: LastName,
      MObileAssetOwner: MObileAssetOwner,
      SiteIds: SiteIds,
      siteName: siteName,
      UnitNumber: UnitNumber,
      distributionBoxName: distributionBoxName,
      distributionBoxLocation: distributionBoxLocation,
      AssetOwnerId: AssetOwnerId,
      Aomail: Aomail,
      PaymentId: PaymentId,
      UserId: UserId,
      siteDetails: siteDetails,
      Email: Email,
      firstName: firstName,
      lastName: lastName,
      spemail: spemail,
      spmobileNumber: spmobileNumber,
      splicence: splicence,
      spProfilepic: spProfilepic,
      userDetails: userDetails,
      CompanyNameSP: CompanyNameSP,
      businessNumber: businessNumber,
      companyaddress: companyaddress,
      PaymentId: PaymentId,
      licencenumb: licencenumb,
      phone: phone,
      website: website,
      EC: EC,
      FAX: FAX,
      logo: logo,
      compEmail: compEmail,
      companySelected: companySelected,
      CompanyIdSelected: CompanyIdSelected,
    });
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xl="12">
          <div className="login-card">
            <Card>
              <CardHeader>
                <div className="login-header text-center">
                  <div className="logo-wrapper">
                    <a href="#javascript">
                      <Image
                        attrImage={{
                          className: "img-fluid",
                          src: `${imggao}`,
                          alt: "",
                        }}
                      />
                    </a>
                  </div>
                  <H4>Create your account</H4>
                </div>
              </CardHeader>
              <CardBody>
                {pageData.step === 1 && (
                  <CompanyDetailsSP nextStep={nextStep} values={pageData} />
                )}
                {pageData.step === 2 && (
                  <Aosp
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )}
                {pageData.step === 3 && (
                  <SiteDetails
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )}
                {pageData.step === 4 && (
                  <BillingPlan
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )}
                {/*
                                {pageData.step === 5 && (
                  <CompanyDetailsSP
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )}
                {pageData.step === 6 && (
                  <SpContain
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )}
              */}
                {pageData.step === 5 && (
                  <RegisterFrom
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )}
                {/* {pageData.step === 5 && (
                  <PaymentOption
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )} */}
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AoSpRegistration;
