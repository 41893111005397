import { Btn, H4, H5, H1, H2 } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Container,
  Col,
  Form,
  Label,
  Row,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Input,
  TabPane,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useForm } from "react-hook-form";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import awsExports from "../../../aws-exports";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  S3_BUCKET,
  REGION,
  Reports,
  SECRETACCESSKEY,
  ACCESSKEYID,
} from "../../../Config/Config";
import Select from "react-select";
import { aocontext } from "../../../App";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const AddReport = () => {
  const navigate = useNavigate();
  const state = useLocation();
  console.log(state);

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    getValues,
    ...form
  } = useForm();
  const [siteDetails, setSiteDetails] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [certificateUrl, setCertificateUrl] = useState([]);
  const [certificateUrlError, setCertificateUrlError] = useState(false);
  const [siteId, setSiteId] = useState([]);
  const [siteIdvalue, setSiteIdvalue] = useState("");
  const LoginUserGroup = localStorage.getItem("groups");
  const [fileSelected, setFileSelected] = useState(true);
  const [siteselected, setSiteselected] = useState(true);
  const [dbselected, setDBselected] = useState(true);
  const [distributionBoxList, setDistributionBoxList] = useState([]);
  const [distributionBoxFilter, setDistributionBoxfilter] = useState([]);
  const [selectedSearchDBData, setSelectedSearchDBData] = useState("");
  const [selectedBox, setSelectedBox] = useState("");

  const [reportsDB, setReportsDB] = useState([]);

  const { selectedAssetOwnerGlobal, handleAssetOwner } = useContext(aocontext);

  useEffect(() => {
    console.log(state.state);
    fetchDb();
    fetchReports();
    if (LoginUserGroup === "site_user") {
      fetchSiteDetailsSU();
    } else if (LoginUserGroup === "electrician") {
      fetchSiteDetailsSP();
    } else {
      fetchSiteDetails();
    }

    async function fetchSiteDetails() {
      //--
      let nextToken = "";
      let assets = [];
      await client
        .graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: 2000000,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.siteDetailsByAssetownerID.items;
          nextToken = result.data.siteDetailsByAssetownerID.nextToken;
          console.log(
            "first token count",
            result.data.siteDetailsByAssetownerID.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.siteDetailsByAssetownerID.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.siteDetailsByAssetownerID,
                variables: {
                  assetownerID: LoginUserDetails.id,
                  limit: 2000000,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.siteDetailsByAssetownerID.nextToken;
                console.log("nextToken", nextToken);
                console.log(
                  results.data.siteDetailsByAssetownerID.items.length
                );
                if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                  assets.push(results.data.siteDetailsByAssetownerID.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            if (mergeAssets.length > 0) {
              console.log("length greater than 0");
              setSiteDetails(mergeAssets);
            } else {
              setSiteDetails([]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function fetchSiteDetailsSP() {
      let nextToken = "";
      let assets = [];
      await client
        .graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: LoginUserDetails.id,
            limit: 200000,
          },
        })
        .then(async (result) => {
          let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
          nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
          console.log(
            "first token count",
            result.data.linkUsersAndSitesByUserdetailsID.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.linkUsersAndSitesByUserdetailsID,
                authMode: "API_KEY",
                variables: {
                  userdetailsID: LoginUserDetails.id,
                  limit: 200000,
                  nextToken: nextToken,
                },
              })
              .then((results) => {
                nextToken =
                  results.data.linkUsersAndSitesByUserdetailsID.nextToken;
                console.log("nextToken", nextToken);
                console.log(
                  results.data.linkUsersAndSitesByUserdetailsID.items.length
                );
                if (
                  results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
                ) {
                  assets.push(
                    results.data.linkUsersAndSitesByUserdetailsID.items
                  );
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            if (mergeAssets.length > 0) {
              let nextToken1 = "";
              let assets1 = [];
              await client
                .graphql({
                  query: queries.siteDetailsByAssetownerID,
                  variables: {
                    assetownerID: LoginUserDetails.id,
                    limit: 2000000,
                  },
                  authMode: "API_KEY",
                })
                .then(async (result) => {
                  let assetDatas = result.data.siteDetailsByAssetownerID.items;
                  nextToken1 = result.data.siteDetailsByAssetownerID.nextToken;
                  console.log(
                    "first token count",
                    result.data.siteDetailsByAssetownerID.items.length
                  );
                  console.log("nextToken 1", nextToken1);
                  if (result.data.siteDetailsByAssetownerID.items.length > 0) {
                    assets1.push(assetDatas);
                  }
                  while (nextToken1 !== null) {
                    await client
                      .graphql({
                        query: queries.siteDetailsByAssetownerID,
                        variables: {
                          assetownerID: LoginUserDetails.id,
                          limit: 2000000,
                          nextToken: nextToken1,
                        },
                        authMode: "API_KEY",
                      })
                      .then((results) => {
                        nextToken1 =
                          results.data.siteDetailsByAssetownerID.nextToken;
                        console.log("nextToken", nextToken1);
                        console.log(
                          results.data.siteDetailsByAssetownerID.items.length
                        );
                        if (
                          results.data.siteDetailsByAssetownerID.items.length >
                          0
                        ) {
                          assets1.push(
                            results.data.siteDetailsByAssetownerID.items
                          );
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                  console.log(assets1);
                  if (assets1.length > 0) {
                    const mergeAssets1 = assets1.flat(1);
                    console.log(mergeAssets1);
                    //--
                    if (mergeAssets1.length > 0) {
                      const siteDetailsu = mergeAssets1.filter((item) => {
                        const _matchingAssetTemplate = siteDetails?.find(
                          (site) => site.sitedetailsID === item?.id
                        );
                        if (_matchingAssetTemplate) {
                          return item;
                        }
                      });
                      const data = siteDetailsu.filter(
                        (item) => item.assetownerID === selectedAssetOwnerGlobal
                      );
                      const filterdata = selectedAssetOwnerGlobal
                        ? data
                        : siteDetailsu;
                      console.log(filterdata);
                      setSiteDetails(filterdata);
                    } else {
                      setSiteDetails([]);
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              setSiteDetails([]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function fetchSiteDetailsSU() {
      let nextToken = "";
      let assets = [];
      await client
        .graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: LoginUserDetails.id,
            limit: 200000,
          },
        })
        .then(async (result) => {
          let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
          nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
          console.log(
            "first token count",
            result.data.linkUsersAndSitesByUserdetailsID.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.linkUsersAndSitesByUserdetailsID,
                authMode: "API_KEY",
                variables: {
                  userdetailsID: LoginUserDetails.id,
                  limit: 200000,
                  nextToken: nextToken,
                },
              })
              .then((results) => {
                nextToken =
                  results.data.linkUsersAndSitesByUserdetailsID.nextToken;
                console.log("nextToken", nextToken);
                console.log(
                  results.data.linkUsersAndSitesByUserdetailsID.items.length
                );
                if (
                  results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
                ) {
                  assets.push(
                    results.data.linkUsersAndSitesByUserdetailsID.items
                  );
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            if (mergeAssets.length > 0) {
              let nextToken1 = "";
              let assets1 = [];
              await client
                .graphql({
                  query: queries.siteDetailsByAssetownerID,
                  variables: {
                    assetownerID: LoginUserDetails.id,
                    limit: 2000000,
                  },
                  authMode: "API_KEY",
                })
                .then(async (result) => {
                  let assetDatas = result.data.siteDetailsByAssetownerID.items;
                  nextToken1 = result.data.siteDetailsByAssetownerID.nextToken;
                  console.log(
                    "first token count",
                    result.data.siteDetailsByAssetownerID.items.length
                  );
                  console.log("nextToken 1", nextToken1);
                  if (result.data.siteDetailsByAssetownerID.items.length > 0) {
                    assets1.push(assetDatas);
                  }
                  while (nextToken1 !== null) {
                    await client
                      .graphql({
                        query: queries.siteDetailsByAssetownerID,
                        variables: {
                          assetownerID: LoginUserDetails.id,
                          limit: 2000000,
                          nextToken: nextToken1,
                        },
                        authMode: "API_KEY",
                      })
                      .then((results) => {
                        nextToken1 =
                          results.data.siteDetailsByAssetownerID.nextToken;
                        console.log("nextToken", nextToken1);
                        console.log(
                          results.data.siteDetailsByAssetownerID.items.length
                        );
                        if (
                          results.data.siteDetailsByAssetownerID.items.length >
                          0
                        ) {
                          assets1.push(
                            results.data.siteDetailsByAssetownerID.items
                          );
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                  console.log(assets1);
                  if (assets1.length > 0) {
                    const mergeAssets1 = assets1.flat(1);
                    console.log(mergeAssets1);
                    //--
                    if (mergeAssets1.length > 0) {
                      const siteDetailsu = mergeAssets1.filter((item) => {
                        const _matchingAssetTemplate = siteDetails?.find(
                          (site) => site.sitedetailsID === item?.id
                        );
                        if (_matchingAssetTemplate) {
                          return item;
                        }
                      });
                      setSiteDetails(siteDetailsu);
                    } else {
                      setSiteDetails([]);
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              setSiteDetails([]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedAssetOwnerGlobal]);

  async function fetchReports() {
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listReports,
        variables: {
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.listReports.items;
        nextToken = result.data.listReports.nextToken;
        console.log("first token count", result.data.listReports.items.length);
        console.log("nextToken 1", nextToken);
        if (result.data.listReports.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listReports,
              variables: {
                limit: 2000000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listReports.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.listReports.items.length);
              if (results.data.listReports.items.length > 0) {
                assets.push(results.data.listReports.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setReportsDB(mergeAssets);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function onSubmit(formData) {
    console.log(fileSelected);
    console.log(fileList);
    const certificateUrl = fileList.map((file) => ({
      fileName: file.name,
      type: file.type,
    }));

    console.log(certificateUrl);
    onSelectFilemultiple(fileList);

    let dupLicence = "";
    console.log(selectedBox);
    if (selectedBox) {
      dupLicence = reportsDB.filter(
        (company) => company.distributionBoxId === selectedBox
      );
      //setDBselected(true);
    }

    if (dupLicence.length === 0) {
      if (siteIdvalue === "" && fileList.length === 0 && selectedBox === "") {
        setFileSelected(false);
        setSiteselected(false);
        setDBselected(false);
      }
      if (fileList.length > 0) {
        setFileSelected(true);

        if (siteIdvalue !== "") {
          setSiteselected(true);

          if (selectedBox !== "") {
            setDBselected(true);

            console.log(state.state.category);
            console.log("formData", formData);
            console.log("certificateUrl", certificateUrl);
            console.log("LoginUserDetails.id", LoginUserDetails.id);
            try {
              await client
                .graphql({
                  query: mutations.createReports,
                  variables: {
                    input: {
                      reportURL: JSON.stringify(certificateUrl),
                      reportName: formData?.reportName,
                      categoriesID: state.state.category.id,
                      sitedetailsID:
                        state.state && state.state.site && state.state.category
                          ? state.state.site.id
                          : siteId,
                      distributionBoxId: selectedBox,
                      userID: LoginUserDetails.id,
                    },
                  },
                  authMode: "API_KEY",
                })
                .then((result) => {
                  console.log("result", result);
                  toast.success("Successfully Uploaded Reports.", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  if (state.state.category && state.state.site) {
                    navigate(`${process.env.PUBLIC_URL}/thermalReport`, {
                      state: {
                        category: state.state.category,
                        site: state.state.site,
                      },
                    });
                  } else {
                    navigate(`${process.env.PUBLIC_URL}/thermalReport`, {
                      state: {
                        category: state.state.category,
                      },
                    });
                  }
                })
                .catch((error) => {
                  toast.error("Error While Uploading Reports : " + error, {
                    position: toast.POSITION.TOP_CENTER,
                  });
                });
            } catch (e) {
              toast.error("Error While Uploading Reports : " + e.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          } else {
            setDBselected(false);
          }
        } else {
          setSiteselected(false);
        }
      } else if (siteselected !== "") {
        setSiteselected(true);

        if (selectedBox !== "") {
          setDBselected(true);

          if (fileList.length > 0) {
            setFileSelected(true);

            console.log(state.state.category);
            console.log("formData", formData);
            console.log("certificateUrl", certificateUrl);
            console.log("LoginUserDetails.id", LoginUserDetails.id);
            try {
              await client
                .graphql({
                  query: mutations.createReports,
                  variables: {
                    input: {
                      reportURL: JSON.stringify(certificateUrl),
                      reportName: formData?.reportName,
                      categoriesID: state.state.category.id,
                      sitedetailsID:
                        state.state && state.state.site && state.state.category
                          ? state.state.site.id
                          : siteId,
                      distributionBoxId: selectedBox,
                      userID: LoginUserDetails.id,
                    },
                  },
                  authMode: "API_KEY",
                })
                .then((result) => {
                  console.log("result", result);
                  toast.success("Successfully Uploaded Reports.", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  if (state.state.category && state.state.site) {
                    navigate(`${process.env.PUBLIC_URL}/thermalReport`, {
                      state: {
                        category: state.state.category,
                        site: state.state.site,
                      },
                    });
                  } else {
                    navigate(`${process.env.PUBLIC_URL}/thermalReport`, {
                      state: {
                        category: state.state.category,
                      },
                    });
                  }
                })
                .catch((error) => {
                  toast.error("Error While Uploading Reports : " + error, {
                    position: toast.POSITION.TOP_CENTER,
                  });
                });
            } catch (e) {
              toast.error("Error While Uploading Reports : " + e.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          } else {
            setFileSelected(false);
          }
        } else {
          setDBselected(false);
        }
      } else {
        setFileSelected(false);
      }
    } else {
      toast.error(
        "Report is already there for the selected Distribution Board",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  }

  const cancelForm = () => {
    if (state.state.category && state.state.site) {
      navigate(`${process.env.PUBLIC_URL}/thermalReport`, {
        state: {
          category: state.state.category,
          site: state.state.site,
        },
      });
    } else {
      navigate(`${process.env.PUBLIC_URL}/thermalReport`, {
        state: {
          category: state.state.category,
        },
      });
    }
  };

  // Image Upload
  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const [fileList, setFileList] = useState([]);
  const onSelectFile = (e) => {
    const selectedFiles = Array.from(e.target.files);
    console.log(selectedFiles);
    setFileList((prevFileList) => [...prevFileList, ...selectedFiles]);
    setCertificateUrlError(false);
  };

  const onSelectFilemultiple = (files) => {
    try {
      const filedetails = [];

      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        const params = {
          ACL: "public-read",
          Body: file,
          Bucket: S3_BUCKET,
          Key: `public/${Reports}/${file.name}`,
        };

        myBucket
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log(evt);
            filedetails.push({ fileName: file.name, type: file.type });
            console.log(filedetails);
            //setCertificateUrl(filedetails);
          })
          .send((err) => {
            if (err) console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const optionsSitesOpen = [
    { value: "", label: "Select Site" },
    ...siteDetails.map((item) => ({
      value: item.id,
      label:
        item.siteName +
        " " +
        "(" +
        (item.unitNumber ? item.unitNumber : "") +
        ")",
    })),
  ];

  const handleSite = (event) => {
    console.log(event.value);
    setSiteId(event.value);
    setSiteIdvalue(event);
    setSiteselected(true);
  };

  const handleSiteDB = (selectedOption) => {
    setSelectedBox(selectedOption.value);
    setSelectedSearchDBData(selectedOption);
    setDBselected(true);
  };

  async function fetchDb() {
    //-
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listDistributionBoxes,
        variables: {
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.listDistributionBoxes.items;
        nextToken = result.data.listDistributionBoxes.nextToken;
        console.log(
          "first token count",
          result.data.listDistributionBoxes.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listDistributionBoxes.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listDistributionBoxes,
              variables: {
                limit: 2000000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listDistributionBoxes.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.listDistributionBoxes.items.length);
              if (results.data.listDistributionBoxes.items.length > 0) {
                assets.push(results.data.listDistributionBoxes.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          mergeAssets.length > 0
            ? setDistributionBoxList(mergeAssets)
            : setDistributionBoxList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const optionsDB = [
    { value: "", label: "All Distribution Board" },
    ...distributionBoxFilter.map((item) => ({
      value: item.id,
      label: item.distributionBoxName,
    })),
  ];

  useEffect(() => {
    console.log("selected Site");
    if (siteId !== "") {
      setDistributionBoxfilter(
        distributionBoxList.filter((item) => {
          return item.sitedetailsID === siteId;
        })
      );
    } else {
      setDistributionBoxfilter(distributionBoxList);
    }
  }, [siteId]);

  return (
    <Fragment>
      <br></br>
      <Container fluid={true} style={{ paddingLeft: "0px !important" }}>
        <H1>Thermal Imaging</H1>
        <Card>
          <CardBody>
            <Form
              className="needs-validation"
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col md="12">
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Report Name</Label>
                      <input
                        type="text"
                        className="form-control"
                        name="reportName"
                        {...register("reportName", { required: true })}
                      />
                      <span>
                        {errors.reportName && "Report Name is required"}
                      </span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Sites</Label>
                      {state.state &&
                      state.state.site &&
                      state.state.category ? (
                        <>
                          <select
                            className="form-control"
                            name="site"
                            placeholder="Sites"
                            value={
                              state.state &&
                              state.state.site &&
                              state.state.category
                                ? state.state.site.id
                                : siteId
                            }
                            readonly={
                              state.state &&
                              state.state.site &&
                              state.state.category
                                ? true
                                : false
                            }
                            {...register("site", {
                              required:
                                state.state &&
                                state.state.site &&
                                state.state.category
                                  ? false
                                  : true,
                            })}
                            onChange={(event) => setSiteId(event.target.value)}
                          >
                            <option value="">Select Sites</option>
                            {siteDetails.map((item) => (
                              <option value={item.id} key={item.id}>
                                {(item?.siteName || "") +
                                  " (" +
                                  (item?.unitNumber || "") +
                                  ")"}
                              </option>
                            ))}
                          </select>

                          <span>{errors.site && "Site is required"}</span>
                        </>
                      ) : (
                        <>
                          <Select
                            options={optionsSitesOpen}
                            isSearchable={true}
                            value={siteIdvalue}
                            placeholder="Select Sites"
                            onChange={handleSite}
                            name="site"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />

                          <span>{!siteselected && "Site is required"}</span>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Distribution Board</Label>
                      <Select
                        value={selectedSearchDBData}
                        onChange={handleSiteDB}
                        options={optionsDB}
                        isSearchable={true}
                        hideSelectedOptions={false}
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                      <span>
                        {!dbselected && "Distribution Board is required"}
                      </span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Report</Label>
                      <input
                        type="file"
                        className="form-control"
                        name="certificateUrl"
                        onChange={onSelectFile}
                        multiple
                      />
                      <span>{!fileSelected && "Report is required"}</span>
                    </Col>
                  </Row>
                </Col>
                <Col md="12" className="text-end">
                  <Btn attrBtn={{ color: "primary" }}>Upload Report</Btn>
                  <Btn attrBtn={{ color: "danger", onClick: cancelForm }}>
                    Cancel
                  </Btn>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};
export default AddReport;
