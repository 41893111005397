import React, { Fragment, useState, useEffect, useCallback } from "react";
import { H5, H4, H6, P, Btn } from "../../AbstractElements";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import { PlusCircle } from "react-feather";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { FormGroup, Label, InputGroupAddon, InputGroupText } from "reactstrap";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import uuid from "react-native-uuid";
import { Link, useParams } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import AWS from "aws-sdk";
import { useNavigate, useLocation } from "react-router-dom";
import {
  S3_BUCKET,
  REGION,
  COMPANY_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
} from "../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const ViewCompanyDetailsContain = () => {
  const navigate = useNavigate();
  const { view } = useParams();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [viewCompanyDetails, setviewCompanyDetails] = useState([]);
  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });
  const [companyAdress, setCompanyAdress] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [downloadedFile, setdownloadedFile] = useState(null);
  const [siteAdressArray, setsiteAdressArray] = useState([]);
  const [logo, setlogo] = useState("");
  //-----------------------------------------------------------------------------
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    const filedetails = [];
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `public/${COMPANY_FOLDER}/${file.name}`,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        filedetails.push({ fileName: file.name, type: file.type });
        setSelectedFile(filedetails);
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };
  //-----------------------------------------------------------------------------
  const getImage = (filename) => {
    var params = {
      Bucket: S3_BUCKET,
      Key: `public/${COMPANY_FOLDER}/${filename}`,
    };
    var promise = myBucket.getSignedUrlPromise("getObject", params);
    promise.then(
      function (url) {
        setdownloadedFile(url);
        //setselectedImg(url);
      },
      function (err) {
        console.log(err);
      }
    );
  };
  //-------------------------------------------------------------------------
  function getfilename(path) {
    try {
      console.log(path);
      //const files = JSON.parse(path);
      //console.log(files)
      if (path.length > 0) {
        var newfilename = path[0].fileName;
        //console.log(newfilename)
        getImage(path);
      }
    } catch (error) {
      console.log("Error parsing JSON input:", error);
    }
  }
  {
    /*
  const getImage = (filename) => {
    var params = {
      Bucket: S3_BUCKET,
      Key: `public/${COMPANY_FOLDER}/${filename}`,
    };
    var promise = myBucket.getSignedUrlPromise("getObject", params);
    promise.then(
      function (url) {
        setdownloadedFile(url);
      },
      function (err) {
        console.log(err);
      }
    );
  };
  //-----------------------------------------------------------------------------
  function getfilename(path) {
    try {
      const files = JSON.parse(path);
      if (files.length > 0) {
        var newfilename = files[0].fileName;
        getImage(newfilename);
      }
    } catch (error) {
      console.log("Error parsing JSON input:", error);
    }
  }
*/
  }

  //----------------------------List--------------------------------------
  useEffect(() => {
    if (id) {
      async function listCompanyDetails() {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const companyId = userDetails.companyDetailsId;
        const dataDetails = await client.graphql({
          query: queries.listCompanyDetails,
          variables: {
            limit: 10000,
          },
          authMode: "API_KEY",
        });
        const dataDetailsCompany =
          dataDetails.data.listCompanyDetails.items.filter(
            (item) => item.id === id
          );
        setviewCompanyDetails({ ...dataDetailsCompany[0] });
        console.log(dataDetailsCompany);
        //getfilename(dataDetailsCompany[0].logo);
        reset({ ...dataDetailsCompany[0] });
        setlogo(dataDetailsCompany[0].logo);
        setCompanyAdress(dataDetailsCompany[0].companyAddress);

        console.log(viewCompanyDetails);
        console.log("ccmmm adresss..");
      }
      listCompanyDetails();
    }
  }, [id, reset]);
  //address...............................
  const onPlaceChanged = (detailAddress) => {
    console.log(detailAddress);
    let fullAddress1 = [];
    for (let i = 0; i < detailAddress.length; i++) {
      if (detailAddress[i].types.includes("postal_code")) {
        fullAddress1["postcode"] = detailAddress[i].long_name;
      }
      if (detailAddress[i].types.includes("locality")) {
        fullAddress1["suburb"] = detailAddress[i].long_name;
      }
      if (detailAddress[i].types.includes("administrative_area_level_1")) {
        fullAddress1["state"] = detailAddress[i].long_name;
      }
      if (detailAddress[i].types.includes("administrative_area_level_2")) {
        fullAddress1["street"] = detailAddress[i].long_name;
      }
      if (detailAddress[i].types.includes("country")) {
        fullAddress1["country"] = detailAddress[i].long_name;
      }
    }
    setsiteAdressArray(fullAddress1);
    setCompanyAdress(
      siteAdressArray.hasOwnProperty("street")
        ? siteAdressArray.street
        : "" + "," + siteAdressArray.hasOwnProperty("state")
        ? siteAdressArray.state
        : "" + "," + siteAdressArray.hasOwnProperty("postcode")
        ? siteAdressArray.postcode
        : "" + "," + siteAdressArray.hasOwnProperty("country")
        ? siteAdressArray.country
        : ""
    );
  };
  //----------------------------add--edit------------------------------------
  const onSubmit = useCallback((data, e) => {
    if (id) {
      console.log(siteAdressArray);
      console.log(siteAdressArray.length);
      const dataDetails = client
        .graphql({
          query: mutations.updateCompanyDetails,
          variables: {
            input: {
              id: id,
              companyName: data.companyName,
              companyBN: data.companyBN,
              companyAddress: siteAdressArray.hasOwnProperty("country")
                ? (siteAdressArray.hasOwnProperty("suburb")
                    ? siteAdressArray.suburb
                    : "") +
                  "," +
                  (siteAdressArray.hasOwnProperty("street")
                    ? siteAdressArray.street
                    : "") +
                  "," +
                  (siteAdressArray.hasOwnProperty("state")
                    ? siteAdressArray.state
                    : "") +
                  "," +
                  (siteAdressArray.hasOwnProperty("postcode")
                    ? siteAdressArray.postcode
                    : "") +
                  "," +
                  (siteAdressArray.hasOwnProperty("country")
                    ? siteAdressArray.country
                    : "")
                : viewCompanyDetails.companyAddress,

              managerName: data.managerName,
              email: data.email,
              mobile: data.mobile,
              logo: selectedFile ? selectedFile : viewCompanyDetails.logo,
              street: siteAdressArray.hasOwnProperty("street")
                ? siteAdressArray.street
                : viewCompanyDetails.street,
              suburb: siteAdressArray.hasOwnProperty("suburb")
                ? siteAdressArray.suburb
                : viewCompanyDetails.suburb,
              state: siteAdressArray.hasOwnProperty("state")
                ? siteAdressArray.state
                : viewCompanyDetails.state,
              postcode: siteAdressArray.hasOwnProperty("postcode")
                ? siteAdressArray.postcode
                : viewCompanyDetails.postcode,
              country: siteAdressArray.hasOwnProperty("country")
                ? siteAdressArray.country
                : viewCompanyDetails.country,
            },
          },
          authMode: "API_KEY",
        })
        .then((response) => {
          console.log(response);
          e.target.reset();
          toast.success(
            data.companyName + " Company Details Updated Succesfully!..",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
          navigate(`${process.env.PUBLIC_URL}/details`);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error while deleting " + data.companyName, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  });
  //-----------------------------------------Display------------------------------------------
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <Row>
                <CardHeader>
                  <H4>Company Details</H4>
                  <Row>
                    <Col md="6">
                      {view && (
                        <Link float="right" to={`/viewdetails/${id}`}>
                          <i className="fa fa-edit "></i> Edit{" "}
                        </Link>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <br></br>
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Company Name</Label>
                      {view ? (
                        <p>{viewCompanyDetails.companyName}</p>
                      ) : (
                        <input
                          className="form-control"
                          name="companyName"
                          type="text"
                          placeholder="Company Name"
                          {...register("companyName", { required: true })}
                        />
                      )}
                      <span>
                        {errors.companyName && "Company Name is required"}
                      </span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Business Number</Label>
                      {view ? (
                        <p>{viewCompanyDetails.companyBN}</p>
                      ) : (
                        <input
                          className="form-control"
                          name="companyBN"
                          type="text"
                          placeholder="Business Number"
                          {...register("companyBN", { required: true })}
                        />
                      )}
                      <span>
                        {errors.companyBN && "Business Number is required"}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Address</Label>
                      {view ? (
                        <p>{viewCompanyDetails.companyAddress}</p>
                      ) : (
                        //<GooglePlacesAutocomplete
                        //  apiKey="AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4"
                        //  className="form-control"
                        //  selectProps={{
                        //    fetchDetails: true,
                        //    onChange: (data, details, place) => {
                        //setAddress(data.label);
                        //      console.log("details", details);
                        //    },
                        //  }}
                        //  onPlaceSelected={(place) => {
                        //    console.log(place);
                        //    const address = place.address_components;
                        //    onPlaceChanged(address);
                        //  }}
                        //  options={{
                        //    types: ["address"],
                        //    componentRestrictions: { country: "AU" },
                        //  }}
                        //  defaultValue={companyAdress}
                        //  placeholder="Company Address"
                        //  {...register("companyAddress", { required: false })}
                        ///>

                        <Autocomplete
                          apiKey="AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4"
                          className="form-control"
                          onPlaceSelected={(place) => {
                            console.log(place);
                            const address = place.address_components;
                            onPlaceChanged(address);
                          }}
                          options={{
                            types: ["address"],
                            componentRestrictions: { country: "AU" },
                          }}
                          name="companyAddress"
                          placeholder="Company Address"
                          defaultValue={companyAdress}
                        />
                      )}
                      <span>
                        {errors.companyAddress &&
                          "Company Address  is required"}
                      </span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Manager Name</Label>
                      {view ? (
                        <p>{viewCompanyDetails.managerName}</p>
                      ) : (
                        <InputGroup>
                          <input
                            className="form-control"
                            name="managerName"
                            type="text"
                            placeholder="Manager Name"
                            {...register("managerName", { required: true })}
                          />
                          <span>
                            {errors.managerName && "Manager name is required"}
                          </span>
                        </InputGroup>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label>E-Mail</Label>
                      {view ? (
                        <p>{viewCompanyDetails.email}</p>
                      ) : (
                        <InputGroup>
                          <InputGroupText>{"@"}</InputGroupText>
                          <input
                            className="form-control"
                            name="email"
                            type="email"
                            placeholder=" E-mail"
                            {...register("email", { required: true })}
                          />
                          <span>
                            {errors.email && "E-mail Address is required"}
                          </span>
                        </InputGroup>
                      )}
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Mobile Number</Label>
                      {view ? (
                        <p>{viewCompanyDetails.mobile}</p>
                      ) : (
                        <input
                          className="form-control"
                          name="mobile"
                          type="Number"
                          placeholder="Mobile number"
                          {...register("mobile", { required: true })}
                        />
                      )}
                      <span>
                        {errors.mobile &&
                          "Please provide a valid Mobile Number"}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-3">
                      <Label>Company Logo</Label>
                      <br></br>

                      {getfilename(logo)}
                      <br></br>
                      {!view && (
                        <input
                          className="form-control"
                          name="logo"
                          type="file"
                          placeholder="Logo"
                          onChange={handleFileInput}
                        />
                      )}
                      {/* {view && <img src={downloadedFile} width="150px" height="150px"  />}  */}
                      <br></br>

                      {downloadedFile ? (
                        <img
                          src={downloadedFile}
                          width="150px"
                          height="150px"
                        />
                      ) : (
                        "No Logo"
                      )}

                      {/* { fileStatus &&
                        <img src={selectedImg} width="150px" height="150px" alt="  No Logo " /> 
                      } */}
                    </Col>
                  </Row>
                  {!view && (
                    <Btn attrBtn={{ color: "primary" }}>
                      {" "}
                      {!id ? "" : "Update Company Details"}
                    </Btn>
                  )}
                  <Link to={`/details/`}>
                    <Button variant="danger">
                      {view ? "Close" : "Cancel"}
                    </Button>
                  </Link>
                </Form>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ViewCompanyDetailsContain;
