import { Btn } from "../../AbstractElements";
import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  FormGroup,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  Label,
  Media,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { toast } from "react-toastify";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useContext } from "react";
import DatePicker from "react-datepicker";
import { SiteContext } from "../../App";
import { limit } from "../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const CompanyDetailsContain = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [Large, setLarge] = useState(false);
  const LargeModaltoggle = () => setLarge(!Large);
  const [companyDetailsList, setCompanyDetailsList] = useState([]);
  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchIndex, setSearchIndex] = useState([]);
  const [viewRecord, setViewRecord] = useState([]);
  const [collection, setCollection] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [siteCompID, setSiteCompID] = React.useState([]);
  const [siteGlobalCompID, setSiteGlobalCompID] = React.useState([]);
  //const [limit, setLimit] = useState(100000);

  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  console.log(selectedSiteGlobal);

  const navigate = useNavigate();
  const handleEdit = useCallback(
    (row) => navigate(`/viewdetails/${row.id}`),
    []
  );
  const handleView = useCallback(
    (row) => navigate(`/viewdetails/${row.id}/view`),
    []
  );
  //---  site global -----
  async function fetchSitesforglobal() {
    setIsLoading(true);
    const response = await client.graphql({
      query: queries.listSiteDetails,
      authMode: "API_KEY",
      variables: {
        limit: limit,
      },
    });
    if (response.data.listSiteDetails.items.length > 0) {
      console.log(response);
      const filtersite = response.data.listSiteDetails.items.filter(
        (item) => item.id === selectedSiteGlobal
      );
      console.log(filtersite);
      console.log(filtersite[0].companydetailsID);
      setSiteGlobalCompID(filtersite[0].companydetailsID);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedSiteGlobal) {
      fetchSitesforglobal();
    }
  }, [selectedSiteGlobal]);
  //---Week------------------------------------------

  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();
  const startOfWeek = moment(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    )
  ).format("DD/MM/YYYY");
  const endOfWeek = moment(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    )
  ).format("DD/MM/YYYY");

  // ---Month---------------------------

  const startOfMonth = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  ).format("DD/MM/YYYY");
  const endOfMonth = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  ).format("DD/MM/YYYY");

  // ---year------------------------------

  const startOfThisYear = moment(
    new Date(currentDate.getFullYear(), 0, 1)
  ).format("DD/MM/YYYY");
  const endOfThisYear = moment(
    new Date(currentDate.getFullYear(), 11, 31)
  ).format("DD/MM/YYYY");

  //---custom -------------------------------------------------

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  //--------------------------------------------------------------

  useEffect(() => {
    fetchCompanyDetailsData("All Time");
  }, [selectedSiteGlobal, siteGlobalCompID]);

  //----------------------------------------------------------------------

  async function fetchCompanyDetailsData1() {
    setIsLoading(true);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const companyId = userDetails.companyDetailsId;
    const response = await client.graphql({
      query: queries.listCompanyDetails,
      authMode: "API_KEY",
      variables: {
        limit: limit,
      },
    });
    if (response.data.listCompanyDetails.items.length > 0) {
      const companyDetails =
        localStorage.getItem("groups") === "site_owner"
          ? response.data.listCompanyDetails.items.filter(
              (item) => item.id === companyId && !item.delete
            )
          : response.data.listCompanyDetails.items.filter(
              (item) => !item.delete
            );
      setIsLoading(false);
      setCompanyDetailsList(companyDetails);
      setCollection(cloneDeep(companyDetails.slice(0, countPerPage)));
    }
  }

  //----------------------------------------------------------------

  async function fetchCompanyDetailsData(DateRange) {
    let companyDetails = [];
    setIsLoading(true);

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const companyId = userDetails.companyDetailsId;
    //--
    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.listCompanyDetails,
      authMode: "API_KEY",
      variables: {
        limit: limit,
        orderBy: {
          field: "createdAt",
          direction: "desc",
        },
      },
    });
    if (result.data.listCompanyDetails.items.length > 0) {
      let assetDatas = result.data.listCompanyDetails.items;
      nextToken = result.data.listCompanyDetails.nextToken;
      console.log(
        "first token count",
        result.data.listCompanyDetails.items.length
      );
      console.log("nextToken 1", nextToken);
      if (result.data.listCompanyDetails.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.listCompanyDetails,
          authMode: "API_KEY",
          variables: {
            limit: limit,
            orderBy: {
              field: "createdAt",
              direction: "desc",
            },
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listCompanyDetails.items.length > 0) {
          nextToken = results.data.listCompanyDetails.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listCompanyDetails.items.length);
          if (results.data.listCompanyDetails.items.length > 0) {
            assets.push(results.data.listCompanyDetails.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        const filterComp = mergeAssets.filter(
          (item) => item.id === siteGlobalCompID
        );
        console.log(filterComp);
        const sortedItemsComp = mergeAssets.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        console.log(sortedItemsComp);
        const sortedItems = selectedSiteGlobal ? filterComp : sortedItemsComp;

        if (DateRange === "This Week") {
          companyDetails = sortedItems.filter((item) => {
            const createdAtDate = moment(item.createdAt);
            return (
              createdAtDate.isSameOrAfter(
                moment(startOfWeek, "DD/MM/YYYY"),
                "day"
              ) &&
              createdAtDate.isSameOrBefore(
                moment(endOfWeek, "DD/MM/YYYY"),
                "day"
              )
            );
          });
        } else if (DateRange === "This Month") {
          companyDetails = sortedItems.filter((item) => {
            const createdAtDate = moment(item.createdAt);
            return createdAtDate.isBetween(
              moment(startOfMonth, "DD/MM/YYYY"),
              moment(endOfMonth, "DD/MM/YYYY"),
              null,
              "[]"
            );
          });
        } else if (DateRange === "Today") {
          companyDetails = sortedItems.filter(
            (item) =>
              moment(item.createdAt).format("DD/MM/YYYY") ===
              moment(new Date()).format("DD/MM/YYYY")
          );
        } else if (DateRange === "This Year") {
          companyDetails = sortedItems.filter((item) => {
            const createdAtDate = moment(item.createdAt);
            return createdAtDate.isBetween(
              moment(startOfThisYear, "DD/MM/YYYY"),
              moment(endOfThisYear, "DD/MM/YYYY"),
              null,
              "[]"
            );
          });
        } else if (DateRange === "Custom") {
          companyDetails = sortedItems.filter((item) => {
            const createdAtDate = moment(item.createdAt);
            return createdAtDate.isBetween(
              moment(startDate, "DD/MM/YYYY"),
              moment(endDate, "DD/MM/YYYY"),
              null,
              "[]"
            );
          });
        } else if (DateRange === "All Time") {
          companyDetails = sortedItems.filter((item) => !item._delete);
        }
        setIsLoading(false);
        setCompanyDetailsList(companyDetails);
        setCollection(cloneDeep(companyDetails.slice(0, countPerPage)));
      }
    }
  }

  //---Approve Status-------------------------------------------------------------------

  const ApproveStatus = (status) => {
    return status === "Active"
      ? "switch-state bg-success"
      : "switch-state bg-danger";
  };

  const handleChange = async (item) => {
    var statusSp;

    if (item.status.toLowerCase() === "active") {
      statusSp = "Inactive";
      toast.success("Deactivated successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      statusSp = "Active";
      toast.success("Activated successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    await client
      .graphql({
        query: mutations.updateCompanyDetails,
        variables: {
          input: { id: item.id, status: statusSp },
        },
      })

      .then((result) => {
        // fetchCompanyDetailsData();
        fetchCompanyDetailsData("All Time");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error while Updating ", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //----------------------------------------------------------------------

  const handleDelete = async (id, version, name) => {
    if (
      window.confirm(
        "Are you sure?? Do you want to delete " + " " + name + "..?"
      )
    ) {
      await client
        .graphql({
          query: mutations.updateCompanyDetails,
          variables: {
            input: { id: id, delete: true },
          },
        })
        .then((result) => {
          toast.success(name + " deleted successfully.", {
            position: toast.POSITION.TOP_CENTER,
          });
          window.location = `/details/`;
          //fetchCompanyDetailsData();
          fetchCompanyDetailsData("All Time");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error while deleting " + name + " Site", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  //---------------------------Search---------------------------------------

  const globalSearch = (searchValue) => {
    let filteredData = companyDetailsList.filter((row) => {
      return (
        row.companyName?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.companyBN?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.state?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.country
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        row.companyAddress?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.status?.toLowerCase().includes(searchValue.toLowerCase()) ||
        moment(row.createdAt)
          .format("DD/MM/YYYY")
          ?.toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    });

    return cloneDeep(filteredData);
  };

  React.useEffect(() => {
    if (!value) {
      setCollection(cloneDeep(companyDetailsList.slice(0, countPerPage)));
      setFilteredData([]);
    } else {
      const filteredData = globalSearch(value);
      setFilteredData(filteredData);
      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;

    if (!value) {
      setCollection(cloneDeep(companyDetailsList.slice(from, to)));
    } else {
      const filteredData = globalSearch(value);
      const pageData = filteredData.slice(from, to);
      setCollection(cloneDeep(pageData));
    }
  };

  //-----------------------------------------------------------------------------------------------------------------------

  const classNameFun = (status) => {
    return status === "active"
      ? "switch-state bg-success"
      : "switch-state bg-danger";
  };

  //----------------------Date Range--------------------------------------------------------------------------------------------
  const [selectedOption, setSelectedOption] = useState("");
  const [dateRange1, setDateRange1] = useState([new Date(), new Date()]);

  const handleDateRangeSelected = (range) => {
    setDateRange1(range);
    console.log(range);
  };

  const onDateRangeSelected = (range) => {
    //setSelectedOption("");
    handleDateRangeSelected(range);
  };

  function DateRangePicker({ onDateRangeSelected }) {
    // const [selectedOption, setSelectedOption] = useState();

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      console.log(selectedOption);

      switch (event.target.value) {
        case "Today":
          onDateRangeSelected(getTodayRange());
          break;
        case "This Week":
          onDateRangeSelected(getWeekRange());
          break;
        case "This Month":
          onDateRangeSelected(getMonthRange());
          break;
        case "This Year":
          onDateRangeSelected(getThisYearRange());
          break;
        case "All Time":
          onDateRangeSelected(getAllTimeRange());
          break;
        case "Custom":
          // onDateRangeSelected(getCustomRange());
          break;
        default:
          break;
      }
    };
    //----------------------------------------------------------------------------------------

    const getTodayRange = () => {
      fetchCompanyDetailsData("Today");
    };

    const getCustomRange = () => {
      fetchCompanyDetailsData("Custom");
    };

    const getAllTimeRange = () => {
      fetchCompanyDetailsData("All Time");
    };

    const getWeekRange = () => {
      fetchCompanyDetailsData("This Week");
    };

    const getMonthRange = () => {
      console.log("inside the month range function........");
      fetchCompanyDetailsData("This Month");
    };

    const getThisYearRange = () => {
      fetchCompanyDetailsData("This Year");
    };

    //----------------------------------------------------------------------------------------

    return (
      <div className="daterange-card">
        <Row>
          <Col xs="6">
            <FormGroup>
              <Input
                type="select"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="All Time">All Time</option>
                <option value="Today">Today</option>
                <option value="This Week">This Week</option>
                <option value="This Month">This Month</option>
                <option value="This Year">This Year</option>
                <option value="Custom">Custom</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              {selectedOption === "Custom" && (
                <Fragment>
                  <Row className="d-flex justify-content-end">
                    <Col xl="6">
                      <div className="daterange-card">
                        <div className="mb-3">
                          <FormGroup>
                            <DatePicker
                              className="form-control digits"
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              // onSelect={(d)=>{setDateRange(d);
                              //   fetchCompanyDetailsData('Custom');
                              // }}
                              onChange={(d) => {
                                setDateRange(d);
                                console.log(d);
                                console.log(startDate);
                                console.log(endDate);
                                fetchCompanyDetailsData("Custom");
                              }}
                              isClearable={true}
                              placeholderText="Enter Date Range"
                              dateFormat="dd-MM-yyyy"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }

  //---------------------------Date Range---------------------------end---------------------------

  //---Site Search Global----
  async function fetchSitesGlobal() {
    setIsLoading(true);
    await client
      .graphql({
        query: queries.listSiteDetails,
        authMode: "API_KEY",
        variables: {
          filter: {
            id: {
              eq: selectedSiteGlobal,
            },
          },
          limit: limit,
        },
      })
      .then((response) => {
        console.log(response);
        const compId = response.data.listSiteDetails.items[0].companydetailsID;
        console.log(compId);
        setSiteCompID(compId);
        setIsLoading(false);
      })
      .catch(() => {
        setErrorMessage("Unable to fetch the list");
        setIsLoading(false);
      });
  }

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Row>
          <Row>
            <DateRangePicker
              onDateRangeSelected={onDateRangeSelected}
            ></DateRangePicker>
          </Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <CardHeader>
                <Row>
                  <Col md="4">
                    <InputGroup>
                      <span className="input-group-text">
                        <i className="icon-search"></i>
                      </span>
                      <Input
                        type="search"
                        placeholder="Search .."
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </CardHeader>
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">
                  <div className="table-responsive">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr>
                          <th>Company Name</th>
                          <th>ABN / Business #</th>
                          <th>Country</th>
                          <th>State</th>
                          <th>Address</th>
                          <th>Added On</th>
                          <th>Status</th>
                          <th>Active</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="trhover">
                        {collection.length > 0 ? (
                          collection.map((item) => (
                            <tr key={item.id}>
                              <td>{item.companyName}</td>
                              <td>{item.companyBN}</td>
                              <td>{item.country}</td>
                              <td>{item.state}</td>
                              <td>{item.companyAddress}</td>
                              <td>
                                {moment(item.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <td>{item.status}</td>
                              <td>
                                <Media body className="icon-state switch-sm">
                                  <Label className="switch">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => handleChange(item, e)}
                                    />
                                    <span
                                      className={ApproveStatus(item.status)}
                                    ></span>
                                  </Label>
                                </Media>
                              </td>
                              <td>
                                {/* <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'danger', title: 'Delete',  onClick: () => handleDelete(item.id,item._version,item.companyName)}} ><i className="fa fa-trash-o"></i></Btn> */}
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "info",
                                    title: "Edit",
                                    onClick: () => handleEdit(item),
                                  }}
                                >
                                  <i className="fa fa-edit "></i>
                                </Btn>
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "primary",
                                    title: "View",
                                    onClick: () => handleView(item),
                                  }}
                                >
                                  <i className="fa fa-eye"></i>{" "}
                                </Btn>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="5" className="text-center">
                                No Data Available.
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </div>
              <CardFooter className="row">
                <Pagination
                  className="p-t-10"
                  pageSize={countPerPage}
                  onChange={updatePage}
                  current={currentPage}
                  total={
                    value ? filteredData.length : companyDetailsList.length
                  }
                  showTotal={(total, range) =>
                    `${range[0]} - ${range[1]} of ${total} items`
                  }
                />
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default CompanyDetailsContain;
