import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { Btn, H3, UL, LI } from "../../AbstractElements";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import * as queries from "../../graphql/queries";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
  REGION,
  ACCESSKEYID,
  SECRETACCESSKEY,
  USERPOOLID,
  limit,
} from "../../Config/Config";
import { SiteContext } from "../../App";
import SiteData from "../../Data/Sites/index";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const PendingSiteUserDetails = () => {
  const AWS = require("aws-sdk");

  AWS.config.update({
    region: REGION,
    credentials: {
      accessKeyId: ACCESSKEYID,
      secretAccessKey: SECRETACCESSKEY,
    },
  });

  const [collection, setCollection] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filtereddata, setFilteredData] = useState([]);
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [value, setValue] = React.useState("");
  const [filterrr, setFilterrr] = useState([]);
  const LoginUserGroup = localStorage.getItem("groups");
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const [sites, setSites] = useState(SiteData.getSiteData());
  //const [limit, setLimit] = useState(100000);

  useEffect(() => {
    listUsersInGroup();
    if (LoginUserGroup === "site_owner") {
      if (sites.length === 0) {
        fetchSiteDetailsAO();
      }
    } else if (LoginUserGroup === "site_user") {
      if (sites.length === 0) {
        fetchSiteDetailsSPSU(LoginUserDetails.id);
      }
    } else {
      if (sites.length === 0) {
        fetchSiteDetailsSPSU(LoginUserDetails.id);
      }
    }
  }, [selectedSiteGlobal]);

  async function fetchSiteDetailsAO() {
    try {
      setIsLoading(true);
      let nextToken = "";
      let sites = [];
      const result = await client.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      });
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          sites.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            nextToken = results.data.siteDetailsByAssetownerID.nextToken;
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.push(results.data.siteDetailsByAssetownerID.items);
            }
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          setSites(mergeSites);
          SiteData.setSiteData(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        } else {
          setSites([]);
          setIsLoading(false);
        }
      } else {
        setSites([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setSites([]);
      setIsLoading(false);
    }
  }
  //--sites for sp/su
  async function fetchSiteDetailsSPSU(assetOwnerId) {
    setIsLoading(true);
    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: assetOwnerId,
        limit: limit,
      },
    });
    if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
      nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
      if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: assetOwnerId,
            limit: limit,
            nextToken: nextToken,
          },
        });
        if (results.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          nextToken = results.data.linkUsersAndSitesByUserdetailsID.nextToken;
          if (results.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
            assets.push(results.data.linkUsersAndSitesByUserdetailsID.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        const siteDetails = mergeAssets;
        let nextToken1 = "";
        let assets1 = [];
        const result = await client.graphql({
          query: queries.listSiteDetails,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (result.data.listSiteDetails.items.length > 0) {
          let assetDatas = result.data.listSiteDetails.items;
          nextToken1 = result.data.listSiteDetails.nextToken;
          if (result.data.listSiteDetails.items.length > 0) {
            assets1.push(assetDatas);
          }
          while (nextToken1 !== null) {
            const results = await client.graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: limit,
                nextToken: nextToken1,
              },
              authMode: "API_KEY",
            });
            if (results.data.listSiteDetails.items.length > 0) {
              nextToken1 = results.data.listSiteDetails.nextToken;
              if (results.data.listSiteDetails.items.length > 0) {
                assets1.push(results.data.listSiteDetails.items);
              }
            }
          }
          console.log(assets1);
          if (assets1.length > 0) {
            const mergeAssets1 = assets1.flat(1);
            const siteDetail = mergeAssets1.filter((item) => {
              const _matchingAssetTemplate = siteDetails?.find(
                (site) => site.sitedetailsID === item?.id
              );
              if (_matchingAssetTemplate) {
                return item;
              }
            });
            //fetchlistDistributionBox(siteDetail);
            setSites(siteDetail);
            localStorage.setItem(
              "site",
              base64.encode(JSON.stringify(siteDetail))
            );
            SiteData.setSiteData(siteDetail);
          }
        }
      }
    }
  }

  const globalSearch = (searchValue) => {
    let filteredData = filtereddata.filter((row) => {
      const email = getUserAttribute(row.Attributes, "email");
      const addedOn = new Date(row.UserCreateDate).toLocaleDateString();
      return (
        email?.toLowerCase().includes(searchValue.toLowerCase()) ||
        addedOn?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.Username?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    return cloneDeep(filteredData);
  };

  React.useEffect(() => {
    if (!value) {
      setCollection(cloneDeep(filtereddata.slice(0, countPerPage)));
      setFilterrr([]);
    } else {
      const filteredData = globalSearch(value);
      setFilterrr(filteredData);
      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    if (!value) {
      setCollection(cloneDeep(filtereddata.slice(from, to)));
    } else {
      const filteredData = globalSearch(value);
      const pageData = filteredData.slice(from, to);
      setCollection(cloneDeep(pageData));
    }
  };

  const cognitoIdentityServiceProvider =
    new AWS.CognitoIdentityServiceProvider();

  async function listUsersInGroup() {
    setIsLoading(true);
    let NextToken;
    let fullList = [];
    let fullListID = [];

    do {
      const params = {
        UserPoolId: USERPOOLID,
        GroupName: "site_user",
        NextToken: NextToken,
      };

      try {
        const result = await cognitoIdentityServiceProvider
          .listUsersInGroup(params)
          .promise();

        console.log(result.Users);
        fullList.push(...result.Users);

        {
          /*
          result.Users.forEach(user => {
          console.log('User Name:', user.Username);
          console.log('Email Address:', getUserAttribute(user.Attributes, 'email'));
          console.log('Email Verified:', getUserAttribute(user.Attributes, 'email_verified'));
          console.log('Confirmation Status:', user.UserStatus);
          console.log('Status:', user.Enabled ? 'Enabled' : 'Disabled');
          console.log('---');
        });
        */
        }

        NextToken = result.NextToken;
        console.log(NextToken);
        console.log(result);

        if (!NextToken) {
          break;
        }
      } catch (error) {
        console.error("Error listing users", error);
        break;
      }
    } while (NextToken);

    console.log("Finished listing users.");
    console.log(fullList);

    const filteredUsers = fullList.filter((user) => {
      //const emailVerified = getUserAttribute(user.Attributes, 'email_verified');
      return user.UserStatus !== "CONFIRMED";
    });

    console.log("Filtered Users:", filteredUsers);

    //fullListID = filteredUsers.map((user) => {
    //    const subAttribute = user.Attributes.find((attr) => attr.Name === 'sub');
    //    return subAttribute ? subAttribute.Value : null;
    //  });

    //console.log('Full List of IDs:', fullListID);

    if (LoginUserGroup === "site_owner" || LoginUserGroup === "spao") {
      //--
      {
        /*
      await client.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
        .then(async (response) => {
          console.log(response);
          const sites = response.data.siteDetailsByAssetownerID.items;
        */
      }
      console.log(sites);
      let siteId = [];
      if (sites.length > 0) {
        //sites.map((item) => {
        for (let item of sites) {
          siteId.push(item.id);
        }
        //--
        const response = await client.graphql({
          query: queries.listLinkUsersAndSites,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (response.data.listLinkUsersAndSites.items.length > 0) {
          console.log(response);
          const sitesuser = response.data.listLinkUsersAndSites.items;
          console.log(sitesuser);

          const userssp = sitesuser.filter((item) => {
            const _matchSites = siteId?.find(
              (id) => id === item?.sitedetailsID
            );
            if (_matchSites) {
              return item;
            }
          });
          console.log(userssp);
          const filteruser = sitesuser.filter(
            (item) => item.sitedetailsID === selectedSiteGlobal
          );
          const datauser = selectedSiteGlobal ? filteruser : userssp;
          console.log(datauser);
          //console.log(userssp);
          let userID = [];
          if (datauser.length > 0) {
            datauser.map((item) => {
              userID.push(item.userdetailsID);
            });

            const filteredList = filteredUsers.filter((user) => {
              const subAttribute = user.Attributes.find(
                (attr) => attr.Name === "sub"
              );
              return subAttribute ? userID.includes(subAttribute.Value) : false;
            });

            console.log("Filtered Users:", filteredList);
            setFilteredData(filteredList);
            setCollection(cloneDeep(filteredList.slice(0, countPerPage)));
            setIsLoading(false);
          } else {
            setFilteredData([]);
            setCollection(cloneDeep([].slice(0, countPerPage)));
            setIsLoading(false);
          }
        }
      } else {
        setFilteredData([]);
        setCollection(cloneDeep([].slice(0, countPerPage)));
        setIsLoading(false);
      }
    } else if (LoginUserGroup === "site_user") {
      const siteDetail = sites;
      console.log(siteDetail);
      let siteId = [];
      if (siteDetail.length > 0) {
        for (let item of siteDetail) {
          siteId.push(item.id);
        }
        //--
        const response = await client.graphql({
          query: queries.listLinkUsersAndSites,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (response.data.listLinkUsersAndSites.items.length > 0) {
          console.log(response);
          const sitesuser = response.data.listLinkUsersAndSites.items;
          console.log(sitesuser);
          const userssp = sitesuser.filter((item) => {
            const _matchSites = siteId?.find(
              (id) => id === item?.sitedetailsID
            );
            if (_matchSites) {
              return item;
            }
          });
          console.log(userssp);
          const filteruser = sitesuser.filter(
            (item) => item.sitedetailsID === selectedSiteGlobal
          );
          const datauser = selectedSiteGlobal ? filteruser : userssp;
          console.log(datauser);
          let userID = [];
          if (datauser.length > 0) {
            datauser.map((item) => {
              userID.push(item.userdetailsID);
            });

            const filteredList = filteredUsers.filter((user) => {
              const subAttribute = user.Attributes.find(
                (attr) => attr.Name === "sub"
              );
              return subAttribute ? userID.includes(subAttribute.Value) : false;
            });

            console.log("Filtered Users:", filteredList);
            setFilteredData(filteredList);
            setCollection(cloneDeep(filteredList.slice(0, countPerPage)));
            setIsLoading(false);
          } else {
            setFilteredData([]);
            setCollection(cloneDeep([].slice(0, countPerPage)));
            setIsLoading(false);
          }
        }
      } else {
        setFilteredData([]);
        setCollection(cloneDeep([].slice(0, countPerPage)));
        setIsLoading(false);
      }
    } else if (LoginUserGroup === "electrician") {
      const siteDetail = sites;
      console.log(siteDetail);
      let siteId = [];
      if (siteDetail.length > 0) {
        for (let item of siteDetail) {
          siteId.push(item.id);
        }
        //--
        const response = await client.graphql({
          query: queries.listLinkUsersAndSites,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (response.data.listLinkUsersAndSites.items.length > 0) {
          console.log(response);
          const sitesuser = response.data.listLinkUsersAndSites.items;
          console.log(sitesuser);

          const userssp = sitesuser.filter((item) => {
            const _matchSites = siteId?.find(
              (id) => id === item?.sitedetailsID
            );
            if (_matchSites) {
              return item;
            }
          });
          console.log(userssp);
          const filteruser = sitesuser.filter(
            (item) => item.sitedetailsID === selectedSiteGlobal
          );
          const datauser = selectedSiteGlobal ? filteruser : userssp;
          console.log(datauser);
          let userID = [];
          if (datauser.length > 0) {
            datauser.map((item) => {
              userID.push(item.userdetailsID);
            });

            const filteredList = filteredUsers.filter((user) => {
              const subAttribute = user.Attributes.find(
                (attr) => attr.Name === "sub"
              );
              return subAttribute ? userID.includes(subAttribute.Value) : false;
            });

            console.log("Filtered Users:", filteredList);
            setFilteredData(filteredList);
            setCollection(cloneDeep(filteredList.slice(0, countPerPage)));
            setIsLoading(false);
          } else {
            setFilteredData([]);
            setCollection(cloneDeep([].slice(0, countPerPage)));
            setIsLoading(false);
          }
        }
      } else {
        setFilteredData([]);
        setCollection(cloneDeep([].slice(0, countPerPage)));
        setIsLoading(false);
      }
    } else {
      //-- site global
      const result = await client.graphql({
        query: queries.listSiteDetails,
        variables: {
          limit: limit,
        },
      });
      if (result.data.listSiteDetails.items.length > 0) {
        console.log(result);
        const siteDetail = result.data.listSiteDetails.items;
        console.log(siteDetail);
        let siteId = [];
        if (siteDetail.length > 0) {
          siteDetail.map((item) => {
            siteId.push(item.id);
          });
          const response = await client.graphql({
            query: queries.listLinkUsersAndSites,
            variables: {
              limit: limit,
            },
            authMode: "API_KEY",
          });
          if (response.data.listLinkUsersAndSites.items.length > 0) {
            console.log(response);
            const sitesuser = response.data.listLinkUsersAndSites.items;
            console.log(sitesuser);
            const userssp = sitesuser.filter((item) => {
              const _matchSites = siteId?.find(
                (id) => id === item?.sitedetailsID
              );
              if (_matchSites) {
                return item;
              }
            });
            console.log(userssp);
            const filteruser = sitesuser.filter(
              (item) => item.sitedetailsID === selectedSiteGlobal
            );
            const datauser = selectedSiteGlobal ? filteruser : userssp;
            console.log(datauser);
            let userID = [];
            if (datauser.length > 0) {
              datauser.map((item) => {
                userID.push(item.userdetailsID);
              });

              const filteredList = filteredUsers.filter((user) => {
                const subAttribute = user.Attributes.find(
                  (attr) => attr.Name === "sub"
                );
                return subAttribute
                  ? userID.includes(subAttribute.Value)
                  : false;
              });

              console.log("Filtered Users:", filteredList);
              setFilteredData(filteredList);
              setCollection(cloneDeep(filteredList.slice(0, countPerPage)));
              setIsLoading(false);
            } else {
              setFilteredData([]);
              setCollection(cloneDeep([].slice(0, countPerPage)));
              setIsLoading(false);
            }
          }
        }
      }

      //--
      //setFilteredData(filteredUsers);
      //setCollection(cloneDeep(filteredUsers.slice(0, countPerPage)));
      //setIsLoading(false);
    }
  }

  function getUserAttribute(attributes, attributeName) {
    const attribute = attributes.find((attr) => attr.Name === attributeName);
    return attribute ? attribute.Value : null;
  }

  const handleResendVerificationEmail = async (item) => {
    console.log(item);

    const params = {
      UserPoolId: USERPOOLID,
      Username: item.Username,
      //UserAttributes: [
      //  {
      //    Name: 'email_verified',
      //    Value: 'true',
      //  },
      //],
      MessageAction: "RESEND",
    };

    try {
      let emailVerificationResponse = await cognitoIdentityServiceProvider
        .adminCreateUser(params)
        .promise();
      console.log(emailVerificationResponse);
      console.log("Verification email resent successfully.");
      toast.success("Verification email resent successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      console.error("Error resending verification email:", error);
    }
  };

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <CardHeader>
                <Row className="card-block row">
                  <Col md="4">
                    <InputGroup>
                      <span className="input-group-text">
                        <i className="icon-search"></i>
                      </span>
                      <Input
                        type="search"
                        placeholder="Search Pending Site Users.."
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </CardHeader>
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">
                  <div className="table-responsive">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr>
                          <th scope="col">UserName</th>
                          <th scope="col">Email</th>
                          <th scope="col">Added On</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody className="trhover">
                        {collection.length > 0 ? (
                          collection.map((item) => (
                            <tr>
                              <td>{item.Username}</td>
                              <td>
                                {getUserAttribute(item.Attributes, "email")}
                              </td>
                              <td>
                                {new Date(
                                  item.UserCreateDate
                                ).toLocaleDateString()}
                              </td>
                              <td>{item.UserStatus}</td>
                              <td>
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "info",
                                    onClick: () =>
                                      handleResendVerificationEmail(item),
                                  }}
                                >
                                  Resend Mail
                                </Btn>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="5" className="text-center">
                                No Site User Available.
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </div>
              <CardFooter className="row">
                <Pagination
                  className="p-t-10"
                  pageSize={countPerPage}
                  onChange={updatePage}
                  current={currentPage}
                  total={value ? filterrr.length : filtereddata.length}
                  showTotal={(total, range) =>
                    `${range[0]} - ${range[1]} of ${total} items`
                  }
                />
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default PendingSiteUserDetails;
