import React, { Fragment, useState, useEffect, createRef, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Btn, H5, H3, H6, LI, P, UL } from "../../AbstractElements";
import {
  Col,
  Container,
  Form,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  CardBody,
  Card,
  CardHeader,
  Media,
  Table,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ScrollBar from "react-perfect-scrollbar";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import AWS from "aws-sdk";
import {
  S3_BUCKET,
  REGION,
  CATEGORIES_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
} from "../../Config/Config";
import Papa from "papaparse";
import { tableMapping } from "./insertTableName";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const XLSX = require("xlsx");
AWS.config.update({ region: REGION });
const dynamoDb = new AWS.DynamoDB({
  region: REGION,
  accessKeyId: ACCESSKEYID,
  secretAccessKey: SECRETACCESSKEY,
  apiVersion: "2012-08-10",
});

const BulkImport = () => {
  const [fileData, setFileData] = useState([]);
  const [fileHeader, setFileHeader] = useState(null);
  const [selectedTable, setSelectedTable] = useState("");
  const [errorCount, setErrorCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableColumnLength, setTableColumnLength] = useState([]);

  const [siteDetails, setSiteDetails] = useState([]);
  const [distributionBoard, setDistributionBoard] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [assetOwner, setAssetOwner] = useState([]);
  const [assetTemplate, setAssetTemplate] = useState([]);
  const [logbooks, setLogbooks] = useState([]);
  let assetColumn = [
    {
      assetDescription: "Asset Description",
      siteId: "Site",
      distributionboxID: "Distribution Board",
      assetLocation: "Asset Location",
      assetMake: "Asset Make",
      assetModel: "Asset Model",
      assetNumber: "Asset Number",
      assetSerialNumber: "Asset Serial Number",
      assettemplateID: "Asset Template",
      assetWarrantyStart: "Asset Warranty Start",
      assetWarrantyEnd: "Asset Warranty End",
      installedOn: "Installed On",
      formFieldValues: "Custom Field Values",
    },
  ];

  let logbookColumn = [
    {
      assetsID: "Assets",
      distributionBox: "Distribution Board",
      logbookName: "Logbook Name",
      sitedetailsID: "site",
      additionalInformation: "Additional Information",
    },
  ];

  let siteColumn = [
    {
      siteName: "Site Name",
      unitNumber: "Unit Number",
      assetownerID: "Asset Owner",
      companydetailsID: "Company Details",
      country: "Country",
      state: "State",
      street: "Street",
      suburb: "Suburb",
      postCode: "Post Code",
      distributionBoxName: "Distribution Board",
      location: "Distribution Board Location",
    },
  ];

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();

    if (extension === "csv") {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          console.log(result);
          const rows = result.data.map((row) => Object.values(row));
          const headers = Object.keys(result.data[0]);
          // const csvHeader = text.slice(0, text.indexOf("\n")).split(",");
          // const csvRows = text.slice(text.indexOf("\n") + 1).split("\r\n");
          console.log(headers);
          console.log(rows);
          setFileData(rows);
          setFileHeader(headers);
          setTableColumnLength(headers);
        },
        error: (error) => {
          console.log(error);
        },
      });
    } else if (extension === "xls" || extension === "xlsx") {
      const reader = new FileReader();
      reader.onload = (event) => {
        let data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        console.log(data);
        const headers = data.splice(0, 1)[0];
        console.log(headers);
        //setFileData({ headers, rows: data });
        setFileData(data);
        setFileHeader(headers);
        setTableColumnLength(headers);
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert("Unsupported file format");
    }
  };

  // drop down of db tables
  const [tables, setTables] = useState([]);

  useEffect(() => {
    const getTables = async () => {
      const response = await dynamoDb.listTables().promise();
      const tblArry = [];
      response.TableNames.map((item) => {
        tblArry.push({ id: item, value: item.split("-")[0] });
      });
      setTables(tblArry);
    };
    getTables();

    const getSiteDetails = async () => {
      await client
        .graphql({
          query: queries.listSiteDetails,
          variables: { limit: 2000000 },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          setSiteDetails(result.data.listSiteDetails.items);
        })
        .catch((error) => {
          console.log(error);
          setSiteDetails([]);
        });
    };
    const getDistributionBoard = async () => {
      await client
        .graphql({
          query: queries.listDistributionBoxes,
          variables: { limit: 2000000 },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          setDistributionBoard(result.data.listDistributionBoxes.items);
        })
        .catch((error) => {
          console.log(error);
          setDistributionBoard([]);
        });
    };
    const getAssetTemplate = async () => {
      await client
        .graphql({
          query: queries.listAssetTemplates,
          variables: { limit: 2000000 },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          setAssetTemplate(result.data.listAssetTemplates.items);
        })
        .catch((error) => {
          console.log(error);
          setAssetTemplate([]);
        });
    };
    const getAssetOwner = async () => {
      await client
        .graphql({
          query: queries.listAssetOwners,
          variables: { limit: 2000000 },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          setAssetOwner(result.data.listAssetOwners.items);
        })
        .catch((error) => {
          console.log(error);
          setAssetOwner([]);
        });
    };
    const getCompanyDetails = async () => {
      await client
        .graphql({
          query: queries.listCompanyDetails,
          variables: { limit: 2000000 },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          setCompanyDetails(result.data.listCompanyDetails.items);
        })
        .catch((error) => {
          console.log(error);
          setCompanyDetails([]);
        });
    };
    const getLogbooks = async () => {
      await client
        .graphql({
          query: queries.listLogBooks,
          variables: { limit: 2000000 },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          setLogbooks(result.data.listLogBooks.items);
        })
        .catch((error) => {
          console.log(error);
          setLogbooks([]);
        });
    };
    getSiteDetails();
    getDistributionBoard();
    getAssetOwner();
    getCompanyDetails();
    getAssetTemplate();
    getLogbooks();
  }, []);

  const handleTableSelection = async (event) => {
    setSelectedTable(event.target.value);
    // if (event.target.value) {
    //   const response = await dynamoDb
    //     .scan({ TableName: event.target.value })
    //     .promise();
    //   setTableColumns(Object.keys(response.Items[0]));
    // }
  };

  const getAssetOwnerIds = (assetowner) => {
    try {
      const dbCount = assetOwner.filter((item) => {
        return (
          item.firstName.toLowerCase() + " " + item.lastName.toLowerCase() ===
          assetowner.toLowerCase()
        );
      });
      console.log(dbCount);
      return dbCount.length > 0 ? dbCount[0].id : "";
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const getCompanyIds = (companyName) => {
    try {
      const dbCount = companyDetails.filter((item) => {
        return item.companyName !== null
          ? item.companyName.toLowerCase() === companyName.toLowerCase()
          : "";
      });
      console.log(dbCount);
      return dbCount.length > 0 ? dbCount[0].id : "";
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const getDistributionBoardId = (db) => {
    try {
      const dbCount = distributionBoard.filter((item) => {
        return item.distributionBoxName !== null
          ? item.distributionBoxName.toLowerCase() === db.toLowerCase()
          : "";
      });
      console.log(dbCount);
      return dbCount.length > 0 ? dbCount[0].id : "";
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const getSiteId = (site) => {
    try {
      const dbCount = siteDetails.filter((item) => {
        return item.siteName !== null
          ? item.siteName.toLowerCase() === site.toLowerCase()
          : "";
      });
      console.log(dbCount);
      return dbCount.length > 0 ? dbCount[0].id : "";
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const getAssetTemplateId = (db) => {
    try {
      const dbCount = assetTemplate.filter((item) => {
        return item.templatename !== null
          ? item.templatename.toLowerCase() === db.toLowerCase()
          : "";
      });
      console.log(dbCount);
      return dbCount.length > 0 ? dbCount[0].id : "";
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const getLogbookId = (id) => {
    try {
      const dbCount = logbooks.filter((item) => {
        return item.sitedetailsID === id;
      });
      console.log(dbCount);
      return dbCount.length > 0 ? dbCount[0].id : "";
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  async function saveSite(data, siteName, DBDetails) {
    await client
      .graphql({
        query: mutations.createSiteDetails,
        variables: { input: data },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        console.log(result);
        const siteId = result.data.createSiteDetails.id;
        DBDetails["sitedetailsID"] = siteId;
        console.log("DBDetails", DBDetails);
        await client
          .graphql({
            query: mutations.createDistributionBox,
            variables: { input: DBDetails },
            authMode: "API_KEY",
          })
          .then((result1) => {
            setSuccessCount(successCount + 1);
            console.log("createDistributionBox", result1);
            const dbId = result1.data.createDistributionBox.id;
            client
              .graphql({
                query: mutations.createLogBook,
                variables: {
                  input: {
                    siteId: siteId,
                    sitedetailsID: siteId,
                    status: "Active",
                    logbookName: siteName + " - Default Logbook",
                    distributionBox: dbId,
                  },
                },
                authMode: "API_KEY",
              })
              .then((result2) => {
                console.log("createLogBook", result2);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setErrorCount(errorCount + 1);
        console.log(error);
      });
  }

  //mapping
  const handleSave = async () => {
    if (!selectedTable) {
      toast.error("Please select a table to import the selected file.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const insetArray = [];
    tableColumnLength.map((v, index) => {
      const keypair = document.getElementById("tableColumn" + index).value;
      const val = document.getElementById("fileColumn" + index).value;
      if (keypair !== "Choose Table Column..." && val !== "Choose Column...") {
        insetArray.push({ col: keypair, data: val });
      }
    });

    console.log(insetArray);

    if (fileData && fileData.length > 0) {
      fileData.map(async (row, i) => {
        const columnDetails = {};
        const DBDetails = {};
        const customFieldsArray = [];
        var siteName = "";
        insetArray.forEach((itemData) => {
          let customFields = {};
          if (itemData.col === "assetownerID") {
            row[fileHeader.indexOf(itemData.data)] = getAssetOwnerIds(
              row[fileHeader.indexOf(itemData.data)]
            );
          }
          if (itemData.col === "siteId" || itemData.col === "sitedetailsID") {
            var siteId = getSiteId(row[fileHeader.indexOf(itemData.data)]);
            row[fileHeader.indexOf(itemData.data)] = siteId;
            var logbookId = getLogbookId(
              row[fileHeader.indexOf(itemData.data)]
            );
            if (selectedTable === "Assets") {
              columnDetails["logbookId"] = logbookId;
            }
            if (selectedTable === "Logbook") {
              columnDetails["siteId"] = siteId;
              columnDetails["sitedetailsID"] = siteId;
            }
          }
          if (itemData.col === "siteName") {
            siteName = row[fileHeader.indexOf(itemData.data)];
          }
          if (itemData.col === "companydetailsID") {
            row[fileHeader.indexOf(itemData.data)] = getCompanyIds(
              row[fileHeader.indexOf(itemData.data)]
            );
          }
          if (
            itemData.col === "distributionBox" ||
            itemData.col === "distributionboxID"
          ) {
            row[fileHeader.indexOf(itemData.data)] = getDistributionBoardId(
              row[fileHeader.indexOf(itemData.data)]
            );
          }
          if (itemData.col === "assettemplateID") {
            columnDetails["assetType"] = row[fileHeader.indexOf(itemData.data)];
            row[fileHeader.indexOf(itemData.data)] = getAssetTemplateId(
              row[fileHeader.indexOf(itemData.data)]
            );
          }
          // if (itemData.col === "category") {
          //   row[fileHeader.indexOf(itemData.data)] = getAssetTemplateByCatId(
          //     row[fileHeader.indexOf(itemData.data)]
          //   );
          // }
          if (itemData.col === "formFieldValues") {
            customFields[itemData.data.toLowerCase().replace(/\s/g, "")] =
              row[fileHeader.indexOf(itemData.data)];
          }
          if (Object.keys(customFields).length > 0) {
            customFieldsArray.push(customFields);
          }
          columnDetails["status"] = "Active";
          if (selectedTable === "SiteDetails") {
            if (itemData.col === "distributionBoxName") {
              DBDetails["distributionBoxName"] =
                row[fileHeader.indexOf(itemData.data)];
            }
            if (itemData.col === "location") {
              DBDetails["location"] = row[fileHeader.indexOf(itemData.data)];
            }
          }
          if (
            itemData.col !== "distributionBoxName" &&
            itemData.col !== "location"
          ) {
            columnDetails[itemData.col] =
              itemData.col === "formFieldValues"
                ? JSON.stringify(customFieldsArray)
                : row[fileHeader.indexOf(itemData.data)];
          }
        });
        console.log("columnDetails", columnDetails);
        const queriesData = tableMapping[selectedTable.split("-")[0]];
        if (selectedTable === "Assets") {
          await client
            .graphql({
              query: mutations.createAssets,
              variables: { input: columnDetails },
              authMode: "API_KEY",
            })
            .then((result) => {
              setSuccessCount(successCount + 1);
              console.log(result);
            })
            .catch((error) => {
              setErrorCount(errorCount + 1);
              console.log(error);
            });
        } else if (selectedTable === "Logbook") {
          console.log("logbook table selected");
          await client
            .graphql({
              query: mutations.createLogBook,
              variables: { input: columnDetails },
              authMode: "API_KEY",
            })
            .then((result) => {
              setSuccessCount(successCount + 1);
              console.log(result);
            })
            .catch((error) => {
              setErrorCount(errorCount + 1);
              console.log(error);
            });
        } else {
          saveSite(columnDetails, siteName, DBDetails);
        }
        console.log();
        if (fileData.length === successCount + errorCount) {
          var message =
            errorCount === 0
              ? successCount +
                " rows imported to " +
                selectedTable +
                " successfully"
              : successCount +
                " rows imported to " +
                selectedTable +
                " successfully, with " +
                errorCount +
                " rows failed to import.";
          toast.success(message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setFileData([]);
          setFileHeader([]);
          setTableColumnLength([]);
          setSelectedTable("");
          setTableColumns([]);
        }
      });
    } else {
      toast.error("No Data To Save", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const loadOptions = (columnId) => {
    if (selectedTable === "Assets") {
      let selectedVal =
        document.getElementById(columnId).options[
          document.getElementById(columnId).selectedIndex
        ].value;
      Object.values(assetColumn[0]).map((item) => {
        console.log(item.toLowerCase().includes(selectedVal.toLowerCase()));
        if (item.toLowerCase().includes(selectedVal.toLowerCase())) {
          return item;
        }
      });
    }
    if (selectedTable === "SiteDetails") {
      let selectedVal =
        document.getElementById(columnId).options[
          document.getElementById(columnId).selectedIndex
        ].value;
      Object.values(siteColumn[0]).map((item) => {
        console.log(item.toLowerCase().includes(selectedVal.toLowerCase()));
        if (item.toLowerCase().includes(selectedVal.toLowerCase())) {
          return item;
        }
      });
    }
    if (selectedTable === "Logbook") {
      let element = document.getElementById(columnId);
      let choosenIndex = element.selectedIndex;
      let choosenVal = element.options[choosenIndex].value;
      Object.values(logbookColumn[0]).map((item) => {
        console.log(item.toLowerCase().includes(choosenVal.toLowerCase()));
        if (item.toLowerCase().includes(choosenVal.toLowerCase())) {
          return item.toLowerCase().includes(choosenVal.toLowerCase());
        }
      });
    }
  };

  return (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <CardBody>
              <Row>
                <Col md="6">
                  <input
                    type="file"
                    className="form-control"
                    accept=".csv,.xls,.xlsx"
                    onChange={handleFileUpload}
                  />
                </Col>
                <Col md="6">
                  <select
                    className="form-control"
                    value={selectedTable}
                    onChange={handleTableSelection}
                  >
                    <option>Select Tables...</option>
                    <option value="Assets">Assets</option>
                    <option value="Logbook">Logbooks</option>
                    <option value="SiteDetails">Sites</option>
                  </select>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <H3>Uploaded File Columns:</H3>
                  <Row>
                    {tableColumnLength.map((v, index) => (
                      <>
                        <Col md="6" className="text-end">
                          Column {index}
                        </Col>
                        <Col md="6" className="text-start">
                          <select
                            className="form-control"
                            key={index}
                            id={`fileColumn${index}`}
                          >
                            <option>Choose Column...</option>
                            {fileHeader &&
                              fileHeader.map((item, i) => (
                                <option
                                  key={i}
                                  value={item}
                                  selected={index === i ? true : false}
                                >
                                  {item}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <br></br>
                        <br></br>
                        <br></br>
                      </>
                    ))}
                  </Row>
                </Col>
                <Col md="6">
                  <H3>Columns Of Selected Table:</H3>
                  <Row>
                    {tableColumnLength.map((v, index) => (
                      <>
                        <Col md="6" className="text-end">
                          Column {index}
                        </Col>
                        <Col md="6" className="text-start">
                          <select
                            className="form-control"
                            key={index}
                            id={`tableColumn${index}`}
                          >
                            <option>Choose Table Column...</option>
                            {selectedTable === "Assets" ? (
                              <>
                                {Object.values(assetColumn[0]).map(
                                  (item, i) => (
                                    <option
                                      key={i}
                                      value={item}
                                      selected={loadOptions(
                                        `fileColumn${index}`
                                      )}
                                    >
                                      {item}
                                    </option>
                                  )
                                )}
                                {/* <option value="assetDescription">
                                  Asset Description
                                </option>
                                <option value="siteId">Site</option>
                                <option value="distributionboxID">
                                  Distribution Board
                                </option>
                                <option value="assetLocation">
                                  Asset Location
                                </option>
                                <option value="assetMake">Asset Make</option>
                                <option value="assetModel">Asset Model</option>
                                <option value="assetNumber">
                                  Asset Number
                                </option>
                                <option value="assetSerialNumber">
                                  Asset Serial Number
                                </option>
                                <option value="assettemplateID">
                                  Asset Template
                                </option>
                                <option value="assetWarrantyStart">
                                  Asset Warranty Start
                                </option>
                                <option value="assetWarrantyEnd">
                                  Asset Warranty End
                                </option>
                                <option value="installedOn">
                                  Installed On
                                </option>
                                <option value="formFieldValues">
                                  Custom Field Values
                                </option> */}
                              </>
                            ) : selectedTable === "Logbook" ? (
                              <>
                                {Object.values(logbookColumn[0]).map(
                                  (item, i) => (
                                    <option
                                      key={i}
                                      value={item}
                                      selected={loadOptions(
                                        `fileColumn${index}`
                                      )}
                                    >
                                      {item}
                                    </option>
                                  )
                                )}
                                {/* <option value="assetsID">Assets</option>
                                <option value="distributionBox">
                                  Distribution Board
                                </option>
                                <option value="logbookName">
                                  Logbook Name
                                </option>
                                <option value="sitedetailsID">site</option>
                                <option value="additionalInformation">
                                  Additional Information
                                </option> */}
                              </>
                            ) : selectedTable === "SiteDetails" ? (
                              <>
                                {Object.values(siteColumn[0]).map((item, i) => (
                                  <option
                                    key={i}
                                    value={item}
                                    selected={loadOptions(`fileColumn${index}`)}
                                  >
                                    {item}
                                  </option>
                                ))}
                                {/* <option value="siteName">Site Name</option>
                                <option value="unitNumber">Unit Number</option>
                                <option value="assetownerID">
                                  Asset Owner
                                </option>
                                <option value="companydetailsID">
                                  Company Details
                                </option>
                                <option value="country">Country</option>
                                <option value="state">State</option>
                                <option value="street">Street</option>
                                <option value="suburb">Suburb</option>
                                <option value="postCode">Post Code</option>
                                <option value="distributionBoxName">
                                  Distribution Board
                                </option>
                                <option value="location">
                                  Distribution Board Location
                                </option> */}
                              </>
                            ) : (
                              tableColumns &&
                              tableColumns.map((item, i) => (
                                <option key={i} value={item}>
                                  {item}
                                </option>
                              ))
                            )}
                          </select>
                        </Col>
                        <br></br>
                        <br></br>
                        <br></br>
                      </>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Btn attrBtn={{ color: "primary", onClick: handleSave }}>
                    Save
                  </Btn>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default BulkImport;
