import { Btn, H3, H6 } from "../../../AbstractElements";
import { useForm, useFieldArray } from "react-hook-form";
import React, { Fragment } from "react";
import { useState, useEffect, useCallback } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import AWS from "aws-sdk";
import {
  User_FOLDER,
  S3_BUCKET,
  REGION,
  ACCESSKEYID,
  SECRETACCESSKEY,
  USERPOOLID,
} from "../../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const UsersDetails = (props) => {
  const [uploadedPP, setUploadedPP] = useState([]);
  const [compId, setcompId] = useState([]);
  const history = useNavigate();
  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
    apiVersion: "2022-10-03",
    region: REGION,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });
  const { state } = useLocation();
  const { register, control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      userDetails: [
        {
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
          license: "",
          profilePic: "",
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "userDetails",
  });
  useEffect(() => {
    console.log(props);
  }, []);
  //---------------------------------------------------------------------

  let cdId;
  const onSubmit = useCallback(async (data) => {
    console.log(props.values.CompanyIdSelected);
    const inputArray = data.userDetails;
    const userDetailsId = [];
    const udetails = [];
    console.log(props.values);
    {
      /*
    if(props.values.CompanyIdSelected){
      client.graphql({
        query: mutations.createSPUserDetails,
        variables: {
          input: {
            id: props.values.UserId,
            fname: state.username,
            email: state.email,
            status: "Active",
            userGroup: "electrician",
          },
        },
        authMode: "API_KEY",
      });
      client.graphql({
        query: mutations.updateUserDetails,
        variables: {
          input: {
            id: props.values.UserId,
            companyDetailsId:props.values.CompanyIdSelected,
          },
        },
        authMode: "API_KEY",
      });
    }
    else{
    await client.graphql({
      query: mutations.createSPCompanyDetails,
      variables: {
        input: {
          companyName: props.values.CompanyName,
          companyBN: props.values.businessNumber,
          companyAddress: props.values.companyaddress,
          companyLicense: props.values.licencenumb,
          logo: props.values.logo,
          status: "Active",
          phone: props.values.phone,
        },
      },
      authMode: "API_KEY",
    }).then(async (result) => {
      console.log(result);

      cdId = result.data.createSPCompanyDetails.id;
      setcompId(cdId)
      console.log(cdId)
    client.graphql({
      query: mutations.createSPUserDetails,
      variables: {
        input: {
          id: props.values.UserId,
          fname: state.username,
          email: state.email,
          status: "Active",
          userGroup: "electrician",
        },
      },
      authMode: "API_KEY",
    });
    client.graphql({
      query: mutations.updateUserDetails,
      variables: {
        input: {
          id: props.values.UserId,
          companyDetailsId:cdId,
        },
      },
      authMode: "API_KEY",
    });
  })
  .catch((error) => {
    console.log(error);
  });
}
*/
    }
    inputArray.map(async function (d, idx) {
      //create cognito user
      // let userSub = "";
      // let userEmail = "";
      // try {
      //  const cognitoParams = {
      //    UserPoolId: USERPOOLID,
      //    Username: d.firstName,
      //   UserAttributes: [
      //     {
      //       Name: "email",
      //      Value: d.email,
      //    },
      //  ],
      //   DesiredDeliveryMediums: ["EMAIL"],
      //  TemporaryPassword: d.firstName + "Z@123456789",
      //    ForceAliasCreation: false,
      //  };
      // console.log(cognitoParams);
      //  let response = await COGNITO_CLIENT.adminCreateUser(
      //    cognitoParams
      //  ).promise();
      // console.log(JSON.stringify(response));
      // const usersDetails = response.User.Attributes;
      // usersDetails.forEach((item) => {
      //  if (item.Name === "sub") {
      //    userSub = item.Value;
      //  }
      //  if (item.Name === "email") {
      //    userEmail = item.Value;
      //   }
      // });
      // console.log(compId)
      // client.graphql({
      //  query: mutations.createUserDetails,
      //  variables: {
      //  input: {
      //  id: userSub,
      //  username: d.firstName.toLowerCase(),
      //  email: userEmail,
      //   status: "Active",
      //   companyDetailsId: props.values.CompanyIdSelected? props.values.CompanyIdSelected : cdId,
      //  },
      //  },
      //  authMode: "API_KEY",
      // });

      // var params = {
      //   GroupName: "electrician" /* required */,
      //   UserPoolId: USERPOOLID /* required */,
      //    Username: d.firstName /* required */,
      //  };
      //  let groupResponse = await COGNITO_CLIENT.adminAddUserToGroup(
      //   params
      // ).promise();
      // console.log(groupResponse);
      // } catch (err) {
      //  console.log("error while adding user to cognito " + err);
      //}
      //create cognito user
      const pp = uploadedPP[idx];
      // await client.graphql({
      //  query: mutations.createSPUserDetails,
      // variables: {
      // input: {
      //  id: userSub,
      //  fname: d.firstName,
      // lname: d.lastName,
      //  email: d.email,
      //  mobile: d.mobileNumber,
      //  license: d.license,
      //  profilepic: pp,
      //  status: "Active",
      // userGroup: "electrician",
      // invitedBy: "",
      // },
      // },
      //authMode: "API_KEY",
      // })
      // .then(async (result) => {
      // console.log(result);
      udetails.push({
        // id: userSub,
        firstName: d.firstName,
        lastName: d.lastName,
        email: d.email,
        mobile: d.mobileNumber,
        license: d.license,
        profilePic: pp,
      });
      // userDetailsId.push(result.data.createSPUserDetails.id);
      // await client.graphql(
      //  {queries:mutations.updateUserDetails, {
      //    input: { id: props.values.UserId, userDetailsId: userDetailsId },
      //  })
      // );
      //})
      //.catch((error) => {
      //  console.log(error);
      //  toast.success("Error While Creating!..", {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      // });
    });
    props.values.userDetails = udetails;
    //toast.success("The User Details Saved Succesfully!..", {
    //  position: toast.POSITION.TOP_CENTER,
    //});
    props.nextStep();
    //history(`${process.env.PUBLIC_URL}/thankyou`);
    //props.nextStep();
  });

  //----------------------------------------------------------------------------------
  const onSelectFile = (e, index) => {
    const file = e.target.files[0];
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `public/${User_FOLDER}/${file.name}`,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        const filedetails = [];
        filedetails.push({ fileName: file.name, type: file.type });
        setUploadedPP((uploadedPP) => ({
          ...uploadedPP,
          [index]: filedetails,
        }));
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };

  return (
    <Fragment>
      <H6>Add Users</H6>
      <Form
        className="needs-validation"
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <ul>
          {fields.map((item, index) => {
            return (
              <li key={item.id}>
                <H3>
                  <b>User {index + 1}</b>
                </H3>
                <hr></hr>
                <Row>
                  <Col md="5 mb-3">
                    <Label>First Name</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="First Name"
                      {...register(`userDetails.${index}.firstName`, {
                        required: true,
                      })}
                    />
                  </Col>
                  <Col md="5 mb-3">
                    <Label>Last Name</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Last Name"
                      {...register(`userDetails.${index}.lastName`, {
                        required: true,
                      })}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="5 mb-3">
                    <Label>E-Mail</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="E-Mail"
                      {...register(`userDetails.${index}.email`, {
                        required: true,
                      })}
                    />
                  </Col>
                  <Col md="5 mb-3">
                    <Label>Mobile Number</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Mobile Number"
                      {...register(`userDetails.${index}.mobileNumber`, {
                        required: true,
                      })}
                    />
                  </Col>
                  <Col md="2 mb-3 text-end">
                    <Label className="m-t-50"></Label>
                    {index > 0 && (
                      <i
                        className="icofont icofont-trash"
                        onClick={() => remove(index)}
                      ></i>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="5 mb-3">
                    <Label>License #</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="License #"
                      {...register(`userDetails.${index}.license`, {
                        required: true,
                      })}
                    />
                  </Col>
                  <Col md="5 mb-3">
                    <Label>Profile Picture</Label>
                    <input
                      className="form-control"
                      type="file"
                      placeholder="Profile Picture"
                      onChange={(event) => onSelectFile(event, index)}
                      {...register(`userDetails.${index}.profilePic`, {
                        required: true,
                      })}
                    />
                  </Col>
                </Row>
              </li>
            );
          })}
        </ul>
        <br></br>
        <button
          type="button"
          className="btn btn-primary m-r-10 f-right"
          onClick={() => {
            append({
              firstName: "",
              lastName: "",
              email: "",
              mobile: "",
              license: "",
              profilePic: "",
            });
          }}
        >
          {" "}
          <i className="icofont icofont-plus"></i> Add More User{" "}
        </button>
        <br></br>
        <Btn attrBtn={{ color: "primary", onClick: props.prevStep }}>Back</Btn>
        <Btn attrBtn={{ color: "primary" }}>Continue</Btn>
        <br></br>
      </Form>
      <br></br>
    </Fragment>
  );
};
export default UsersDetails;
