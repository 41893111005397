import React, { Fragment, useState, useEffect } from "react";
import { Btn, H3 } from "../../AbstractElements";
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  CardHeader,
  Table,
  Button,
} from "reactstrap";
import Papa from "papaparse";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import moment from "moment";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  S3_BUCKET,
  REGION,
  IMPORT,
  FAILED,
  FILES,
  SECRETACCESSKEY,
  ACCESSKEYID,
  limit,
  ASSETIMPORT_S3_BUCKET,
} from "../../Config/Config";
import uuid from "react-native-uuid";
import { parse } from "ini";
import { generateClient } from "aws-amplify/api";
const XLSX = require("xlsx");
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const LogbookAssetDetails = (props) => {
  const navigate = useNavigate();
  const state = useLocation();
  const [fileData, setFileData] = useState([]);
  const [fileHeader, setFileHeader] = useState(null);
  const [tableColumnLength, setTableColumnLength] = useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const [distributionBoard, setDistributionBoard] = useState([]);
  const [assetTemplateId, setAssetTemplateId] = useState([]);
  const [assetByTemplates, setAssetByTemplates] = useState([]);
  const [logbooks, setLogbooks] = useState([]);
  const [error, setError] = useState([]);
  const [filename, setFilename] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dbError, setDBError] = useState("");
  const [fileExt, setFileExt] = useState("");
  const [maxNum, setmaxNum] = useState(0);
  const [email, setEmail] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserGroup = localStorage.getItem("groups");
  const [sitesFromFile1, setSitesFromFile1] = useState([]);

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  async function fetchSiteDetailsAO() {
    try {
      let nextToken = "";
      let sites = [];
      const result = await client.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      });
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          sites.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            nextToken = results.data.siteDetailsByAssetownerID.nextToken;
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.push(results.data.siteDetailsByAssetownerID.items);
            }
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          setSiteDetails(mergeSites.filter((item) => item.status === "Active"));
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        }
      }
    } catch (error) {
      console.log(error);
      setSiteDetails([]);
    }
  }

  useEffect(() => {
    console.log(state);
    console.log(props);

    async function fetchSiteDetailsSU() {
      await client
        .graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: LoginUserDetails.id,
            limit: limit,
          },
        })
        .then(async (dataDetails) => {
          const siteDetails =
            dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
          if (siteDetails.length > 0) {
            const promises = siteDetails.map((item) => {
              return client.graphql({
                query: queries.getSiteDetails,
                variables: {
                  id: item.sitedetailsID,
                },
                authMode: "API_KEY",
              });
            });
            Promise.all(promises).then((results) => {
              console.log(results);
              if (results.length > 0) {
                const siteDetail = results.map((items) => {
                  if (items.data.getSiteDetails !== null) {
                    return items.data.getSiteDetails;
                  }
                });
                console.log(siteDetail);
                setSiteDetails(siteDetail);
                getAssetTemplate(siteDetail);
              } else {
                setIsLoading(false);
                setSiteDetails([]);
              }
            });
          } else {
            setIsLoading(false);
            setSiteDetails([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }

    const getAssetTemplate = async (siteData) => {
      await client
        .graphql({
          query: queries.assetTemplatesByCategoriesID,
          variables: {
            categoriesID: state.state.category.id,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result.data.assetTemplatesByCategoriesID.items);
          setAssetTemplateId(result.data.assetTemplatesByCategoriesID.items);
          if (result.data.assetTemplatesByCategoriesID.items.length > 0) {
            console.log("siteDetails", siteDetails);
            getAssetsByTemplates(
              result.data.assetTemplatesByCategoriesID.items,
              siteData
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setAssetTemplateId([]);
        });
    };

    const getLogbooks = async () => {
      await client
        .graphql({
          query: queries.listLogBooks,
          variables: {
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          setLogbooks(result.data.listLogBooks.items);
        })
        .catch((error) => {
          console.log(error);
          setLogbooks([]);
        });
    };

    //--- email id to send mail
    const fetchEmailId = async () => {
      if (LoginUserGroup === "site_user") {
        await client
          .graphql({
            query: queries.getSPUserDetails,
            variables: {
              id: LoginUserDetails.id,
              limit: limit,
            },
            authMode: "API_KEY",
          })
          .then((result) => {
            console.log(result);
            const emaill = result.data.getSPUserDetails;
            console.log(emaill);
            setEmail(emaill.email);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await client
          .graphql({
            query: queries.getAssetOwner,
            variables: {
              id: LoginUserDetails.id,
              limit: limit,
            },
            authMode: "API_KEY",
          })
          .then((result) => {
            console.log(result);
            const emaill = result.data.getAssetOwner;
            console.log(emaill);
            setEmail(emaill.email);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    if (localStorage.getItem("site") === null && siteDetails.length === 0) {
      LoginUserGroup === "site_user"
        ? fetchSiteDetailsSU()
        : fetchSiteDetailsAO();
    } else {
      let activeSites = JSON.parse(base64.decode(localStorage.getItem("site")));
      setSiteDetails(activeSites.filter((item) => item.status === "Active"));
      getAssetTemplate(activeSites.filter((item) => item.status === "Active"));
    }
    if (
      localStorage.getItem("siteDB") === null &&
      distributionBoard.length === 0
    ) {
      getDistributionBoard();
    } else {
      let DB = JSON.parse(base64.decode(localStorage.getItem("siteDB")));
      setDistributionBoard(DB);
    }
    getDistributionBoard();
    getLogbooks();
    fetchEmailId();
    fetchAssetsData();
  }, []);

  async function getDistributionBoard() {
    try {
      let sites = JSON.parse(base64.decode(localStorage.getItem("site")));
      let activeSites = sites.filter((item) => item.status === "Active");
      if (activeSites.length > 0) {
        let nextToken = "";
        let db = [];
        for (let site of activeSites) {
          const DBResult = await client.graphql({
            query: queries.distributionBoxesBySitedetailsID,
            variables: {
              sitedetailsID: site.id,
            },
            authMode: "API_KEY",
          });
          let assetDatas = DBResult.data.distributionBoxesBySitedetailsID.items;
          nextToken = DBResult.data.distributionBoxesBySitedetailsID.nextToken;
          if (DBResult.data.distributionBoxesBySitedetailsID.items.length > 0) {
            db.push(assetDatas);
          }
          while (nextToken !== null) {
            const DBResult1 = await client.graphql({
              query: queries.distributionBoxesBySitedetailsID,
              variables: {
                sitedetailsID: site.id,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            });
            nextToken =
              DBResult1.data.distributionBoxesBySitedetailsID.nextToken;
            if (
              DBResult1.data.distributionBoxesBySitedetailsID.items.length > 0
            ) {
              db.push(DBResult1.data.distributionBoxesBySitedetailsID.items);
            }
          }
        }
        if (db.length > 0) {
          const mergeDB = db.flat(1);
          console.log(mergeDB);
          setDistributionBoard(mergeDB);
          localStorage.setItem(
            "siteDB",
            base64.encode(JSON.stringify(mergeDB))
          );
        }
      } else {
        setDistributionBoard([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchAssetsData() {
    await client
      .graphql({
        query: queries.assetsByTypeAndAssetNum,
        authMode: "API_KEY",
        variables: {
          limit: limit,
          type: "Assets",
          sortDirection: "DESC",
        },
      })
      .then((response) => {
        console.log(response);
        let maxnumber = 0;
        let assetsDetails = response.data.assetsByTypeAndAssetNum.items;
        if (assetsDetails.length > 0) {
          maxnumber = assetsDetails[0].assetNum;
          setmaxNum(parseInt(maxnumber) + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAssetsByTemplates(assetTemplates, siteData) {
    try {
      setIsLoading(true);
      let nextToken = "";
      let assets = [];
      let count = 0;
      assetTemplates.map((assetTemplate) => {
        count = count + 1;
        siteData.map(async (sites) => {
          let tempSiteId = assetTemplate.id + "_" + sites.id;
          await client
            .graphql({
              query: queries.assetsByAssettemplateSiteIDAndAssetNum,
              variables: {
                limit: limit,
                assettemplateSiteID: tempSiteId,
                filetr: {
                  status: {
                    ne: "delete",
                  },
                },
              },
              authMode: "API_KEY",
            })
            .then(async (result) => {
              let assetDatas =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
              nextToken =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
              console.log(
                "first token count",
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items.length
              );
              if (
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                  .length > 0
              ) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                await client
                  .graphql({
                    query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                    variables: {
                      assettemplateSiteID: tempSiteId,
                      limit: limit,
                      filetr: {
                        status: {
                          ne: "delete",
                        },
                      },
                      nextToken: nextToken,
                    },
                    authMode: "API_KEY",
                  })
                  .then((results) => {
                    nextToken =
                      results.data.assetsByAssettemplateSiteIDAndAssetNum
                        .nextToken;
                    console.log(
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                        .length
                    );
                    if (
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                        .length > 0
                    ) {
                      assets.push(
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .items
                      );
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setAssetByTemplates([]);
                    setIsLoading(false);
                  });
              }
              console.log(assets);
            })
            .catch((error) => {
              console.log(error);
              setAssetByTemplates([]);
            });
          if (count === assetTemplates.length) {
            const mergeAssets = assets.flat(1); //The depth level specifying how deep a nested array structure should be flattened. Defaults to 1.
            console.log(mergeAssets);
            setAssetByTemplates(mergeAssets);
            setIsLoading(false);
          }
        });
      });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  let assetColumn = [
    "siteName",
    "distributionboard",
    "assetDescription",
    "assetLocation",
    "assetMake",
    "assetModel",
    "fittingNumber",
    "assettemplate",
    "assetWarrantyStart",
    "assetWarrantyEnd",
    "installedOn",
    "FittingId",
    "duplicate",
    "siteId",
    "sitedetailsID",
    "logbookId",
    "assetOwnerID",
    "assettemplateID",
    "assetType",
    "assettemplateSiteID",
  ];

  const handleFileUpload = async (event) => {
    try {
      setIsLoading(true);
      let sitesFromFile = [];
      const file = event.target.files[0];
      if (file !== undefined) {
        const filename = file.name;
        const alphanumericRegex = /^[a-zA-Z0-9._\s]+$/; // Added \s to allow spaces
        if (!alphanumericRegex.test(filename)) {
          setIsLoading(false);
          setDBError("disabled");
          toast.error(
            "Filename must be alphanumeric and cannot contain special characters!",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
          return;
        } else {
          var excelData = "";
          setFilename(file);
          console.log(file);
          const fileNamespace = file.name.replace(/\s+/g, "");
          console.log(fileNamespace);

          const extension = file.name.split(".").pop().toLowerCase();
          setFileExt(extension);
          var matchHeaderWithColumn = true;
          if (extension === "csv") {
            Papa.parse(file, {
              header: true,
              skipEmptyLines: true,
              complete: (result) => {
                const rows = result.data;
                rows.map((obj, index) => {
                  if (
                    index !==
                    rows.findIndex(
                      (o) =>
                        obj.fittingNumber === o.fittingNumber &&
                        obj.distributionboard === o.distributionboard &&
                        obj.siteName === o.siteName
                    )
                  ) {
                    obj.duplicate =
                      "Duplicate Fitting Number for Site and Distribution Board.";
                  }
                });
                console.log(rows);
                let assetData = rows;
                console.log(assetData);
                let db = [];
                try {
                  setDBError("");
                  assetData.map((item) => {
                    if (
                      item.distributionboard !== "" &&
                      item.distributionboard !== null
                    ) {
                      if (item.distributionboard.toLowerCase() !== "unknown") {
                        item.assetWarrantyStart = moment(
                          item.assetWarrantyStart
                        ).format("DD/MM/YYYY");
                        item.assetWarrantyEnd = moment(
                          item.assetWarrantyEnd
                        ).format("DD/MM/YYYY");
                        item.installedOn = moment(item.installedOn).format(
                          "DD/MM/YYYY"
                        );
                        db.push({
                          db: item.distributionboard.trim(),
                          site: item.siteName.trim(),
                        });
                      }
                    }
                  });
                  let uniqueChars = db
                    .map(JSON.stringify)
                    .reverse() // convert to JSON string the dbay content, then reverse it (to check from end to begining)
                    .filter(function (item, index, db) {
                      return db.indexOf(item, index + 1) === -1;
                    }) // check if there is any occurence of the item in whole array
                    .reverse()
                    .map(JSON.parse);
                  console.log(uniqueChars);
                  try {
                    const promises = uniqueChars.map((db) => {
                      var siteId = siteDetails.filter((site) => {
                        return (
                          site.siteName.toLowerCase().trim() ===
                          db.site.toLowerCase().trim()
                        );
                      });
                      let dbData = distributionBoard.filter((item) => {
                        return item.distributionBoxName !== null
                          ? item.distributionBoxName.toLowerCase() ===
                              db.db.toLowerCase() &&
                              siteId[0].id === item.sitedetailsID
                          : "";
                      });
                      console.log(dbData);
                      if (dbData.length === 0) {
                        var siteId = siteDetails.filter((site) => {
                          return (
                            site.siteName.toLowerCase().trim() ===
                            db.site.toLowerCase().trim()
                          );
                        });
                        return client.graphql({
                          query: mutations.createDistributionBox,
                          variables: {
                            input: {
                              distributionBoxName: db.db,
                              sitedetailsID: siteId[0].id,
                              status: "Active",
                            },
                          },
                          authMode: "API_KEY",
                        });
                      }
                    });
                    Promise.all(promises)
                      .then(async (results) => {
                        console.log(results);
                        await appendDistributionBoard(results);
                      })
                      .catch((error) => {
                        console.log(error);
                        setErrorMessage("Unable to fetch the list");
                      });
                  } catch (e) {
                    console.log(e);
                    if (
                      state.state.category.categoryName === "Emergency Lights"
                    ) {
                      setDBError("disabled");
                      toast.error(
                        "Distribution Board Missing!!!!. Please check the data and import again.",
                        {
                          position: toast.POSITION.TOP_CENTER,
                        }
                      );
                    }
                  }
                } catch (error) {
                  console.log(error);
                }
                const headers = Object.keys(result.data[0]);
                headers.map((item) => {
                  if (item !== null) {
                    if (!assetColumn.includes(item)) {
                      if (!item.includes("custom-")) {
                        matchHeaderWithColumn = false;
                      }
                    }
                  }
                });
                console.log(headers);
                console.log(rows);
                console.log(matchHeaderWithColumn);
                if (!matchHeaderWithColumn) {
                  setFileData([]);
                  setFileHeader([]);
                  setTableColumnLength([]);
                  setErrorMessage(!errorMessage);
                  setIsLoading(false);
                } else {
                  setFileData(assetData);
                  setFileHeader(headers);
                  setTableColumnLength(headers);
                  if (errorMessage) {
                    setErrorMessage(!errorMessage);
                  }
                  setIsLoading(false);
                }
              },
              error: (error) => {
                console.log(error);
              },
            });
          } else if (extension === "xls" || extension === "xlsx") {
            const jsonOpts = {
              header: 1,
              blankrows: false,
              defval: null,
              raw: false,
              cellDates: true,
              dateNF: "mm/dd/yyyy",
            };
            const reader = new FileReader();
            reader.onload = async (event) => {
              let data = new Uint8Array(event.target.result);
              const workbook = XLSX.read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const rows = XLSX.utils.sheet_to_json(worksheet, jsonOpts);
              console.log("rows", rows);
              excelData = rows.slice(1);
              const headers = rows.splice(0, 1)[0];
              headers.push("duplicate");
              console.log(headers);
              let importData = [];
              excelData.map((item) => {
                let excelObject = {};
                item.map((val, i) => {
                  excelObject[headers[i]] = val;
                });
                if (Object.keys(excelObject).length > 0) {
                  importData.push(excelObject);
                }
              });
              importData = importData.filter(
                (o) => !Object.keys(o).every((k) => !o[k])
              );
              console.log(importData);
              let assetData = [];
              assetData.push(Object.keys(importData[0]));
              importData.map((item) => {
                assetData.push(Object.values(item));
              });
              console.log(assetData);
              let db = [];
              try {
                setDBError("");
                assetData.map((item) => {
                  if (item[assetData[0].indexOf("siteName")] !== null) {
                    sitesFromFile.push(item[assetData[0].indexOf("siteName")]);
                  }
                  if (
                    item[assetData[0].indexOf("distributionboard")] !==
                      "distributionboard" &&
                    item[assetData[0].indexOf("distributionboard")] !== null
                  ) {
                    item[assetData[0].indexOf("assetWarrantyStart")] = moment(
                      item[assetData[0].indexOf("assetWarrantyStart")]
                    ).format("DD/MM/YYYY");
                    item[assetData[0].indexOf("assetWarrantyEnd")] = moment(
                      item[assetData[0].indexOf("assetWarrantyEnd")]
                    ).format("DD/MM/YYYY");
                    item[assetData[0].indexOf("installedOn")] = moment(
                      item[assetData[0].indexOf("installedOn")]
                    ).format("DD/MM/YYYY");

                    if (
                      item[assetData[0].indexOf("distributionboard")] !==
                        null &&
                      item[
                        assetData[0].indexOf("distributionboard")
                      ].toLowerCase() !== "unknown"
                    ) {
                      db.push({
                        db: item[
                          assetData[0].indexOf("distributionboard")
                        ].trim(),
                        site:
                          item[
                            assetData[0].indexOf("distributionboard") - 1
                          ] !== null
                            ? item[
                                assetData[0].indexOf("distributionboard") - 1
                              ].trim()
                            : "",
                      });
                    }
                  }
                });
                console.log("sitesFromFile", sitesFromFile);
                // let uniqueChars = [...new Set(db)];
                let uniqueChars = db
                  .map(JSON.stringify)
                  .reverse()
                  .filter(function (item, index, db) {
                    return db.indexOf(item, index + 1) === -1;
                  })
                  .reverse()
                  .map(JSON.parse);
                console.log(uniqueChars);
                setSitesFromFile1([...new Set(sitesFromFile)]);
                try {
                  const promises = uniqueChars.map((db) => {
                    var siteId = siteDetails.filter((site) => {
                      return (
                        site.siteName.toLowerCase().trim() ===
                        db.site.toLowerCase().trim()
                      );
                    });
                    if (siteId.length > 0) {
                      let dbData = distributionBoard.filter((item) => {
                        return item.distributionBoxName !== null
                          ? item.distributionBoxName.toLowerCase() ===
                              db.db.toLowerCase() &&
                              siteId[0].id === item.sitedetailsID
                          : "";
                      });
                      console.log(dbData);
                      if (dbData.length === 0) {
                        var siteId = siteDetails.filter((site) => {
                          return (
                            site.siteName.toLowerCase().trim() ===
                            db.site.toLowerCase().trim()
                          );
                        });
                        return client.graphql({
                          query: mutations.createDistributionBox,
                          variables: {
                            input: {
                              distributionBoxName: db.db,
                              sitedetailsID: siteId[0].id,
                              status: "Active",
                            },
                          },
                          authMode: "API_KEY",
                        });
                      }
                    }
                  });
                  Promise.all(promises)
                    .then(async (results) => {
                      console.log(results);
                      if (results[0] !== undefined) {
                        await appendDistributionBoard(results);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      setErrorMessage("Unable to fetch the list");
                    });
                } catch (e) {
                  console.log(e);
                  if (
                    state.state.category.categoryName === "Emergency Lights"
                  ) {
                    setDBError("disabled");
                    toast.error(
                      "Distribution Board Missing!!!!. Please check the data and import again.",
                      {
                        position: toast.POSITION.TOP_CENTER,
                      }
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              var matchHeaderWithColumn = true;
              headers.map((item) => {
                if (item !== null) {
                  if (!assetColumn.includes(item)) {
                    if (!item.includes("custom-")) {
                      matchHeaderWithColumn = false;
                    }
                  }
                }
              });
              if (!matchHeaderWithColumn) {
                setFileData([]);
                setFileHeader([]);
                setTableColumnLength([]);
                setErrorMessage(!errorMessage);
                setIsLoading(false);
              } else {
                setFileData(excelData.slice(1));
                setFileHeader(headers);
                setTableColumnLength(headers);
                if (errorMessage) {
                  setErrorMessage(!errorMessage);
                }
                setIsLoading(false);
              }
            };
            reader.readAsArrayBuffer(file);
          } else {
            setIsLoading(false);
            alert("Unsupported file format");
          }
        }
      } else {
        setFileData([]);
        setFileHeader([]);
        setTableColumnLength([]);
        setErrorMessage(!errorMessage);
        setIsLoading(false);
        toast.info("File not selected", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  async function appendDistributionBoard(params) {
    try {
      for (let db of params) {
        const addedsite = await client.graphql({
          query: queries.getDistributionBox,
          variables: {
            id: db.data.createDistributionBox.id,
          },
          authMode: "API_KEY",
        });
        if (addedsite.data.getDistributionBox !== null) {
          if (localStorage.getItem("siteDB") !== null) {
            let activeDB = JSON.parse(
              base64.decode(localStorage.getItem("siteDB"))
            );
            console.log(activeDB);
            activeDB.push(addedsite.data.getDistributionBox);
            console.log(activeDB);
            setDistributionBoard(activeDB);
            localStorage.setItem(
              "siteDB",
              base64.encode(JSON.stringify(activeDB))
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [importlogid, setImportlogid] = useState("");
  const handleSave = async () => {
    setIsLoading(true);
    //-- appending todays date and time to error file
    const dateTime = new Date().toISOString();
    console.log(dateTime);
    //--save file to s3 bucket--
    console.log(filename);
    const fileNamespace = filename.name.replace(/\s+/g, "");
    console.log(fileNamespace);
    //const file = e.target.files[0];
    const params = {
      ACL: "public-read",
      Body: filename,
      Bucket: S3_BUCKET,
      Key: `public/import/${FILES}/${fileNamespace}`,
    };
    await myBucket
      .putObject(params)
      .on("httpUploadProgress", async (evt) => {
        console.log(evt);
      })
      .send((err) => {
        if (err) console.log(err);
      });
    try {
      const sourcefilelocation = [];
      sourcefilelocation.push({
        fileName: fileNamespace,
        type: filename.type,
      });
      console.log(sourcefilelocation);
      const importLogs = await client.graphql({
        query: mutations.createImportLogs,
        variables: {
          input: {
            dataType: "Assets",
            sourceFileLocation: sourcefilelocation,
            impotedBy: LoginUserDetails.id,
            categoryId: state.state.category.id,
          },
        },
        authMode: "API_KEY",
      });
      if (importLogs.data.createImportLogs !== null) {
        setImportlogid(importLogs.data.createImportLogs.id);
        let fileSplit = fileNamespace.split(".");
        let tranfereId = importLogs.data.createImportLogs.id.replace("-", "_");
        let newFileName = fileSplit[0] + "_" + tranfereId + "." + fileSplit[1];
        console.log("file", filename);
        console.log("newFileName", newFileName);
        const params = {
          ACL: "public-read",
          Body: filename,
          Bucket: ASSETIMPORT_S3_BUCKET,
          Key: newFileName,
        };
        await myBucket
          .putObject(params)
          .on("httpUploadProgress", async (evt) => {
            console.log(evt);
          })
          .send((err) => {
            if (err) console.log(err);
            let dataCount = parseInt(fileData.length) + 1;
            toast.success(
              dataCount +
                " Assets are queued to import. Once import is completed we will notify through email to " +
                email +
                ".",
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false,
              }
            );
            setFileData([]);
            setFileHeader([]);
            setTableColumnLength([]);
            setIsLoading(false);
            cancelForm();
          });
      }
    } catch (error) {}
  };

  const handleExport = (tblName) => {
    console.log(tblName);
    if (tblName === "Logbook") {
      var elt = document.getElementById(tblName);
      var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
      return XLSX.writeFile(wb, tblName + ".xlsx");
    } else {
      client
        .graphql({
          query: queries.listStaticForms,
          variables: {
            limit: limit,
            filter: {
              formName: {
                eq: tblName,
              },
            },
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result.data.listStaticForms.items[0].formFields);
          console.log(typeof result.data.listStaticForms.items[0].formFields);
          var dt = JSON.parse(result.data.listStaticForms.items[0].formFields);
          //console.log(JSON.parse(dt[0]));
          var elt = dt[0];
          var data = XLSX.utils.json_to_sheet(elt);
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, data, tblName);
          return XLSX.writeFile(wb, tblName + ".xlsx");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/assets`, {
      state: {
        category: state.state.category,
        site: state.state.site,
      },
    });
  };

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <CardHeader>
              <Col md="12" className="text-end">
                {state.state && state.state.category && state.state.site ? (
                  <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>
                    Back
                  </Btn>
                ) : (
                  ""
                )}
              </Col>
              {state.state && state.state.category ? (
                <H3>Bulk Import For {state.state.category.categoryName}</H3>
              ) : (
                ""
              )}
              {state.state && state.state.category ? (
                <span className="d-block">
                  Import Logbook for{" "}
                  {state.state.category.categoryName === "Emergency Lights" ? (
                    <a
                      class="downloadLink"
                      onClick={() => handleExport("emergencylights")}
                    >
                      {" "}
                      Emergency Lights
                    </a>
                  ) : state.state.category.categoryName === "RCD's" ? (
                    <a class="downloadLink" onClick={() => handleExport("rcd")}>
                      {" "}
                      RCD's
                    </a>
                  ) : state.state.category.categoryName ===
                    "Appliance Testing" ? (
                    <a
                      class="downloadLink"
                      onClick={() => handleExport("appliancetesting")}
                    >
                      {" "}
                      Appliance Testing
                    </a>
                  ) : (
                    <a
                      class="downloadLink"
                      onClick={() => handleExport("Logbook")}
                    >
                      {" "}
                      {state.state.category.categoryName}
                    </a>
                  )}
                </span>
              ) : (
                " "
              )}
              <Table
                className="table-striped  table-de"
                style={{ display: "none" }}
                id="Logbook"
              >
                <thead className="custom-table-head">
                  <tr>
                    <th>siteName</th>
                    <th>distributionboard</th>
                    <th>assetDescription</th>
                    <th>assetLocation</th>
                    <th>assetMake</th>
                    <th>assetModel</th>
                    <th>fittingNumber</th>
                    <th>assettemplate</th>
                    <th>assetWarrantyStart</th>
                    <th>assetWarrantyEnd</th>
                    <th>installedOn</th>
                    <th>
                      Any custom label for the{" "}
                      {state.state && state.state.category && state.state.site
                        ? state.state.category.categoryName
                        : "Assets"}{" "}
                      Template with "custom-" as the prefix
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>
              </Table>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="5">
                  <input
                    type="file"
                    className="form-control"
                    accept=".csv,.xls,.xlsx"
                    onChange={handleFileUpload}
                  />
                </Col>
                {fileData.length > 0 && (
                  <Col md="3">
                    <span>
                      Total Assets in the uploaded file - {fileData.length + 1}.
                    </span>
                  </Col>
                )}
                <Col md="4">
                  <Btn
                    attrBtn={{
                      color: "primary",
                      onClick: handleSave,
                      disabled: dbError,
                    }}
                  >
                    Import Logbook Details
                  </Btn>
                  {!state.state && (
                    <Btn
                      attrBtn={{ color: "primary", onClick: props.prevStep }}
                    >
                      Back
                    </Btn>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {errorMessage && (
                    <span className="txt-danger">
                      Data Columns are not matching the sample import template.
                      Please check the sample import template by downloading.
                    </span>
                  )}
                </Col>
              </Row>
              {/* {fileData.length > 0 && (
                <Row>
                  <Col md="12">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr key={"header"}>
                          {tableColumnLength.map((key) => (
                            <th>{key}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {fileData.map((item, i) => (
                          <tr key={i}>
                            {Object.values(item).map((val) => (
                              <td>{val}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )} */}
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
  return <div>{isLoading ? <LoadingSpinner /> : renderList}</div>;
};

export default LogbookAssetDetails;
