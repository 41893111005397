import React, {
  Fragment,
  useState,
  useEffect,
  createRef,
  useRef,
  useContext,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { Btn, H5, H4, H6, LI, P, UL } from "../../AbstractElements";
import {
  Col,
  Container,
  Form,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  CardBody,
  Card,
  CardHeader,
  Media,
  Table,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ScrollBar from "react-perfect-scrollbar";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import AWS from "aws-sdk";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  S3_BUCKET,
  CATEGORIES_FOLDER,
  REGION,
  SECRETACCESSKEY,
  ACCESSKEYID,
  limit,
} from "../../Config/Config";

import { SiteContext } from "../../App";
import { aocontext } from "../../App";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const CateGoryCertificates = () => {
  const [isActive, setIsActive] = useState(true);
  const [assets, setAssets] = useState([]);
  const [distributionBoxFilter, setDistributionBoxfilter] = useState([]);
  const [distributionBoxList, setDistributionBoxList] = useState([]);
  const [sites, setSites] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [fullAssets, setFullAssets] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [serviceGroup, setServiceGroup] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [collection, setCollection] = useState([]);
  const [certificateDetails, setCertificateDetails] = useState([]);
  const [formDetails, setFormDetails] = useState([]);
  const [formIds, setFormIds] = useState([]);
  const state = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const LoginUserGroup = localStorage.getItem("groups");
  const LoggedInUserId = LoginUserDetails.id;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const { selectedAssetOwnerGlobal, handleAssetOwner } = useContext(aocontext);
  //const [limit, setLimit] = useState(100000);
  console.log(selectedSiteGlobal);

  const globalSearch = (searchValue) => {
    setCurrentPage(1);
    const filteredData = categoryDetails.filter((item) => {
      const serviceGroup = getServiceGroup(item.servicegroupID) || "";
      return (
        item.categoryName
          ?.toLowerCase()
          .includes(searchValue.trim().toLowerCase()) ||
        serviceGroup.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
        item.formDescription
          ?.toLowerCase()
          .includes(searchValue.trim().toLowerCase()) ||
        item.status?.toLowerCase().includes(searchValue.trim().toLowerCase())
      );
    });
    setCollection(filteredData);
  };

  const getFormIds = (e) => {
    if (e.target.checked) {
      console.log(e.target.id);
      const fId = [...formIds];
      fId.push(e.target.id);
      setFormIds(fId);
    } else {
      console.log(formIds.indexOf(e.target.id));
    }
  };

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      globalSearch(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(categoryDetails);
  };

  //------------------------list------

  async function fetchCategoriesData() {
    setIsLoading(true);
    const response = await client.graphql({
      query: queries.listCategories,
      authMode: "API_KEY",
      variables: {
        limit: limit,
      },
    });
    if (response.data.listCategories.items.length > 0) {
      setIsLoading(false);
      const sortItems = response.data.listCategories.items.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      console.log(sortItems);
      const categoriesDetails = sortItems;
      console.log(categoriesDetails);
      setCategoryDetails(categoriesDetails);
      const configuredCollections = getConfiguredCollection(categoriesDetails);
      setCollection(categoriesDetails);
      setCategoryId(categoriesDetails.map((category) => category.id));
    } else {
      setIsLoading(false);
      setCategoryDetails([]);
      setCollection([]);
      setCategoryId([]);
    }
  }

  useEffect(() => {
    async function fetchFormDetailsData() {
      const result = await client.graphql({
        query: queries.listFormDetails,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      });
      if (result.data.listFormDetails.items.length > 0) {
        console.log(result);
        const formDetails = result.data.listFormDetails.items;
        console.log(formDetails);
        setFormDetails(formDetails);
      } else {
        setFormDetails([]);
      }
    }
    fetchFormDetailsData();
    fetchCategoriesData();
    async function fetchServiceGroups() {
      const result = await client.graphql({
        query: queries.listServiceGroups,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      });
      if (result.data.listServiceGroups.items.length > 0) {
        const serviceGroups = result.data.listServiceGroups.items;
        console.log(serviceGroups);
        setServiceGroup(serviceGroups);
      } else {
        setServiceGroup([]);
      }
    }
    fetchServiceGroups();
    //fetchListCertificates();
    if (LoginUserGroup === "site_user") {
      fetchListCertificatesSU();
    } else if (LoginUserGroup === "electrician") {
      fetchListCertificatesSP();
    } else {
      fetchListCertificates();
    }
  }, [selectedAssetOwnerGlobal]);
  // toggle
  const activeInactive = () => {
    setIsActive(!isActive);
  };

  const getServiceGroup = (id) => {
    const groupName = serviceGroup.filter((item) => {
      return item.id === id;
    });
    if (groupName.length > 0) {
      return groupName[0].serviceName;
    } else {
      return "";
    }
  };

  const getfilename = async (path) => {
    if (!path || path.trim() === "") {
      return null;
    }
    const files = JSON.parse(path);
    if (files.length > 0) {
      var newfilename = files[0].fileName;
      const imageUrl = await getImage(newfilename);
      return imageUrl;
    } else {
      return null;
    }
  };

  const getConfiguredCollection = (filteredData) => {
    filteredData.forEach(async (data) => {
      //console.log("imageURL", data?.imageURL)
      if (data?.imageURL?.length) {
        data["image"] = await getfilename(data?.imageURL);
      }
    });
    return filteredData;
  };

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const getImage = async (filename) => {
    var params = {
      Bucket: S3_BUCKET,
      Key: `public/${CATEGORIES_FOLDER}/${filename}`,
    };
    try {
      const url = await myBucket.getSignedUrlPromise("getObject", params);
      return url;
    } catch (err) {
      return null;
    }
  };

  function countCertificatesForCategory(categoriesID) {
    const filteredAssets = certificateDetails.filter(
      (asset) => asset.categoriesID === categoriesID
    );
    return filteredAssets.length;
  }

  // --
  async function fetchListCertificates() {
    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.siteDetailsByAssetownerID,
      variables: {
        assetownerID: LoginUserDetails.id,
        filter: {
          status: {
            eq: "Active",
          },
        },
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.siteDetailsByAssetownerID.items.length > 0) {
      let assetDatas = result.data.siteDetailsByAssetownerID.items;
      nextToken = result.data.siteDetailsByAssetownerID.nextToken;
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: limit,
            filter: {
              status: {
                eq: "Active",
              },
            },
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.siteDetailsByAssetownerID.items.length > 0) {
          nextToken = results.data.siteDetailsByAssetownerID.nextToken;
          assets.push(results.data.siteDetailsByAssetownerID.items);
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        let nextToken1 = "";
        let assets1 = [];
        for (let item of mergeAssets) {
          const result = await client.graphql({
            query: queries.certificatesBySitedetailsID,
            variables: {
              sitedetailsID: item.id,
              limit: limit,
            },
            authMode: "API_KEY",
          });
          if (result.data.certificatesBySitedetailsID.items.length > 0) {
            let assetDatas = result.data.certificatesBySitedetailsID.items;
            nextToken1 = result.data.certificatesBySitedetailsID.nextToken;
            assets1.push(assetDatas);
            while (nextToken1 !== null) {
              const results = await client.graphql({
                query: queries.certificatesBySitedetailsID,
                variables: {
                  sitedetailsID: item.id,
                  limit: limit,
                  nextToken: nextToken1,
                },
                authMode: "API_KEY",
              });
              if (results.data.certificatesBySitedetailsID.items.length > 0) {
                nextToken1 = results.data.certificatesBySitedetailsID.nextToken;
                assets1.push(results.data.certificatesBySitedetailsID.items);
              }
            }
            console.log(assets1);
            if (assets1.length > 0) {
              const mergeAssets1 = assets1.flat(1);
              console.log(mergeAssets1);
              //--
              const sortItems = mergeAssets1.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
              console.log(sortItems);
              console.log(LoggedInUserId);
              const certificateDetails = sortItems;
              console.log(certificateDetails);
              const filterCerti = sortItems.filter(
                (item) => item.sitedetailsID === selectedSiteGlobal
              );
              console.log(filterCerti);
              const newCertificates = selectedSiteGlobal
                ? filterCerti
                : certificateDetails;
              setCertificateDetails(newCertificates);
            }
          }
        }
      } else {
        setCertificateDetails([]);
      }
    }
  }

  //-- site user certificates
  async function fetchListCertificatesSU(catId) {
    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      variables: {
        userdetailsID: LoginUserDetails.id,
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
      nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;

      if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          variables: {
            userdetailsID: LoginUserDetails.id,
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          nextToken = results.data.linkUsersAndSitesByUserdetailsID.nextToken;

          if (results.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
            assets.push(results.data.linkUsersAndSitesByUserdetailsID.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        let nextToken1 = "";
        let assets1 = [];
        for (let item of mergeAssets) {
          const result = await client.graphql({
            query: queries.certificatesBySitedetailsID,
            variables: {
              sitedetailsID: item.sitedetailsID,
              limit: limit,
            },
            authMode: "API_KEY",
          });
          if (result.data.certificatesBySitedetailsID.items.length > 0) {
            let assetDatas = result.data.certificatesBySitedetailsID.items;
            nextToken1 = result.data.certificatesBySitedetailsID.nextToken;

            if (result.data.certificatesBySitedetailsID.items.length > 0) {
              assets1.push(assetDatas);
            }
            while (nextToken1 !== null) {
              const results = await client.graphql({
                query: queries.certificatesBySitedetailsID,
                variables: {
                  sitedetailsID: item.sitedetailsID,
                  limit: limit,
                  nextToken: nextToken1,
                },
                authMode: "API_KEY",
              });
              if (results.data.certificatesBySitedetailsID.items.length > 0) {
                nextToken1 = results.data.certificatesBySitedetailsID.nextToken;

                if (results.data.certificatesBySitedetailsID.items.length > 0) {
                  assets1.push(results.data.certificatesBySitedetailsID.items);
                }
              }
            }
            console.log(assets1);
            if (assets1.length > 0) {
              const mergeAssets1 = assets1.flat(1);
              console.log(mergeAssets1);
              //--
              const sortItems = mergeAssets1.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
              console.log(sortItems);
              const certificateDetails = sortItems;
              console.log(certificateDetails);
              const filterCerti = sortItems.filter(
                (item) => item.sitedetailsID === selectedSiteGlobal
              );
              console.log(filterCerti);
              const newCertificates = selectedSiteGlobal
                ? filterCerti
                : certificateDetails;
              setCertificateDetails(newCertificates);
              setIsLoading(false);
            }
          }
        }
      } else {
        setCertificateDetails([]);
        setIsLoading(false);
      }
    }
  }

  //---sp ---
  async function fetchListCertificatesSP(catId) {
    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      variables: {
        userdetailsID: LoginUserDetails.id,
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
      nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;

      if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          variables: {
            userdetailsID: LoginUserDetails.id,
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          nextToken = results.data.linkUsersAndSitesByUserdetailsID.nextToken;

          if (results.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
            assets.push(results.data.linkUsersAndSitesByUserdetailsID.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        const siteFilterArray = [];
        mergeAssets.map(async (item) => {
          siteFilterArray.push(item.sitedetailsID);
        });
        let nextToken1 = "";
        let assets1 = [];
        const result = await client.graphql({
          query: queries.listSiteDetails,
          variables: {
            limit: limit,
            filter: {
              status: {
                eq: "Active",
              },
            },
          },
          authMode: "API_KEY",
        });
        if (result.data.listSiteDetails.items.length > 0) {
          let assetDatas = result.data.listSiteDetails.items;
          nextToken1 = result.data.listSiteDetails.nextToken;

          if (result.data.listSiteDetails.items.length > 0) {
            assets1.push(assetDatas);
          }
          while (nextToken1 !== null) {
            const results = await client.graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: limit,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
                nextToken: nextToken1,
              },
              authMode: "API_KEY",
            });
            if (results.data.listSiteDetails.items.length > 0) {
              nextToken1 = results.data.listSiteDetails.nextToken;

              if (results.data.listSiteDetails.items.length > 0) {
                assets1.push(results.data.listSiteDetails.items);
              }
            }
          }
          console.log(assets1);
          if (assets1.length > 0) {
            const mergeAssets1 = assets1.flat(1);
            console.log(mergeAssets1);
            //--
            const siteDetail = mergeAssets1.filter((item) => {
              const _matchSites = siteFilterArray?.find(
                (assetTemp) => assetTemp === item?.id
              );
              if (_matchSites) {
                return item;
              }
            });
            const data = siteDetail.filter(
              (item) => item.assetownerID === selectedAssetOwnerGlobal
            );
            const filterdata = selectedAssetOwnerGlobal ? data : siteDetail;
            console.log(filterdata);

            let nextToken2 = "";
            let assets2 = [];
            for (let item of filterdata) {
              const result = await client.graphql({
                query: queries.certificatesBySitedetailsID,
                variables: {
                  sitedetailsID: item.id,
                  limit: limit,
                },
                authMode: "API_KEY",
              });
              if (result.data.certificatesBySitedetailsID.items.length > 0) {
                let assetDatas = result.data.certificatesBySitedetailsID.items;
                nextToken2 = result.data.certificatesBySitedetailsID.nextToken;

                if (result.data.certificatesBySitedetailsID.items.length > 0) {
                  assets2.push(assetDatas);
                }
                while (nextToken2 !== null) {
                  const results = await client.graphql({
                    query: queries.certificatesBySitedetailsID,
                    variables: {
                      sitedetailsID: item.sitedetailsID,
                      limit: limit,
                      nextToken: nextToken2,
                    },
                    authMode: "API_KEY",
                  });
                  if (
                    results.data.certificatesBySitedetailsID.items.length > 0
                  ) {
                    nextToken2 =
                      results.data.certificatesBySitedetailsID.nextToken;

                    if (
                      results.data.certificatesBySitedetailsID.items.length > 0
                    ) {
                      assets2.push(
                        results.data.certificatesBySitedetailsID.items
                      );
                    }
                  }
                }
                console.log(assets2);
                if (assets2.length > 0) {
                  const mergeAssets2 = assets2.flat(1);
                  console.log(mergeAssets2);
                  //--
                  const sortItems = mergeAssets2.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  });
                  console.log(sortItems);
                  const certificateDetails = sortItems;
                  console.log(certificateDetails);
                  const filterCerti = sortItems.filter(
                    (item) => item.sitedetailsID === selectedSiteGlobal
                  );
                  console.log(filterCerti);
                  const newCertificates = selectedSiteGlobal
                    ? filterCerti
                    : certificateDetails;
                  setCertificateDetails(newCertificates);
                  setIsLoading(false);
                }
              }
            }
          }
        }
      } else {
        setCertificateDetails([]);
        setIsLoading(false);
      }
    }
  }

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <Row>
              <Col md="6">
                <InputGroup>
                  <span className="input-group-text bg-transparent border-end-0">
                    <i className="icon-search"></i>
                  </span>
                  <Input
                    type="search"
                    placeholder="Search Category.."
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    className="border-start-0 py-3 ps-0"
                  />
                </InputGroup>
              </Col>
              <Col md="6">
                <div className="d-flex align-items-center justify-content-end h-100">
                  <p className={`mb-0 ${isActive && "fw-semibold"}`}>Active</p>
                  <Media body className="icon-stateCat switch-smcat px-2">
                    <Label className="switchCat mb-0">
                      <input type="checkbox" onClick={activeInactive} />
                      <span
                        className={
                          isActive
                            ? "switch-state bg-success"
                            : "switch-state bg-danger"
                        }
                      ></span>
                    </Label>
                  </Media>
                  <p className={`mb-0 ${!isActive && "fw-semibold"}`}>
                    Inactive
                  </p>
                </div>
              </Col>
            </Row>
          </Card>
          <Card className="custom-card">
            <Row>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  {collection.filter((item) =>
                    isActive
                      ? item.status === "Active"
                      : item.status === "Inactive"
                  ).length > 0 ? (
                    collection &&
                    collection
                      .filter((catItem) =>
                        isActive
                          ? catItem.status === "Active"
                          : catItem.status === "Inactive"
                      )
                      .map((catItem) => (
                        <Col md="3" key={catItem.id}>
                          <Link
                            to={`${process.env.PUBLIC_URL}/catCertificates`}
                            state={{ category: catItem }}
                          >
                            <Card className="category-list rounded-2">
                              <img
                                className="card-img"
                                src={catItem.image}
                                alt={catItem.categoryName}
                              ></img>
                              <div className="card-img-overlay text-white p-0 overlay-dark">
                                <CardBody className="d-flex flex-column p-0 h-100">
                                  <div className="d-flex justify-content-end">
                                    <div
                                      className={
                                        catItem.status === "Active"
                                          ? "ribbon ribbon-clip-right ribbon-right ribbon-success"
                                          : "ribbon ribbon-clip-right ribbon-right ribbon-danger"
                                      }
                                    >
                                      {catItem.status}
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between flex-column h-100">
                                    <p className="activestatus text-white pt-4 pb-5">
                                      {catItem.categoryName}
                                    </p>
                                    <Media className="static-widget">
                                      <Media body>
                                        <H4
                                          id="status"
                                          attrH6={{ className: "font-roboto" }}
                                        >
                                          {getServiceGroup(
                                            catItem.servicegroupID
                                          )}
                                        </H4>
                                        <H6>
                                          Certificates Count:{" "}
                                          {countCertificatesForCategory(
                                            catItem.id
                                          )}
                                        </H6>
                                      </Media>
                                    </Media>
                                  </div>
                                  <br></br>
                                </CardBody>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                      ))
                  ) : (
                    <p>No Category Found</p>
                  )}{" "}
                </>
              )}
            </Row>
          </Card>
          <br></br> <br></br>
        </Col>
      </Container>
    </Fragment>
  );
  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default CateGoryCertificates;
