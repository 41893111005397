import { useState, useRef, useEffect, useCallback } from "react";
import * as queries from "../../../graphql/queries";
import { SiteDetails } from "../../../models";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const useInfiniteLoading = ({
  isSearch,
  isSecoundSearch,
  isReset,
  catid,
  assetTemplatData,
  selectedSite,
  value,
  selectedBox,
  searchAsset,
  siteDetails,
}) => {
  const [items, setItems] = useState([]);
  const pageToLoad = useRef(
    new URLSearchParams(window.location.search).get("page") || 1
  );
  const initialPageLoaded = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserDetailsId =
    LoginUserDetails.hasOwnProperty("id") && LoginUserDetails.id !== null
      ? LoginUserDetails.id
      : [];
  const nextTokenRef = useRef(null);
  const isLoading = useRef(false);
  const [siteIndex, setSiteIndex] = useState(0);
  // console.log("assetTemplatData", assetTemplatData);
  var group = localStorage.getItem("groups");

  const getItems = async () => {
    setHasMore(true);
    let siteId = siteDetails[siteIndex].id;
    if (assetTemplatData.length > 0) {
      if (isLoading.current || !hasMore) return;
      isLoading.current = true;
      try {
        let assets = [];
        let count = 0;
        let nextToken = "";
        //siteDetails.map(async (site) => {
        assetTemplatData.map(async (item) => {
          count = count + 1;
          let assetSiteTemp = item.id + "_" + siteId;
          await client
            .graphql({
              query: queries.assetsByAssettemplateSiteIDAndAssetNum,
              variables: {
                assettemplateSiteID: assetSiteTemp,
                limit: 5000000,
                nextToken: nextTokenRef.current,
              },
              authMode: "API_KEY",
            })
            .then(async (response) => {
              console.log(response);
              const data = response.data.assetsByAssettemplateSiteIDAndAssetNum;
              nextToken = data.nextToken;
              if (data.items.length > 0) {
                assets.push(data.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          if (count === assetTemplatData.length) {
            const mergeAssets = assets.flat(1);
            nextTokenRef.current = nextToken;
            setItems((prevItems) => [...prevItems, ...mergeAssets]);
            setHasMore(nextToken !== null);
            isLoading.current = false;
            if (nextToken === null) {
              console.log(siteIndex);
              setSiteIndex(siteIndex + 1);
            }
            if (mergeAssets.length < 800) {
              console.log(siteIndex);
              setSiteIndex(siteIndex + 1);
            }
          }
        });
        //});
      } catch (error) {
        console.error("Error fetching data:", error);
        isLoading.current = false;
      }
    } else {
      const assetTemplateResults = await client.graphql({
        query: queries.assetTemplatesByCategoriesID,
        variables: {
          categoriesID: catid,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: 1000,
        },
        // signal: controller.signal,
        authMode: "API_KEY",
      });

      console.log(assetTemplateResults.data.assetTemplatesByCategoriesID.items);
      if (
        assetTemplateResults.data.assetTemplatesByCategoriesID.items.length > 0
      ) {
        if (isLoading.current || !hasMore) return;
        isLoading.current = true;
        try {
          let assets = [];
          let count = 0;
          let nextToken = "";

          assetTemplateResults.data.assetTemplatesByCategoriesID.items.map(
            async (item) => {
              count = count + 1;
              let assetSiteTemp = item.id + "_" + siteId;
              var assetResults = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 5000000,
                  nextToken: nextTokenRef.current,
                },
                authMode: "API_KEY",
              });

              console.log(assetResults);
              const assetData =
                assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
              nextToken = assetData.nextToken;
              if (assetData.items.length > 0) {
                assets.push(assetData.items);
              }
              if (
                count ===
                assetTemplateResults.data.assetTemplatesByCategoriesID.items
                  .length
              ) {
                const mergeAssets = assets.flat(1);
                nextTokenRef.current = nextToken;
                setItems((prevItems) => [...prevItems, ...mergeAssets]);
                setHasMore(nextToken !== null);
                isLoading.current = false;
                if (nextToken === null) {
                  console.log(siteIndex);
                  setSiteIndex(siteIndex + 1);
                }
                if (mergeAssets.length < 800) {
                  console.log(siteIndex);
                  setSiteIndex(siteIndex + 1);
                }
              }
            }
          );
        } catch (error) {
          console.error("Error fetching asset data:", error);
          isLoading.current = false;
        }
      }

      // await client.graphql({
      //   query: queries.assetTemplatesByCategoriesID,
      //   variables: {
      //     categoriesID: catid,
      //     filter: {
      //       status: {
      //         eq: "Active",
      //       },
      //     },
      //     limit: 1000,
      //   },
      //   // signal: controller.signal,
      //   authMode: "API_KEY",
      // })
      //   .then(async (result) => {
      //     console.log(result.data.assetTemplatesByCategoriesID.items);
      //     if (result.data.assetTemplatesByCategoriesID.items.length > 0) {
      //       if (isLoading.current || !hasMore) return;
      //       isLoading.current = true;
      //       try {
      //         let assets = [];
      //         let count = 0;
      //         let nextToken = "";
      //         // siteDetails.map(async (site) => {
      //         result.data.assetTemplatesByCategoriesID.items.map(
      //           async (item) => {
      //             count = count + 1;
      //             let assetSiteTemp = item.id + "_" + siteId;
      //             await client.graphql({
      //               query: queries.assetsByAssettemplateSiteIDAndAssetNum,
      //               variables: {
      //                 assettemplateSiteID: assetSiteTemp,
      //                 limit: 5000000,
      //                 nextToken: nextTokenRef.current,
      //               },
      //               authMode: "API_KEY",
      //             })
      //               .then(async (response) => {
      //                 console.log(response);
      //                 const data =
      //                   response.data.assetsByAssettemplateSiteIDAndAssetNum;
      //                 nextToken = data.nextToken;
      //                 if (data.items.length > 0) {
      //                   assets.push(data.items);
      //                 }
      //               })
      //               .catch((error) => {
      //                 console.log(error);
      //               });
      //             if (
      //               count ===
      //               result.data.assetTemplatesByCategoriesID.items.length
      //             ) {
      //               const mergeAssets = assets.flat(1);
      //               nextTokenRef.current = nextToken;
      //               setItems((prevItems) => [...prevItems, ...mergeAssets]);
      //               setHasMore(
      //                 nextToken !== null
      //               );
      //               isLoading.current = false;
      //               if (nextToken === null) {
      //                 console.log(siteIndex);
      //                 setSiteIndex(siteIndex + 1);
      //               }
      //               if (mergeAssets.length < 800) {
      //                 console.log(siteIndex);
      //                 setSiteIndex(siteIndex + 1);
      //               }
      //             }
      //           }
      //         );
      //         // });
      //       } catch (error) {
      //         console.error("Error fetching data:", error);
      //         isLoading.current = false;
      //       }
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  };

  const getFilteredItems = async () => {
    console.log("search", isSearch);
    console.log("searchAsset", searchAsset);
    console.log("search 2", isSecoundSearch);
    if (isSearch && !isSecoundSearch) {
      setItems([]);
    }

    setHasMore(true);
    let filters = {};
    filters.assetOwnerID = { eq: LoginUserDetails.id };
    if (selectedBox) {
      filters.distributionboxID = { eq: selectedBox };
    }
    if (assetTemplatData.length > 0) {
      let count = 0;
      let filteredAssets = [];
      let nextTokens = "";
      assetTemplatData.map(async (item) => {
        count = count + 1;
        let assetSiteTemp = item.id + "_" + selectedSite;
        await client
          .graphql({
            query: queries.assetsByAssettemplateSiteIDAndAssetNum,
            variables: {
              assettemplateSiteID: assetSiteTemp,
              filter: filters,
              limit: 5000000,
              nextToken: nextTokenRef.current,
            },
            authMode: "API_KEY",
          })
          .then(async (response) => {
            console.log(response);
            const data = response.data.assetsByAssettemplateSiteIDAndAssetNum;
            nextTokens = data.nextToken;
            if (data.items.length > 0) {
              filteredAssets.push(data.items);
            }
            console.log("filteredAssets", filteredAssets);
            if (count === assetTemplatData.length) {
              const mergeAssets = filteredAssets.flat(1);
              nextTokenRef.current = nextTokens;
              // console.log("dtit", items);
              setItems((prevItems) => [...prevItems, ...mergeAssets]);
              // console.log("dt", [...new Set(items)]);
              setHasMore(nextTokens !== null);
              isLoading.current = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    if (isSearch) {
      getFilteredItems();
    }
  }, [isSearch]);

  useEffect(() => {
    if (isReset) {
      setItems([]);
      if (!isSearch) {
        getItems();
      } else {
        getFilteredItems();
      }
    }
  }, [isReset]);

  useEffect(() => {
    if (items.length < 800) {
      getItems();
    }
  }, [siteIndex > 0]);

  return {
    items,
    loadMoreItems: getItems,
    searchMoreItems: getFilteredItems,
    hasMore,
    siteIndex,
  };
};

export default useInfiniteLoading;
