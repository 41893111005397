import { Btn } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Form,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import { LargeModal } from "../../../Constant/index";
import AddServiceGroups from "./AddServiceGroups";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const ServiceGroupDetailsContain = () => {
  const [servicePId, setSPId] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    e,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [filteredData, setFilteredData] = React.useState([]);

  const navigate = useNavigate();
  const [viewData, setViewData] = useState([]);
  const [Large, setLarge] = useState(false);
  const LargeModaltoggle = () => {
    const formsdata = {
      serviceName: "",
      serviceProvider: "",
    };
    reset({ ...formsdata });
    setSPId(0);
    setViewData([]);
    setLarge(!Large);
  };
  const EditLargeModal = (serviceProvider) => {
    setSPId(serviceProvider.id);
    setViewData(serviceProvider);
    reset({ ...serviceProvider });
    setLarge(!Large);
  };
  const [ViewLarge, setViewLarge] = useState(false);
  const handleClose = () => setViewLarge(!ViewLarge);
  const ViewLargeModal = (serviceProvider) => {
    setViewData(serviceProvider);
    ViewLargeModaltoggle();
  };
  const ViewLargeModaltoggle = () => setViewLarge(!ViewLarge);

  const [formDetails, setFormDetails] = useState([]);
  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [collection, setCollection] = useState([]);
  const [catId, setCatId] = useState();
  const [maxNum, setmaxNum] = useState();

  const globalSearch = (searchValue) => {
    let filteredData = formDetails.filter((row) => {
      return (
        row.serviceName.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.serviceProvider.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.status.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    return cloneDeep(filteredData);
  };

  React.useEffect(() => {
    if (!value) {
      setCollection(cloneDeep(formDetails.slice(0, countPerPage)));
      setFilteredData([]);
    } else {
      const filteredData = globalSearch(value);
      setFilteredData(filteredData);
      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;

    if (!value) {
      setCollection(cloneDeep(formDetails.slice(from, to)));
    } else {
      const filteredData = globalSearch(value);
      const pageData = filteredData.slice(from, to);
      setCollection(cloneDeep(pageData));
    }
  };

  const classNameFun = (status) => {
    return status === "active"
      ? "switch-state bg-success"
      : "switch-state bg-danger";
  };

  async function handelDelete(id, version, name) {
    await client
      .graphql({
        query: mutations.updateServiceGroup,
        variables: {
          input: {
            id: id,
          },
        },
      })
      .then((result) => {
        console.log(result);
        toast.success(name + " service was deleted Successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchFormDetailsData();
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Error while deletind " + name + " service -> " + error.message,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  }

  //---Approve Status-----------------------------

  const ApproveStatus = (status) => {
    return status.toLowerCase() === "active"
      ? "switch-state bg-success"
      : "switch-state bg-danger";
  };

  const handleChange = async (item) => {
    var statusSp;
    if (item.status.toLowerCase() === "active") {
      statusSp = "Inactive";
      toast.success("Deactivated Successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      statusSp = "Active";
      toast.success("Activated Successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    await client
      .graphql({
        query: mutations.updateServiceGroup,
        variables: {
          input: {
            id: item.id,
            status: statusSp,
          },
        },
      })
      .then((result) => {
        fetchFormDetailsData();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error while updating.", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //----------------------------------------

  async function fetchFormDetailsData() {
    setIsLoading(true);
    const dataDetails = await client
      .graphql({
        query: queries.listServiceGroups,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
        },
      })
      .then((response) => {
        setIsLoading(false);
        const sortedItems = response.data.listServiceGroups.items.sort(
          (a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          }
        );
        console.log(sortedItems);
        const formDetails = sortedItems;
        console.log(formDetails);
        setFormDetails(formDetails);
        setCollection(cloneDeep(formDetails.slice(0, countPerPage)));
      })
      .catch(() => {
        setErrorMessage("Unable to fetch the list");
        setIsLoading(false);
      });
  }

  async function fetchlistServiceGroups() {
    await client
      .graphql({
        query: queries.listServiceGroups,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
        },
      })
      .then((response) => {
        const sortedItems = response.data.listServiceGroups.items.sort(
          (a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          }
        );
        console.log(sortedItems);
        const listServiceGroups1 = sortedItems;
        let idArry = [];
        let maxnumber = 0;
        listServiceGroups1.map((item) => {
          if (item.serviceNumber !== "" && item.serviceNumber !== null) {
            if (isNaN(Number(item.serviceNumber.replace(/^\D+/g, "")))) {
              idArry.push(1);
            } else {
              idArry.push(Number(item.serviceNumber.replace(/^\D+/g, "")));
            }
          }
        });
        maxnumber = Math.max(...idArry);
        if (idArry.length != 0) {
          if (maxnumber !== 0 && maxnumber !== null) {
            const res = digits_count(Number(maxnumber + 1));
            if (res === 1) {
              setCatId("000");
              setmaxNum(maxnumber + 1);
            } else if (res === 2) {
              setCatId("00");
              setmaxNum(maxnumber + 1);
            } else if (res === 3) {
              setCatId("0");
              setmaxNum(maxnumber + 1);
            } else if (res === 4) {
              setCatId("");
              setmaxNum(maxnumber + 1);
            }
          } else {
            setCatId("000");
            setmaxNum(1);
          }
        } else {
          setCatId("000");
          setmaxNum(1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const digits_count = (n) => {
    var count = 0;
    if (n >= 1) ++count;
    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }
    return count;
  };

  useEffect(() => {
    fetchFormDetailsData();
    fetchlistServiceGroups();
  }, [reset]);

  async function onSubmit(formData, e) {
    if (servicePId === 0) {
      await client
        .graphql({
          query: mutations.createServiceGroup,
          variables: {
            input: {
              serviceName: formData.serviceName,
              serviceProvider: formData.serviceProvider,
              status: "Active",
              serviceNumber: "SG" + catId + maxNum,
            },
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          fetchFormDetailsData();
          toast.success("Service Group Saved Successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          LargeModaltoggle();
          e.target.reset();
        })
        .catch((error) => {
          toast.error("Error While Saving Service Group -> " + error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      await client
        .graphql({
          query: mutations.updateServiceGroup,
          variables: {
            input: {
              id: servicePId,
              serviceName: formData.serviceName,
              serviceProvider: formData.serviceProvider,
            },
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          fetchFormDetailsData();
          toast.success("Service Group Updated Successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          LargeModaltoggle();
          setViewData([]);
          setSPId(0);
        })
        .catch((error) => {
          toast.error(
            "Error While Updating Service Group -> " + error.message,
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });
    }
  }

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <Row>
              <Col md="6">
                <InputGroup>
                  <span className="input-group-text">
                    <i className="icon-search"></i>
                  </span>
                  <Input
                    type="search"
                    placeholder="Search Service Groups.."
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col md="6" className="text-end">
                <Btn attrBtn={{ color: "primary", onClick: LargeModaltoggle }}>
                  <i className="icofont icofont-plus"></i> Add Service Groups
                </Btn>
                <AddServiceGroups
                  isOpen={Large}
                  title={LargeModal}
                  toggler={LargeModaltoggle}
                  size="lg"
                  defaultVal="add"
                >
                  ...
                </AddServiceGroups>
              </Col>
            </Row>
            <Row>
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">
                  <div className="table-responsive">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr>
                          <th>S No.</th>
                          <th>Service Group Name</th>
                          <th>Service Provider</th>
                          <th>Status</th>
                          <th>Active</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="trhover">
                        {collection.length > 0 ? (
                          collection.map((item, i) => (
                            <tr key={item.id}>
                              <td>{item.serviceNumber}</td>
                              <td>{item.serviceName}</td>
                              <td>{item.serviceProvider}</td>
                              <td>{item.status}</td>
                              <td>
                                <Media body className="icon-state switch-sm">
                                  <Label className="switch">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => handleChange(item, e)}
                                      key={`active${i}`}
                                    />
                                    <span
                                      className={ApproveStatus(item.status)}
                                    ></span>
                                  </Label>
                                </Media>
                              </td>
                              <td className="text-center">
                                {/* <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'danger', title: 'Delete',  onClick: () => handelDelete(`${item.id}`, `${item._version}`, `${item.serviceName}`)}} key={`delete${item.id}`}><i className="fa fa-trash-o"></i></Btn> */}
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "info",
                                    title: "Edit",
                                    onClick: () => EditLargeModal(item),
                                  }}
                                  key={`edit${i}`}
                                >
                                  <i className="fa fa-edit "></i>
                                </Btn>
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "primary",
                                    title: "View",
                                    onClick: () => ViewLargeModal(item),
                                  }}
                                  key={`view${i}`}
                                >
                                  <i className="fa fa-eye"></i>{" "}
                                </Btn>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="5" className="text-center">
                                No Data Available.
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </div>
            </Row>
            <CardFooter className="row">
              <Pagination
                className="p-t-10"
                pageSize={countPerPage}
                onChange={updatePage}
                current={currentPage}
                total={value ? filteredData.length : formDetails.length}
                showTotal={(total, range) =>
                  `${range[0]} - ${range[1]} of ${total} items`
                }
              />
            </CardFooter>
          </Card>
        </Col>
        <Modal
          isOpen={ViewLarge}
          toggle={ViewLargeModaltoggle}
          size="lg"
          centered
        >
          <ModalHeader toggle={ViewLargeModaltoggle}>
            View Service Group
          </ModalHeader>
          <ModalBody className="modal-body">
            <Container fluid={true}>
              <Row>
                <Col md="6 mb-3">
                  <Label>Service Group Name: </Label>
                  <Label>{viewData.serviceName}</Label>
                </Col>
                <Col md="6 mb-3">
                  <Label>Service Provider: </Label>
                  <Label>{viewData.serviceProvider}</Label>
                </Col>
              </Row>
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: () =>
                    navigate(
                      `${process.env.PUBLIC_URL}/assetcategory/add-forms`,
                      {
                        state: {
                          serviceName: `${viewData.serviceName}`,
                          id: `${viewData.id}`,
                          edit: false,
                          type: "Logbook",
                        },
                      }
                    ),
                }}
              >
                Add Logbook Form
              </Btn>
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: () =>
                    navigate(
                      `${process.env.PUBLIC_URL}/assetcategory/add-forms`,
                      {
                        state: {
                          serviceName: `${viewData.serviceName}`,
                          id: `${viewData.id}`,
                          edit: false,
                          type: "Repair",
                        },
                      }
                    ),
                }}
              >
                Add Repair Form
              </Btn>
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: () =>
                    navigate(
                      `${process.env.PUBLIC_URL}/assetcategory/add-forms`,
                      {
                        state: {
                          serviceName: `${viewData.serviceName}`,
                          id: `${viewData.id}`,
                          edit: false,
                          type: "Testing",
                        },
                      }
                    ),
                }}
              >
                Add Testing Form
              </Btn>
            </Container>
          </ModalBody>
        </Modal>

        <Modal isOpen={Large} toggle={LargeModaltoggle} size="lg" centered>
          <ModalHeader toggle={LargeModaltoggle}>
            {servicePId === 0 ? "Add Service Group" : "Edit Service Group"}
          </ModalHeader>
          <ModalBody className="modal-body">
            <Form
              className="needs-validation"
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col md="6 mb-3">
                  <Label>Service Group Name</Label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Service Group Name"
                    name="serviceName"
                    {...register("serviceName", { required: true })}
                  />
                  <span>
                    {errors.serviceName && "Please Enter Service Group Name."}
                  </span>
                </Col>
                <Col md="6 mb-3">
                  <Label>Service Provider</Label>
                  <select
                    className="form-control"
                    placeholder="Service Provider"
                    name="serviceProvider"
                    {...register("serviceProvider", { required: true })}
                  >
                    <option value="">Choose Service Provider</option>
                    <option value="Electrician">Electrician</option>
                  </select>
                  {/* <input type="text" className="form-control" placeholder="Service Provider" name="serviceProvider" {...register('serviceProvider', { required: true })} />                   */}
                  <span>
                    {errors.serviceProvider &&
                      "Please Select Service Provider."}
                  </span>
                </Col>
              </Row>
              <Btn attrBtn={{ color: "primary" }}>
                {servicePId === 0 ? "Save" : "Update"}
              </Btn>
            </Form>
          </ModalBody>
        </Modal>
      </Container>
    </Fragment>
  );
  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default ServiceGroupDetailsContain;
