import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { Btn, H4 } from "../../AbstractElements";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  Media,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import { useNavigate, useLocation, Link, matchRoutes } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { FormGroup, Label } from "reactstrap";

import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import * as mergedQueries from "../../graphql/mergedQueries";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import ScrollBar from "react-perfect-scrollbar";
import AWS from "aws-sdk";
import DatePicker from "react-datepicker";
import moment from "moment";
import { USERPOOLID, ACCESSKEYID, SECRETACCESSKEY } from "../../Config/Config";
import { Typeahead } from "react-bootstrap-typeahead";
import { SiteDetailsContext } from "../../App";
import { nanoid } from "nanoid";
import { limit } from "../../Config/Config";
import { SiteContext } from "../../App";
import SiteData from "../../Data/Sites/index";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const SiteUserDetails = () => {
  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });

  const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
    apiVersion: "2022-10-03",
    region: "ap-southeast-2",
  });

  const state = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [listofSiteUser, setlistofSiteUser] = useState([]);
  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [collection, setCollection] = React.useState([]);
  const [sites, setsites] = React.useState([]);
  const [sitesSelected, setsitesSelected] = React.useState([]);
  const [permissions, setPermissions] = useState([]);
  const [siteError, setSiteError] = useState(false);
  const [siteListVersion, setSiteListVersion] = useState(false);
  const [username, setusername] = useState([]);
  const [spglobal, setspglobal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userCount, setuserCount] = useState([]);
  const [subscriptionPlan, setsubscriptionplan] = useState([]);
  const [addOn, setAddOn] = useState([]);
  const [userPlancount, setuserplancount] = useState([]);
  const [sitesssaofilter, setSitesAofilter] = useState([]);
  const LoginUserDetailss = JSON.parse(localStorage.getItem("userDetails"));
  const permissionsList = [
    "Create Assets",
    "Import Assets",
    "Edit Assets",
    "View Assets",
    "Create Tasks",
    "Assign Tasks",
    "View Tasks",
    "Export Tasks",
    "Create Sites",
    "Edit Sites",
    "View Sites",
    "Import Sites",
    "Create Certificates",
    "View Certificates",
    "Download Reports",
    "View Reports",
  ];

  const [itemforPermission, setItemforPermission] = useState([]);
  const [userSite, setUserSite] = useState([]);
  const [spDB, setSpDB] = useState([]);
  const [siteuserexist, setsiteuserexist] = useState(SiteData.getSiteData());
  //const [limit, setLimit] = useState(100000);

  const [showInvite, setShowInvite] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShowInvite = () => {
    setShowInvite(true);
  };
  const handleCloseInvite = () => {
    setShowInvite(false);
  };
  const handleShow = (item) => {
    setShow(true);
    setItemforPermission(item);
    fnPermissionPopup(item);
  };

  const fnPermissionPopup = (item) => {
    if (item.permission) {
      setPermissions(JSON.parse(item.permission));
    } else {
      setPermissions([]);
    }
  };

  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserGroup = localStorage.getItem("groups");
  var status = LoginUserGroup === "admin" ? "Active" : "Inactive";

  const [filteredData, setFilteredData] = React.useState([]);
  const LoggedInUserId = LoginUserDetails.id;

  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  console.log(selectedSiteGlobal);

  const { siteDetailsGlobal, handleSiteDetailsChange } =
    useContext(SiteDetailsContext);

  const handleEdit = useCallback(
    (row) => navigate(`/addeditsiteuser/${row.id}`),
    []
  );
  const handleView = useCallback(
    (row) => navigate(`/addeditsiteuser/${row.id}/view`),
    []
  );

  //-----------------Permissions-------------------------------------------------------------

  const editPermissions = async () => {
    console.log("inside editPermission function");
    console.log(permissions);
    console.log(itemforPermission);
    const previousPermissions = itemforPermission.permission
      ? JSON.parse(itemforPermission.permission)
      : [];
    console.log(previousPermissions);
    let otherPerm = ["View Logbooks", "View Service Providers"];
    let permSet = new Set(permissions);
    otherPerm.forEach((permission) => permSet.add(permission));
    let perm = Array.from(permSet);
    console.log(perm);

    client
      .graphql({
        query: mutations.updateUserDetails,
        variables: {
          input: {
            id: itemforPermission.id,
            permissions: JSON.stringify(perm),
          },
        },
      })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });

    await client
      .graphql({
        query: mutations.updateSPUserDetails,
        variables: {
          input: {
            id: itemforPermission.id,
            permission: JSON.stringify(perm),
          },
        },
      })
      .then((result) => {
        console.log(result);
        toast.success(" Updated successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
        window.location = `/addEditSiteUser/${itemforPermission.id}/view`;
      })
      .catch((error) => {
        console.log(error);
        toast.error(" Error while Updating ", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  // Add/Remove Permissions-----------------------------------------------------------------

  const handleCheck = (event) => {
    var updatedList = [...permissions];
    if (event.target.checked) {
      updatedList = [...permissions, event.target.value];
    } else {
      updatedList.splice(permissions.indexOf(event.target.value), 1);
    }
    setPermissions(updatedList);
  };

  const checkedItems = permissions.length
    ? permissions.reduce((total, item) => {
      return total + ", " + item;
    })
    : "";

  // Return classes based on whether item is checked
  var isChecked = (item) =>
    permissions.includes(item) ? "checked-item" : "not-checked-item";

  async function fetchLinkUserSite() {
    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.listLinkUsersAndSites,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listLinkUsersAndSites.items.length > 0) {
      let assetDatas = result.data.listLinkUsersAndSites.items;
      nextToken = result.data.listLinkUsersAndSites.nextToken;
      if (result.data.listLinkUsersAndSites.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.listLinkUsersAndSites,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listLinkUsersAndSites.items.length > 0) {
          nextToken = results.data.listLinkUsersAndSites.nextToken;
          if (results.data.listLinkUsersAndSites.items.length > 0) {
            assets.push(results.data.listLinkUsersAndSites.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        setUserSite(mergeAssets);
      }
    }
  }

  async function fetchSp() {
    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.listSPUserDetails,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listSPUserDetails.items.length > 0) {
      let assetDatas = result.data.listSPUserDetails.items;
      nextToken = result.data.listSPUserDetails.nextToken;
      if (result.data.listSPUserDetails.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.listSPUserDetails,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listSPUserDetails.items.length > 0) {
          nextToken = results.data.listSPUserDetails.nextToken;
          if (results.data.listSPUserDetails.items.length > 0) {
            assets.push(results.data.listSPUserDetails.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        setSpDB(mergeAssets);
      }
    }
  }

  async function fetchUser() {
    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.listUserDetails,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listUserDetails.items.length > 0) {
      let assetDatas = result.data.listUserDetails.items;
      nextToken = result.data.listUserDetails.nextToken;
      if (result.data.listUserDetails.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.listUserDetails,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listUserDetails.items.length > 0) {
          nextToken = results.data.listUserDetails.nextToken;
          if (results.data.listUserDetails.items.length > 0) {
            assets.push(results.data.listUserDetails.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        setusername(mergeAssets);
      }
    }
  }

  function getUsername(spid) {
    const name = username.find((item) => item.id === spid);
    return name ? name.username : "";
  }

  async function fetchSitesForUserExist() {
    //---
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listSiteDetails,
        variables: {
          limit: limit,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.listSiteDetails.items;
        nextToken = result.data.listSiteDetails.nextToken;
        if (result.data.listSiteDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: limit,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listSiteDetails.nextToken;
              if (results.data.listSiteDetails.items.length > 0) {
                assets.push(results.data.listSiteDetails.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setsiteuserexist(mergeAssets);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //---------------------------Add/Remove sites-----------------------------------------------

  const onSubmit = useCallback(async (formData, e) => {
    //create cognito user
    setLoading(true);
    console.log(permissions);
    let otherPerm = ["View Logbooks", "View Service Providers"];
    let perm = [];
    if (permissions.length > 0) {
      perm = permissions.concat(otherPerm);
    }
    console.log(perm);
    if (sitesSelected.length > 0) {
      setSiteError(false);
      //await fetchLinkUserSite();
      await fetchSp();
      console.log(formData);
      const filteredUsers = spDB.filter((item) => {
        return (
          (item.fname !== ""
            ? item.fname?.toLowerCase() === formData.firstName.toLowerCase()
            : "") &&
          (item.lname !== ""
            ? item.lname?.toLowerCase() === formData.lastName.toLowerCase()
            : "") &&
          item.email === formData.email
        );
      });
      console.log(filteredUsers);
      if (filteredUsers.length > 0) {
        if (filteredUsers[0].userGroup === "site_user") {
          let successCount = 0;
          let siteIds = [];
          let siteIdsmatch = [];
          let dataCount = 0;
          let errorCount = 0;
          console.log("some sites exist");
          await client
            .graphql({
              query: queries.listLinkUsersAndSites,
              variables: {
                limit: limit,
                filter: {
                  userdetailsID: {
                    eq: filteredUsers[0].id,
                  },
                },
              },
              authMode: "API_KEY",
            })
            .then((result) => {
              console.log(result);
              const filterUser = result.data.listLinkUsersAndSites.items;
              console.log(filterUser);
              const sitedetailsIDs = filterUser.map(
                (item) => item.sitedetailsID
              );
              console.log(sitedetailsIDs);
              const FilterSites = siteuserexist.filter((item) =>
                sitedetailsIDs.includes(item.id)
              );
              //const FilterSites = sites.filter(item => item.id === filterUser[0].sitedetailsID);
              console.log(FilterSites);
              //const siteNames = FilterSites.map(item => `${item.siteName} (${item.unitNumber})`).join(', ');
              console.log(sitesSelected);
              sitesSelected?.map(async (item) => {
                const matchingSite = FilterSites.find(
                  (site) => site.id === item.id
                );

                if (matchingSite) {
                  successCount = successCount + 1;
                  errorCount = errorCount + 1;
                  siteIdsmatch.push(item.id);
                  // const siteName = `${matchingSite.siteName} (${matchingSite.unitNumber})`;
                  // toast.error(`User already exists for site: ${siteName}`, {
                  //   position: toast.POSITION.TOP_CENTER,
                  // });
                } else {
                  client
                    .graphql({
                      query: mutations.createLinkUsersAndSite,
                      variables: {
                        input: {
                          sitedetailsID: item.id,
                          userdetailsID: filteredUsers[0].id,
                          status: status,
                        },
                      },
                      authMode: "API_KEY",
                    })
                    .then((result) => {
                      console.log(result);
                      successCount = successCount + 1;
                      console.log(successCount);
                      dataCount = dataCount + 1;
                      siteIds.push(item.id);
                      console.log(siteIds);
                      if (sitesSelected.length === successCount) {
                        const matchingSite = siteuserexist.filter((item) =>
                          siteIds.includes(item.id)
                        );
                        const siteNames = matchingSite.map(
                          (site) => `${site.siteName} (${site.unitNumber})`
                        );
                        const concatenatedSiteNames = siteNames.join(", ");

                        toast.success(
                          `Created Successfully for sites: ${concatenatedSiteNames}`,
                          {
                            position: toast.POSITION.TOP_CENTER,
                          }
                        );
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      toast.error("Error while Creating ", {
                        position: toast.POSITION.TOP_CENTER,
                      });
                    });

                  e.target.reset();
                  setShowInvite(false);
                  setLoading(false);
                  reset();
                  setSiteListVersion(true);
                  fetchSiteUserData("All Time");
                  fetchSubscriptionList();
                  fetchSiteDetailsassetcount();
                  reset();
                  setsitesSelected([]);
                  //fetchLinkUserSite();
                  fetchSp();
                }
              });
              if (errorCount > 0) {
                const matchingSite = siteuserexist.filter((item) =>
                  siteIdsmatch.includes(item.id)
                );
                const siteNames = matchingSite.map(
                  (site) => `${site.siteName} (${site.unitNumber})`
                );
                const concatenatedSiteNames = siteNames.join(", ");
                toast.success(
                  `User has already accepted the invitation for site:  ${concatenatedSiteNames}`,
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
                setLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          //   toast.error("User already exist as Service Provider ", {
          //     position: toast.POSITION.TOP_CENTER,
          //   });
          //----
          let userSub = "";
          let userEmail = "";
          try {
            let userName =
              formData.firstName.toLowerCase() +
              formData.lastName.toLowerCase() +
              "su";
            await client
              .graphql({
                query: queries.listUserDetails,
                variables: {
                  limit: limit,
                  filter: {
                    username: {
                      eq: userName.replace(/\s/g, ""),
                    },
                    email: {
                      eq: formData.email,
                    },
                  },
                },
                authMode: "API_KEY",
              })
              .then(async (userD) => {
                console.log(userD.data.listUserDetails.items);
                if (userD.data.listUserDetails.items.length > 0) {
                  console.log("Same Email");
                  setLoading(false);
                  toast.success(
                    `User has already accepted the invitation for all selected site/s`,
                    {
                      position: toast.POSITION.TOP_CENTER,
                    }
                  );
                } else {
                  console.log("different Email");
                  console.log("userName (before nanoid): ", userName);
                  let nname = "";
                  nname = userName + nanoid(5);
                  userName = nname.toLowerCase();
                  console.log("userName (after nanoid): ", userName);
                  const cognitoParams = {
                    UserPoolId: USERPOOLID,
                    Username: userName.replace(/\s/g, ""),
                    UserAttributes: [
                      {
                        Name: "email",
                        Value: formData.email,
                      },
                      {
                        Name: "custom:userType",
                        Value: "site_user",
                      },
                    ],
                    DesiredDeliveryMediums: ["EMAIL"],
                    TemporaryPassword: userName + "Z@123456789",
                    ForceAliasCreation: false,
                    // SoftwareTokenMfaSettings: {
                    //   Enabled: true,
                    //   PreferredMfa: true,
                    // },
                  };
                  console.log(cognitoParams);
                  let response = await COGNITO_CLIENT.adminCreateUser(
                    cognitoParams
                  ).promise();
                  console.log(JSON.stringify(response));

                  // -- email verification
                  const verificationParams = {
                    UserPoolId: USERPOOLID,
                    Username: userName.replace(/\s/g, ""),
                    UserAttributes: [
                      {
                        Name: "email_verified",
                        Value: "true",
                      },
                    ],
                  };

                  let result = await COGNITO_CLIENT.adminUpdateUserAttributes(
                    verificationParams
                  ).promise();
                  console.log(JSON.stringify(result));

                  const usersDetails = response.User.Attributes;
                  usersDetails.forEach((item) => {
                    if (item.Name === "sub") {
                      userSub = item.Value;
                    }
                    if (item.Name === "email") {
                      userEmail = item.Value;
                    }
                  });
                  client.graphql({
                    query: mutations.createUserDetails,
                    variables: {
                      input: {
                        id: userSub,
                        username: userName.replace(/\s/g, ""),
                        email: userEmail,
                        assetOwnerId: LoginUserDetails.id,
                        status: "Active",
                        permissions: JSON.stringify(perm),
                      },
                    },
                    authMode: "API_KEY",
                  });
                  var params = {
                    GroupName: "site_user" /* required */,
                    UserPoolId: USERPOOLID /* required */,
                    Username: userName.replace(/\s/g, "") /* required */,
                  };
                  let groupResponse = await COGNITO_CLIENT.adminAddUserToGroup(
                    params
                  ).promise();
                  console.log(groupResponse);

                  //create cognito user
                  client
                    .graphql({
                      query: mutations.createSPUserDetails,
                      variables: {
                        input: {
                          id: userSub,
                          fname: formData.firstName,
                          lname: formData.lastName,
                          email: formData.email,
                          userGroup: "site_user",
                          invitedBy: LoginUserDetails.id,
                          status: status,
                          permission: JSON.stringify(perm),
                        },
                      },
                      authMode: "API_KEY",
                    })
                    .then(async (res) => {
                      console.log(res);

                      const addedsite = await client.graphql({
                        query: queries.getSPUserDetails,
                        variables: {
                          id: userSub,
                        },
                        authMode: "API_KEY",
                      });
                      if (addedsite.data.getSPUserDetails !== null) {
                        if (localStorage.getItem("spuser") !== null) {
                          let activeSites = JSON.parse(
                            base64.decode(localStorage.getItem("spuser"))
                          );
                          console.log(activeSites);
                          activeSites.push(addedsite.data.getSPUserDetails);
                          console.log(activeSites);
                          localStorage.setItem(
                            "spuser",
                            base64.encode(JSON.stringify(activeSites))
                          );
                        }
                      }
                      const su = res.data.createSPUserDetails;
                      console.log(su);
                      e.target.reset();
                      setShowInvite(false);
                      reset();
                      //fetchLinkUserSite();
                      fetchUser();
                      fetchSp();
                      sitesSelected?.map((item) => {
                        client.graphql({
                          query: mutations.updateSPUserDetails,
                          variables: {
                            input: {
                              sitedetailsID: item.id,
                              id: userSub,
                            },
                          },
                          authMode: "API_KEY",
                        });
                        client
                          .graphql({
                            query: mutations.createLinkUsersAndSite,
                            variables: {
                              input: {
                                sitedetailsID: item.id,
                                userdetailsID: userSub,
                                status: status,
                              },
                            },
                            authMode: "API_KEY",
                          })
                          .then((result) => {
                            console.log(result);
                          })
                          .catch((error) => {
                            console.log(error);
                            toast.error(" Error while Creating ", {
                              position: toast.POSITION.TOP_CENTER,
                            });
                          });
                      });
                      fetchSiteUserData("All Time");
                      fetchSubscriptionList();
                      fetchSiteDetailsassetcount();
                      reset();
                      setLoading(false);
                      setsitesSelected([]);
                      toast.success(" Created successfully.", {
                        position: toast.POSITION.TOP_CENTER,
                      });
                    })
                    .catch((errors) => {
                      toast.error(errors, {
                        position: toast.POSITION.TOP_CENTER,
                      });
                    });
                }
              });
          } catch (err) {
            console.log(err);
            toast.error(err.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        }
      } else {
        let userSub = "";
        let userEmail = "";
        try {
          let userName =
            formData.firstName.toLowerCase() +
            formData.lastName.toLowerCase() +
            "su";
          await client
            .graphql({
              query: queries.listUserDetails,
              variables: {
                limit: limit,
                filter: {
                  username: {
                    eq: userName.replace(/\s/g, ""),
                  },
                  email: {
                    eq: formData.email,
                  },
                },
              },
              authMode: "API_KEY",
            })
            .then(async (userD) => {
              console.log(userD.data.listUserDetails.items);
              if (userD.data.listUserDetails.items.length > 0) {
                setLoading(false);
                console.log("Same Email");
                toast.success(
                  `User has already accepted the invitation for all selected site/s`,
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
              } else {
                console.log("different Email");
                let nname = "";
                nname = userName + nanoid(5);
                userName = nname.toLowerCase();
                const cognitoParams = {
                  UserPoolId: USERPOOLID,
                  Username: userName.replace(/\s/g, ""),
                  UserAttributes: [
                    {
                      Name: "email",
                      Value: formData.email,
                    },
                  ],
                  DesiredDeliveryMediums: ["EMAIL"],
                  TemporaryPassword: userName + "Z@123456789",
                  ForceAliasCreation: false,
                  // SoftwareTokenMfaSettings: {
                  //   Enabled: true,
                  //   PreferredMfa: true,
                  // },
                };
                console.log(cognitoParams);
                let response = await COGNITO_CLIENT.adminCreateUser(
                  cognitoParams
                ).promise();
                console.log(JSON.stringify(response));

                // -- email verification
                const verificationParams = {
                  UserPoolId: USERPOOLID,
                  Username: userName.replace(/\s/g, ""),
                  UserAttributes: [
                    {
                      Name: "email_verified",
                      Value: "true",
                    },
                  ],
                };

                let result = await COGNITO_CLIENT.adminUpdateUserAttributes(
                  verificationParams
                ).promise();
                console.log(JSON.stringify(result));

                const usersDetails = response.User.Attributes;
                usersDetails.forEach((item) => {
                  if (item.Name === "sub") {
                    userSub = item.Value;
                  }
                  if (item.Name === "email") {
                    userEmail = item.Value;
                  }
                });
                client.graphql({
                  query: mutations.createUserDetails,
                  variables: {
                    input: {
                      id: userSub,
                      username: userName.replace(/\s/g, ""),
                      email: userEmail,
                      assetOwnerId: LoginUserDetails.id,
                      status: "Active",
                      permissions: JSON.stringify(perm),
                    },
                  },
                  authMode: "API_KEY",
                });
                var params = {
                  GroupName: "site_user" /* required */,
                  UserPoolId: USERPOOLID /* required */,
                  Username: userName.replace(/\s/g, "") /* required */,
                };
                let groupResponse = await COGNITO_CLIENT.adminAddUserToGroup(
                  params
                ).promise();
                console.log(groupResponse);

                //create cognito user
                client
                  .graphql({
                    query: mutations.createSPUserDetails,
                    variables: {
                      input: {
                        id: userSub,
                        fname: formData.firstName,
                        lname: formData.lastName,
                        email: formData.email,
                        userGroup: "site_user",
                        invitedBy: LoginUserDetails.id,
                        status: status,
                        permission: JSON.stringify(perm),
                      },
                    },
                    authMode: "API_KEY",
                  })
                  .then(async (res) => {
                    console.log(res);

                    const addedsite = await client.graphql({
                      query: queries.getSPUserDetails,
                      variables: {
                        id: userSub,
                      },
                      authMode: "API_KEY",
                    });
                    if (addedsite.data.getSPUserDetails !== null) {
                      if (localStorage.getItem("spuser") !== null) {
                        let activeSites = JSON.parse(
                          base64.decode(localStorage.getItem("spuser"))
                        );
                        console.log(activeSites);
                        activeSites.push(addedsite.data.getSPUserDetails);
                        console.log(activeSites);
                        localStorage.setItem(
                          "spuser",
                          base64.encode(JSON.stringify(activeSites))
                        );
                      }
                    }
                    const su = res.data.createSPUserDetails;
                    console.log(su);
                    e.target.reset();
                    setShowInvite(false);
                    setLoading(false);
                    reset();
                    //fetchSiteUserData("All Time");
                    //fetchLinkUserSite();
                    fetchSp();
                    fetchUser();
                    sitesSelected?.map((item) => {
                      client.graphql({
                        query: mutations.updateSPUserDetails,
                        variables: {
                          input: {
                            sitedetailsID: item.id,
                            id: userSub,
                          },
                        },
                        authMode: "API_KEY",
                      });
                      client
                        .graphql({
                          query: mutations.createLinkUsersAndSite,
                          variables: {
                            input: {
                              sitedetailsID: item.id,
                              userdetailsID: userSub,
                              status: status,
                            },
                          },
                          authMode: "API_KEY",
                        })
                        .then((result) => {
                          console.log(result);
                        })
                        .catch((error) => {
                          console.log(error);
                          toast.error(" Error while Creating ", {
                            position: toast.POSITION.TOP_CENTER,
                          });
                        });
                    });
                    setSiteListVersion(true);
                    fetchSiteUserData("All Time");
                    fetchSubscriptionList();
                    fetchSiteDetailsassetcount();
                    reset();
                    setsitesSelected([]);
                    toast.success(" Created successfully.", {
                      position: toast.POSITION.TOP_CENTER,
                    });
                  })
                  .catch((errors) => {
                    toast.error(errors, {
                      position: toast.POSITION.TOP_CENTER,
                    });
                  });
              }
            });
        } catch (err) {
          console.log(err);
          toast.error(err.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    } else {
      setSiteError(true);
    }
  });

  useEffect(() => {
    if (siteListVersion === true) {
      fetchSiteUserData("All Time");
      setSiteListVersion(false);
    }
  }, [siteListVersion]);

  //---Approve Status-----------------------------

  const ApproveStatus = (status) => {
    return status?.toLowerCase() === "active"
      ? "switch-state bg-success"
      : "switch-state bg-danger";
  };

  const handleChange = async (item) => {
    var statusSp;

    if (item.status.toLowerCase() === "active") {
      statusSp = "Inactive";
      toast.success("Deactivated successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      statusSp = "Active";
      toast.success("Activated successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    console.log(item);

    await client
      .graphql({
        query: mutations.updateSPUserDetails,
        variables: {
          input: { id: item.id, status: statusSp },
        },
      })
      .then((result) => {
        fetchSiteUserData("All Time");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error while Updating ", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //-----------------------------------------------------------------------------
  async function fetchSites() {
    // if (LoginUserGroup === "admin") {
    //--
    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.listSiteDetails,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listSiteDetails.items.length > 0) {
      let assetDatas = result.data.listSiteDetails.items;
      nextToken = result.data.listSiteDetails.nextToken;
      if (result.data.listSiteDetails.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.listSiteDetails,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listSiteDetails.items.length > 0) {
          nextToken = results.data.listSiteDetails.nextToken;
          if (results.data.listSiteDetails.items.length > 0) {
            assets.push(results.data.listSiteDetails.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        const sites = mergeAssets;
        console.log(sites);
        setsites(sites);
        // if (LoginUserGroup === "admin") {
        //   setsites(sites);
        // } else if (LoginUserGroup === "site_user") {
        //   const dataDetails = await client.graphql({
        //     query: queries.linkUsersAndSitesByUserdetailsID,
        //     authMode: "API_KEY",
        //     variables: {
        //       userdetailsID: LoginUserDetails.id,
        //       limit: limit,
        //     },
        //   });
        //   if (
        //     dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0
        //   ) {
        //     const siteDetails =
        //       dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
        //     if (siteDetails.length > 0) {
        //       const siteDetailsu = sites.filter((item) => {
        //         const _matchingSites = siteDetails?.find(
        //           (site) => site.sitedetailsID === item?.id
        //         );
        //         if (_matchingSites) {
        //           return item;
        //         }
        //       });
        //       console.log(siteDetailsu);
        //       setsites(siteDetailsu);
        //       setIsLoading(false);
        //     } else {
        //       setsites([]);
        //       setIsLoading(false);
        //     }
        //   }
        // } else {
        //   setsites(
        //     sites.filter((item) => {
        //       return item.assetownerID === LoginUserDetails.id;
        //     })
        //   );
        // }
      }
    }
  }

  //----------------------------Table-------------------------------------------------

  const globalSearch = (searchValue) => {
    let filteredData = listofSiteUser.filter((row) => {
      const username = getUsername(row.id);
      return (
        row.fname?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.lname?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.status?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.mobile?.toLowerCase().includes(searchValue.toLowerCase()) ||
        moment(row.createdAt)
          .format("DD/MM/YYYY")
          ?.toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        username?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    return cloneDeep(filteredData);
  };

  React.useEffect(() => {
    if (!value) {
      setCollection(cloneDeep(listofSiteUser.slice(0, countPerPage)));
      setFilteredData([]);
    } else {
      const filteredData = globalSearch(value);
      setFilteredData(filteredData);
      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    }
  }, [value]);

  useEffect(() => {
    fetchUser();
    //fetchLinkUserSite();
    fetchSp();
    //fetchSitesForUserExist();
  }, []);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;

    if (!value) {
      setCollection(cloneDeep(listofSiteUser.slice(from, to)));
    } else {
      const filteredData = globalSearch(value);
      const pageData = filteredData.slice(from, to);
      setCollection(cloneDeep(pageData));
    }
  };

  //-----------------------------------------Display------------------------------------------

  // date range ---
  const [selectedOption, setSelectedOption] = useState("");
  const [dateRange1, setDateRange1] = useState([new Date(), new Date()]);

  //---Week------------------------------------------

  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();
  const startOfWeek = moment(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    )
  ).format("DD/MM/YYYY");
  const endOfWeek = moment(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    )
  ).format("DD/MM/YYYY");

  // ---Month---------------------------

  const startOfMonth = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  ).format("DD/MM/YYYY");
  const endOfMonth = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  ).format("DD/MM/YYYY");

  // ---year------------------------------

  const startOfThisYear = moment(
    new Date(currentDate.getFullYear(), 0, 1)
  ).format("DD/MM/YYYY");
  const endOfThisYear = moment(
    new Date(currentDate.getFullYear(), 11, 31)
  ).format("DD/MM/YYYY");

  //---custom -------------------------------------------------

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  //--------------------------------------------------------------

  //----------------------------------------------------------------------

  const handleDateRangeSelected = (range) => {
    setDateRange1(range);
    console.log(range);
  };

  const onDateRangeSelected = (range) => {
    //setSelectedOption("");
    handleDateRangeSelected(range);
  };

  function DateRangePicker({ onDateRangeSelected }) {
    // const [selectedOption, setSelectedOption] = useState();

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      console.log(selectedOption);

      switch (event.target.value) {
        case "Today":
          onDateRangeSelected(getTodayRange());
          break;
        case "This Week":
          onDateRangeSelected(getWeekRange());
          break;
        case "This Month":
          onDateRangeSelected(getMonthRange());
          break;
        case "This Year":
          onDateRangeSelected(getThisYearRange());
          break;
        case "All Time":
          onDateRangeSelected(getAllTimeRange());
          break;
        case "Custom":
          // onDateRangeSelected(getCustomRange());
          break;
        default:
          break;
      }
    };

    const getTodayRange = () => {
      fetchSiteUserData("Today");
    };

    const getCustomRange = () => {
      fetchSiteUserData("Custom");
    };

    const getAllTimeRange = () => {
      fetchSiteUserData("All Time");
    };

    const getWeekRange = () => {
      fetchSiteUserData("This Week");
    };

    const getMonthRange = () => {
      console.log("inside the month range function........");
      fetchSiteUserData("This Month");
    };

    const getThisYearRange = () => {
      fetchSiteUserData("This Year");
    };

    //----------------------------------------------------------------------------------------

    return (
      <div className="daterange-card">
        <Row>
          <Col xs="6">
            <FormGroup>
              <Input
                type="select"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="All Time">All Time</option>
                <option value="Today">Today</option>
                <option value="This Week">This Week</option>
                <option value="This Month">This Month</option>
                <option value="This Year">This Year</option>
                <option value="Custom">Custom</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              {selectedOption === "Custom" && (
                <Fragment>
                  <Row className="d-flex justify-content-end">
                    <Col xl="6">
                      <div className="daterange-card">
                        <div className="mb-3">
                          <FormGroup>
                            <DatePicker
                              className="form-control digits"
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              onChange={(d) => {
                                setDateRange(d);
                                console.log(d);
                                console.log(startDate);
                                console.log(endDate);
                                fetchSiteUserData("Custom");
                              }}
                              isClearable={true}
                              placeholderText="Enter Date Range"
                              dateFormat="dd-MM-yyyy"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }

  useEffect(() => {
    // fetchSiteUserData("All Time");
    if (LoginUserGroup === "admin") {
      fetchSites();
    } else {
      if (localStorage.getItem("site") === null && sites.length === 0) {
        LoginUserGroup === "site_owner" || LoginUserGroup === "spao"
          ? fetchSiteDetailsAO()
          : LoginUserGroup === "site_user"
            ? fetchSiteDetailsSU()
            : fetchSiteDetailsSP(LoginUserDetails.id);
      } else {
        let activeSites = JSON.parse(
          base64.decode(localStorage.getItem("site"))
        );
        setsites(activeSites.filter((item) => item.status === "Active"));
      }
    }
  }, []);

  //--sites for ao
  async function fetchSiteDetailsAO() {
    try {
      //setIsLoading(true);
      let nextToken = "";
      let sites = [];
      const result = await client.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      });
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        sites.push(assetDatas);
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          nextToken = results.data.siteDetailsByAssetownerID.nextToken;
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            sites.push(results.data.siteDetailsByAssetownerID.items);
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          setsites(mergeSites.filter((item) => item.status === "Active"));
          SiteData.setSiteData(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        }
      } else {
        setsites([]);
        //setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setsites([]);
      //setIsLoading(false);
    }
  }

  //---site global filter for admin
  async function fetchSitesforglobal() {
    //setIsLoading(true);
    //-
    try {
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.linkUsersAndSitesBySitedetailsID,
        authMode: "API_KEY",
        variables: {
          sitedetailsID: selectedSiteGlobal,
          limit: limit,
        },
      });
      if (result.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
        let assetDatas = result.data.linkUsersAndSitesBySitedetailsID.items;
        nextToken = result.data.linkUsersAndSitesBySitedetailsID.nextToken;
        if (result.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.linkUsersAndSitesBySitedetailsID,
            authMode: "API_KEY",
            variables: {
              sitedetailsID: selectedSiteGlobal,
              limit: limit,
              nextToken: nextToken,
            },
          });
          if (results.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
            nextToken = results.data.linkUsersAndSitesBySitedetailsID.nextToken;
            if (results.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
              assets.push(results.data.linkUsersAndSitesBySitedetailsID.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setspglobal(mergeAssets);
        }
      }
    } catch (error) {
      console.log(error);
      //setIsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedSiteGlobal) {
      fetchSitesforglobal();
    }
    fetchSiteUserData("All Time");
  }, [selectedSiteGlobal, spglobal]);

  useEffect(() => {
    if (localStorage.getItem("site") === null && sitesssaofilter.length === 0) {
      LoginUserGroup === "site_user"
        ? fetchSiteDetailsSU()
        : fetchSiteDetailsSP(LoginUserDetails.id);
    } else {
      let activeSites = JSON.parse(base64.decode(localStorage.getItem("site")));
      setSitesAofilter(activeSites.filter((item) => item.status === "Active"));
    }
  }, []);

  // async function fetchSiteDetailsSU(id) {
  //   try {
  //     ////setIsLoading(true);
  //     let nextToken = "";
  //     let sites = [];
  //     const result = await client.graphql({
  //       query: queries.siteDetailsByAssetownerID,
  //       variables: {
  //         assetownerID: id,
  //         limit: 200000,
  //       },
  //       authMode: "API_KEY",
  //     });
  //     if (result.data.siteDetailsByAssetownerID.items.length > 0) {
  //       let assetDatas = result.data.siteDetailsByAssetownerID.items;
  //       nextToken = result.data.siteDetailsByAssetownerID.nextToken;
  //       sites.push(assetDatas);
  //       while (nextToken !== null) {
  //         const results = await client.graphql({
  //           query: queries.siteDetailsByAssetownerID,
  //           variables: {
  //             assetownerID: id,
  //             limit: 200000,
  //             nextToken: nextToken,
  //           },
  //           authMode: "API_KEY",
  //         });
  //         nextToken = results.data.siteDetailsByAssetownerID.nextToken;
  //         if (results.data.siteDetailsByAssetownerID.items.length > 0) {
  //           sites.push(results.data.siteDetailsByAssetownerID.items);
  //         }
  //       }
  //       console.log(sites);
  //       if (sites.length > 0) {
  //         const mergeSites = sites.flat(1);
  //         setSitesAofilter(mergeSites);
  //         setsites(mergeSites.filter((item) => item.status === "Active"));
  //         SiteData.setSiteData(mergeSites);
  //         localStorage.setItem(
  //           "site",
  //           base64.encode(JSON.stringify(mergeSites))
  //         );
  //       }
  //     } else {
  //       setSitesAofilter([]);
  //       setsites([]);
  //       //setIsLoading(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setSitesAofilter([]);
  //     setsites([]);
  //     //setIsLoading(false);
  //   }
  // }

  async function fetchSiteDetailsSU() {
    try {
      //setIsLoading(true);
      const response = await client.graphql({
        query: mergedQueries.getSiteUsersFromLink,
        authMode: "API_KEY",
        variables: {
          //nextToken: '',
          assetownerID: LoginUserDetails.assetOwnerId,
          userdetailsID: LoginUserDetails.id,
        },
      });
      console.log(response);
      let nextToken = "";
      let sitess = [];
      if (response.data.siteDetailsByAssetownerID.items.length > 0) {
        let sites = response.data.siteDetailsByAssetownerID.items;
        nextToken = response.data.siteDetailsByAssetownerID.nextToken;
        for (let site of sites) {
          console.log(site);
          console.log(site.LinkUsersAndSites.items.length);
          if (site.LinkUsersAndSites.items.length > 0) {
            sitess.push(site);
          }
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: mergedQueries.getSiteUsersFromLink,
            variables: {
              nextToken: nextToken,
              assetownerID: LoginUserDetails.assetOwnerId,
              userdetailsID: LoginUserDetails.id,
            },
            authMode: "API_KEY",
          });
          nextToken = results.data.siteDetailsByAssetownerID.nextToken;
          sites = results.data.siteDetailsByAssetownerID.items;
          for (let site of sites) {
            console.log(site);
            console.log(site.LinkUsersAndSites.items.length);
            if (site.LinkUsersAndSites.items.length > 0) {
              sitess.push(site);
            }
          }
        }
        console.log(sitess);
        if (sitess.length > 0) {
          const mergeSites = sitess.flat(1);
          setsites(mergeSites.filter((item) => item.status === "Active"));
          SiteData.setSiteData(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        }
      }
    } catch (error) {
      console.log(error);
      setsites([]);
      setIsLoading(false);
    }
  }
  //--sites for sp/su
  async function fetchSiteDetailsSP(assetOwnerId) {
    ////setIsLoading(true);
    try {
      let nextToken = "";
      let sites = [];
      const result = await client.graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: assetOwnerId,
          limit: limit,
        },
      });
      if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
        let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
        nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
        sites.push(assetDatas);
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.linkUsersAndSitesByUserdetailsID,
            authMode: "API_KEY",
            variables: {
              userdetailsID: assetOwnerId,
              limit: limit,
              nextToken: nextToken,
            },
          });
          nextToken = results.data.linkUsersAndSitesByUserdetailsID.nextToken;
          if (results.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
            sites.push(results.data.linkUsersAndSitesByUserdetailsID.items);
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergesites = sites.flat(1);
          let sitesforUsers = [];
          const promises = mergesites.map(async (item) => {
            return await client.graphql({
              query: queries.getSiteDetails,
              variables: {
                id: item.sitedetailsID,
              },
              authMode: "API_KEY",
            });
          });
          Promise.all(promises).then((sitesData) => {
            sitesData.forEach((sites) => {
              if (sites.data.getSiteDetails !== null) {
                sitesforUsers.push(sites.data.getSiteDetails);
              }
            });

            setsites(sitesforUsers.filter((item) => item.status === "Active"));
            setSitesAofilter(
              sitesforUsers.filter((item) => item.status === "Active")
            );
            localStorage.setItem(
              "site",
              base64.encode(JSON.stringify(sitesforUsers))
            );
          });
        }
      }
    } catch (error) {
      console.log(error);
      //setIsLoading(false);
    }
  }

  async function fetchSiteUserData(DateRange) {
    //setIsLoading(true);
    try {
      let siteUserDetailsData = [];
      if (LoginUserGroup === "admin") {
        //-----------Admin users---------------
        setIsLoading(true);
        //--
        let nextToken = "";
        let assets = [];
        const result = await client.graphql({
          query: mergedQueries.listSPSUUserDetails,
          authMode: "API_KEY",
          variables: {
            limit: limit,
            userGroup: "site_user",
          },
        });
        if (result.data.listSPUserDetails.items.length > 0) {
          let assetDatas = result.data.listSPUserDetails.items;
          nextToken = result.data.listSPUserDetails.nextToken;
          if (result.data.listSPUserDetails.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            const results = await client.graphql({
              query: mergedQueries.listSPSUUserDetails,
              authMode: "API_KEY",
              variables: {
                limit: limit,
                userGroup: "site_user",
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            });
            if (results.data.listSPUserDetails.items.length > 0) {
              nextToken = results.data.listSPUserDetails.nextToken;
              if (results.data.listSPUserDetails.items.length > 0) {
                assets.push(results.data.listSPUserDetails.items);
              }
            }
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            const spUser = mergeAssets;
            if (spUser.length > 0) {
              const sortedItems = spUser.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
              console.log(sortedItems);
              const spDetails = sortedItems;
              if (DateRange === "This Week") {
                siteUserDetailsData = spDetails.filter((item) => {
                  const createdAtDate = moment(item.createdAt);
                  return (
                    createdAtDate.isSameOrAfter(
                      moment(startOfWeek, "DD/MM/YYYY"),
                      "day"
                    ) &&
                    createdAtDate.isSameOrBefore(
                      moment(endOfWeek, "DD/MM/YYYY"),
                      "day"
                    )
                  );
                });
              } else if (DateRange === "This Month") {
                siteUserDetailsData = spDetails.filter((item) => {
                  const createdAtDate = moment(item.createdAt);
                  return createdAtDate.isBetween(
                    moment(startOfMonth, "DD/MM/YYYY"),
                    moment(endOfMonth, "DD/MM/YYYY"),
                    null,
                    "[]"
                  );
                });
              } else if (DateRange === "Today") {
                siteUserDetailsData = spDetails.filter(
                  (item) =>
                    moment(item.createdAt).format("DD/MM/YYYY") ===
                    moment(new Date()).format("DD/MM/YYYY")
                );
              } else if (DateRange === "This Year") {
                siteUserDetailsData = spDetails.filter((item) => {
                  const createdAtDate = moment(item.createdAt);
                  return createdAtDate.isBetween(
                    moment(startOfThisYear, "DD/MM/YYYY"),
                    moment(endOfThisYear, "DD/MM/YYYY"),
                    null,
                    "[]"
                  );
                });
              } else if (DateRange === "Custom") {
                siteUserDetailsData = spDetails.filter((item) => {
                  const createdAtDate = moment(item.createdAt);
                  return createdAtDate.isBetween(
                    moment(startDate, "DD/MM/YYYY"),
                    moment(endDate, "DD/MM/YYYY"),
                    null,
                    "[]"
                  );
                });
              } else if (DateRange === "All Time") {
                siteUserDetailsData = spDetails.filter((item) => !item._delete);
              }
              setIsLoading(false);
              setlistofSiteUser(siteUserDetailsData);
              console.log(siteUserDetailsData);
              setCollection(
                cloneDeep(siteUserDetailsData.slice(0, countPerPage))
              );
            } else {
              setlistofSiteUser([]);
              setIsLoading(false);
              setCollection([]);
            }
          }
        }
      } else if (LoginUserGroup === "site_user") {
        console.log("site user login related site users");
        if (localStorage.getItem("spuser") !== null) {
          let activeAssets = JSON.parse(
            base64.decode(localStorage.getItem("spuser"))
          );
          console.log(activeAssets);
          const siteuser = activeAssets.filter(
            (item) => item.userGroup === "site_user"
          );
          const spDetails = siteuser;
          if (spDetails.length > 0) {
            if (DateRange === "This Week") {
              siteUserDetailsData = spDetails.filter((item) => {
                const createdAtDate = moment(item.createdAt);
                return (
                  createdAtDate.isSameOrAfter(
                    moment(startOfWeek, "DD/MM/YYYY"),
                    "day"
                  ) &&
                  createdAtDate.isSameOrBefore(
                    moment(endOfWeek, "DD/MM/YYYY"),
                    "day"
                  )
                );
              });
            } else if (DateRange === "This Month") {
              siteUserDetailsData = spDetails.filter((item) => {
                const createdAtDate = moment(item.createdAt);
                return createdAtDate.isBetween(
                  moment(startOfMonth, "DD/MM/YYYY"),
                  moment(endOfMonth, "DD/MM/YYYY"),
                  null,
                  "[]"
                );
              });
            } else if (DateRange === "Today") {
              siteUserDetailsData = spDetails.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") ===
                  moment(new Date()).format("DD/MM/YYYY")
              );
            } else if (DateRange === "This Year") {
              siteUserDetailsData = spDetails.filter((item) => {
                const createdAtDate = moment(item.createdAt);
                return createdAtDate.isBetween(
                  moment(startOfThisYear, "DD/MM/YYYY"),
                  moment(endOfThisYear, "DD/MM/YYYY"),
                  null,
                  "[]"
                );
              });
            } else if (DateRange === "Custom") {
              siteUserDetailsData = spDetails.filter((item) => {
                const createdAtDate = moment(item.createdAt);
                return createdAtDate.isBetween(
                  moment(startDate, "DD/MM/YYYY"),
                  moment(endDate, "DD/MM/YYYY"),
                  null,
                  "[]"
                );
              });
            } else if (DateRange === "All Time") {
              siteUserDetailsData = spDetails.filter((item) => !item._delete);
            }

            setlistofSiteUser(siteUserDetailsData);
            setCollection(cloneDeep(siteUserDetailsData.slice(0, countPerPage)));
            setIsLoading(false);
          } else {
            setlistofSiteUser([]);
            setIsLoading(false);
            setCollection([]);
          }
        } else {
          console.log(sitesssaofilter);
          if (sitesssaofilter.length > 0) {
            setLoading(true);
            let userIds = [];
            let nextToken1 = "";
            for (let site of sitesssaofilter) {
              const getSiteIds = await client.graphql({
                query: queries.linkUsersAndSitesBySitedetailsID,
                authMode: "API_KEY",
                variables: {
                  sitedetailsID: site.id,
                },
              });
              if (
                getSiteIds.data.linkUsersAndSitesBySitedetailsID.items.length > 0
              ) {
                nextToken1 =
                  getSiteIds.data.linkUsersAndSitesBySitedetailsID.nextToken;
                userIds.push(
                  getSiteIds.data.linkUsersAndSitesBySitedetailsID.items
                );
                while (nextToken1 !== null) {
                  const getSiteIds1 = await client.graphql({
                    query: queries.linkUsersAndSitesBySitedetailsID,
                    authMode: "API_KEY",
                    variables: {
                      sitedetailsID: site.id,
                      nextToken: nextToken1,
                    },
                  });
                  if (
                    getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                      .length > 0
                  ) {
                    nextToken1 =
                      getSiteIds1.data.linkUsersAndSitesBySitedetailsID.nextToken;
                    userIds.push(
                      getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                    );
                  }
                }
              } else {
                setlistofSiteUser([]);
                setIsLoading(false);
                setCollection([]);
              }
            }
            console.log("userIds 1", userIds);
            if (userIds.length > 0) {
              let nextToken = "";
              let assets = [];
              const result = await client.graphql({
                query: mergedQueries.listSUSPUserDetails,
                authMode: "API_KEY",
                variables: {
                  userGroup: "site_user",
                },
              });
              console.log("SU Users", result);
              if (result.data.listSPUserDetails.items.length > 0) {
                let assetDatas = result.data.listSPUserDetails.items;
                nextToken = result.data.listSPUserDetails.nextToken;
                if (result.data.listSPUserDetails.items.length > 0) {
                  assets.push(assetDatas);
                }
                while (nextToken !== null) {
                  const results = await client.graphql({
                    query: mergedQueries.listSUSPUserDetails,
                    authMode: "API_KEY",
                    variables: {
                      userGroup: "site_user",
                      nextToken: nextToken,
                    },
                    authMode: "API_KEY",
                  });
                  if (results.data.listSPUserDetails.items.length > 0) {
                    nextToken = results.data.listSPUserDetails.nextToken;
                    if (results.data.listSPUserDetails.items.length > 0) {
                      assets.push(results.data.listSPUserDetails.items);
                    }
                  }
                }
                console.log("SU Users", assets);
                if (assets.length > 0) {
                  const mergeAssets = assets.flat(1);
                  console.log(mergeAssets);
                  //--
                  console.log(LoggedInUserId);
                  const spDetails2 = mergeAssets.filter((item) => {
                    const _matchSites = userIds?.find(
                      (user) => user.userdetailsID === item?.id
                    );
                    if (_matchSites) {
                      return item;
                    }
                  });
                  console.log(spDetails2);
                  const sortedItems = spDetails2.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  });
                  console.log(sortedItems);
                  const spDetails = sortedItems;
                  console.log(spDetails);
                  if (spDetails.length > 0) {
                    if (DateRange === "This Week") {
                      siteUserDetailsData = spDetails.filter((item) => {
                        const createdAtDate = moment(item.createdAt);
                        return (
                          createdAtDate.isSameOrAfter(
                            moment(startOfWeek, "DD/MM/YYYY"),
                            "day"
                          ) &&
                          createdAtDate.isSameOrBefore(
                            moment(endOfWeek, "DD/MM/YYYY"),
                            "day"
                          )
                        );
                      });
                    } else if (DateRange === "This Month") {
                      siteUserDetailsData = spDetails.filter((item) => {
                        const createdAtDate = moment(item.createdAt);
                        return createdAtDate.isBetween(
                          moment(startOfMonth, "DD/MM/YYYY"),
                          moment(endOfMonth, "DD/MM/YYYY"),
                          null,
                          "[]"
                        );
                      });
                    } else if (DateRange === "Today") {
                      siteUserDetailsData = spDetails.filter(
                        (item) =>
                          moment(item.createdAt).format("DD/MM/YYYY") ===
                          moment(new Date()).format("DD/MM/YYYY")
                      );
                    } else if (DateRange === "This Year") {
                      siteUserDetailsData = spDetails.filter((item) => {
                        const createdAtDate = moment(item.createdAt);
                        return createdAtDate.isBetween(
                          moment(startOfThisYear, "DD/MM/YYYY"),
                          moment(endOfThisYear, "DD/MM/YYYY"),
                          null,
                          "[]"
                        );
                      });
                    } else if (DateRange === "Custom") {
                      siteUserDetailsData = spDetails.filter((item) => {
                        const createdAtDate = moment(item.createdAt);
                        return createdAtDate.isBetween(
                          moment(startDate, "DD/MM/YYYY"),
                          moment(endDate, "DD/MM/YYYY"),
                          null,
                          "[]"
                        );
                      });
                    } else if (DateRange === "All Time") {
                      siteUserDetailsData = spDetails.filter(
                        (item) => !item._delete
                      );
                    }
                    console.timeLog(siteUserDetailsData);
                    setlistofSiteUser(siteUserDetailsData);
                    setCollection(
                      cloneDeep(siteUserDetailsData.slice(0, countPerPage))
                    );
                    setIsLoading(false);
                  } else {
                    setlistofSiteUser([]);
                    setIsLoading(false);
                    setCollection([]);
                  }
                }
              }
            } else {
              setlistofSiteUser([]);
              setIsLoading(false);
              setCollection([]);
            }
          } else {
            setLoading(true);
            const dataDetails = await client.graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              authMode: "API_KEY",
              variables: {
                userdetailsID: LoginUserDetails.id,
                limit: limit,
              },
            });
            console.log("dataDetails", dataDetails);
            if (
              dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0
            ) {
              const siteDetails =
                dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
              console.log(siteDetails);

              const siteglobal = siteDetails.filter(
                (item) => item.sitedetailsID === selectedSiteGlobal
              );
              const sitesss = selectedSiteGlobal ? siteglobal : siteDetails;
              console.log(sitesss);

              let userIds = [];
              let nextToken1 = "";
              if (sitesss.length > 0) {
                for (let site of sitesss) {
                  const getSiteIds = await client.graphql({
                    query: queries.linkUsersAndSitesBySitedetailsID,
                    authMode: "API_KEY",
                    variables: {
                      sitedetailsID: site.sitedetailsID,
                    },
                  });
                  console.log(getSiteIds);
                  if (
                    getSiteIds.data.linkUsersAndSitesBySitedetailsID.items
                      .length > 0
                  ) {
                    nextToken1 =
                      getSiteIds.data.linkUsersAndSitesBySitedetailsID.nextToken;
                    userIds.push(
                      getSiteIds.data.linkUsersAndSitesBySitedetailsID.items
                    );
                    while (nextToken1 !== null) {
                      const getSiteIds1 = await client.graphql({
                        query: queries.linkUsersAndSitesBySitedetailsID,
                        authMode: "API_KEY",
                        variables: {
                          sitedetailsID: site.sitedetailsID,
                          nextToken: nextToken1,
                        },
                      });
                      console.log(getSiteIds1);
                      if (
                        getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                          .length > 0
                      ) {
                        nextToken1 =
                          getSiteIds1.data.linkUsersAndSitesBySitedetailsID
                            .nextToken;
                        userIds.push(
                          getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                        );
                      }
                    }
                  } else {
                    setlistofSiteUser([]);
                    setIsLoading(false);
                    setCollection([]);
                  }
                }
                console.log("userIds 2", userIds);
                if (userIds.length > 0) {
                  let linkedUsers = userIds.flat(1);
                  console.log(linkedUsers);
                  let nextToken = "";
                  let assets = [];
                  const result = await client.graphql({
                    query: mergedQueries.listSUSPUserDetails,
                    authMode: "API_KEY",
                    variables: {
                      userGroup: "site_user",
                    },
                  });
                  console.log("SU Users", result);
                  if (result.data.listSPUserDetails.items.length > 0) {
                    let assetDatas = result.data.listSPUserDetails.items;
                    nextToken = result.data.listSPUserDetails.nextToken;
                    if (result.data.listSPUserDetails.items.length > 0) {
                      assets.push(assetDatas);
                    }
                    while (nextToken !== null) {
                      const results = await client.graphql({
                        query: mergedQueries.listSUSPUserDetails,
                        authMode: "API_KEY",
                        variables: {
                          userGroup: "site_user",
                          nextToken: nextToken,
                        },
                        authMode: "API_KEY",
                      });
                      if (results.data.listSPUserDetails.items.length > 0) {
                        nextToken = results.data.listSPUserDetails.nextToken;
                        if (results.data.listSPUserDetails.items.length > 0) {
                          assets.push(results.data.listSPUserDetails.items);
                        }
                      }
                    }
                    console.log(assets);
                    if (assets.length > 0) {
                      const mergeAssets = assets.flat(1);
                      console.log(mergeAssets);
                      const spDetails2 = mergeAssets.filter((item) => {
                        const _matchSites = linkedUsers?.find(
                          (user) => user.userdetailsID === item?.id
                        );
                        if (_matchSites) {
                          return item;
                        }
                      });
                      console.log(spDetails2);
                      const sortedItems = spDetails2.sort((a, b) => {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                      });
                      console.log(sortedItems);
                      const spDetails = sortedItems;
                      console.log(spDetails);
                      if (spDetails.length > 0) {
                        if (DateRange === "This Week") {
                          siteUserDetailsData = spDetails.filter((item) => {
                            const createdAtDate = moment(item.createdAt);
                            return (
                              createdAtDate.isSameOrAfter(
                                moment(startOfWeek, "DD/MM/YYYY"),
                                "day"
                              ) &&
                              createdAtDate.isSameOrBefore(
                                moment(endOfWeek, "DD/MM/YYYY"),
                                "day"
                              )
                            );
                          });
                        } else if (DateRange === "This Month") {
                          siteUserDetailsData = spDetails.filter((item) => {
                            const createdAtDate = moment(item.createdAt);
                            return createdAtDate.isBetween(
                              moment(startOfMonth, "DD/MM/YYYY"),
                              moment(endOfMonth, "DD/MM/YYYY"),
                              null,
                              "[]"
                            );
                          });
                        } else if (DateRange === "Today") {
                          siteUserDetailsData = spDetails.filter(
                            (item) =>
                              moment(item.createdAt).format("DD/MM/YYYY") ===
                              moment(new Date()).format("DD/MM/YYYY")
                          );
                        } else if (DateRange === "This Year") {
                          siteUserDetailsData = spDetails.filter((item) => {
                            const createdAtDate = moment(item.createdAt);
                            return createdAtDate.isBetween(
                              moment(startOfThisYear, "DD/MM/YYYY"),
                              moment(endOfThisYear, "DD/MM/YYYY"),
                              null,
                              "[]"
                            );
                          });
                        } else if (DateRange === "Custom") {
                          siteUserDetailsData = spDetails.filter((item) => {
                            const createdAtDate = moment(item.createdAt);
                            return createdAtDate.isBetween(
                              moment(startDate, "DD/MM/YYYY"),
                              moment(endDate, "DD/MM/YYYY"),
                              null,
                              "[]"
                            );
                          });
                        } else if (DateRange === "All Time") {
                          siteUserDetailsData = spDetails.filter(
                            (item) => !item._delete
                          );
                        }
                        console.log(siteUserDetailsData);
                        setlistofSiteUser(siteUserDetailsData);
                        setCollection(
                          cloneDeep(siteUserDetailsData.slice(0, countPerPage))
                        );
                        setIsLoading(false);
                      } else {
                        setlistofSiteUser([]);
                        setIsLoading(false);
                        setCollection([]);
                      }
                    }
                  }
                } else {
                  setlistofSiteUser([]);
                  setIsLoading(false);
                  setCollection([]);
                }
              }
            }
          }
        }
      } else {
        console.log("ao related site user");
        //setLoading(true);
        let nextToken1 = "";
        let assets = [];
        const result = await client.graphql({
          query: mergedQueries.listSUAOUserDetails,
          authMode: "API_KEY",
          variables: {
            limit: limit,
            invitedBy: LoginUserDetails.id,
            userGroup: "site_user",
          },
        });
        if (result.data.listSPUserDetails.items.length > 0) {
          let assetDatas = result.data.listSPUserDetails.items;
          nextToken1 = result.data.listSPUserDetails.nextToken;
          if (result.data.listSPUserDetails.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken1 !== null) {
            const results = await client.graphql({
              query: mergedQueries.listSUAOUserDetails,
              authMode: "API_KEY",
              variables: {
                limit: limit,
                invitedBy: LoginUserDetails.id,
                userGroup: "site_user",
                nextToken: nextToken1,
              },
            });
            if (results.data.listSPUserDetails.items.length > 0) {
              nextToken1 = results.data.listSPUserDetails.nextToken;
              if (results.data.listSPUserDetails.items.length > 0) {
                assets.push(results.data.listSPUserDetails.items);
              }
            }
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            if (mergeAssets.length > 0) {
              const sortedItems = mergeAssets.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
              // console.log(sortedItems);
              // //const spDetails = sortedItems;
              // //console.log(spDetails);
              // const SpUsers = sortedItems;
              if (sortedItems.length > 0) {
                if (DateRange === "This Week") {
                  siteUserDetailsData = sortedItems.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return (
                      createdAtDate.isSameOrAfter(
                        moment(startOfWeek, "DD/MM/YYYY"),
                        "day"
                      ) &&
                      createdAtDate.isSameOrBefore(
                        moment(endOfWeek, "DD/MM/YYYY"),
                        "day"
                      )
                    );
                  });
                } else if (DateRange === "This Month") {
                  siteUserDetailsData = sortedItems.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return createdAtDate.isBetween(
                      moment(startOfMonth, "DD/MM/YYYY"),
                      moment(endOfMonth, "DD/MM/YYYY"),
                      null,
                      "[]"
                    );
                  });
                } else if (DateRange === "Today") {
                  siteUserDetailsData = sortedItems.filter(
                    (item) =>
                      moment(item.createdAt).format("DD/MM/YYYY") ===
                      moment(new Date()).format("DD/MM/YYYY")
                  );
                } else if (DateRange === "This Year") {
                  siteUserDetailsData = sortedItems.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return createdAtDate.isBetween(
                      moment(startOfThisYear, "DD/MM/YYYY"),
                      moment(endOfThisYear, "DD/MM/YYYY"),
                      null,
                      "[]"
                    );
                  });
                } else if (DateRange === "Custom") {
                  siteUserDetailsData = sortedItems.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return createdAtDate.isBetween(
                      moment(startDate, "DD/MM/YYYY"),
                      moment(endDate, "DD/MM/YYYY"),
                      null,
                      "[]"
                    );
                  });
                } else if (DateRange === "All Time") {
                  siteUserDetailsData = sortedItems.filter(
                    (item) => !item._delete
                  );
                }

                setlistofSiteUser(siteUserDetailsData);
                console.log(siteUserDetailsData);
                setCollection(
                  cloneDeep(siteUserDetailsData.slice(0, countPerPage))
                );
                //setIsLoading(false);
              } else {
                setlistofSiteUser([]);
                //setIsLoading(false);
                setCollection([]);
              }
            } else {
              setlistofSiteUser([]);
              //setIsLoading(false);
              setCollection([]);
            }
          }
        } else {
          setlistofSiteUser([]);
          //setIsLoading(false);
          setCollection([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [assetData, setAssetData] = useState({});
  const getusers = async (aid) => {
    try {
      const data = await client.graphql({
        query: queries.getUserDetails,
        authMode: "API_KEY",
        variables: { id: aid },
      });
      console.log(data);
      if (data.data.getUserDetails !== null) {
        const fittingNumberr = data.data.getUserDetails.username;
        console.log(fittingNumberr)
        setAssetData((prevState) => ({
          ...prevState,
          [aid]: fittingNumberr,
        }));
      } else {
        setAssetData((prevState) => ({
          ...prevState,
          [aid]: null,
        }));
      }
    } catch (error) {
      console.error("Error fetching UserDetails with id", aid, ":", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(listofSiteUser)
    if (listofSiteUser.length > 0) {
      listofSiteUser.forEach((item) => {
        if (item.id) {
          console.log(item.id)
          getusers(item.id);
        }
      });
    }
  }, [listofSiteUser]);

  const selectSites = (d) => {
    console.log(d);
    setsitesSelected(d);
    if (d.length > 0) {
      setSiteError(false);
    } else {
      setSiteError(true);
    }
  };

  const handleSelectAllSites = (event) => {
    try {
      if (event.target.checked) {
        setsitesSelected(sites);
      } else {
        setsitesSelected([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //----user limit changes
  useEffect(() => {
    if (LoginUserGroup === "site_user") {
      fetchSubscriptionListSU();
    } else {
      fetchSubscriptionList();
    }
    fetchSiteDetailsassetcount();
  }, []);

  async function fetchSiteDetailsassetcount() {
    try {
      const result = await client.graphql({
        query: queries.listSPUserDetails,
        variables: {
          filter: {
            invitedBy: {
              eq: LoginUserDetails.id,
            },
            userGroup: {
              eq: "site_user",
            },
          },
          limit: limit,
        },
        authMode: "API_KEY",
      });
      console.log(result);
      if (result.data.listSPUserDetails.items.length > 0) {
        let dataDetails = result.data.listSPUserDetails.items;
        console.log(dataDetails);
        setuserCount(dataDetails.length);
      } else {
        setuserCount([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchSubscriptionListSU() {
    try {
      const response3 = await client.graphql({
        query: queries.listUserDetails,
        authMode: "API_KEY",
        variables: {
          limit: limit,
          filter: {
            id: {
              eq: LoginUserDetails.assetOwnerId,
            },
          },
        },
      });
      const assetowner = response3.data.listUserDetails.items;
      console.log(assetowner);
      if (assetowner.length > 0) {
        const response = await client.graphql({
          query: queries.listSubscriptionLimits,
          authMode: "API_KEY",
          variables: {
            limit: limit,
          },
        });
        if (response.data.listSubscriptionLimits.items.length > 0) {
          let setdata = [];
          setdata = response.data.listSubscriptionLimits.items;
          let assetLimitdata = setdata.filter(
            (item) => item.subscriptionId === assetowner[0].paymentId
          );
          let userdata = setdata.filter(
            (item) => item.SubscriptionName === "User Add-On"
          );
          console.log(userdata);
          console.log(assetLimitdata);
          setsubscriptionplan(assetLimitdata);
          if (assetLimitdata.length > 0) {
            if (
              assetLimitdata[0].subscriptionId !== 5 ||
              assetLimitdata[0].subscriptionId !== 4
            ) {
              const response1 = await client.graphql({
                query: queries.listSubscriptionAdds,
                authMode: "API_KEY",
                variables: {
                  limit: limit,
                  filter: {
                    status: {
                      eq: "Active",
                    },
                  },
                },
              });
              if (response1.data.listSubscriptionAdds.items.length > 0) {
                const addons = response1.data.listSubscriptionAdds.items;
                let addonsdata = addons.filter(
                  (item) => item.userdetailsID === LoginUserDetailss.id
                );
                console.log(addonsdata);
                const userAddOn = addonsdata.filter(
                  (item) => item.subscriptionId === "7"
                );
                console.log(userAddOn);

                if (userAddOn.length > 0) {
                  const sort = userAddOn.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  });
                  const activeuser = userAddOn.filter(
                    (item) => item.status === "Active"
                  );
                  console.log(activeuser);
                  if (activeuser.length > 0) {
                    setAddOn(sort);
                    const totalQuantity = activeuser.reduce(
                      (acc, user) => acc + user.quantity,
                      0
                    );
                    console.log(totalQuantity);
                    const assetcount =
                      assetLimitdata[0].SiteUserLimit + totalQuantity;
                    console.log(assetcount);
                    setuserplancount(assetcount);
                  } else {
                    const assetcount = assetLimitdata[0].SiteUserLimit;
                    console.log(assetcount);
                    setuserplancount(assetcount);
                  }
                } else {
                  const assetcount = assetLimitdata[0].SiteUserLimit;
                  console.log(assetcount);
                  setuserplancount(assetcount);
                }
              } else {
                const limit = assetLimitdata[0].SiteUserLimit;
                console.log(limit);
                setuserplancount(limit);
              }
            } else {
              const limit = assetLimitdata[0].SiteUserLimit;
              console.log(limit);
              setuserplancount(limit);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchSubscriptionList() {
    try {
      const response = await client.graphql({
        query: queries.listSubscriptionLimits,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      });
      if (response.data.listSubscriptionLimits.items.length > 0) {
        let setdata = [];
        setdata = response.data.listSubscriptionLimits.items;
        let assetLimitdata = setdata.filter(
          (item) => item.subscriptionId === LoginUserDetailss.paymentId
        );
        let userdata = setdata.filter(
          (item) => item.SubscriptionName === "User Add-On"
        );
        console.log(userdata);
        console.log(assetLimitdata);
        setsubscriptionplan(assetLimitdata);
        if (assetLimitdata.length > 0) {
          if (
            assetLimitdata[0].subscriptionId !== 5 ||
            assetLimitdata[0].subscriptionId !== 4
          ) {
            const response1 = await client.graphql({
              query: queries.listSubscriptionAdds,
              authMode: "API_KEY",
              variables: {
                limit: limit,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
              },
            });
            if (response1.data.listSubscriptionAdds.items.length > 0) {
              const addons = response1.data.listSubscriptionAdds.items;
              let addonsdata = addons.filter(
                (item) => item.userdetailsID === LoginUserDetailss.id
              );
              console.log(addonsdata);
              const userAddOn = addonsdata.filter(
                (item) => item.subscriptionId === "7"
              );
              console.log(userAddOn);

              if (userAddOn.length > 0) {
                const sort = userAddOn.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });
                const activeuser = userAddOn.filter(
                  (item) => item.status === "Active"
                );
                console.log(activeuser);
                if (activeuser.length > 0) {
                  setAddOn(sort);
                  const totalQuantity = activeuser.reduce(
                    (acc, user) => acc + user.quantity,
                    0
                  );
                  console.log(totalQuantity);
                  const assetcount =
                    assetLimitdata[0].SiteUserLimit + totalQuantity;
                  console.log(assetcount);
                  setuserplancount(assetcount);
                } else {
                  const assetcount = assetLimitdata[0].SiteUserLimit;
                  console.log(assetcount);
                  setuserplancount(assetcount);
                }
              } else {
                const assetcount = assetLimitdata[0].SiteUserLimit;
                console.log(assetcount);
                setuserplancount(assetcount);
              }
            } else {
              const limit = assetLimitdata[0].SiteUserLimit;
              console.log(limit);
              setuserplancount(limit);
            }
          } else {
            const limit = assetLimitdata[0].SiteUserLimit;
            console.log(limit);
            setuserplancount(limit);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Row>
          <DateRangePicker
            onDateRangeSelected={onDateRangeSelected}
          ></DateRangePicker>
        </Row>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <CardHeader>
                <Row>
                  <Col md="4">
                    <InputGroup>
                      <span className="input-group-text">
                        <i className="icon-search"></i>
                      </span>
                      <Input
                        type="search"
                        placeholder="Search Site Users.."
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col sm="2"></Col>
                  <Col sm="3"></Col>
                  <Col md="3">
                    {(LoginUserDetailss.paymentId !== 5 ||
                      LoginUserDetailss.paymentId !== 4) &&
                      (addOn.length > 0
                        ? userCount === userPlancount &&
                        new Date(addOn[0].dueDate) > new Date()
                        : userCount === userPlancount) ? (
                      <Button title="User Limit Exceeded">
                        {" "}
                        + Invite Site Users{" "}
                      </Button>
                    ) : (
                      <Button onClick={handleShowInvite}>
                        {" "}
                        + Invite Site Users{" "}
                      </Button>
                    )}
                  </Col>
                </Row>
                {(LoginUserDetailss.paymentId !== 5 ||
                  LoginUserDetailss.paymentId !== 4) &&
                  (addOn.length > 0
                    ? userCount === userPlancount &&
                    new Date(addOn[0].dueDate) > new Date()
                    : userCount === userPlancount) && (
                    <Row>
                      <Col sm="6">
                        <p className="errorSubscription">
                          Sorry!.. Your Site User Limit is Exceeded, Please
                          Upgrade your Subscription
                        </p>
                      </Col>
                      <Col sm="6">
                        <Link to={`${process.env.PUBLIC_URL}/subscriptions`}>
                          {/* <Media className="static-widget">
                          <Media body> */}
                          <H4>Click here to Top Up the User</H4>
                          {/* </Media>
                        </Media> */}
                        </Link>
                      </Col>
                    </Row>
                  )}
              </CardHeader>
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">
                  <div className="table-responsive">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr>
                          <th scope="col">First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">UserName</th>
                          <th scope="col">Email</th>
                          <th scope="col">Mobile Number</th>
                          <th scope="col">Added On</th>
                          <th scope="col">Approval Status</th>
                          <th scope="col">Approve</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody className="trhover">
                        {collection.length > 0 ? (
                          collection.map((item) => (
                            <tr key={item.id}>
                              <td>{item.fname}</td>
                              <td>{item.lname}</td>
                              <td>{localStorage.getItem("spuser") !== null ? assetData[item.id] : item.UserDetails?.username}</td>
                              <td>{item.email}</td>
                              <td>{item.mobile}</td>
                              <td>
                                {moment(item.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <td>{item.status}</td>
                              <td>
                                <Media body className="icon-state switch-sm">
                                  <Label className="switch">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => handleChange(item, e)}
                                    />
                                    <span
                                      className={ApproveStatus(item.status)}
                                    ></span>
                                  </Label>
                                </Media>
                              </td>
                              <td>
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "secondary",
                                    title: "Permissions",
                                    onClick: () => handleShow(item),
                                  }}
                                >
                                  <i className="fa fa-check-square"></i>
                                </Btn>
                                {/* <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'danger', title: 'Delete',  onClick: () => handleDelete( `${item.id}`, `${item._version}`, `${item.fname}`)}} ><i className="fa fa-trash-o"></i></Btn> */}
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "info",
                                    title: "Edit",
                                    onClick: () => handleEdit(item),
                                  }}
                                >
                                  <i className="fa fa-edit "></i>
                                </Btn>
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "primary",
                                    title: "View",
                                    onClick: () => handleView(item),
                                  }}
                                >
                                  <i className="fa fa-eye"></i>{" "}
                                </Btn>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="5" className="text-center">
                                No Site User Available.
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </div>
              <CardFooter className="row">
                <Pagination
                  className="p-t-10"
                  pageSize={countPerPage}
                  onChange={updatePage}
                  current={currentPage}
                  total={value ? filteredData.length : listofSiteUser.length}
                  showTotal={(total, range) =>
                    `${range[0]} - ${range[1]} of ${total} items`
                  }
                />
              </CardFooter>
            </Card>
            <Modal show={show} size="lg" onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Edit Permissions for{" "}
                  {" " +
                    itemforPermission.fname +
                    " " +
                    itemforPermission.lname}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md="6 mb-3">
                    <Label>Choose Permissions</Label>
                    <ScrollBar
                      className="vertical-scroll ps-container ps-theme-default ps-active-y"
                      style={{
                        height: "300px",
                        border: "1px solid #efefef",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      {permissionsList.map((item, index) => (
                        <Label className="d-block" key={index}>
                          <input
                            value={item}
                            type="checkbox"
                            onChange={handleCheck}
                            checked={permissions.includes(item)}
                          />
                          <span className={isChecked(item)}>{item}</span>
                        </Label>
                      ))}
                    </ScrollBar>
                  </Col>
                  <Col md="6 mb-3">
                    <Label>Selected Permissions</Label>
                    <ScrollBar
                      className="vertical-scroll ps-container ps-theme-default ps-active-y"
                      style={{
                        height: "300px",
                        border: "1px solid #efefef",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      {permissions.map((item, index) => (
                        <li>{item}</li>
                      ))}
                    </ScrollBar>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={editPermissions}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showInvite} size="lg" onHide={handleCloseInvite}>
              <Form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSubmit)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Invite Site Users</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col md="4 mb-3">
                      <Label>First Name</Label>
                      <input
                        className="form-control"
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        {...register("firstName", { required: true })}
                      />
                      <span>{errors.email && "First Name is required"}</span>
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </Col>
                    <Col md="4 mb-3">
                      <Label>Last Name</Label>
                      <input
                        className="form-control"
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        {...register("lastName", { required: true })}
                      />
                      <span>{errors.email && "Last Name is required"}</span>
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom02">E-mail</Label>
                      <input
                        className="form-control"
                        name="email"
                        type="email"
                        placeholder="E-mail"
                        {...register("email", { required: true })}
                      />
                      <span>{errors.email && "email is required"}</span>
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Choose Permissions</Label>
                      <ScrollBar
                        className="vertical-scroll ps-container ps-theme-default ps-active-y"
                        style={{
                          height: "300px",
                          border: "1px solid #efefef",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        {permissionsList.map((item, index) => (
                          <Label className="d-block" key={index}>
                            <input
                              value={item}
                              type="checkbox"
                              onChange={handleCheck}
                              checked={permissions.includes(item)}
                            />
                            <span className={isChecked(item)}>{item}</span>
                          </Label>
                        ))}
                      </ScrollBar>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Selected Permissions</Label>
                      <ScrollBar
                        className="vertical-scroll ps-container ps-theme-default ps-active-y"
                        style={{
                          height: "300px",
                          border: "1px solid #efefef",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        {permissions.map((item, index) => (
                          <li>{item}</li>
                        ))}
                      </ScrollBar>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        type="checkbox"
                        onChange={(event) => handleSelectAllSites(event)}
                      />{" "}
                      Select All Sites
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Choose Sites</Label>
                      <Typeahead
                        id="rendering-example"
                        multiple={true}
                        options={sites}
                        labelKey={(sites) =>
                          `${sites.siteName} ,(${sites.unitNumber ? sites.unitNumber : ""
                          })`
                        }
                        placeholder="Choose Sites"
                        onChange={(d) => selectSites(d)}
                      />
                      {siteError ? (
                        <span>{"Selecting Site is required"}</span>
                      ) : (
                        ""
                      )}
                      {sitesSelected.length > 0 && (
                        <>
                          <Label>Selected Sites</Label>
                          <ScrollBar
                            className="vertical-scroll ps-container ps-theme-default ps-active-y"
                            style={{
                              height: "200px",
                              border: "1px solid #efefef",
                              padding: "1px",
                              borderRadius: "1px",
                            }}
                          >
                            {sitesSelected.map((item, index) => (
                              <Label className="d-block" key={index}>
                                <span>
                                  {item.siteName},{item.unitNumber}
                                </span>
                              </Label>
                            ))}
                          </ScrollBar>
                        </>
                      )}
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      disabled: loading ? loading : loading,
                    }}
                  >
                    {loading ? "Inviting..." : "Add"}
                  </Btn>
                  <Button variant="danger" onClick={handleCloseInvite}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default SiteUserDetails;
