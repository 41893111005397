import React, { Fragment, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Btn,
  H2,
  H3,
  H5,
  LI,
  P,
  UL,
  Spinner,
} from "../../../../AbstractElements";
import {
  Container,
  Col,
  Form,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  CardBody,
  Card,
  CardHeader,
  Media,
  Table,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import * as mutations from "../../../../graphql/mutations";
import * as queries from "../../../../graphql/queries";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { limit } from "../../../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const AddFormContain = (props) => {
  const { state } = useLocation();
  console.log(state);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
    e,
  } = useForm();
  const [param, setParam] = useState([]);
  const [isYesChecked, setIsYesChecked] = useState(false);
  const [isNoChecked, setIsNoChecked] = useState(false);
  const [serviceNameId, setServiceNameId] = useState("");
  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [staticFormDetails, setFormDetails] = useState([]);
  const [formDetails, setFormData] = useState([]);
  const [isEnable, setisEnable] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownField, setddField] = useState(false);
  const initialFormData = Object.freeze({
    label: "",
    type: "",
    options: "",
    isRequired: "",
    errorMessage: "",
  });
  const [formFieldData, updateFormData] = React.useState(initialFormData);
  const [catId, setCatId] = useState();
  const [maxNum, setmaxNum] = useState();
  //const [limit, setLimit] = useState(100000);
  const [pageData, setPageData] = useState({
    step: 1,
  });
  const nextStep = () => {
    const { step } = pageData;
    setPageData({
      step: step + 1,
    });
  };
  // Go to prev step
  const prevStep = () => {
    const { step } = pageData;
    setPageData({
      step: step - 1,
    });
  };
  const firstStep = () => {
    setPageData({
      step: 1,
    });
  };
  // Handle fields change
  const handleChange = (input) => (e) => {
    setPageData({ [input]: e.target.value });
  };
  const enableButton = (value) => {
    console.log(value);
    setisEnable(value === "yes");
  };

  async function fetchStaticForm() {
    await client
      .graphql({
        query: queries.listStaticForms,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      })
      .then((result) => {
        console.log(result);
        if (state) {
          const formDetails = result.data.listStaticForms.items.filter(
            (item) => {
              return item.formName === state.type;
            }
          );
          console.log(JSON.parse(formDetails[0].formFields));
          const frmDt = JSON.parse(formDetails[0].formFields);
          setFormDetails(frmDt[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchServiceGroups() {
    await client
      .graphql({
        query: queries.listServiceGroups,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      })
      .then((result) => {
        const serviceGroups = result.data.listServiceGroups.items;
        console.log(serviceGroups);
        if (state) {
          serviceGroups.map((item) => {
            if (item.serviceName === state.serviceName) {
              setServiceNameId(item.id);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setParam(state);
    if (state && state.edit) {
      setFormDetails(JSON.parse(state.data.formFields[0]));
      reset({ ...state.data });
      setisEnable(state.data.enableSchedule);
    } else {
      fetchStaticForm();
      fetchServiceGroups();
    }
    async function fetchFormDetailsDataMaxId() {
      await client
        .graphql({
          query: queries.listFormDetails,
          authMode: "API_KEY",
          variables: {
            limit: limit,
          },
        })
        .then((result) => {
          console.log(result);
          const formDetails = result.data.listFormDetails.items;
          let idArry = [];
          let maxnumber = 0;
          formDetails.map((item) => {
            if (item.formNumber !== "" && item.formNumber !== null) {
              if (isNaN(Number(item.formNumber.replace(/^\D+/g, "")))) {
                idArry.push(1);
              } else {
                idArry.push(Number(item.formNumber.replace(/^\D+/g, "")));
              }
            }
          });
          maxnumber = Math.max(...idArry);
          if (idArry.length != 0) {
            if (maxnumber !== 0 && maxnumber !== null) {
              const res = digits_count(Number(maxnumber + 1));
              if (res === 1) {
                setCatId("000");
                setmaxNum(maxnumber + 1);
              } else if (res === 2) {
                setCatId("00");
                setmaxNum(maxnumber + 1);
              } else if (res === 3) {
                setCatId("0");
                setmaxNum(maxnumber + 1);
              } else if (res === 4) {
                setCatId("");
                setmaxNum(maxnumber + 1);
              }
            } else {
              setCatId("000");
              setmaxNum(1);
            }
          } else {
            setCatId("000");
            setmaxNum(1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchFormDetailsDataMaxId();
  }, []);
  const digits_count = (n) => {
    var count = 0;
    if (n >= 1) ++count;
    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }
    return count;
  };
  async function onSubmit(formData) {
    console.log(formData);
    setFormData(formData);
    nextStep();
  }
  const formInputs = Object.keys(staticFormDetails).map((e) => {
    try {
      const { rules, label, type, options, onSelectFile, placeholder } =
        staticFormDetails[e];
      console.log(staticFormDetails[e]);
      let optionData = [];
      if (
        (type === "dropdown" || type === "checkbox" || type === "radio") &&
        typeof options !== "object"
      ) {
        optionData = options.split(/\r?\n/);
        console.log(optionData);
      }
      const { required, message } = rules;
      if (e !== "subForm") {
        return (
          <Col md="6 mb-3" key={e}>
            <label>{label}</label>
            {type === "text" && (
              <input
                placeholder={placeholder}
                className="form-control disabled"
                type={type}
                name={e}
                {...register(e)}
              />
            )}
            {type === "date" && (
              <input
                placeholder={placeholder}
                className="form-control disabled"
                type={type}
                name={e}
                {...register(e)}
              />
            )}
            {type === "dropdown" && (
              <select
                className="form-control disabled"
                name={e}
                {...register(e)}
              >
                <option value="">Choose {label}...</option>
              </select>
            )}
            {type === "autocomplete" && (
              <input
                placeholder={placeholder}
                className="form-control disabled"
                type="text"
                name={e}
                {...register(e)}
              />
            )}
            {type === "radio" &&
              optionData.map((item) => (
                <Label className="d-block disabled" for={item}>
                  <input
                    className="radio_animated disabled"
                    id={item}
                    type="radio"
                    name={e}
                    {...register(e)}
                  />
                  {item}
                </Label>
              ))}
            {type === "checkbox" &&
              optionData.map((item) => (
                <div className="disabled">
                  <input
                    id={item}
                    type="checkbox"
                    className="form-control  disabled"
                    name={e}
                    {...register(e)}
                  />
                  <Label htmlFor={item}>{item}</Label>
                </div>
              ))}
            {type === "file" && (
              <input
                type="file"
                className="form-control disabled"
                name={e}
                {...register(e)}
                onChange={onSelectFile}
              />
            )}
            {type === "textarea" && (
              <input
                placeholder={placeholder}
                className="form-control disabled"
                type={type}
                rows="3"
                name={e}
                {...register(e)}
              />
            )}
            {type === "toggle" && (
              <Media body className="icon-state switch-lg disabled">
                <Label className="switch">
                  <input type="checkbox" name={e} {...register(e)} />
                  <span className="switch-state bg-success"></span>
                </Label>
              </Media>
            )}
            <span>{errors.e && { message }}</span>
          </Col>
        );
      } else {
        return "";
      }
    } catch (e) {
      console.log(e);
    }
    // try{
    //     const { rules, label, name, placeholder, type, options, onSelectFile } = staticFormDetails[e];
    //     const { message, required } = rules;
    //     return (
    //         <Col md="6 mb-3" key={e}>
    //             <label>{label}</label>
    //                 {
    //                     type === "text" &&
    //                     <input
    //                         placeholder={placeholder}
    //                         className="form-control disabled"
    //                         type={type}
    //                         name={name}
    //                         {...register(name, {required: required})}
    //                     />
    //                 }
    //                 {
    //                     type === "date" &&
    //                     <input
    //                         placeholder={placeholder}
    //                         className="form-control disabled"
    //                         type={type}
    //                         name={name}
    //                         {...register(name, {required: required})}
    //                     />
    //                 }
    //                 {
    //                     type === "dropdown" &&
    //                     <Input type="select" className="form-control disabled"
    //                     name={name}>
    //                         {options.map((item) =>
    //                             <option value={item}>{item}</option>
    //                         )}
    //                     </Input>
    //                 }
    //                 {
    //                     type === "autocomplete" &&
    //                     <Autocomplete
    //                         apiKey='AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4'
    //                         className="form-control disabled"
    //                         onPlaceSelected={(place) => {
    //                             const address = place.formatted_address;
    //                             console.log(address);
    //                             //setCompanyAdress(address);
    //                         }}
    //                         options={{
    //                             types: ["address"],
    //                             componentRestrictions: { country: "AU" },
    //                         }}
    //                         placeholder={placeholder}
    //                         {...register(name, {required: required})}
    //                     />
    //                 }
    //                 {
    //                     type === "radio" &&
    //                         options.map((item) =>
    //                             <Label className="d-block" for={item}>
    //                                 <input className="radio_animated disabled" id={item}
    //                         name={name} type="radio" />
    //                                 {item}
    //                             </Label>
    //                         )
    //                 }
    //                 {
    //                     type === "checkbox" &&
    //                     options.map((item) =>
    //                         <div className="checkbox disabled">
    //                             <Input id={item} type="checkbox"
    //                         name={name} />
    //                             <Label htmlFor={item}>{item}</Label>
    //                         </div>
    //                     )
    //                 }
    //                 {
    //                     type === "file" &&
    //                     <Input type="file" className="form-control disabled"
    //                     name={name}
    //                     {...register(name, {required: required})}  onChange={onSelectFile} />
    //                 }
    //                 {
    //                     type === "textarea" &&
    //                     <input
    //                     placeholder={placeholder}

    //                     className="form-control disabled"

    //                     type={type}
    //                     name={name}
    //                     rows="3"
    //                     {...register(name, {required: required})}
    //                     />
    //                 }
    //                 {errors[e] && <p className="invalid-feedback">{message}</p>}
    //         </Col>
    //     );
    // }
    // catch(e){

    // }
  });
  async function onUpdateFormFields() {
    console.log(formDetails);
    Object.keys(staticFormDetails).map((e) => {
      const { type, options } = staticFormDetails[e];
      if (
        (type === "dropdown" || type === "checkbox" || type === "radio") &&
        typeof options !== "object"
      ) {
        let optionData = options.split(/\r?\n/);
        console.log(optionData);
        staticFormDetails[e].options = optionData;
      }
    });
    try {
      if (state.edit) {
        await client
          .graphql({
            query: mutations.updateFormDetails,
            variables: {
              input: {
                id: param.id,
                formName: formDetails.formName,
                serviceGroup: param.data.serviceGroup,
                servicegroupID: param.data.servicegroupID,
                formDescription: formDetails.formDescription,
                enableSchedule: isEnable,
                formFields: JSON.stringify(staticFormDetails),
                formType: param.data.formType,
              },
            },
            authMode: "API_KEY",
          })
          .then((result) => {
            console.log(result);
            toast.success(
              state.data.formType +
                " form details for " +
                param.serviceName +
                " was updated Successfully.",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
            navigate(`${process.env.PUBLIC_URL}/formDetails`, { state: "2" });
          })
          .catch((err) => {
            console.log(err);
            toast.error(
              "Error while updating " +
                param.type +
                " form details for " +
                param.serviceName +
                err.message,
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          });
      } else {
        await client
          .graphql({
            query: mutations.createFormDetails,
            variables: {
              input: {
                formName: formDetails.formName,
                serviceGroup: param.id,
                servicegroupID: param.id,
                formDescription: formDetails.formDescription,
                enableSchedule: isEnable,
                formFields: JSON.stringify(staticFormDetails),
                formType: param.type,
                status: "Active",
                formNumber: "FORM" + catId + maxNum,
              },
            },
            authMode: "API_KEY",
          })
          .then((result) => {
            console.log(result);
            toast.success(
              param.type +
                " form details for " +
                param.serviceName +
                " was added Successfully.",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
            navigate(`${process.env.PUBLIC_URL}/formDetails`, { state: "2" });
          })
          .catch((err) => {
            console.log(err);
            toast.error(
              "Error while adding " +
                param.type +
                " form details for " +
                param.serviceName +
                err.message,
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          });
      }
    } catch (e) {}
  }
  const editField = (field) => {
    console.log(field);
  };
  const handleDDFieldChange = (e) => {
    e.target.value === "dropdown" ||
    e.target.value === "checkbox" ||
    e.target.value === "radio"
      ? setddField(true)
      : setddField(false);
    updateFormData({
      ...formFieldData,
      [e.target.name]: e.target.value.trim(),
    });
  };
  const handleFieldChange = (e) => {
    updateFormData({
      ...formFieldData,
      [e.target.name]: e.target.value.trim(),
    });
  };
  const addNewField = () => {
    console.log(formFieldData);
    setIsLoading(true);
    setddField(false);
    const newFormData = {
      label: formFieldData.label,
      type: formFieldData.type,
      options: formFieldData.options,
      custom: true,
      name: formFieldData.label.toLowerCase().replace(/\s/g, ""),
      rules: {
        message: formFieldData.errorMessage,
        required: formFieldData.isRequired === "on" ? true : false,
      },
    };
    setFormDetails({
      ...staticFormDetails,
      [formFieldData.label.toLowerCase().replace(/\s/g, "")]: newFormData,
    });
    formFieldData.type !== "dropdown" ||
    formFieldData.type !== "checkbox" ||
    formFieldData.type !== "radio"
      ? (formFieldData.options = "")
      : console.log(formFieldData.options);

    document.getElementsByName("label")[0].value = "";
    document.getElementsByName("type")[0].value = "";
    document.getElementsByName("isRequired")[0].checked = false;
    document.getElementsByName("errorMessage")[0].value = "";

    // Reset formFieldData
    updateFormData({
      label: "",
      type: "",
      options: "",
      errorMessage: "",
      isRequired: false,
    });
  };
  const redirect = () => {
    if (state.edit) {
      navigate(`${process.env.PUBLIC_URL}/formDetails`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/serviceGroups`);
    }
  };

  useEffect(() => {
    if (state) {
      setIsYesChecked(state.edit && state.data.enableSchedule === true);
      setIsNoChecked(state.edit && state.data.enableSchedule === false);
    }
  }, [state]);

  return (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <CardHeader>
              <H3>
                {!state || state.edit == false
                  ? `Add ${param ? param.type : "unknown"} form for ${
                      param ? param.serviceName : "unknown"
                    }`
                  : `Edit ${state.data.formType} form for ${param.serviceName}`}
              </H3>
            </CardHeader>
            <CardBody>
              {pageData.step === 1 && (
                <Row>
                  <H5>Basic Form Details</H5>
                  <Form
                    className="needs-validation"
                    noValidate=""
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row>
                      <Col md="6 mb-3">
                        <Label>Form Name</Label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Form Name"
                          name="formName"
                          {...register("formName", { required: true })}
                        />
                        <span>
                          {errors.formName && "Please Enter Form Name."}
                        </span>
                      </Col>
                      <Col md="6 mb-3">
                        <Label>Enable Next Schedule Setup Option</Label>
                        <Label className="d-block" for="yes">
                          <input
                            className="radio_animated"
                            id="yes"
                            value="yes"
                            type="radio"
                            name="enableSchedule"
                            {...register("enableSchedule", { required: false })}
                            checked={isYesChecked}
                            onChange={(event) => {
                              enableButton(event.target.value);
                              setIsYesChecked(true);
                              setIsNoChecked(false);
                            }}
                          />
                          Yes
                        </Label>
                        <Label className="d-block" for="no">
                          <input
                            className="radio_animated"
                            id="no"
                            value="no"
                            type="radio"
                            name="enableSchedule"
                            {...register("enableSchedule", { required: false })}
                            checked={isNoChecked}
                            onChange={(event) => {
                              enableButton(event.target.value);
                              setIsYesChecked(false);
                              setIsNoChecked(true);
                            }}
                          />
                          No
                        </Label>
                        <input
                          type="hidden"
                          name="serviceGroup"
                          value={serviceNameId}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mb-3">
                        <Label>Form Description</Label>
                        <input
                          type="textarea"
                          className="form-control"
                          rows="3"
                          placeholder="Form Description"
                          name="formDescription"
                          {...register("formDescription", { required: false })}
                        />
                      </Col>
                    </Row>
                    <Btn attrBtn={{ color: "danger", onClick: redirect }}>
                      Cancel
                    </Btn>
                    <Btn attrBtn={{ color: "primary" }}>Continue</Btn>
                  </Form>
                </Row>
              )}
              {
                pageData.step === 2 && (
                  // <Row>
                  <Form
                    id="formData"
                    className="needs-validation"
                    noValidate=""
                    onSubmit={handleSubmit(onUpdateFormFields)}
                  >
                    <Row>
                      <Col md="6 mb-3">
                        <H5>Add New Field Forms</H5>
                        <hr className="m-t5 m-b5"></hr>
                        <Row>
                          <Col md="6 mb-3">
                            <Label>Field label</Label>
                            <input
                              type="text"
                              className="form-control"
                              name="label"
                              placeholder="Field label"
                              onChange={handleFieldChange}
                            />
                            {/* <span>{errors.label && 'Please Select Field label.'}</span> */}
                          </Col>
                          <Col md="6 mb-3">
                            <Label>Field Type</Label>
                            <select
                              className="form-control"
                              name="type"
                              onChange={handleDDFieldChange}
                            >
                              <option value="">Choose Field Type</option>
                              <option value="text">Text</option>
                              <option value="date">Date</option>
                              <option value="dropdown">DropDown</option>
                              <option value="file">File Upload</option>
                              <option value="checkbox">Checkbox</option>
                              <option value="radio">Radio</option>
                            </select>
                            {/* <span>{errors.type && 'Please Select Choose Field Type.'}</span> */}
                          </Col>
                        </Row>
                        <Row>
                          {dropdownField && (
                            <Col md="12">
                              <Label>Add Options, one per line</Label>
                              <Input
                                type="textarea"
                                className="form-control"
                                onChange={handleFieldChange}
                                name="options"
                                rows="3"
                              />
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col md="12 mb-3">
                            <Label className="d-block" for="isRequired">
                              <Input
                                className="checkbox_animated"
                                id="isRequired"
                                onChange={handleFieldChange}
                                name="isRequired"
                                type="checkbox"
                              />
                              Is Required
                            </Label>
                          </Col>
                          <Col md="12 mb-3">
                            <Label>Error Message</Label>
                            <input
                              type="text"
                              className="form-control"
                              name="errorMessage"
                              placeholder="Error Message"
                              onChange={handleFieldChange}
                            />
                            {/* <span>{errors.errorMessage && 'Please Enter Error Message.'}</span> */}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Btn
                              attrBtn={{
                                color: "primary",
                                onClick: addNewField,
                              }}
                            >
                              Add New Field
                            </Btn>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6 mb-3">
                        {isLoading && (
                          <div className="loader-boxs">
                            <Spinner attrSpinner={{ className: "loader-5" }} />
                          </div>
                        )}
                        <H5>Form View</H5>
                        <hr className="m-t5 m-b5"></hr>
                        <Row>
                          {formInputs}
                          <div style={{ textAlign: "center" }}>
                            <Btn
                              attrBtn={{
                                color: "primary",
                                className: "disabled",
                              }}
                            >
                              Success
                            </Btn>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-3">
                        <Btn attrBtn={{ color: "primary", onClick: prevStep }}>
                          Back
                        </Btn>
                      </Col>
                      <Col md="6 mb-3" className="text-end">
                        <Btn attrBtn={{ color: "danger", onClick: redirect }}>
                          Cancel
                        </Btn>
                        <Btn attrBtn={{ color: "primary" }}>
                          {!state.edit ? "Submit" : "Update"}
                        </Btn>
                      </Col>
                    </Row>
                  </Form>
                )
                // </Row>
              }
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};
export default AddFormContain;
