import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Btn, H2, P, Image } from "../../../AbstractElements";
import { SignIn } from "../../../Constant";
import { useForm } from "react-hook-form";
import { User } from "react-feather";
import imgg from "../../../assets/images/logo/logo.png";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";

const ForceChangePwd = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [userDetail, setUserDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const state = useLocation();
  const [codeStep, setCodeStep] = useState(0);

  useEffect(() => {
    console.log(state);
    setUserDetail(state.state);
  }, []);

  async function changePassword(data) {
    try {
      setLoading(true);
      console.log(userDetail);
      const { requiredAttributes } = userDetail.challengeParam;
      // Auth.completeNewPassword(
      //     userDetail, // the Cognito User Object
      //     data.newPassword,
      //     requiredAttributes
      // )
      // .then((user) => {
      //     // at this time the user is logged in if no MFA required
      //     console.log(user);
      // })
      // .catch((e) => {
      //     console.log(e);
      // });
      // await Auth.forgotPasswordSubmit(newusername, data.code, data.password).then(data => {
      //     console.log(data);
      //     toast.success("Your Password Changed Successfully", {
      //         position: toast.POSITION.TOP_CENTER
      //     });
      //     setLoading(false);
      // }).catch(err =>{
      //     console.log(err);
      //     toast.error('Error Changing The Password: '+ err.message, {
      //         position: toast.POSITION.TOP_CENTER
      //     });
      //     setLoading(false);
      // });
    } catch (err) {
      toast.error("Error Changing The Password: " + err.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  }

  const submit = async (data) => {
    try {
      const { username } = data.username;
      const output = await resetPassword({ username });
      handleResetPasswordNextSteps(output);
    } catch (error) {
      console.error(error);
    }
  };

  function handleResetPasswordNextSteps(output) {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        setCodeStep(1);
        console.log(
          `Confirmation code was sent to ${nextStep.codeDeliveryDetails.deliveryMedium}`
        );
        break;
      case "DONE":
        console.log("Password reset successfully.");
        break;
    }
  }

  const handleConfirmResetPassword = async (data) => {
    try {
      console.log(data);
      const { username, confirmationCode, newPassword } = data;
      const result = await confirmResetPassword({
        username,
        confirmationCode,
        newPassword,
      });
      console.log("Password reset confirmation result", result);
      alert("Success !!", "Password reset successfully.");
      //   navigate(`${import.meta.env.BASE_URL}`);
    } catch (error) {
      console.error(error);
      alert("Error !!", "Invalid verification code provided");
    }
  };

  return (
    <Fragment>
      <section>
        <Container className="p-0" fluid={true}>
          <Row className="m-0">
            <Col className="p-0">
              <div className="login-main">
                <div className="login-card1">
                  {codeStep === 0 ? (
                    <Form
                      className="theme-form login-form needs-validation"
                      noValidate=""
                      onSubmit={handleSubmit(submit)}
                    >
                      <div className="login-header text-center">
                        <div className="logo-wrapper">
                          <a href="#javascript">
                            <Image
                              attrImage={{
                                className: "img-fluid",
                                src: `${imgg}`,
                                alt: "",
                              }}
                            />
                          </a>
                        </div>
                      </div>
                      <H2 attrH2={{ className: "mb-3 text-center" }}>
                        Force Change Password
                      </H2>
                      <FormGroup>
                        <Label>Username</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="icon-lock"></i>
                          </span>
                          <input
                            className="form-control"
                            type="text"
                            name="username"
                            {...register("username", { required: true })}
                            placeholder="Confirmation Code"
                          />
                        </div>
                        <span className="invalid-span">
                          {errors.username && "Please enter username"}
                        </span>
                        <div className="invalid-feedback">
                          {"Please enter username"}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Btn
                          attrBtn={{
                            color: "primary",
                            disabled: loading ? loading : loading,
                          }}
                        >
                          {loading ? "LOADING..." : "Force Change Password"}
                        </Btn>
                      </FormGroup>
                    </Form>
                  ) : (
                    <Form
                      className="theme-form login-form needs-validation"
                      noValidate=""
                      onSubmit={handleSubmit(handleConfirmResetPassword)}
                    >
                      <div className="login-header text-center">
                        <div className="logo-wrapper">
                          <a href="#javascript">
                            <Image
                              attrImage={{
                                className: "img-fluid",
                                src: `${imgg}`,
                                alt: "",
                              }}
                            />
                          </a>
                        </div>
                      </div>
                      <H2 attrH2={{ className: "mb-3 text-center" }}>
                        Force Change Password
                      </H2>
                      <FormGroup>
                        <Label>Confirmation Code</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="icon-lock"></i>
                          </span>
                          <input
                            className="form-control"
                            type="text"
                            name="confirmationCode"
                            {...register("confirmationCode", {
                              required: true,
                            })}
                            placeholder="Confirmation Code"
                          />
                        </div>
                        <span className="invalid-span">
                          {errors.confirmationCode &&
                            "Please enter confirmationCode"}
                        </span>
                        <div className="invalid-feedback">
                          {"Please enter confirmationCode"}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label>New Password</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="icon-lock"></i>
                          </span>
                          <input
                            className="form-control"
                            type="password"
                            name="newPassword"
                            {...register("newPassword", { required: true })}
                            placeholder="New Password"
                          />
                        </div>
                        <span className="invalid-span">
                          {errors.newPassword && "Please enter New Password"}
                        </span>
                        <div className="invalid-feedback">
                          {"Please enter New Password"}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Btn
                          attrBtn={{
                            color: "primary",
                            disabled: loading ? loading : loading,
                          }}
                        >
                          {loading ? "LOADING..." : "Force Change Password"}
                        </Btn>
                      </FormGroup>
                    </Form>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ForceChangePwd;
