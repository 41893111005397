import { Btn } from "../../../AbstractElements";
import React, { Fragment, useContext } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";

import * as mutations from "../../../graphql/mutations";

import awsExports from "../../../aws-exports";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const CompanyDetails = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  async function onSubmit() {
    const data = await client.graphql({
      query: mutations.createCompanyDetails,
      variables: {
        input: {
          id: "322323",
          companyName: "Test",
          companyBN: "Test",
          companyAddress: "Test",
          logo: "Test",
          managerName: "Test",
          email: "Test@gmail.com",
          mobile: "Test",
        },
      },
      authMode: "API_KEY",
    });

    console.log(data);
  }

  return (
    <Fragment>
      <Form
        className="needs-validation"
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col md="6 mb-3">
            <Label>Company Name</Label>
            <input
              className="form-control"
              name="companyName"
              type="text"
              placeholder="Company name"
              {...register("companyName", { required: true })}
            />
            <span style={{ color: "red" }}>
              {errors.companyName && "Company Name is required"}{" "}
            </span>
          </Col>
          <Col md="6 mb-3">
            <Label>Company Bussiness Number</Label>
            <input
              className="form-control"
              name="companyBN"
              type="text"
              placeholder="Company Bussiness Number"
              {...register("companyBN", { required: true })}
            />
            <span>{errors.companyBN && "Last name is required"}</span>
            <div className="valid-feedback">{"Looks good!"}</div>
          </Col>
        </Row>
        <Row>
          <Col md="6 mb-3">
            <Label>Comapny Address</Label>
            <input
              className="form-control"
              name="companyAddress"
              type="text"
              placeholder="Comapny Address"
              {...register("companyAddress", { required: true })}
            />
            <span>
              {errors.companyAddress && "Please provide a valid companyAddress"}
            </span>
            <div className="invalid-feedback">
              {"Please provide a valid companyAddress."}
            </div>
          </Col>
          <Col md="6 mb-3">
            <Label>Manager Name</Label>
            <input
              className="form-control"
              name="managerName"
              type="text"
              placeholder="Manager Name"
              {...register("managerName", { required: true })}
            />
            <span>
              {errors.managerName && "Please provide a valid managerName."}
            </span>
            <div className="invalid-feedback">
              {"Please provide a valid managerName."}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="6 mb-3">
            <Label>Email</Label>
            <input
              className="form-control"
              name="email"
              type="text"
              placeholder="Email"
              {...register("email", { required: true })}
            />
            <span>{errors.email && "Please provide a valid email."}</span>
            <div className="invalid-feedback">
              {"Please provide a valid email."}
            </div>
          </Col>
          <Col md="6 mb-3">
            <Label>Mobile</Label>
            <input
              className="form-control"
              name="mobile"
              type="text"
              placeholder="Mobile"
              {...register("mobile", { required: true })}
            />
            <span>{errors.mobile && "Please provide a valid mobile."}</span>
            <div className="invalid-feedback">
              {"Please provide a valid mobile."}
            </div>
          </Col>
        </Row>
        <Btn attrBtn={{ color: "primary" }}>Submit</Btn>
      </Form>
    </Fragment>
  );
};
export default CompanyDetails;
