import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  CardBody,
  Media,
  Label,
  ModalBody,
  ModalHeader,
  Modal,
  Form,
} from "reactstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Btn, H4, H1, Badges, H6 } from "../../../AbstractElements";
import Select from "react-select";
import { SiteContext } from "../../../App";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import { toast } from "react-toastify";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import cloneDeep from "lodash/cloneDeep";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const ListAssetDetails = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const [selectedSearchDBData, setSelectedSearchDBData] = useState("");
  const [sites, setSites] = useState([]);
  const [assets, setAssets] = useState([]);
  const [selectedSearchSiteData, setSelectedSearchSiteData] = useState("");
  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const [distributionBoxFilter, setDistributionBoxfilter] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedSiteData, setSelectedSiteData] = useState("");
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
    LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];
  const LoginUserGroup = localStorage.getItem("groups");
  const countPerPage = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [collection, setCollection] = React.useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const [distributionBoxList, setDistributionBoxList] = useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [fullAssets, setFullAssets] = useState([]);
  const [activedata, setactiveData] = React.useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [deleteAsset, setDelete] = useState(false);
  const [selectedItemsAsset, setSelectedItemsAsset] = useState([]);
  const [addOn, setAddOn] = useState([]);
  const countPerPageCard = 8;
  const [currentPageCard, setCurrentPageCard] = useState(1);
  const [assetPlancount, setAssetplancount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = useState("");
  const [assetDemo, setAssetsDemo] = React.useState([]);
  const [assetDemoCard, setAssetsDemoCard] = React.useState([]);
  const [collectionCard, setCollectionCard] = React.useState([]);
  const [selectedBox, setSelectedBox] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [isActive, setIsActive] = useState(true);

  const [deleteModal, setDeleteModal] = useState(false);
  const deleteToggle = () => {
    selectedItemsAsset.length > 0
      ? setDeleteModal(!deleteModal)
      : setDeleteModal(false);
  };

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    const data =
      value ||
      (state.state && state.state.site ? false : selectedSite) ||
      selectedLocation ||
      selectedBox
        ? filteredData
        : state.state && state.state.site && state.state.category
        ? assetDemo
        : assetDemo;
    setCollection(cloneDeep(data.slice(from, to)));
  };

  const updatePageCard = (p) => {
    setCurrentPageCard(p);
    const to = countPerPageCard * p;
    const from = to - countPerPageCard;
    const data =
      value ||
      (state.state && state.state.site ? false : selectedSite) ||
      selectedLocation ||
      selectedBox
        ? filteredData
        : state.state && state.state.site && state.state.category
        ? assetDemoCard
        : assetDemoCard;
    setCollectionCard(cloneDeep(data.slice(from, to)));
  };

  useEffect(() => {
    if (LoginUserGroup === "site_user") {
      fetchSitesDetailsSU();
    } else if (LoginUserGroup === "electrician") {
      fetchSitesDetailsSU();
    } else {
      fetchSitesDetails();
    }
  }, []);

  // --- site user assets ---
  async function fetchSitesDetailsSU() {
    setIsLoading(true);
    await client
      .graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        variables: {
          userdetailsID: LoginUserDetails.id,
          limit: 100000,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        const sites = result.data.linkUsersAndSitesByUserdetailsID.items;
        if (sites.length > 0) {
          const promises = sites.map(async (item) => {
            return client.graphql({
              query: queries.getSiteDetails,
              variables: {
                id: item.sitedetailsID,
              },
            });
          });
          Promise.all(promises)
            .then(async (results) => {
              console.log(results);
              let siteData = [];
              if (results.length > 0) {
                results.map((site) => {
                  if (site.data.getSiteDetails !== null)
                    siteData.push(site.data.getSiteDetails);
                });
                if (siteData.length > 0) {
                  const siteFilterArray = [];
                  siteData.map((item) => {
                    siteFilterArray.push(item.id);
                  });
                  let nextToken = "";
                  let assets = [];
                  await client
                    .graphql({
                      query: queries.listDistributionBoxes,
                      variables: {
                        limit: 100000,
                      },
                      authMode: "API_KEY",
                    })
                    .then(async (result) => {
                      let assetDatas = result.data.listDistributionBoxes.items;
                      nextToken = result.data.listDistributionBoxes.nextToken;
                      if (result.data.listDistributionBoxes.items.length > 0) {
                        assets.push(assetDatas);
                      }
                      while (nextToken !== null) {
                        await client
                          .graphql({
                            query: queries.listDistributionBoxes,
                            variables: {
                              limit: 100000,
                              nextToken: nextToken,
                            },
                            authMode: "API_KEY",
                          })
                          .then((results) => {
                            nextToken =
                              results.data.listDistributionBoxes.nextToken;
                            if (
                              results.data.listDistributionBoxes.items.length >
                              0
                            ) {
                              assets.push(
                                results.data.listDistributionBoxes.items
                              );
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                            setDistributionBoxList([]);
                            setDistributionBoxfilter([]);
                          });
                      }
                      console.log(assets);
                      if (assets.length > 0) {
                        const mergeAssets = assets.flat(1);
                        console.log(mergeAssets);
                        //--
                        const Assets = mergeAssets.filter((item) => {
                          const _matchSites = siteFilterArray?.find(
                            (id) => id === item?.sitedetailsID
                          );
                          if (_matchSites) {
                            return item;
                          }
                        });
                        console.log(Assets);
                        setDistributionBoxList(Assets);
                        setDistributionBoxfilter(Assets);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      setDistributionBoxList([]);
                      setDistributionBoxfilter([]);
                    });

                  const siteOptions = [{ value: "", label: "Select Site" }];
                  if (state.state) {
                    getAssetTemplate(siteData);
                  }
                  setSiteDetails(siteData);
                  siteData.map(async (item) => {
                    siteOptions.push({
                      value: item.id,
                      label:
                        item.siteName +
                        " " +
                        (item.unitNumber ? item.unitNumber : " "),
                    });
                  });
                  setSites(siteOptions);
                } else {
                  setDistributionBoxList([]);
                  setDistributionBoxfilter([]);
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setSites([]);
          setAssets([]);
          setIsLoading(false);
          // setErrorMessage("No Assets to fetch");
        }
      });
  }

  async function fetchSitesDetails() {
    setIsLoading(true);
    await client
      .graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        const sites = result.data.siteDetailsByAssetownerID.items;
        let nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        let siteData = [];
        if (sites.length > 0) {
          siteData.push(sites);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.siteDetailsByAssetownerID,
              variables: {
                assetownerID: LoginUserDetails.id,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((result) => {
              nextToken = result.data.siteDetailsByAssetownerID.nextToken;
              if (result.data.siteDetailsByAssetownerID.items.length > 0) {
                siteData.push(result.data.siteDetailsByAssetownerID.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        if (siteData.length > 0) {
          let mergeSites = siteData.flat(1);
          if (mergeSites.length > 0) {
            const siteFilterArray = [];
            mergeSites.map(async (item) => {
              siteFilterArray.push(item.id);
            });
            let nextToken = "";
            let dbs = [];
            await client
              .graphql({
                query: queries.listDistributionBoxes,
                variables: {
                  limit: 100000,
                },
                authMode: "API_KEY",
              })
              .then(async (result) => {
                let assetDatas = result.data.listDistributionBoxes.items;
                nextToken = result.data.listDistributionBoxes.nextToken;
                if (result.data.listDistributionBoxes.items.length > 0) {
                  dbs.push(assetDatas);
                }
                while (nextToken !== null) {
                  await client
                    .graphql({
                      query: queries.listDistributionBoxes,
                      variables: {
                        limit: 100000,
                        nextToken: nextToken,
                      },
                      authMode: "API_KEY",
                    })
                    .then((results) => {
                      nextToken = results.data.listDistributionBoxes.nextToken;
                      if (results.data.listDistributionBoxes.items.length > 0) {
                        dbs.push(results.data.listDistributionBoxes.items);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      setDistributionBoxList([]);
                      setDistributionBoxfilter([]);
                    });
                }
                console.log(dbs);
                if (dbs.length > 0) {
                  const mergeDBs = dbs.flat(1);
                  console.log(mergeDBs);
                  //--
                  const DBS = mergeDBs.filter((item) => {
                    const _matchSites = siteFilterArray?.find(
                      (id) => id === item?.sitedetailsID
                    );
                    if (_matchSites) {
                      return item;
                    }
                  });
                  console.log(DBS);
                  setDistributionBoxList(DBS);
                  setDistributionBoxfilter(DBS);
                } else {
                  setDistributionBoxList([]);
                  setDistributionBoxfilter([]);
                }
              })
              .catch((error) => {
                console.log(error);
                setDistributionBoxList([]);
                setDistributionBoxfilter([]);
              });
          } else {
            setDistributionBoxList([]);
          }
          const siteFilterArray = [];
          const siteOptions = [{ value: "", label: "Select Site" }];
          let siteFilter = [];
          if (state.state && state.state.site) {
            siteFilter = mergeSites.filter(
              (site) => site.id === state.state.site.id
            );
          }
          const globalsite = mergeSites.filter(
            (site) => site.id === selectedSiteGlobal
          );
          console.log(globalsite);
          const dataFilter = selectedSiteGlobal ? globalsite : siteFilter;
          if (state.state) {
            dataFilter.length > 0
              ? getAssetTemplate(dataFilter)
              : getAssetTemplate(mergeSites);
          }
          dataFilter.length > 0
            ? setSiteDetails(dataFilter)
            : setSiteDetails(mergeSites);
          mergeSites.map(async (item) => {
            siteFilterArray.push(item.id);
            siteOptions.push({
              value: item.id,
              label:
                item.siteName + " " + (item.unitNumber ? item.unitNumber : " "),
            });
          });
          setSites(siteOptions);
        } else {
          setAssets([]);
          setIsLoading(false);
          // setErrorMessage("No Assets to fetch");
        }
      });
  }

  const handleSiteDDChange = (selectedOption) => {
    setSelectedSite(selectedOption.value);
    setSelectedSearchSiteData(selectedOption);
    // console.log(fullAssets);
  };

  const handleSiteDB = (selectedOption) => {
    // setSelectedBox(selectedOption.value);
    setSelectedSearchDBData(selectedOption);
  };

  const optionsDB = [
    { value: "", label: "All Distribution Board" },
    ...distributionBoxFilter.map((item) => ({
      value: item.id,
      label: item.distributionBoxName,
    })),
  ];

  const getSiteName = (siteId) => {
    try {
      const sites = siteDetails.filter((site) => {
        return site.id === siteId;
      });
      //console.log("display site name", sites);
      return sites.length > 0 ? sites[0].siteName : "";
    } catch (error) {
      console.log("Error while displaying site name", error);
      return "";
    }
  };

  const getDBName = (dbId) => {
    try {
      const db = distributionBoxList.filter((db) => {
        return db.id === dbId;
      });
      return db.length > 0 ? db[0].distributionBoxName : "";
    } catch (error) {
      console.log("display site name", error);
      return "";
    }
  };

  const handleCheckboxAssetChange = (event, itemId) => {
    let assets = isSearch ? filteredData : fullAssets;
    const isChecked = event.target.checked;
    if (itemId === "selectAll") {
      setIsAllSelected(isChecked);

      if (isChecked) {
        const allItemIds = assets.map((item) => item.id);
        setSelectedItemsAsset(allItemIds);
      } else {
        setSelectedItemsAsset([]);
      }
    } else {
      setSelectedItemsAsset((prevSelectedItems) => {
        if (isChecked && !prevSelectedItems.includes(itemId)) {
          return [...prevSelectedItems, itemId];
        } else if (!isChecked && prevSelectedItems.includes(itemId)) {
          return prevSelectedItems.filter((item) => item !== itemId);
        }
        return prevSelectedItems;
      });

      const allItemIds = assets.map((item) => item.id);
      setIsAllSelected(selectedItemsAsset.length === allItemIds.length);
    }
  };

  const handleDeleteAssets = () => {
    try {
      let counter = 0;
      selectedItemsAsset.map(async (item, i) => {
        await sleep(Math.random() * i * 17); // wait
        await client
          .graphql({
            query: mutations.updateAssets,
            variables: {
              input: { id: item, status: "delete" },
            },
          })
          .then((result) => {
            console.log(result);
            counter = counter + 1;
            if (counter === selectedItemsAsset.length) {
              toast.success("Selected Assets Deleted Successfully.", {
                position: toast.POSITION.TOP_CENTER,
              });
              navigate(`${process.env.PUBLIC_URL}/asset/deleteList`, {
                state: {
                  category: state.state.category,
                  site: state.state.site,
                },
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } catch (error) {
      console.log(error);
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const ApproveStatus = (status) => {
    return status?.toLowerCase() === "active"
      ? "switch-state bg-success"
      : "switch-state bg-danger";
  };

  const handleChange = async (item, e) => {
    //console.log(e);
    try {
      var statusSp;
      if (item.status.toLowerCase() === "active") {
        statusSp = "Inactive";
        toast.success("Deactivated successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        statusSp = "Active";
        toast.success("Activated successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      //console.log(item);
      await client
        .graphql({
          query: mutations.updateAssets,
          variables: {
            input: { id: item.id, status: statusSp },
          },
        })
        .then((result) => {
          //console.log(result);
          if (LoginUserGroup === "site_user") {
            fetchSitesDetailsSU();
          } else {
            fetchSitesDetails();
          }
        })
        .catch((error) => {
          //console.log(error);
          toast.error("Error while Updating ", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (e) {
      toast.error("Error while Updating ", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getAssetTemplate = async (siteData) => {
    await client
      .graphql({
        query: queries.assetTemplatesByCategoriesID,
        variables: {
          categoriesID: state.state.category.id,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
      .then((result) => {
        console.log(result.data.assetTemplatesByCategoriesID.items);
        if (result.data.assetTemplatesByCategoriesID.items.length > 0) {
          // setAssetTemplatData(result.data.assetTemplatesByCategoriesID.items);
          getAssetsByTemplates(
            result.data.assetTemplatesByCategoriesID.items,
            siteData
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function getAssetsByTemplates(assetTemplates, siteData) {
    try {
      setIsLoading(true);
      let nextToken = "";
      let assets = [];
      let count = 0;
      assetTemplates.map((assetTemplate) => {
        count = count + 1;
        siteData.map(async (sites) => {
          let tempSiteId = assetTemplate.id + "_" + sites.id;
          await client
            .graphql({
              query: queries.assetsByAssettemplateSiteIDAndAssetNum,
              variables: {
                assettemplateSiteID: tempSiteId,
              },
              authMode: "API_KEY",
            })
            .then(async (result) => {
              let assetDatas =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
              nextToken =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
              if (
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                  .length > 0
              ) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                await client
                  .graphql({
                    query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                    variables: {
                      assettemplateSiteID: tempSiteId,
                      nextToken: nextToken,
                    },
                    authMode: "API_KEY",
                  })
                  .then((results) => {
                    nextToken =
                      results.data.assetsByAssettemplateSiteIDAndAssetNum
                        .nextToken;
                    if (
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                        .length > 0
                    ) {
                      assets.push(
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .items
                      );
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    // setFullAssets([]);
                    setIsLoading(false);
                  });
              }
              console.log(assets);
              if (count === assetTemplates.length) {
                const mergeAssets = assets.flat(1);
                console.log(mergeAssets);
                let siteAssets = [];
                const filterAssets = mergeAssets;
                // .filter(
                //   (item) => item.sitedetailsID === selectedSiteGlobal
                // );
                if (state.state && state.state.site) {
                  siteAssets = filterAssets.filter(
                    (item) => item.sitedetailsID === state.state.site.id
                  );
                }
                console.log(siteAssets);
                // siteAssets.length > 0
                //   ? setFullAssets(siteAssets)
                //   : setFullAssets(filterAssets);
                // const sort =
                //   siteAssets.length > 0
                //     ? siteAssets.sort((a, b) => a.assetNum - b.assetNum)
                //     : filterAssets.sort((a, b) => a.assetNum - b.assetNum);
                // console.log(sort);
                // setactiveData(sort);
                // const filteredAssets = sort.filter((item) =>
                //   isActive
                //     ? item.status === "Active"
                //     : item.status === "Inactive"
                // );
                // setFullAssets(filteredAssets);
                // setAssetsDemo(filteredAssets);
                // setCollection(cloneDeep(filteredAssets.slice(0, countPerPage)));
                // setAssetsDemoCard(filteredAssets);
                // setCollectionCard(
                //   cloneDeep(filteredAssets.slice(0, countPerPageCard))
                // );

                if (selectedSite !== "") {
                  // const sort = filteredAssets.sort(
                  //   (a, b) => a.fittingNumber - b.fittingNumber
                  // );
                  // const to = countPerPage * currentPage;
                  // const from = to - countPerPage;
                  // const tocard = countPerPageCard * currentPageCard;
                  // const fromcard = tocard - countPerPageCard;
                  // const paginatedAssets = sort.slice(from, to);
                  // const paginatedAssetsCard = sort.slice(fromcard, tocard);
                  // setFilteredData(sort);
                  // setCollection(cloneDeep(sort.slice(0, countPerPage)));
                  // setCollectionCard(cloneDeep(sort.slice(0, countPerPageCard)));
                  // setCurrentPage(1);
                  setIsLoading(false);
                } else {
                  setIsLoading(false);
                }
              }
            })
            .catch((error) => {
              console.log(error);
              // setFullAssets([]);
              setIsLoading(false);
            });
        });
      });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  return (
    <Fragment>
      <Container fluid={true} className="p-0 project-list">
        {state.state && state.state.category && (
          <H1>{state.state.category.categoryName}</H1>
        )}
        <Card className="custom-card">
          <Row>
            {/* <Col md="3">
              <InputGroup>
                <span className="input-group-text">
                  <i className="icon-search"></i>
                </span>
                <Input
                  type="search"
                  placeholder="Search Assets.."
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </InputGroup>
            </Col> */}
            <Col md="3">
              {selectedSiteData !== "" || selectedSiteGlobal ? (
                <select
                  className="form-select"
                  onChange={(e) => setSelectedSite(e.target.value)}
                  disabled={
                    state.state.site ? true : selectedSiteGlobal ? true : false
                  }
                  value={selectedSiteGlobal ? selectedSiteGlobal : selectedSite}
                  menuPlacement="auto"
                  menuPosition="fixed"
                >
                  isLoading ? <LoadingSpinner /> :(
                  <option value="">All Sites</option>
                  {sites.map((site, index) => (
                    <option value={site.value} key={index}>
                      {site?.label}
                    </option>
                  ))}
                  )
                </select>
              ) : (
                <Select
                  value={selectedSearchSiteData}
                  onChange={handleSiteDDChange}
                  options={sites}
                  isSearchable={true}
                  hideSelectedOptions={false}
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              )}
            </Col>
            <Col md="3">
              <Select
                value={selectedSearchDBData}
                onChange={handleSiteDB}
                options={optionsDB}
                isSearchable={true}
                hideSelectedOptions={false}
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </Col>
          </Row>
        </Card>
        <Row></Row>
        <div className="card-block row">
          <Col sm="12" lg="12" xl="12">
            <Pagination
              className="p-b-10"
              pageSize={countPerPage}
              onChange={updatePage}
              current={currentPage}
              total={
                value ||
                (state.state && state.state.site ? false : selectedSite) ||
                selectedLocation ||
                selectedBox
                  ? filteredData.length
                  : state.state && state.state.site && state.state.category
                  ? assetDemo.length
                  : assetDemo.length
              }
              showTotal={(total, range) =>
                `${range[0]} - ${range[1]} of ${total} items`
              }
            />
            <div className="table-responsive">
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Table className="table-striped  table-de ">
                    <thead className="custom-table-head">
                      <tr>
                        {deleteAsset && (
                          <th>
                            <input
                              type="checkbox"
                              checked={isAllSelected}
                              onChange={(event) =>
                                handleCheckboxAssetChange(event, "selectAll")
                              }
                            />
                          </th>
                        )}
                        <th>Fitting No.</th>
                        <th>Asset Model</th>
                        <th>Site</th>
                        <th>Distribution board</th>
                        <th>Installed On</th>
                        <th>Asset Warranty</th>
                        <th>Asset Location</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="trhover">
                      {collection.filter((item) =>
                        isActive
                          ? item.status === "Active"
                          : item.status === "Inactive"
                      ).length > 0 ? (
                        collection &&
                        collection
                          .filter((item) =>
                            isActive
                              ? item.status === "Active"
                              : item.status === "Inactive"
                          )
                          .map((item, i) => (
                            <tr
                              key={item.id}
                              className={item.duplicate ? "duplicateRow" : ""}
                              title={item.duplicate ? item.duplicate : ""}
                            >
                              {/* <td>{item.duplicate}</td> */}
                              {deleteAsset && (
                                <td>
                                  <input
                                    type="checkbox"
                                    name="completionLink"
                                    checked={selectedItemsAsset.includes(
                                      item.id
                                    )}
                                    onChange={(event) =>
                                      handleCheckboxAssetChange(event, item.id)
                                    }
                                  />
                                </td>
                              )}
                              <td>
                                {userPermission.includes("Edit Assets") ? (
                                  state.state && (
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/asset/view`}
                                      state={{
                                        category: state.state.category,
                                        item: item,
                                        site: getSiteName(item.sitedetailsID),
                                        db: getDBName(item.distributionboxID),
                                      }}
                                    >
                                      {item.fittingNumber}
                                    </Link>
                                  )
                                ) : (
                                  <>{item.fittingNumber}</>
                                )}
                              </td>
                              <td>{item.assetModel}</td>
                              <td>{getSiteName(item.sitedetailsID)}</td>
                              <td>{getDBName(item.distributionboxID)}</td>
                              <td>
                                {item.installedOn !== null
                                  ? moment(item.installedOn).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </td>
                              {item.assetWarrantyStart != null && (
                                <td>
                                  {moment(item.assetWarrantyStart).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  -
                                  {moment(item.assetWarrantyEnd).format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                              )}
                              {item.assetWarrantyStart === null && <td>-</td>}
                              <td>{item.assetLocation}</td>
                              <td>
                                <Media body className="icon-state switch-sm">
                                  <Label className="switch">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => handleChange(item, e)}
                                    />
                                    <span
                                      className={ApproveStatus(item.status)}
                                    ></span>
                                  </Label>
                                </Media>
                              </td>
                            </tr>
                          ))
                      ) : (
                        <p>No Assets Found</p>
                      )}{" "}
                    </tbody>
                  </Table>
                </>
              )}
            </div>
          </Col>
        </div>
      </Container>
    </Fragment>
  );
};
export default ListAssetDetails;
