import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Btn, H2, P, Image } from "../../../AbstractElements";
import { SignIn } from "../../../Constant";
import { useForm } from "react-hook-form";
import { User } from "react-feather";
import imgg from "../../../assets/images/logo/logo.png";
import { toast } from "react-toastify";
import { generateClient } from "aws-amplify/api";
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const ForgetPwd = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [step, setStep] = useState(0);
  const [newusername, setNewUsername] = useState("");
  const [loading, setLoading] = useState(false);
  async function sendConfirmationCode(data) {
    try {
      setLoading(true);
      const { username } = data;
      const output = await resetPassword({ username });
      console.log(output);
      if (output) {
        setStep(1);
        setNewUsername(data.username);
        toast.success("Verification Code Sent To Your Email Successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
      // await Auth.forgotPassword(data.username)
      //   .then((data1) => {
      //     setStep(1);
      //     setNewUsername(data.username);
      //     toast.success("Verification Code Sent To Your Email Successfully", {
      //       position: toast.POSITION.TOP_CENTER,
      //     });
      //     console.log(data1);
      //     setLoading(false);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     toast.error("Error Sending Code: " + err.message, {
      //       position: toast.POSITION.TOP_CENTER,
      //     });
      //     setLoading(false);
      //   });
    } catch (err) {
      toast.error("Error Sending Code: " + err.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  }

  async function changePassword(data) {
    try {
      setLoading(true);
      console.log(data);
      const { username, confirmationCode, newPassword } = data;
      const result = await confirmResetPassword({
        username,
        confirmationCode,
        newPassword,
      });
      console.log("Password reset confirmation result", result);
      // if (result) {
      toast.success("Your Password Changed Successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
      window.location.replace(`${process.env.PUBLIC_URL}/login`);
      //}
      // await Auth.forgotPasswordSubmit(newusername, data.code, data.password)
      //   .then((data) => {
      //     console.log(data);
      //     toast.success("Your Password Changed Successfully", {
      //       position: toast.POSITION.TOP_CENTER,
      //     });
      //     setLoading(false);
      //     window.location.replace(`${process.env.PUBLIC_URL}/login`);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     toast.error("Error Changing The Password: " + err.message, {
      //       position: toast.POSITION.TOP_CENTER,
      //     });
      //     setLoading(false);
      //   });
    } catch (err) {
      toast.error("Error Changing The Password: " + err.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  }

  return (
    <Fragment>
      <section>
        <Container className="p-0" fluid={true}>
          <Row className="m-0">
            <Col className="p-0">
              <div className="login-main">
                <div className="login-card1">
                  {step === 0 && (
                    <Form
                      className="theme-form login-form needs-validation"
                      noValidate=""
                      onSubmit={handleSubmit(sendConfirmationCode)}
                    >
                      <div className="login-header text-center">
                        <div className="logo-wrapper">
                          <a href="#javascript">
                            <Image
                              attrImage={{
                                className: "img-fluid",
                                src: `${imgg}`,
                                alt: "",
                              }}
                            />
                          </a>
                        </div>
                      </div>
                      <H2
                        attrH2={{
                          className: "btn-block",
                          className: "mb-3 text-center",
                        }}
                      >
                        Reset Your Password
                      </H2>
                      <FormGroup>
                        <Label htmlFor="validationCustom01">Username</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <User />
                          </span>
                          <input
                            className="form-control"
                            name="username"
                            type="text"
                            placeholder="Username"
                            {...register("username", { required: true })}
                          />
                        </div>
                        <span className="invalid-span">
                          {errors.username && "Please enter Username"}
                        </span>
                        <div className="invalid-feedback">
                          {"Please enter Username"}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Btn
                          attrBtn={{
                            className: "btn-block",
                            color: "primary",
                            disabled: loading ? loading : loading,
                          }}
                        >
                          {loading ? "LOADING..." : "Get Confirmation Code"}
                        </Btn>
                      </FormGroup>
                      <P>
                        Already have an password?
                        <Link to={`${process.env.PUBLIC_URL}/login`}>
                          {SignIn}
                        </Link>
                      </P>
                    </Form>
                  )}
                  {step === 1 && (
                    <Form
                      className="theme-form login-form needs-validation"
                      noValidate=""
                      onSubmit={handleSubmit(changePassword)}
                    >
                      <div className="login-header text-center">
                        <div className="logo-wrapper">
                          <a href="#javascript">
                            <Image
                              attrImage={{
                                className: "img-fluid",
                                src: `${imgg}`,
                                alt: "",
                              }}
                            />
                          </a>
                        </div>
                      </div>
                      <H2 attrH2={{ className: "mb-3 text-center" }}>
                        Reset Your Password
                      </H2>
                      <FormGroup>
                        <Label>Confirmation Code</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="icon-lock"></i>
                          </span>
                          <input
                            className="form-control"
                            type="text"
                            name="confirmationCode"
                            {...register("confirmationCode", { required: true })}
                            placeholder="Confirmation Code"
                          />
                        </div>
                        <span className="invalid-span">
                          {errors.code && "Please enter Code"}
                        </span>
                        <div className="invalid-feedback">
                          {"Please enter Code"}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label>New Password</Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="icon-lock"></i>
                          </span>
                          <input
                            className="form-control"
                            type={togglePassword ? "text" : "password"}
                            name="newPassword"
                            {...register("newPassword", { required: true })}
                            placeholder="Password"
                          />
                          <div
                            className="show-hide"
                            onClick={() => setTogglePassword(!togglePassword)}
                          >
                            <span
                              className={togglePassword ? "" : "show"}
                            ></span>
                          </div>
                        </div>
                        <p>
                          Password should have at least 1 Capital Letter, 1
                          Number, 1 Special Character and be a minimum of 8
                          characters in length
                        </p>
                        <span className="invalid-span">
                          {errors.password && "Please enter Password"}
                        </span>
                        <div className="invalid-feedback">
                          {"Please enter Password"}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Btn
                          attrBtn={{
                            className: "btn-block",
                            color: "primary",
                            disabled: loading ? loading : loading,
                          }}
                        >
                          {loading ? "LOADING..." : "Change Password"}
                        </Btn>
                      </FormGroup>
                      <P>
                        Already have an password?
                        <Link to={`${process.env.PUBLIC_URL}/login`}>
                          {SignIn}
                        </Link>
                      </P>
                    </Form>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ForgetPwd;
