import { Btn, H6 } from "../../../AbstractElements";
import { FirstName, LastName, Username, State, City } from "../../../Constant";
import React, { Fragment, useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import {
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Row,
} from "reactstrap";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import awsExports from "../../../aws-exports";
import uuid from "react-native-uuid";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import {
  COMPANY_FOLDER,
  S3_BUCKET,
  REGION,
  ACCESSKEYID,
  SECRETACCESSKEY,
  limit,
} from "../../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const CompanyDetails = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [companyAdress, setCompanyAdress] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [spcompanyname, setspcompanyname] = useState([]);
  const [spcompanynamelice, setspcompanynamelice] = useState([]);
  const [spCompany, setSpCompany] = useState("");
  const [spCompanydisplay, setSpCompanydisplay] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [companySelected, setCompanySelected] = useState(false);
  const [disable, setDisable] = useState(false);
  const [downloadedFile, setdownloadedFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [addressError, setAddressError] = useState(false);
  const { state } = useLocation();
  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });
  //-----logo
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    console.log("file", file);
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `public/${COMPANY_FOLDER}/${file.name}`,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        const filedetails = [];
        filedetails.push({ fileName: file.name, type: file.type });
        console.log(filedetails[0].fileName);
        setSelectedFile(filedetails[0].fileName);
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };

  const getImage = async (filename) => {
    try {
      if (!filename) {
        console.log("No logo available.");
        setdownloadedFile(null);
        return;
      }

      var params = {
        Bucket: S3_BUCKET,
        Key: `public/${COMPANY_FOLDER}/${filename}`,
      };

      try {
        var url = await myBucket.getSignedUrlPromise("getObject", params);
        console.log(url);
        setdownloadedFile(url);
      } catch (error) {
        if (error.code === "NotFound") {
          console.log("File not found.");
          setdownloadedFile(null);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  //-----------------------------------------------------------------------------
  function getfilename(path) {
    getImage(path);
  }

  const getImage1 = async (filename) => {
    try {
      if (!filename) {
        console.log("No logo available.");
        return null;
      }

      var params = {
        Bucket: S3_BUCKET,
        Key: `public/${COMPANY_FOLDER}/${filename}`,
      };

      try {
        var url = await myBucket.getSignedUrlPromise("getObject", params);
        console.log(url);
        return url;
      } catch (error) {
        if (error.code === "NotFound") {
          console.log("File not found.");
          return null;
        }
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  };
  const getfilename1 = async (path) => {
    try {
      const url = await getImage1(path);
      console.log(url);
      return url;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  //----
  const onSubmit = useCallback(async (data) => {
    console.log(props);
    console.log(companySelected);
    console.log(spCompany);
    let dupLicence = "";
    console.log(data);
    if (data.licencenumb !== undefined && data.licencenumb !== null) {
      dupLicence = spcompanynamelice.filter(
        (company) =>
          company.companyLicense &&
          company.companyLicense.toLowerCase() ===
            data.licencenumb.toLowerCase()
      );
    }

    if (companySelected || props.values.companySelected) {
      console.log(" sp companny selected from dropdown ");

      const id = spCompany !== "" ? spCompany : props.values.CompanyIdSelected;
      console.log(id);
      const data =
        spCompany !== "" ? companySelected : props.values.companySelected;
      console.log(data);
      props.values.CompanyIdSelected = id;
      props.values.companySelected = data;
      props.nextStep();
    } else {
      if (dupLicence.length === 0) {
        if (companyAdress || props.values.companyaddress) {
          console.log(addressError);
          setAddressError(false);
          const address =
            companyAdress !== "" ? companyAdress : props.values.companyaddress;
          console.log(address);
          const pic = selectedFile !== null ? selectedFile : props.values.logo;
          console.log(pic);

          props.values.CompanyName = data.companyname;
          props.values.businessNumber = data.businessNumber;
          props.values.companyaddress = address;
          props.values.licencenumb = data.licencenumb;
          props.values.phone = data.companyPhone;
          props.values.website = data.companyWebsite;
          props.values.EC = data.companyEC;
          props.values.FAX = data.companyFAX;
          props.values.logo = pic;
          props.values.compEmail = data.email;
          props.nextStep();
        } else {
          console.log(addressError);
          setAddressError(true);
        }
      } else {
        toast.error(
          "Duplicate Electrical Licence Number.Please Check Electrical Licence Number Entered.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
    }
  });

  useEffect(() => {
    if (props.values && props.values.CompanyId !== "") {
      console.log(props);
      setSelectedFile(props.values.logo);
      const companyValue = {
        companyname: props.values.CompanyName,
        businessNumber: props.values.businessNumber,
        companyaddress: props.values.companyaddress,
        licencenumb: props.values.licencenumb,
        companyPhone: props.values.phone,
        companyWebsite: props.values.website,
        companyEC: props.values.EC,
        companyFAX: props.values.FAX,
      };
      reset({ ...companyValue });
    }
  }, []);
  //--------------------------------------------------------------------------------

  // sp company name ----
  async function fetchSpCompanyname() {
    try {
      //--
      let nextToken = "";
      let assets = [];
      await client
        .graphql({
          query: queries.listSPCompanyDetails,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.listSPCompanyDetails.items;
          nextToken = result.data.listSPCompanyDetails.nextToken;
          console.log(
            "first token count",
            result.data.listSPCompanyDetails.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.listSPCompanyDetails.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listSPCompanyDetails,
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.listSPCompanyDetails.nextToken;
                console.log("nextToken", nextToken);
                console.log(results.data.listSPCompanyDetails.items.length);
                if (results.data.listSPCompanyDetails.items.length > 0) {
                  assets.push(results.data.listSPCompanyDetails.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            setspcompanyname(mergeAssets);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    {
      /*
    try {
      await client.graphql({
        query: queries.listSPCompanyDetails,
        variables: {
          limit: 10000,
        },
        authMode: "API_KEY",
      })
        .then((response2) => {
          setspcompanyname(response2.data.listSPCompanyDetails.items);
          console.log(response2.data.listSPCompanyDetails.items);
        })
        .catch(() => {
          setErrorMessage("Unable to fetch the list");
        });
    } catch (error) {
      console.log(error);
    }
  */
    }
  }

  async function fetchSpCompanynameDisplay(compId) {
    try {
      await client
        .graphql({
          query: queries.listSPCompanyDetails,
          variables: {
            filter: {
              id: {
                eq: compId,
              },
            },
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then((response2) => {
          setSpCompanydisplay(response2.data.listSPCompanyDetails.items);
          getfilename(response2.data.listSPCompanyDetails.items[0].logo);
          console.log(response2.data.listSPCompanyDetails.items);
        })
        .catch(() => {
          setErrorMessage("Unable to fetch the list");
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (props.values && props.values.CompanyIdSelected) {
      fetchSpCompanynameDisplay(props.values.CompanyIdSelected);
    } else {
      fetchSpCompanynameDisplay(spCompany);
    }
  }, [spCompany, props.values && props.values.CompanyIdSelected]);

  const handlecompanyOpen = (event) => {
    const selectedcomp = event.target.value;
    setSpCompany(selectedcomp);
    setCompanySelected(selectedcomp !== "");
    setDisable(selectedcomp !== "");
  };

  useEffect(() => {
    fetchSpCompanyname();
    fetchSpCompanynamelice();
  }, []);

  useEffect(() => {
    console.log(getfilename1(props.values.logo));
  }, []);

  useEffect(() => {
    const fetchLogoUrl = async () => {
      try {
        const url = await getfilename1(props.values.logo);
        setLogoUrl(url);
      } catch (error) {
        console.error(error);
      }
    };

    fetchLogoUrl();
  }, [props.values.logo]);

  const createNew = (e) => {
    setDisable(true);
    setSpCompany("");
    console.log(companySelected);
    setCompanySelected(false);
    reset({
      companyname: "",
      businessNumber: "",
      licencenumb: "",
      companyPhone: "",
      companylogo: "",
      email: "",
    });
    setSpCompanydisplay([]);
  };

  // sp company name ----
  async function fetchSpCompanynamelice() {
    try {
      //--
      let nextToken = "";
      let assets = [];
      await client
        .graphql({
          query: queries.listSPCompanyDetails,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.listSPCompanyDetails.items;
          nextToken = result.data.listSPCompanyDetails.nextToken;
          console.log(
            "first token count",
            result.data.listSPCompanyDetails.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.listSPCompanyDetails.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listSPCompanyDetails,
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.listSPCompanyDetails.nextToken;
                console.log("nextToken", nextToken);
                console.log(results.data.listSPCompanyDetails.items.length);
                if (results.data.listSPCompanyDetails.items.length > 0) {
                  assets.push(results.data.listSPCompanyDetails.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            setspcompanynamelice(mergeAssets);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Fragment>
      <H6>Enter Company Details</H6>
      <Row>
        <Col md="4 mb-3">
          <select
            className="form-select"
            onChange={handlecompanyOpen}
            value={
              props.values && props.values.CompanyIdSelected
                ? props.values.CompanyIdSelected
                : spCompany
            }
          >
            <option value="">Find Your Company</option>
            {spcompanyname.map((item) => (
              <option value={item.id} key={item.id}>
                {item.companyName}
              </option>
            ))}
          </select>
        </Col>
        <Col md="2 mb-3"></Col>
        <Col md="2 mb-3">
          <H6>OR</H6>
        </Col>
        <Col md="4 mb-3">
          <Btn
            attrBtn={{
              color: "primary",
              onClick: () => {
                createNew();
              },
            }}
          >
            Create New
          </Btn>
        </Col>
      </Row>
      {(disable ||
        companySelected ||
        props.values.CompanyName ||
        props.values.CompanyIdSelected) && (
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            className={`carddd ${
              companySelected ||
              (props.values && props.values.CompanyIdSelected)
                ? "disabled"
                : ""
            }`}
          >
            <Row>
              <Col md="6 mb-3">
                <Label>Company Name</Label>
                {spCompany !== "" || props.values.CompanyIdSelected ? (
                  <input
                    className="form-control"
                    name="companyname"
                    type="text"
                    placeholder="Company Name"
                    {...register("companyname", { required: false })}
                    {...((companySelected ||
                      (props.values && props.values.CompanyIdSelected)) && {
                      value:
                        props.values &&
                        props.values.CompanyIdSelected &&
                        spCompanydisplay[0]
                          ? spCompanydisplay[0].companyName
                          : companySelected && spCompanydisplay[0]
                          ? spCompanydisplay[0].companyName
                          : "",
                    })}
                  />
                ) : (
                  <input
                    className="form-control"
                    name="companyname"
                    type="text"
                    placeholder="Company Name"
                    defaultValue={props.values.CompanyName}
                    {...register("companyname", { required: true })}
                  />
                )}
                <span>{errors.companyname && "Company Name is required"}</span>
                <div className="valid-feedback">{"Looks good!"}</div>
              </Col>
              <Col md="6 mb-3">
                <Label>Business Number (ABN)</Label>
                {spCompany !== "" || props.values.CompanyIdSelected ? (
                  <input
                    className="form-control"
                    name="businessNumber"
                    type="text"
                    placeholder="Business Number"
                    {...register("businessNumber", { required: false })}
                    {...((companySelected ||
                      (props.values && props.values.CompanyIdSelected)) && {
                      value:
                        props.values &&
                        props.values.CompanyIdSelected &&
                        spCompanydisplay[0]
                          ? spCompanydisplay[0].companyBN
                          : companySelected && spCompanydisplay[0]
                          ? spCompanydisplay[0].companyBN
                          : "",
                    })}
                  />
                ) : (
                  <input
                    className="form-control"
                    name="businessNumber"
                    type="text"
                    placeholder="Business Number"
                    defaultValue={props.values.businessNumber}
                    {...register("businessNumber", { required: true })}
                  />
                )}
                <span>
                  {errors.businessNumber && "Business Number is required"}
                </span>
                <div className="valid-feedback">{"Looks good!"}</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label>Company Address</Label>
                {spCompany !== "" || props.values.CompanyIdSelected ? (
                  <>
                    <input
                      className="form-control"
                      name="companyaddress"
                      type="hidden"
                      placeholder="Company Address"
                      value={
                        props.values && props.values.CompanyIdSelected
                          ? spCompanydisplay[0]
                            ? spCompanydisplay[0].companyAddress
                            : ""
                          : companySelected && spCompanydisplay[0]
                          ? spCompanydisplay[0].companyAddress
                          : props.values && props.values.companyName
                          ? props.values.companyaddress
                          : ""
                      }
                    />
                    <Autocomplete
                      apiKey="AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4"
                      className="form-control"
                      onPlaceSelected={(place) => {
                        console.log(place);
                        setCompanyAdress(place.formatted_address);
                      }}
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "AU" },
                      }}
                      name="companyaddress"
                      placeholder="Company Address"
                      defaultValue={
                        props.values && props.values.CompanyIdSelected
                          ? spCompanydisplay[0]
                            ? spCompanydisplay[0].companyAddress
                            : ""
                          : companySelected && spCompanydisplay[0]
                          ? spCompanydisplay[0].companyAddress
                          : props.values && props.values.companyName
                          ? props.values.companyaddress
                          : ""
                      }
                    />
                  </>
                ) : (
                  <>
                    <input
                      className="form-control"
                      name="companyaddress"
                      type="hidden"
                      placeholder="Company Address"
                    />
                    <Autocomplete
                      apiKey="AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4"
                      className="form-control"
                      onPlaceSelected={(place) => {
                        console.log(place);
                        setCompanyAdress(place.formatted_address);
                        if (place) {
                          setAddressError(false);
                        } else {
                          setAddressError(true);
                        }
                      }}
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "AU" },
                      }}
                      name="companyaddress"
                      placeholder="Company Address"
                      defaultValue={props.values.companyaddress}
                    />
                    {addressError ? (
                      <span>{"Selecting Address is required"}</span>
                    ) : (
                      ""
                    )}
                  </>
                )}

                <div className="invalid-feedback">
                  {"Please Select the Address..."}
                </div>
              </Col>
              <Col md="6 mb-3">
                <Label>Electrical Licence#</Label>
                <InputGroup>
                  {spCompany !== "" || props.values.CompanyIdSelected ? (
                    <input
                      className="form-control"
                      name="licencenumb"
                      type="text"
                      placeholder="Company/Service License Number"
                      {...register("licencenumb", { required: false })}
                      {...((companySelected ||
                        (props.values && props.values.CompanyIdSelected)) && {
                        value:
                          props.values &&
                          props.values.CompanyIdSelected &&
                          spCompanydisplay[0]
                            ? spCompanydisplay[0].companyLicense
                            : companySelected && spCompanydisplay[0]
                            ? spCompanydisplay[0].companyLicense
                            : "",
                      })}
                    />
                  ) : (
                    <input
                      className="form-control"
                      name="licencenumb"
                      type="text"
                      defaultValue={props.values.licencenumb}
                      placeholder="Company/Service License Number"
                      {...register("licencenumb", { required: true })}
                    />
                  )}
                  <span>
                    {errors.licencenumb && "Licence Number is required"}
                  </span>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label>Company Phone Number</Label>
                {spCompany !== "" || props.values.CompanyIdSelected ? (
                  <input
                    className="form-control"
                    name="companyPhone"
                    type="Number"
                    placeholder="Company Phone Number"
                    {...register("companyPhone", { required: false })}
                    {...((companySelected ||
                      (props.values && props.values.CompanyIdSelected)) && {
                      value:
                        props.values &&
                        props.values.CompanyIdSelected &&
                        spCompanydisplay[0]
                          ? spCompanydisplay[0].phone
                          : companySelected && spCompanydisplay[0]
                          ? spCompanydisplay[0].phone
                          : "",
                    })}
                  />
                ) : (
                  <input
                    className="form-control"
                    name="companyPhone"
                    type="Number"
                    defaultValue={props.values.phone}
                    placeholder="Company Phone Number"
                    {...register("companyPhone", { required: true })}
                  />
                )}
                <span>
                  {errors.companyPhone && "Company Phone Number is required"}
                </span>
              </Col>
              <Col md="5 mb-3">
                <Label>E-Mail</Label>
                {spCompany !== "" || props.values.CompanyIdSelected ? (
                  <input
                    className="form-control"
                    name="email"
                    type="email"
                    placeholder="E-Mail"
                    {...register("email", { required: false })}
                    {...((companySelected ||
                      (props.values && props.values.CompanyIdSelected)) && {
                      value:
                        props.values &&
                        props.values.CompanyIdSelected &&
                        spCompanydisplay[0]
                          ? spCompanydisplay[0].email
                          : companySelected && spCompanydisplay[0]
                          ? spCompanydisplay[0].email
                          : "",
                    })}
                  />
                ) : (
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    defaultValue={props.values.compEmail}
                    placeholder="E-Mail"
                    {...register(`email`, {
                      required: true,
                    })}
                  />
                )}
                <span>{errors.email && "Email is required"}</span>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label>Company Logo</Label>
                <input
                  className="form-control"
                  name="companylogo"
                  type="file"
                  placeholder="Logo"
                  onChange={handleFileInput}
                />
                {props.values && props.values.CompanyIdSelected ? (
                  downloadedFile ? (
                    <img
                      src={downloadedFile}
                      width="150px"
                      height="150px"
                      alt="Company Logo"
                    />
                  ) : (
                    "No Logo"
                  )
                ) : (
                  companySelected &&
                  (downloadedFile ? (
                    <img
                      src={downloadedFile}
                      width="150px"
                      height="150px"
                      alt="Company Logo"
                    />
                  ) : (
                    "No Logo"
                  ))
                )}
                {props.values && props.values.logo && (
                  <div>
                    <img
                      src={logoUrl}
                      width="150px"
                      height="150px"
                      alt="Company Logo"
                    />
                  </div>
                )}
              </Col>
            </Row>
            {/*
          <Col md="6 mb-3">
            <Label>Company Website</Label>
            <input
              className="form-control"
              name="companyWebsite"
              type="text"
              placeholder="Company Website"
              {...register("companyWebsite", { required: false })}
              disabled={companySelected}
            />
          </Col>
        
        <Row>
          <Col md="6 mb-3">
            <Label>Company EC</Label>
            <input
              className="form-control"
              name="companyEC"
              type="text"
              placeholder="Company EC"
              {...register("companyEC", { required: false })}
              disabled={companySelected}
            />
          </Col>
          <Col md="6 mb-3">
            <Label>Company FAX</Label>
            <input
              className="form-control"
              name="companyFAX"
              type="text"
              placeholder="Company FAX"
              {...register("companyFAX", { required: false })}
              disabled={companySelected}
            />
          </Col>
        </Row>
              */}
            <Row></Row>
          </div>
          <Btn attrBtn={{ color: "primary" }}>Continue</Btn>
        </Form>
      )}
      <br></br>
    </Fragment>
  );
};
export default CompanyDetails;
