import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { H5, H4, H6, Btn, P } from "../../AbstractElements";
import NavClass from "./NavClass";
import TabClass from "./TabClass";
import { useNavigate, useLocation, Link } from "react-router-dom";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { limit } from "../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const TaskContain = () => {
  const newtaskdata = "";
  const [activeTab, setActiveTab] = useState("");

  const activeToggle = (tab) => {
    setActiveTab(tab);
  };

  const navigate = useNavigate();

  const state = useLocation();
  const [IsOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const [assets, setAssets] = useState([]);
  const [assetsFilter, setAssetsFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  //const [limit, setLimit] = useState(100000);

  const onClickSidebar = () => {
    setIsOpen(!IsOpen);
  };

  async function fetchassetTempByCat() {
    try {
      const catAssets = await client.graphql({
        query: queries.assetTemplatesByCategoriesID,
        variables: {
          categoriesID: state.state.category.id,
          limit: limit,
        },
        authMode: "API_KEY",
      });
      if (catAssets.data.assetTemplatesByCategoriesID.items.length > 0) {
        console.log(catAssets);
        const assetTemp = catAssets.data.assetTemplatesByCategoriesID.items;
        if (assetTemp.length > 0) {
          let nextToken = "";
          let assets = [];
          const result = await client.graphql({
            query: queries.assetsBySitedetailsID,
            variables: {
              sitedetailsID: state.state.site.id,
              filter: {
                status: {
                  ne: "delete",
                },
              },
              limit: limit,
            },
            authMode: "API_KEY",
          });
          if (result.data.assetsBySitedetailsID.items.length > 0) {
            let assetDatas = result.data.assetsBySitedetailsID.items;
            nextToken = result.data.assetsBySitedetailsID.nextToken;
            console.log(
              "first token count",
              result.data.assetsBySitedetailsID.items.length
            );
            console.log("nextToken 1", nextToken);
            if (result.data.assetsBySitedetailsID.items.length > 0) {
              assets.push(assetDatas);
            }
            while (nextToken !== null) {
              const results = await client.graphql({
                query: queries.assetsBySitedetailsID,
                variables: {
                  sitedetailsID: state.state.site.id,
                  limit: limit,
                  filter: {
                    status: {
                      ne: "delete",
                    },
                  },
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
              if (results.data.assetsBySitedetailsID.items.length > 0) {
                nextToken = results.data.assetsBySitedetailsID.nextToken;
                console.log("nextToken", nextToken);
                console.log(results.data.assetsBySitedetailsID.items.length);
                if (results.data.assetsBySitedetailsID.items.length > 0) {
                  assets.push(results.data.assetsBySitedetailsID.items);
                }
              }
            }
            console.log(assets);
            if (assets.length > 0) {
              const mergeAssets = assets.flat(1);
              console.log(mergeAssets);
              //--
              const filterAssets = mergeAssets;
              console.log(filterAssets);
              const assetData = filterAssets.filter((item) => {
                const _matchingAssetTemplate = assetTemp?.find(
                  (temp) => temp.id === item?.assettemplateID
                );
                if (_matchingAssetTemplate) {
                  return item;
                }
              });
              if (assetData.length > 0) {
                const sort = assetData.sort(
                  (a, b) => a.fittingNumber - b.fittingNumber
                );
                console.log(sort);
                setAssets(sort);
                setAssetsFilter(sort);
                setActiveTab(sort[0].id);
                setIsLoading(false);
              } else {
                setAssets([]);
                setAssetsFilter([]);
                setActiveTab("");
                setIsLoading(false);
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchassetTempByCat();
  }, []);

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/sites/listsubcat`, {
      state: {
        category: state.state.category,
        site: state.state.site,
      },
    });
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="email-wrap bookmark-wrap">
          <Row>
            <NavClass activeToggle={activeToggle} assets={assets} />
            <TabClass activeTab={activeTab} />
          </Row>
          <Row>
            <Col md="12" className="text-end">
              <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>
                Back
              </Btn>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default TaskContain;
