import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, MoreHorizontal, Trash2 } from "react-feather";
import { CardBody, Table, CardHeader } from "reactstrap";
import SweetAlert from "sweetalert2";
import { NoTasksFound } from "../../Constant";
import TaskContext from "../../_helper/task-app";
import { H6, P, H5 } from "../../AbstractElements";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import * as mergedQueries from "../../graphql/mergedQueries";
import moment from "moment";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const CreateTag = ({ activeTab }) => {
  const { allTask, RemoveTask } = useContext(TaskContext);
  const [tests, setTestsData] = useState([]);
  const [repair, setRepairData] = useState([]);

  const deleteTask = (userId) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        RemoveTask(userId);
        SweetAlert.fire("Deleted!", "Your file has been deleted.", "success");
      } else {
        SweetAlert.fire("Your imaginary file is safe!");
      }
    });
  };

  async function fetchtesting() {
    const inactiveAsset = [];
    let repair = [];
    await client
      .graphql({
        query: mergedQueries.getRepairTesting,
        variables: {
          assetsID: activeTab.id,
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
      .then(async (response2) => {
        console.log(response2);
        const repairactive = response2.data.getAssets.Repairs.items;
        repair.push(...repairactive);
        console.log(repair);
        const testing = response2.data.getAssets.Testings.items;
        console.log(testing);
        //--
        let testArray = [];
        testing.map((test) => {
          testArray.push({
            id: test.id,
            createdAt: test.createdAt,
            testOn: test.testOn,
            testResult: test.testResult,
            nextTestSchedule: test.nextTestSchedule,
            additionalInformationTest: test.additionalInformation,
          });
        });
        console.log(testArray);
        //--
        await client
          .graphql({
            query: queries.listRepairs,
            variables: {
              limit: 2000000,
              filter: {
                replacedAssetID: {
                  eq: activeTab.id,
                },
              },
            },
            authMode: "API_KEY",
          })
          .then(async (response3) => {
            console.log(response3);
            console.log(repair);
            const repair2 = response3.data.listRepairs.items;
            //---
            let i = 0;
            while (i < repair.length) {
              const currentRepair = repair[i];
              console.log(currentRepair);

              if (currentRepair.replacedAssetID) {
                inactiveAsset.push(currentRepair.replacedAssetID);
                console.log(inactiveAsset);

                await client
                  .graphql({
                    query: mergedQueries.getRepairTesting,
                    variables: {
                      assetsID: currentRepair.replacedAssetID,
                      limit: 2000000,
                    },
                    authMode: "API_KEY",
                  })
                  .then((response2) => {
                    console.log(response2);
                    const replacedAssetRepairs =
                      response2.data.getAssets.Repairs.items;
                    console.log(replacedAssetRepairs);

                    repair.push(...replacedAssetRepairs);
                    console.log(repair);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                console.log("no replace asset id in this repair record");
              }

              console.log(repair);
              console.log(i);
              i++;
            }

            console.log(inactiveAsset);
            if (inactiveAsset.length > 0) {
              const promises = inactiveAsset.map(async (siteee) => {
                return client.graphql({
                  query: mergedQueries.getRepairTesting,
                  variables: {
                    assetsID: siteee,
                    limit: 2000000,
                  },
                  authMode: "API_KEY",
                });
              });
              Promise.all(promises)
                .then(async (results) => {
                  const testingsss = [];
                  const repairssss = [];
                  results.forEach((result1) => {
                    const repair3 = result1.data.getAssets.Repairs.items;
                    console.log(repair3);
                    if (repair3.length > 0) {
                      repairssss.push(...repair3);
                    }
                    const testing2 = result1.data.getAssets.Testings.items;
                    console.log(testing2);
                    if (testing2.length > 0) {
                      testingsss.push(...testing2);
                    }
                  });
                  console.log("final data");
                  console.log(testingsss);
                  console.log(repairssss);
                  //--
                  let testArray2 = [];
                  testingsss.map((test) => {
                    testArray2.push({
                      id: test.id,
                      createdAt: test.createdAt,
                      testOn: test.testOn,
                      testResult: test.testResult,
                      nextTestSchedule: test.nextTestSchedule,
                      additionalInformationTest: test.additionalInformation,
                    });
                  });
                  console.log(testArray2);
                  //--

                  const promises = inactiveAsset.map(async (siteee) => {
                    return client.graphql({
                      query: queries.listRepairs,
                      variables: {
                        limit: 2000000,
                        filter: {
                          replacedAssetID: {
                            eq: siteee,
                          },
                        },
                      },
                      authMode: "API_KEY",
                    });
                  });
                  Promise.all(promises)
                    .then((results) => {
                      const repair4 = [];
                      results.forEach((result2) => {
                        const assetList = result2.data.listRepairs.items;
                        if (assetList.length > 0) {
                          console.log(assetList);
                          repair4.push(...assetList);
                        }
                      });
                      console.log("final data");
                      console.log(repair4);
                      //--
                      const combinedRepairs = [
                        ...repairssss,
                        ...repair2,
                        ...repair,
                        ...repair4,
                      ];
                      console.log(combinedRepairs);
                      let repairArray = [];
                      combinedRepairs.map((test) => {
                        repairArray.push({
                          id: test.id,
                          createdAt: test.createdAt,
                          repairDate: test.repairDate,
                          replacedAssetID: test.replacedAssetID,
                          warrantyUpto: test.warrantyUpto,
                          additionalInformationRepair:
                            test.additionalInformation,
                        });
                      });
                      console.log(repairArray);
                      const combinedTesting = [...testArray, ...testArray2];
                      console.log(combinedTesting);
                      const combinedArray = combinedTesting.concat(repairArray);
                      console.log(combinedArray);

                      const uniqueCombinedArray = Array.from(
                        new Set(repairArray.map(JSON.stringify))
                      ).map(JSON.parse);

                      console.log(uniqueCombinedArray);
                      const sort = uniqueCombinedArray.sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                      );
                      setRepairData(uniqueCombinedArray);
                      setTestsData(combinedTesting);
                      //setCombinedData(sort);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              const combinedRepairs = [...repairactive, ...repair2];
              console.log(combinedRepairs);
              let repairArray = [];
              combinedRepairs.map((test) => {
                repairArray.push({
                  id: test.id,
                  createdAt: test.createdAt,
                  repairDate: test.repairDate,
                  replacedAssetID: test.replacedAssetID,
                  warrantyUpto: test.warrantyUpto,
                  additionalInformationRepair: test.additionalInformation,
                });
              });
              console.log(repairArray);
              //const combinedArray = repairArray;

              const uniqueCombinedArray = Array.from(
                new Set(repairArray.map(JSON.stringify))
              ).map(JSON.parse);

              console.log(uniqueCombinedArray);
              const sort = uniqueCombinedArray.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              );
              setRepairData(uniqueCombinedArray);
              setTestsData(testArray);
              //setCombinedData(sort);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        //--
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    try {
      console.log(activeTab);
      if (activeTab !== "") {
        //--
        fetchtesting();
        //--
      }
    } catch (error) {
      console.log(error);
    }
  }, [activeTab]);

  return (
    <Fragment>
      <CardBody className="p-0">
        <div className="taskadd">
          <div className="table-responsive table-borderless">
            <Table borderless>
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                {repair.length > 0 ? (
                  repair.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <H6 attrH6={{ className: "task_title_0" }}>
                            Repaired On
                          </H6>
                          <P attrPara={{ className: "task_desc_0" }}>
                            {moment(item.repairDate).format("DD/MM/YYYY")}{" "}
                            {item.repairTime}
                          </P>
                          <P attrPara={{ className: "project_name_0" }}>
                            {item.replacedAssetID !== null ? "Replace" : ""}
                          </P>
                        </td>
                        <td>
                          <P attrPara={{ className: "task_desc_0" }}>
                            {item.details}
                          </P>
                        </td>
                        {/* <td>
                      <a className="me-2" href="#javascript">
                        <Link />
                      </a>
                      <a href="#javascript">
                        <MoreHorizontal />
                      </a>
                    </td>
                    <td>
                      <a
                        href="#javascript"
                        onClick={() => deleteTask(item.id)}
                      >
                        <Trash2 />
                      </a>
                    </td> */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>
                      <div className="no-favourite">
                        <span>No Repairs Found</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </CardBody>
    </Fragment>
  );
};
export default CreateTag;
