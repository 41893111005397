import React, { Fragment, useState, useEffect, useCallback } from "react";
import { H5, H4, H6, Btn, P, H2 } from "../../AbstractElements";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  CardBody,
  Form,
} from "reactstrap";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import * as mergedQueries from "../../graphql/mergedQueries";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import uuid from "react-native-uuid";
import moment from "moment";
import Pagination from "rc-pagination";
import cloneDeep from "lodash/cloneDeep";
import jsPDF from "jspdf";
import AWS from "aws-sdk";
import {
  S3_BUCKET,
  REGION,
  ASSET_OWNER_FOLDER,
  SECRETACCESSKEY,
  COMPANY_FOLDER,
  ACCESSKEYID,
  USERPOOLID,
} from "../../Config/Config";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import DatePicker from "react-datepicker";
import Select from "react-select";
import SiteData from "../../Data/Sites/index";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

AWS.config.update({
  accessKeyId: ACCESSKEYID,
  secretAccessKey: SECRETACCESSKEY,
  region: REGION,
});
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
const docClient = new AWS.DynamoDB.DocumentClient();

const GenerateRepairReports = ({ data }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [sites, setSites] = useState([]);
  const [fullSites, setFullSites] = useState(SiteData.getSiteData());
  const [distributionBox, setDistributionBox] = useState([]);
  const [distributionBoxFilter, setDistributionBoxfilter] = useState([]);
  const [assets1, setAssets1] = useState([]);
  const [assets, setAssets] = useState([]);
  const [siteId, setSiteId] = useState("");
  const [loading, setLoading] = useState(false);
  const [siteDetail, setSiteDetail] = useState([]);
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [assetDemo, setAssetDemo] = useState([]);
  const [company, setCompany] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
    LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];
  const [ao, setAo] = useState([]);
  const [selectedImg, setselectedImg] = useState(null);
  const [downloadedFile, setdownloadedFile] = useState(null);
  const [mc, setMc] = useState([]);
  const [filterdemo, setFilter] = useState([]);
  const [repairedBy, setRepairedBy] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const LoginUserGroup = localStorage.getItem("groups");
  const [asset, setAsset] = useState([]);
  const [assetVal, setAssetVal] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [spdata, setspdata] = useState([]);

  async function getCompanyByAssetId() {
    try {
      await client
        .graphql({
          query: queries.listAssetOwners,
          variables: {
            limit: 2000000,
            filter: {
              id: {
                eq: LoginUserDetails.id,
              },
            },
          },
          authMode: "API_KEY",
        })
        .then((result2) => {
          console.log(result2);
          if (result2.data.listAssetOwners.items.length > 0) {
            client
              .graphql({
                query: queries.listCompanyDetails,
                variables: {
                  limit: 2000000,
                  filter: {
                    id: {
                      eq: result2.data.listAssetOwners.items[0]
                        .companydetailsID,
                    },
                  },
                },
                authMode: "API_KEY",
              })
              .then((result) => {
                console.log(result);
                //setCompany(result.data.listCompanyDetails.items[0]);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (LoginUserGroup === "site_user") {
      fetchSUByAo();
    } else if (LoginUserGroup === "electrician") {
      fetchSUByAo();
    } else {
      getAO();
    }
    setLoading(true);
    getUserdetails();
    fetchSP();
    getDistributionBox();
    console.log(data);
    fetchspdata();
    getSite(data.sites);
    console.log(data.sites);
    if (
      data.sites === "allSites" &&
      data.distributionBox === "allDistribution"
    ) {
      console.log("All site  and all distribution boards selected");

      if (LoginUserGroup === "site_user") {
        fetchSiteDetailsSU();
      } else if (LoginUserGroup === "electrician") {
        fetchSiteDetailsSU();
      } else {
        fetchRepairDemo();
      }
      getCompanyByAssetId();
    } else if (
      data.sites !== "allSites" &&
      data.distributionBox === "allDistribution"
    ) {
      console.log("single site  and all distribution boards selected");
      fetchRepairDemo1(data.sites);
    } else {
      console.log("single site  and single distribution boards selected");
      getAssets(data.sites, data.distributionBox);
    }
  }, []);

  async function getSite(siteId) {
    console.log(siteId);
    await client
      .graphql({
        query: queries.listSiteDetails,
        variables: {
          limit: 2000000,
          filter: {
            id: {
              eq: siteId,
            },
          },
        },
        authMode: "API_KEY",
      })
      .then((result) => {
        console.log(result);
        setSites(result.data.listSiteDetails.items[0]);
        const CompId = result.data.listSiteDetails.items[0].companydetailsID;
        console.log(CompId);
        client
          .graphql({
            query: queries.listCompanyDetails,
            variables: {
              limit: 2000000,
              filter: {
                id: {
                  eq: CompId,
                },
              },
            },
            authMode: "API_KEY",
          })
          .then((result2) => {
            console.log(result2);
            //setCompany(result2.data.listCompanyDetails.items[0]);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ----site user invited by ao
  async function fetchSUByAo() {
    //--
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listUserDetails,
        variables: {
          limit: 2000000,
          filter: {
            id: {
              eq: LoginUserDetails.id,
            },
          },
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.listUserDetails.items;
        nextToken = result.data.listUserDetails.nextToken;
        console.log(
          "first token count",
          result.data.listUserDetails.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listUserDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listUserDetails,
              variables: {
                limit: 2000000,
                filter: {
                  id: {
                    eq: LoginUserDetails.id,
                  },
                },
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listUserDetails.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.listUserDetails.items.length);
              if (results.data.listUserDetails.items.length > 0) {
                assets.push(results.data.listUserDetails.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          const aoid = mergeAssets[0].assetOwnerId;
          console.log(aoid);
          //--
          let nextToken1 = "";
          let assets1 = [];
          await client
            .graphql({
              query: queries.listAssetOwners,
              variables: {
                limit: 2000000,
                filter: {
                  id: {
                    eq: aoid,
                  },
                },
              },
              authMode: "API_KEY",
            })
            .then(async (result) => {
              let assetDatas = result.data.listAssetOwners.items;
              nextToken1 = result.data.listAssetOwners.nextToken;
              console.log(
                "first token count",
                result.data.listAssetOwners.items.length
              );
              console.log("nextToken 1", nextToken1);
              if (result.data.listAssetOwners.items.length > 0) {
                assets1.push(assetDatas);
              }
              while (nextToken1 !== null) {
                await client
                  .graphql({
                    query: queries.listAssetOwners,
                    variables: {
                      limit: 2000000,
                      filter: {
                        id: {
                          eq: aoid,
                        },
                      },
                      nextToken: nextToken1,
                    },
                    authMode: "API_KEY",
                  })
                  .then((results) => {
                    nextToken1 = results.data.listAssetOwners.nextToken;
                    console.log("nextToken", nextToken1);
                    console.log(results.data.listAssetOwners.items.length);
                    if (results.data.listAssetOwners.items.length > 0) {
                      assets1.push(results.data.listAssetOwners.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              console.log(assets1);
              if (assets1.length > 0) {
                const mergeAssets1 = assets1.flat(1);
                console.log(mergeAssets1);
                //--
                setAo(mergeAssets1[0]);
                //getfilename(mergeAssets1[0].profilePicture);
                console.log(mergeAssets1[0].profilePicture);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    {
      /*
    await client.graphql({
      query: queries.listUserDetails,
      variables: {
        limit: 2000000,
        filter: {
          id: {
            eq: LoginUserDetails.id,
          },
        },
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        const aoid = result.data.listUserDetails.items[0].assetOwnerId;
        console.log(aoid);
        client.graphql({
          query: queries.listAssetOwners,
          variables: {
            limit: 2000000,
            filter: {
              id: {
                eq: aoid,
              },
            },
          },
          authMode: "API_KEY",
        })
          .then((result2) => {
            console.log(result2);
            setAo(result2.data.listAssetOwners.items[0]);
            getfilename(result2.data.listAssetOwners.items[0].profilePicture);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    */
    }
  }

  // AO details
  async function getAO() {
    //--
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listAssetOwners,
        variables: {
          limit: 2000000,
          filter: {
            id: {
              eq: LoginUserDetails.id,
            },
          },
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.listAssetOwners.items;
        nextToken = result.data.listAssetOwners.nextToken;
        console.log(
          "first token count",
          result.data.listAssetOwners.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listAssetOwners.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listAssetOwners,
              variables: {
                limit: 2000000,
                filter: {
                  id: {
                    eq: LoginUserDetails.id,
                  },
                },
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listAssetOwners.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.listAssetOwners.items.length);
              if (results.data.listAssetOwners.items.length > 0) {
                assets.push(results.data.listAssetOwners.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setAo(mergeAssets[0]);
          //getfilename(mergeAssets[0].profilePicture);
          console.log(mergeAssets[0].profilePicture);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    {
      /*
    await client.graphql({
      query: queries.listAssetOwners,
      variables: {
        limit: 2000000,
        filter: {
          id: {
            eq: LoginUserDetails.id,
          },
        },
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setAo(result.data.listAssetOwners.items[0]);
        getfilename(result.data.listAssetOwners.items[0].profilePicture);
        console.log(result.data.listAssetOwners.items[0].profilePicture);
      })
      .catch((error) => {
        console.log(error);
      });
    */
    }
  }

  //------------------------------------------------------------------------
  const getImage = (filename) => {
    try {
      var params = {
        Bucket: S3_BUCKET,
        Key: `public/${COMPANY_FOLDER}/${filename}`,
      };
      var promise = myBucket.getSignedUrlPromise("getObject", params);
      promise.then(
        function (url) {
          console.log(url);
          setdownloadedFile(url);
          setselectedImg(url);
        },
        function (err) {
          console.log(err);
        }
      );
    } catch (error) {
      console.log("Error while fetching:", error);
    }
  };
  //-------------------------------------------------------------------------
  function getfilename(path) {
    try {
      //const files = JSON.parse(path);
      const files = path;
      console.log(files);
      if (files) {
        var newfilename = files;
        getImage(newfilename);
      }
    } catch (error) {
      console.log("Error parsing JSON input:", error);
    }
  }

  async function getDistributionBox() {
    //--
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listDistributionBoxes,
        variables: {
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.listDistributionBoxes.items;
        nextToken = result.data.listDistributionBoxes.nextToken;
        if (result.data.listDistributionBoxes.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listDistributionBoxes,
              variables: {
                limit: 2000000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listDistributionBoxes.nextToken;
              if (results.data.listDistributionBoxes.items.length > 0) {
                assets.push(results.data.listDistributionBoxes.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setDistributionBox(mergeAssets);
          setDistributionBoxfilter(mergeAssets);
        } else {
          setDistributionBox([]);
          setDistributionBoxfilter([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAssets1(aid) {
    console.log(aid);
    try {
      await client
        .graphql({
          query: queries.getAssets,
          authMode: "API_KEY",
          variables: {
            id: aid,
          },
        })
        .then((data) => {
          console.log(data);
          const fittingNumber = data.data.getAssets.fittingNumber;
          return fittingNumber;
        })
        .catch((error) => {
          console.error("Error fetching assets:", error);
          return null;
        });
    } catch (error) {
      console.error("Error fetching assets:", error);
      return null;
    }
  }

  async function getAssetsWarranty(aid) {
    try {
      const data = await client.graphql({
        query: queries.getAssets,
        authMode: "API_KEY",
        variables: {
          id: aid,
        },
      });
      console.log(data);
      const warranty = data.data.getAssets.assetWarrantyEnd;
      return warranty;
    } catch (error) {
      console.error("Error fetching assets:", error);
      return null;
    }
  }

  {
    /*
  function getAssetsWarranty(id) {
    const db = assets1.find((item) => item.id === id);
    return db ? db.assetWarrantyEnd : "";
  }
*/
  }

  function getDB(id) {
    const db = distributionBox.find((item) => item.id === id);
    return db ? db.distributionBoxName : "";
  }

  function getDB1(id) {
    const dbId = assets1.find((item) => item.id === id);
    if (dbId) {
      const db = distributionBox.find(
        (item) => item.id === dbId.distributionboxID
      );
      return db ? db.distributionBoxName : "";
    } else {
      return "";
    }
  }

  React.useEffect(() => {
    if (siteId !== "") {
      setDistributionBoxfilter(
        distributionBox.filter((item) => {
          return item.sitedetailsID === siteId;
        })
      );
      setSiteDetail(
        sites.filter((item) => {
          return item.id === siteId;
        })
      );
    } else {
      setSiteDetail([]);
      setDistributionBoxfilter(distributionBox);
    }
  }, [siteId]);

  // const onSubmit = async (data) => {
  //   try {
  //     setLoading(true);
  //     console.log(data);
  //     getAssets(data.sites, data.distributionBox);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  async function getAssets(siteId, distriButionBox) {
    setLoading(true);
    //---
    let nextToken = "";
    let assets = [];
    let filter = {};
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - data.date);
    if (data.date !== "") {
      if (data.date === "Custom") {
        const startDate = new Date(data.startDate);
        const endDate = new Date(data.endDate);

        filter = {
          repairDate: {
            between: [
              moment(startDate).format("YYYY-MM-DD"),
              moment(endDate).format("YYYY-MM-DD"),
            ],
          },
        };
      } else {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - data.date);

        filter = {
          repairDate: {
            between: [
              moment(thirtyDaysAgo).format("YYYY-MM-DD"),
              moment(today).format("YYYY-MM-DD"),
            ],
          },
        };
      }
    } else {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);

      filter = {
        repairDate: {
          between: [
            moment(thirtyDaysAgo).format("YYYY-MM-DD"),
            moment(today).format("YYYY-MM-DD"),
          ],
        },
      };
    }
    await client
      .graphql({
        query: mergedQueries.getRepairReports,
        variables: {
          sitedetailsID: siteId,
          filter: filter,
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        console.log("result", result);
        let assetDatas = result.data.assetsBySitedetailsID.items;
        nextToken = result.data.assetsBySitedetailsID.nextToken;
        if (result.data.assetsBySitedetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: mergedQueries.getRepairReports,
              variables: {
                sitedetailsID: siteId,
                limit: 2000000,
                filter: filter,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.assetsBySitedetailsID.nextToken;
              if (results.data.assetsBySitedetailsID.items.length > 0) {
                assets.push(results.data.assetsBySitedetailsID.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          const filterdelete = mergeAssets.filter(
            (item) => item.status !== "delete"
          );
          //---
          const distri = filterdelete.filter(
            (item) => item.distributionboxID === distriButionBox
          );
          console.log(distri);
          let assetFilter = [];
          let testing = distri.filter((item) => {
            const _matchingAssetTemplate = data.assetTemp?.find(
              (temp) => temp === item?.assettemplateID
            );
            if (_matchingAssetTemplate) {
              return item;
            }
          });
          console.log(testing);
          const asset = testing;
          console.log(data.warranty);
          if (data.warranty !== "") {
            if (data.warranty === "InWarranty") {
              console.log("In Warranty");
              const today = new Date();
              assetFilter = asset.filter((item) => {
                const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                return assetWarrantyEndDate > today;
              });
            } else {
              console.log("Out Warranty");
              const today = new Date();
              assetFilter = asset.filter((item) => {
                const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                return assetWarrantyEndDate < today;
              });
            }
          } else {
            assetFilter = asset;
          }
          // }
          console.log(assetFilter);
          const testReport = [];
          if (assetFilter.length > 0) {
            const repairData = [];
            let counter = 0;
            const assetOptions = [{ value: null, label: "All Assets" }];
            const sort = assetFilter.sort(
              (a, b) => a.fittingNumber - b.fittingNumber
            );
            console.log(sort);
            const sortoption = sort.filter((item) => item.status === "Active");
            console.log(sortoption);
            sortoption.map((item) => {
              assetOptions.push({
                value: item.id,
                label: item.fittingNumber,
              });
            });
            setAssets1(sort);
            sort.map(async (item) => {
              console.log(item);
              counter = counter + 1;
              let repaircount = 0;
              if (item.Repairs.items.length > 0) {
                item.Repairs.items.map(async (repair) => {
                  repaircount = repaircount + 1;
                  repairData.push({
                    id: repair.id,
                    createdAt: repair.createdAt,
                    asset: item.fittingNumber,
                    RepairDate: repair.repairDate,
                    RepairTime: repair.repairTime,
                    warrantyUpto: repair.warrantyUpto,
                    assetWarranty: item.assetWarrantyEnd,
                    technician: repair.technician[0],
                    assetId: repair.assetsID,
                    status: item.status,
                    distributionBox:
                      repair.replacedAssetID !== null
                        ? repair.replacedAssetID
                        : repair.assetsID,
                    remarks: repair.additionalInformation,
                    assettemplateID: item.assettemplateID,
                  });
                  if (
                    assetFilter.length === counter &&
                    repaircount === item.Repairs.items.length
                  ) {
                    if (repairData.length > 0) {
                      const sort = repairData.sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                      );
                      console.log(repairData);
                      console.log(sort);
                      console.log(data.dateRange);

                      setAssetDemo(sort);
                      setFilter(sort);
                      setAssets(cloneDeep(sort.slice(0, countPerPage)));
                      //---
                      const spUserIds = new Set();

                      sort.forEach((item) => {
                        spUserIds.add(item.technician);
                      });

                      const areSpUserIdsUnique = spUserIds.size === sort.length;

                      if (areSpUserIdsUnique) {
                        console.log(spUserIds);
                        console.log("All spuserid values are unique.");
                        //--
                        const filterdata = sort.filter((item) => {
                          return spdata.some(
                            (spuser) =>
                              spuser.id === item.technician &&
                              spuser.SPGroup === "admin"
                          );
                        });
                        console.log(filterdata);
                        if (filterdata.length > 0) {
                          fetchspusercomp(filterdata[0].technician);
                        } else {
                          const myArray = Array.from(spUserIds);
                          const myValue = myArray[0];
                          console.log(myValue);
                          fetchspusercompAdmin(myValue);
                        }
                      } else {
                        console.log(spUserIds);
                        console.log("Some spuserid values are not unique.");
                        if (spUserIds.size === 1) {
                          const myArray = Array.from(spUserIds);
                          const myValue = myArray[0];
                          console.log(myValue);
                          fetchspusercompAdmin(myValue);
                        } else {
                          const filterdata = sort.filter((item) => {
                            return spdata.some(
                              (spuser) =>
                                spuser.id === item.technician &&
                                spuser.SPGroup === "admin"
                            );
                          });
                          console.log(filterdata);
                          if (filterdata.length > 0) {
                            fetchspusercomp(filterdata[0].technician);
                          } else {
                            const myArray = Array.from(spUserIds);
                            const myValue = myArray[0];
                            console.log(myValue);
                            fetchspusercompAdmin(myValue);
                          }
                        }
                      }
                      setLoading(false);
                    } else {
                      setAssetDemo([]);
                      setAssets([]);
                      setFilter([]);
                      setLoading(false);
                    }
                  }
                });
              }

              if (
                assetFilter.length === counter &&
                repaircount === item.Repairs.items.length
              ) {
                setAsset(assetOptions);
                if (repairData.length > 0) {
                  const sort = repairData.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                  );
                  console.log(repairData);
                  console.log(sort);
                  console.log(data.dateRange);

                  setAssetDemo(sort);
                  setFilter(sort);
                  setAssets(cloneDeep(sort.slice(0, countPerPage)));
                  setLoading(false);
                } else {
                  setAssetDemo([]);
                  setAssets([]);
                  setFilter([]);
                  setLoading(false);
                }
              }
            });
          } else {
            setAssetDemo([]);
            setAssets([]);
            setFilter([]);
            setLoading(false);
          }
        } else {
          setAssetDemo([]);
          setAssets([]);
          setFilter([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    {
      /*
    await client.graphql({
      query: mergedQueries.getRepairReports,
      variables: {
        sitedetailsID: siteId,
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        console.log(result);
        const distri = result.data.assetsBySitedetailsID.items.filter(
          (item) => item.distributionboxID === distriButionBox
        );
        console.log(distri);
        let assetFilter = [];
        let testing = distri.filter((item) => {
          const _matchingAssetTemplate = data.assetTemp?.find(
            (temp) => temp === item?.assettemplateID
          );
          if (_matchingAssetTemplate) {
            return item;
          }
        });
        console.log(testing);
        const asset = testing;
        console.log(data.warranty);
        if (data.warranty !== "") {
          if (data.warranty === "InWarranty") {
            console.log("In Warranty");
            const today = new Date();
            assetFilter = asset.filter((item) => {
              const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
              return assetWarrantyEndDate > today;
            });
          } else {
            console.log("Out Warranty");
            const today = new Date();
            assetFilter = asset.filter((item) => {
              const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
              return assetWarrantyEndDate < today;
            });
          }
        } else {
          assetFilter = asset;
        }
        // }
        console.log(assetFilter);
        const testReport = [];
        if (assetFilter.length > 0) {
          const repairData = [];
          let counter = 0;
          const assetOptions = [{ value: null, label: "All Assets" }];
          const sort = assetFilter.sort((a, b) => a.fittingNumber - b.fittingNumber);
          console.log(sort);             
          setAssets1(sort);
          sort.map(async (item) => {
            console.log(item);
            counter = counter + 1;
            let repaircount = 0;
            if (item.Repairs.items.length > 0) {
              item.Repairs.items.map(async (repair) => {
                repaircount = repaircount + 1;
                repairData.push({
                  id: repair.id,
                  createdAt: repair.createdAt,
                  asset: item.fittingNumber,
                  RepairDate: repair.repairDate,
                  RepairTime: repair.repairTime,
                  warrantyUpto: repair.warrantyUpto,
                  assetWarranty: item.assetWarrantyEnd,
                  technician: repair.technician[0],
                  assetId: repair.assetsID,
                  status: item.status,
                  distributionBox:
                    repair.replacedAssetID !== null
                      ? repair.replacedAssetID
                      : repair.assetsID,
                  remarks: repair.additionalInformation,
                  assettemplateID: item.assettemplateID,
                });
                if (
                  assetFilter.length === counter &&
                  repaircount === item.Repairs.items.length
                ) {
                  if (repairData.length > 0) {
                    const sort = repairData.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    );
                    console.log(repairData);
                    console.log(sort);
                    setAssetDemo(sort);
                    setFilter(sort);
                    setAssets(cloneDeep(sort.slice(0, countPerPage)));
                    setLoading(false);
                  } else {
                    setAssetDemo([]);
                    setAssets([]);
                    setFilter([]);
                    setLoading(false);
                  }
                }
              });
            }
            assetOptions.push({
              value: item.id,
              label: item.fittingNumber,
            });
            if (
              assetFilter.length === counter &&
              repaircount === item.Repairs.items.length
            ) {
              setAsset(assetOptions);
              if (repairData.length > 0) {
                const sort = repairData.sort(
                  (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                );
                console.log(repairData);
                console.log(sort);
                setAssetDemo(sort);
                setFilter(sort);
                setAssets(cloneDeep(sort.slice(0, countPerPage)));
                setLoading(false);
              } else {
                setAssetDemo([]);
                setAssets([]);
                setFilter([]);
                setLoading(false);
              }
            }
          });
        } else {
          setAssetDemo([]);
          setAssets([]);
          setFilter([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setAssets1([]);
        setFilter([]);
        setAssetDemo([]);
        setAssets([]);
        console.log(error);
        setLoading(false);
      });
    */
    }
  }

  //----repair pdf ---

  async function fetchspusercomp(userid) {
    await client
      .graphql({
        query: queries.getUserDetails,
        variables: {
          id: userid,
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        console.log(result);
        const SpUser = result.data.getUserDetails;
        //const compid = result.data.getUserdetails.companyDetailsId;
        await client
          .graphql({
            query: queries.getSPCompanyDetails,
            variables: {
              id: SpUser.companyDetailsId,
              limit: 2000000,
            },
            authMode: "API_KEY",
          })
          .then(async (result1) => {
            console.log(result1);
            const spcomp = result1.data.getSPCompanyDetails;
            console.log(spcomp);
            getfilename(spcomp.logo);
            setCompany(spcomp);
            //---
            await client
              .graphql({
                query: queries.getSPUserDetails,
                variables: {
                  id: userid,
                  limit: 2000000,
                },
                authMode: "API_KEY",
              })
              .then(async (result2) => {
                console.log(result2);
                const spp = result2.data.getSPUserDetails;
                console.log(spp);
                setMc(spp);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //----testing pdf filtering spgroup admin---
  async function fetchspusercompAdmin(userid) {
    await client
      .graphql({
        query: queries.getUserDetails,
        variables: {
          id: userid,
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        console.log(result);
        const SpUser = result.data.getUserDetails;
        //const compid = result.data.getUserdetails.companyDetailsId;
        await client
          .graphql({
            query: queries.getSPCompanyDetails,
            variables: {
              id: SpUser.companyDetailsId,
              limit: 2000000,
            },
            authMode: "API_KEY",
          })
          .then(async (result1) => {
            console.log(result1);
            const spcomp = result1.data.getSPCompanyDetails;
            console.log(spcomp);
            getfilename(spcomp.logo);
            setCompany(spcomp);
            //---
            let nextToken = "";
            let assets = [];
            await client
              .graphql({
                query: queries.listUserDetails,
                variables: {
                  limit: 2000000,
                },
                authMode: "API_KEY",
              })
              .then(async (result) => {
                let assetDatas = result.data.listUserDetails.items;
                nextToken = result.data.listUserDetails.nextToken;
                console.log(
                  "first token count",
                  result.data.listUserDetails.items.length
                );
                console.log("nextToken 1", nextToken);
                if (result.data.listUserDetails.items.length > 0) {
                  assets.push(assetDatas);
                }
                while (nextToken !== null) {
                  await client
                    .graphql({
                      query: queries.listUserDetails,
                      variables: {
                        limit: 2000000,
                        nextToken: nextToken,
                      },
                      authMode: "API_KEY",
                    })
                    .then((results) => {
                      nextToken = results.data.listUserDetails.nextToken;
                      console.log("nextToken", nextToken);
                      console.log(results.data.listUserDetails.items.length);
                      if (results.data.listUserDetails.items.length > 0) {
                        assets.push(results.data.listUserDetails.items);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log(assets);
                if (assets.length > 0) {
                  const mergeAssets = assets.flat(1);
                  console.log(mergeAssets);
                  //--
                  const filterusers = mergeAssets.filter(
                    (item) => item.companyDetailsId === SpUser.companyDetailsId
                  );
                  console.log(filterusers);
                  const siteFilterArray = [];
                  filterusers.map(async (item) => {
                    siteFilterArray.push(item.id);
                  });

                  //--spuser filtering

                  let nextToken1 = "";
                  let assets1 = [];
                  await client
                    .graphql({
                      query: queries.listSPUserDetails,
                      variables: {
                        limit: 2000000,
                      },
                      authMode: "API_KEY",
                    })
                    .then(async (result) => {
                      let assetDatas = result.data.listSPUserDetails.items;
                      nextToken1 = result.data.listSPUserDetails.nextToken;
                      console.log(
                        "first token count",
                        result.data.listSPUserDetails.items.length
                      );
                      console.log("nextToken 1", nextToken1);
                      if (result.data.listSPUserDetails.items.length > 0) {
                        assets1.push(assetDatas);
                      }
                      while (nextToken !== null) {
                        await client
                          .graphql({
                            query: queries.listSPUserDetails,
                            variables: {
                              limit: 2000000,
                              nextToken: nextToken1,
                            },
                            authMode: "API_KEY",
                          })
                          .then((results) => {
                            nextToken1 =
                              results.data.listSPUserDetails.nextToken;
                            console.log("nextToken", nextToken1);
                            console.log(
                              results.data.listSPUserDetails.items.length
                            );
                            if (
                              results.data.listSPUserDetails.items.length > 0
                            ) {
                              assets1.push(
                                results.data.listSPUserDetails.items
                              );
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                      console.log(assets1);
                      if (assets1.length > 0) {
                        const mergeAssets1 = assets1.flat(1);
                        console.log(mergeAssets1);
                        //--
                        const filterSpuser = mergeAssets1.filter((item) => {
                          const _matchSites = siteFilterArray?.find(
                            (id) => id === item?.id
                          );
                          if (_matchSites) {
                            return item;
                          }
                        });
                        console.log(filterSpuser);
                        const filteradmin = filterSpuser.filter(
                          (item) => item.SPGroup === "admin"
                        );
                        console.log(filteradmin);
                        setMc(filteradmin[0]);
                      } else {
                        setMc([]);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  setMc([]);
                }
              })
              .catch((error) => {
                console.log(error);
              });
            {
              /*
            await client.graphql({
              query: queries.getSPUserDetails,
              variables: {
                id: userid,
                limit: 2000000,
              },
              authMode: "API_KEY",
            })
              .then(async (result2) => {
                console.log(result2);
                const spp = result2.data.getSPUserDetails;
                console.log(spp);
                setMc(spp);
              })
              .catch((error) => {
                console.log(error);
              });
            */
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //-- sp user for filter spuserid spgroup
  async function fetchspdata() {
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listSPUserDetails,
        variables: {
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.listSPUserDetails.items;
        nextToken = result.data.listSPUserDetails.nextToken;
        console.log(
          "first token count",
          result.data.listSPUserDetails.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listSPUserDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listSPUserDetails,
              variables: {
                limit: 2000000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listSPUserDetails.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.listSPUserDetails.items.length);
              if (results.data.listSPUserDetails.items.length > 0) {
                assets.push(results.data.listSPUserDetails.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setspdata(mergeAssets);
        } else {
          setspdata([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ---All Site And All Distribution For Site User

  async function fetchSiteDetailsSU() {
    //--
    let nextToken = "";
    let assets = [];
    let filter = {};
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - data.date);
    if (data.date !== "") {
      if (data.date === "Custom") {
        const startDate = new Date(data.startDate);
        const endDate = new Date(data.endDate);

        filter = {
          repairDate: {
            between: [startDate.toISOString(), endDate.toISOString()],
          },
        };
      } else {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - data.date);

        filter = {
          repairDate: {
            between: [
              moment(thirtyDaysAgo).format("YYYY-MM-DD"),
              moment(today).format("YYYY-MM-DD"),
            ],
          },
        };
      }
    } else {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);

      filter = {
        repairDate: {
          between: [
            moment(thirtyDaysAgo).format("YYYY-MM-DD"),
            moment(today).format("YYYY-MM-DD"),
          ],
        },
      };
    }

    await client
      .graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        variables: {
          userdetailsID: LoginUserDetails.id,
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
        nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
        if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              variables: {
                userdetailsID: LoginUserDetails.id,
                limit: 2000000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken =
                results.data.linkUsersAndSitesByUserdetailsID.nextToken;
              if (
                results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
              ) {
                assets.push(
                  results.data.linkUsersAndSitesByUserdetailsID.items
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          let nextToken1 = "";
          let assets1 = [];
          mergeAssets.map(async (item) => {
            await client
              .graphql({
                query: mergedQueries.getRepairReports,
                variables: {
                  sitedetailsID: item.sitedetailsID,
                  filter,
                  limit: 2000000,
                },
                authMode: "API_KEY",
              })
              .then(async (result) => {
                let assetDatas = result.data.assetsBySitedetailsID.items;
                nextToken1 = result.data.assetsBySitedetailsID.nextToken;
                if (result.data.assetsBySitedetailsID.items.length > 0) {
                  assets1.push(assetDatas);
                }
                while (nextToken1 !== null) {
                  await client
                    .graphql({
                      query: mergedQueries.getRepairReports,
                      variables: {
                        sitedetailsID: item.sitedetailsID,
                        limit: 2000000,
                        filter,
                        nextToken: nextToken1,
                      },
                      authMode: "API_KEY",
                    })
                    .then((results) => {
                      nextToken1 = results.data.assetsBySitedetailsID.nextToken;
                      console.log("nextToken", nextToken1);
                      console.log(
                        results.data.assetsBySitedetailsID.items.length
                      );
                      if (results.data.assetsBySitedetailsID.items.length > 0) {
                        assets1.push(results.data.assetsBySitedetailsID.items);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log(assets1);
                if (assets1.length > 0) {
                  const mergeAssets1 = assets1.flat(1);
                  console.log(mergeAssets1);
                  const filterdelete = mergeAssets1.filter(
                    (item) => item.status !== "delete"
                  );
                  //---

                  let logbook = filterdelete.filter((item) => {
                    const _matchingAssetTemplate = data.assetTemp?.find(
                      (temp) => temp === item?.assettemplateID
                    );
                    if (_matchingAssetTemplate) {
                      return item;
                    }
                  });
                  const asset = logbook;
                  let assetFilter = [];
                  console.log(data.warranty);
                  if (data.warranty !== "") {
                    if (data.warranty === "InWarranty") {
                      console.log("In Warranty");
                      const today = new Date();
                      assetFilter = asset.filter((item) => {
                        const assetWarrantyEndDate = new Date(
                          item.assetWarrantyEnd
                        );
                        return assetWarrantyEndDate > today;
                      });
                    } else {
                      console.log("Out Warranty");
                      const today = new Date();
                      assetFilter = asset.filter((item) => {
                        const assetWarrantyEndDate = new Date(
                          item.assetWarrantyEnd
                        );
                        return assetWarrantyEndDate < today;
                      });
                    }
                  } else {
                    assetFilter = asset;
                  }
                  console.log(assetFilter);
                  setAssets1(assetFilter);
                  if (assetFilter.length > 0) {
                    const repairData = [];
                    let counter = 0;
                    const assetOptions = [{ value: null, label: "All Assets" }];
                    const sort = assetFilter.sort(
                      (a, b) => a.fittingNumber - b.fittingNumber
                    );
                    const sortoption = sort.filter(
                      (item) => item.status === "Active"
                    );
                    console.log(sortoption);
                    sortoption.map((item) => {
                      assetOptions.push({
                        value: item.id,
                        label: item.fittingNumber,
                      });
                    });
                    console.log(sort);
                    sort.map(async (item) => {
                      console.log(item);
                      counter = counter + 1;
                      let repaircount = 0;
                      if (item.Repairs.items.length > 0) {
                        item.Repairs.items.map(async (repair) => {
                          repaircount = repaircount + 1;
                          repairData.push({
                            id: repair.id,
                            createdAt: repair.createdAt,
                            asset: item.fittingNumber,
                            RepairDate: repair.repairDate,
                            RepairTime: repair.repairTime,
                            warrantyUpto: repair.warrantyUpto,
                            assetWarranty: item.assetWarrantyEnd,
                            technician: repair.technician[0],
                            assetId: repair.assetsID,
                            status: item.status,
                            distributionBox:
                              repair.replacedAssetID !== null
                                ? repair.replacedAssetID
                                : repair.assetsID,
                            remarks: repair.additionalInformation,
                            assettemplateID: item.assettemplateID,
                          });
                          if (
                            assetFilter.length === counter &&
                            repaircount === item.Repairs.items.length
                          ) {
                            if (repairData.length > 0) {
                              const sort = repairData.sort(
                                (a, b) =>
                                  new Date(b.createdAt) - new Date(a.createdAt)
                              );
                              console.log(repairData);
                              console.log(sort);
                              console.log(data.dateRange);

                              setAssetDemo(sort);
                              setFilter(sort);
                              setAssets(cloneDeep(sort.slice(0, countPerPage)));
                              //---
                              const spUserIds = new Set();

                              sort.forEach((item) => {
                                spUserIds.add(item.technician);
                              });

                              const areSpUserIdsUnique =
                                spUserIds.size === sort.length;

                              if (areSpUserIdsUnique) {
                                console.log(spUserIds);
                                console.log("All spuserid values are unique.");
                                //--
                                const filterdata = sort.filter((item) => {
                                  return spdata.some(
                                    (spuser) =>
                                      spuser.id === item.technician &&
                                      spuser.SPGroup === "admin"
                                  );
                                });
                                console.log(filterdata);
                                if (filterdata.length > 0) {
                                  fetchspusercomp(filterdata[0].technician);
                                } else {
                                  const myArray = Array.from(spUserIds);
                                  const myValue = myArray[0];
                                  console.log(myValue);
                                  fetchspusercompAdmin(myValue);
                                }
                              } else {
                                console.log(spUserIds);
                                console.log(
                                  "Some spuserid values are not unique."
                                );
                                if (spUserIds.size === 1) {
                                  const myArray = Array.from(spUserIds);
                                  const myValue = myArray[0];
                                  console.log(myValue);
                                  fetchspusercompAdmin(myValue);
                                } else {
                                  const filterdata = sort.filter((item) => {
                                    return spdata.some(
                                      (spuser) =>
                                        spuser.id === item.technician &&
                                        spuser.SPGroup === "admin"
                                    );
                                  });
                                  console.log(filterdata);
                                  if (filterdata.length > 0) {
                                    fetchspusercomp(filterdata[0].technician);
                                  } else {
                                    const myArray = Array.from(spUserIds);
                                    const myValue = myArray[0];
                                    console.log(myValue);
                                    fetchspusercompAdmin(myValue);
                                  }
                                }
                              }
                              setLoading(false);
                            } else {
                              setAssetDemo([]);
                              setAssets([]);
                              setFilter([]);
                              setLoading(false);
                            }
                          }
                        });
                      } else {
                        console.log("---repair ");
                        console.log(repairData);

                        setAssetDemo(repairData);
                        setFilter(repairData);
                        setAssets(cloneDeep(repairData.slice(0, countPerPage)));
                        //---
                        const spUserIds = new Set();

                        repairData.forEach((item) => {
                          spUserIds.add(item.technician);
                        });

                        const areSpUserIdsUnique =
                          spUserIds.size === repairData.length;

                        if (areSpUserIdsUnique) {
                          console.log(spUserIds);
                          console.log("All spuserid values are unique.");
                          //--
                          const filterdata = repairData.filter((item) => {
                            return spdata.some(
                              (spuser) =>
                                spuser.id === item.technician &&
                                spuser.SPGroup === "admin"
                            );
                          });
                          console.log(filterdata);
                          if (filterdata.length > 0) {
                            fetchspusercomp(filterdata[0].technician);
                          } else {
                            const myArray = Array.from(spUserIds);
                            const myValue = myArray[0];
                            console.log(myValue);
                            fetchspusercompAdmin(myValue);
                          }
                        } else {
                          console.log(spUserIds);
                          console.log("Some spuserid values are not unique.");
                          if (spUserIds.size === 1) {
                            const myArray = Array.from(spUserIds);
                            const myValue = myArray[0];
                            console.log(myValue);
                            fetchspusercompAdmin(myValue);
                          } else {
                            const filterdata = repairData.filter((item) => {
                              return spdata.some(
                                (spuser) =>
                                  spuser.id === item.technician &&
                                  spuser.SPGroup === "admin"
                              );
                            });
                            console.log(filterdata);
                            if (filterdata.length > 0) {
                              fetchspusercomp(filterdata[0].technician);
                            } else {
                              const myArray = Array.from(spUserIds);
                              const myValue = myArray[0];
                              console.log(myValue);
                              fetchspusercompAdmin(myValue);
                            }
                          }
                        }
                        setLoading(false);
                      }
                      if (
                        assetFilter.length === counter &&
                        repaircount === item.Repairs.items.length
                      ) {
                        setAsset(assetOptions);
                        if (repairData.length > 0) {
                          const sort = repairData.sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt)
                          );
                          console.log(repairData);
                          console.log(sort);
                          setAssetDemo(sort);
                          setFilter(sort);
                          setAssets(cloneDeep(sort.slice(0, countPerPage)));
                          //---
                          const spUserIds = new Set();

                          sort.forEach((item) => {
                            spUserIds.add(item.technician);
                          });

                          const areSpUserIdsUnique =
                            spUserIds.size === sort.length;

                          if (areSpUserIdsUnique) {
                            console.log(spUserIds);
                            console.log("All spuserid values are unique.");
                            //--
                            const filterdata = sort.filter((item) => {
                              return spdata.some(
                                (spuser) =>
                                  spuser.id === item.technician &&
                                  spuser.SPGroup === "admin"
                              );
                            });
                            console.log(filterdata);
                            if (filterdata.length > 0) {
                              fetchspusercomp(filterdata[0].technician);
                            } else {
                              const myArray = Array.from(spUserIds);
                              const myValue = myArray[0];
                              console.log(myValue);
                              fetchspusercompAdmin(myValue);
                            }
                          } else {
                            console.log(spUserIds);
                            console.log("Some spuserid values are not unique.");
                            if (spUserIds.size === 1) {
                              const myArray = Array.from(spUserIds);
                              const myValue = myArray[0];
                              console.log(myValue);
                              fetchspusercompAdmin(myValue);
                            } else {
                              const filterdata = sort.filter((item) => {
                                return spdata.some(
                                  (spuser) =>
                                    spuser.id === item.technician &&
                                    spuser.SPGroup === "admin"
                                );
                              });
                              console.log(filterdata);
                              if (filterdata.length > 0) {
                                fetchspusercomp(filterdata[0].technician);
                              } else {
                                const myArray = Array.from(spUserIds);
                                const myValue = myArray[0];
                                console.log(myValue);
                                fetchspusercompAdmin(myValue);
                              }
                            }
                          }
                          setLoading(false);
                        } else {
                          setAssetDemo([]);
                          setAssets([]);
                          setFilter([]);
                          setLoading(false);
                        }
                      }
                    });
                  } else {
                    console.log("---assets count 0 ");
                    setAssetDemo([]);
                    setFilter([]);
                    setAssets(cloneDeep([].slice(0, countPerPage)));
                    setLoading(false);
                  }
                } else {
                  setAssetDemo([]);
                  setFilter([]);
                  setAssets(cloneDeep([].slice(0, countPerPage)));
                  setLoading(false);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        } else {
          setAssetDemo([]);
          setFilter([]);
          setAssets(cloneDeep([].slice(0, countPerPage)));
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ---- All sites and all distribution for Asset Owner ----
  async function fetchRepairDemo() {
    setLoading(true);
    let nextToken = "";
    let assets = [];
    let filter = {};
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - data.date);
    if (data.date !== "") {
      if (data.date === "Custom") {
        const startDate = new Date(data.startDate);
        const endDate = new Date(data.endDate);

        filter = {
          repairDate: {
            between: [
              moment(startDate).format("YYYY-MM-DD"),
              moment(endDate).format("YYYY-MM-DD"),
            ],
          },
        };
      } else {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - data.date);

        filter = {
          repairDate: {
            between: [
              moment(thirtyDaysAgo).format("YYYY-MM-DD"),
              moment(today).format("YYYY-MM-DD"),
            ],
          },
        };
      }
    } else {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);

      filter = {
        repairDate: {
          between: [
            moment(thirtyDaysAgo).format("YYYY-MM-DD"),
            moment(today).format("YYYY-MM-DD"),
          ],
        },
      };
    }
    if (fullSites.length > 0) {
      let nextToken1 = "";
      let assets1 = [];
      let count = 0;
      fullSites.map(async (item) => {
        count = count + 1;
        await client
          .graphql({
            query: mergedQueries.getRepairReports,
            variables: {
              sitedetailsID: item.id,
              filter: filter,
            },
            authMode: "API_KEY",
          })
          .then(async (result) => {
            console.log("result", result);
            let assetDatas = result.data.assetsBySitedetailsID.items;
            nextToken1 = result.data.assetsBySitedetailsID.nextToken;
            if (result.data.assetsBySitedetailsID.items.length > 0) {
              assets1.push(assetDatas);
            }
            while (nextToken1 !== null) {
              await client
                .graphql({
                  query: mergedQueries.getRepairReports,
                  variables: {
                    sitedetailsID: item.id,
                    filter: filter,
                    nextToken: nextToken1,
                  },
                  authMode: "API_KEY",
                })
                .then((results) => {
                  nextToken1 = results.data.assetsBySitedetailsID.nextToken;
                  if (results.data.assetsBySitedetailsID.items.length > 0) {
                    assets1.push(results.data.assetsBySitedetailsID.items);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            console.log(assets1);
            if (assets1.length > 0 && count === fullSites.length) {
              const mergeAssets1 = assets1.flat(1);
              console.log(mergeAssets1);
              let assetsId = [];
              let assetsIds = [];
              assets1.map((item) => {
                assetsIds.push({ assetsID: item.id });
                assetsId.push(item.id);
              });
              const filterdelete = mergeAssets1.filter(
                (item) => item.status !== "delete"
              );
              let logbook = filterdelete.filter((item) => {
                const _matchingAssetTemplate = data.assetTemp?.find(
                  (temp) => temp === item?.assettemplateID
                );
                if (_matchingAssetTemplate) {
                  return item;
                }
              });
              const asset = logbook;
              let assetFilter = [];
              console.log(data.warranty);
              if (data.warranty !== "") {
                if (data.warranty === "InWarranty") {
                  console.log("In Warranty");
                  const today = new Date();
                  assetFilter = asset.filter((item) => {
                    const assetWarrantyEndDate = new Date(
                      item.assetWarrantyEnd
                    );
                    return assetWarrantyEndDate > today;
                  });
                } else {
                  console.log("Out Warranty");
                  const today = new Date();
                  assetFilter = asset.filter((item) => {
                    const assetWarrantyEndDate = new Date(
                      item.assetWarrantyEnd
                    );
                    return assetWarrantyEndDate < today;
                  });
                }
              } else {
                assetFilter = asset;
              }
              console.log(assetFilter);
              setAssets1(assetFilter);
              if (assetFilter.length > 0) {
                const repairData = [];
                let counter = 0;
                const assetOptions = [{ value: null, label: "All Assets" }];
                const sort = assetFilter.sort(
                  (a, b) => a.fittingNumber - b.fittingNumber
                );
                const sortoption = sort.filter(
                  (item) => item.status === "Active"
                );
                console.log(sortoption);
                sortoption.map((item) => {
                  assetOptions.push({
                    value: item.id,
                    label: item.fittingNumber,
                  });
                });
                console.log(sort);
                sort.map(async (item) => {
                  console.log(item);
                  counter = counter + 1;
                  let repaircount = 0;
                  if (item.Repairs.items.length > 0) {
                    item.Repairs.items.map(async (repair) => {
                      repaircount = repaircount + 1;
                      repairData.push({
                        id: repair.id,
                        createdAt: repair.createdAt,
                        asset: item.fittingNumber,
                        RepairDate: repair.repairDate,
                        RepairTime: repair.repairTime,
                        warrantyUpto: repair.warrantyUpto,
                        assetWarranty: item.assetWarrantyEnd,
                        technician: repair.technician[0],
                        assetId: repair.assetsID,
                        status: item.status,
                        distributionBox:
                          repair.replacedAssetID !== null
                            ? repair.replacedAssetID
                            : repair.assetsID,
                        remarks: repair.additionalInformation,
                        assettemplateID: item.assettemplateID,
                      });
                      if (
                        assetFilter.length === counter &&
                        repaircount === item.Repairs.items.length
                      ) {
                        if (repairData.length > 0) {
                          const sort = repairData.sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt)
                          );
                          console.log(repairData);
                          console.log(sort);
                          setAssetDemo(sort);
                          setFilter(sort);
                          setAssets(cloneDeep(sort.slice(0, countPerPage)));
                          //---
                          const spUserIds = new Set();

                          sort.forEach((item) => {
                            spUserIds.add(item.technician);
                          });

                          const areSpUserIdsUnique =
                            spUserIds.size === sort.length;

                          if (areSpUserIdsUnique) {
                            console.log(spUserIds);
                            console.log("All spuserid values are unique.");
                            //--
                            const filterdata = sort.filter((item) => {
                              return spdata.some(
                                (spuser) =>
                                  spuser.id === item.technician &&
                                  spuser.SPGroup === "admin"
                              );
                            });
                            console.log(filterdata);
                            if (filterdata.length > 0) {
                              fetchspusercomp(filterdata[0].technician);
                            } else {
                              const myArray = Array.from(spUserIds);
                              const myValue = myArray[0];
                              console.log(myValue);
                              fetchspusercompAdmin(myValue);
                            }
                          } else {
                            console.log(spUserIds);
                            console.log("Some spuserid values are not unique.");
                            if (spUserIds.size === 1) {
                              const myArray = Array.from(spUserIds);
                              const myValue = myArray[0];
                              console.log(myValue);
                              fetchspusercompAdmin(myValue);
                            } else {
                              const filterdata = sort.filter((item) => {
                                return spdata.some(
                                  (spuser) =>
                                    spuser.id === item.technician &&
                                    spuser.SPGroup === "admin"
                                );
                              });
                              console.log(filterdata);
                              if (filterdata.length > 0) {
                                fetchspusercomp(filterdata[0].technician);
                              } else {
                                const myArray = Array.from(spUserIds);
                                const myValue = myArray[0];
                                console.log(myValue);
                                fetchspusercompAdmin(myValue);
                              }
                            }
                          }
                          setLoading(false);
                        } else {
                          setAssetDemo([]);
                          setAssets([]);
                          setFilter([]);
                          setLoading(false);
                        }
                      }
                    });
                  } else {
                    console.log("---repair ");
                    console.log(repairData);
                    setAssetDemo(repairData);
                    setFilter(repairData);
                    setAssets(cloneDeep(repairData.slice(0, countPerPage)));
                    //---
                    const spUserIds = new Set();

                    repairData.forEach((item) => {
                      spUserIds.add(item.technician);
                    });

                    const areSpUserIdsUnique =
                      spUserIds.size === repairData.length;

                    if (areSpUserIdsUnique) {
                      console.log(spUserIds);
                      console.log("All spuserid values are unique.");
                      //--
                      const filterdata = repairData.filter((item) => {
                        return spdata.some(
                          (spuser) =>
                            spuser.id === item.technician &&
                            spuser.SPGroup === "admin"
                        );
                      });
                      console.log(filterdata);
                      if (filterdata.length > 0) {
                        fetchspusercomp(filterdata[0].technician);
                      } else {
                        const myArray = Array.from(spUserIds);
                        const myValue = myArray[0];
                        console.log(myValue);
                        fetchspusercompAdmin(myValue);
                      }
                    } else {
                      console.log(spUserIds);
                      console.log("Some spuserid values are not unique.");
                      if (spUserIds.size === 1) {
                        const myArray = Array.from(spUserIds);
                        const myValue = myArray[0];
                        console.log(myValue);
                        fetchspusercompAdmin(myValue);
                      } else {
                        const filterdata = repairData.filter((item) => {
                          return spdata.some(
                            (spuser) =>
                              spuser.id === item.technician &&
                              spuser.SPGroup === "admin"
                          );
                        });
                        console.log(filterdata);
                        if (filterdata.length > 0) {
                          fetchspusercomp(filterdata[0].technician);
                        } else {
                          const myArray = Array.from(spUserIds);
                          const myValue = myArray[0];
                          console.log(myValue);
                          fetchspusercompAdmin(myValue);
                        }
                      }
                    }
                    setLoading(false);
                  }
                  if (
                    assetFilter.length === counter &&
                    repaircount === item.Repairs.items.length
                  ) {
                    setAsset(assetOptions);
                    if (repairData.length > 0) {
                      const sort = repairData.sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                      );
                      console.log(repairData);
                      console.log(sort);
                      setAssetDemo(sort);
                      setFilter(sort);
                      setAssets(cloneDeep(sort.slice(0, countPerPage)));
                      //---
                      const spUserIds = new Set();

                      sort.forEach((item) => {
                        spUserIds.add(item.technician);
                      });

                      const areSpUserIdsUnique = spUserIds.size === sort.length;

                      if (areSpUserIdsUnique) {
                        console.log(spUserIds);
                        console.log("All spuserid values are unique.");
                        //--
                        const filterdata = sort.filter((item) => {
                          return spdata.some(
                            (spuser) =>
                              spuser.id === item.technician &&
                              spuser.SPGroup === "admin"
                          );
                        });
                        console.log(filterdata);
                        if (filterdata.length > 0) {
                          fetchspusercomp(filterdata[0].technician);
                        } else {
                          const myArray = Array.from(spUserIds);
                          const myValue = myArray[0];
                          console.log(myValue);
                          fetchspusercompAdmin(myValue);
                        }
                      } else {
                        console.log(spUserIds);
                        console.log("Some spuserid values are not unique.");
                        if (spUserIds.size === 1) {
                          const myArray = Array.from(spUserIds);
                          const myValue = myArray[0];
                          console.log(myValue);
                          fetchspusercompAdmin(myValue);
                        } else {
                          const filterdata = sort.filter((item) => {
                            return spdata.some(
                              (spuser) =>
                                spuser.id === item.technician &&
                                spuser.SPGroup === "admin"
                            );
                          });
                          console.log(filterdata);
                          if (filterdata.length > 0) {
                            fetchspusercomp(filterdata[0].technician);
                          } else {
                            const myArray = Array.from(spUserIds);
                            const myValue = myArray[0];
                            console.log(myValue);
                            fetchspusercompAdmin(myValue);
                          }
                        }
                      }
                      setLoading(false);
                    } else {
                      setAssetDemo([]);
                      setAssets([]);
                      setFilter([]);
                      setLoading(false);
                    }
                  }
                });
              } else {
                console.log("---assets count 0 ");
                setAssetDemo([]);
                setFilter([]);
                setAssets(cloneDeep([].slice(0, countPerPage)));
                setLoading(false);
              }
            } else {
              setAssetDemo([]);
              setFilter([]);
              setAssets(cloneDeep([].slice(0, countPerPage)));
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      setAssetDemo([]);
      setFilter([]);
      setAssets(cloneDeep([].slice(0, countPerPage)));
      setLoading(false);
    }
  }

  // single site and all distribution board
  async function fetchRepairDemo1(siteId) {
    try {
      setLoading(true);
      //-
      let nextToken = "";
      let assets = [];
      let filter = {};
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - data.date);
      if (data.date !== "") {
        if (data.date === "Custom") {
          const startDate = new Date(data.startDate);
          const endDate = new Date(data.endDate);

          filter = {
            repairDate: {
              between: [
                moment(startDate).format("YYYY-MM-DD"),
                moment(endDate).format("YYYY-MM-DD"),
              ],
            },
          };
        } else {
          const today = new Date();
          const thirtyDaysAgo = new Date();
          thirtyDaysAgo.setDate(today.getDate() - data.date);

          filter = {
            repairDate: {
              between: [
                moment(thirtyDaysAgo).format("YYYY-MM-DD"),
                moment(today).format("YYYY-MM-DD"),
              ],
            },
          };
        }
      } else {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);

        filter = {
          repairDate: {
            between: [
              moment(thirtyDaysAgo).format("YYYY-MM-DD"),
              moment(today).format("YYYY-MM-DD"),
            ],
          },
        };
      }
      await client
        .graphql({
          query: mergedQueries.getRepairReports,
          variables: {
            sitedetailsID: siteId,
            limit: 2000000,
            filter,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          console.log("result", result);
          let assetDatas = result.data.assetsBySitedetailsID.items;
          nextToken = result.data.assetsBySitedetailsID.nextToken;
          if (result.data.assetsBySitedetailsID.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: mergedQueries.getRepairReports,
                variables: {
                  sitedetailsID: siteId,
                  limit: 2000000,
                  filter,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.assetsBySitedetailsID.nextToken;
                if (results.data.assetsBySitedetailsID.items.length > 0) {
                  assets.push(results.data.assetsBySitedetailsID.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            const filterdelete = mergeAssets.filter(
              (item) => item.status !== "delete"
            );
            let assetFilter = [];
            let testing = filterdelete.filter((item) => {
              const _matchingAssetTemplate = data.assetTemp?.find(
                (temp) => temp === item?.assettemplateID
              );
              if (_matchingAssetTemplate) {
                return item;
              }
            });
            console.log(testing);
            const asset = testing;
            console.log(data.warranty);
            if (data.warranty !== "") {
              if (data.warranty === "InWarranty") {
                console.log("In Warranty");
                const today = new Date();
                assetFilter = asset.filter((item) => {
                  const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                  return assetWarrantyEndDate > today;
                });
              } else {
                console.log("Out Warranty");
                const today = new Date();
                assetFilter = asset.filter((item) => {
                  const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                  return assetWarrantyEndDate < today;
                });
              }
            } else {
              assetFilter = asset;
            }
            console.log(assetFilter);
            const testReport = [];
            if (assetFilter.length > 0) {
              const repairData = [];
              let counter = 0;
              const assetOptions = [{ value: null, label: "All Assets" }];
              const sort = assetFilter.sort(
                (a, b) => a.fittingNumber - b.fittingNumber
              );
              const sortoption = sort.filter(
                (item) => item.status === "Active"
              );
              console.log(sortoption);
              sortoption.map((item) => {
                assetOptions.push({
                  value: item.id,
                  label: item.fittingNumber,
                });
              });
              console.log(sort);
              setAssets1(sort);
              sort.map(async (item) => {
                console.log(item);
                counter = counter + 1;
                let repaircount = 0;
                if (item.Repairs.items.length > 0) {
                  item.Repairs.items.map(async (repair) => {
                    repaircount = repaircount + 1;
                    repairData.push({
                      id: repair.id,
                      createdAt: repair.createdAt,
                      asset: item.fittingNumber,
                      RepairDate: repair.repairDate,
                      RepairTime: repair.repairTime,
                      warrantyUpto: repair.warrantyUpto,
                      assetWarranty: item.assetWarrantyEnd,
                      technician: repair.technician[0],
                      assetId: repair.assetsID,
                      status: item.status,
                      distributionBox:
                        repair.replacedAssetID !== null
                          ? repair.replacedAssetID
                          : repair.assetsID,
                      remarks: repair.additionalInformation,
                      assettemplateID: item.assettemplateID,
                    });
                    if (
                      assetFilter.length === counter &&
                      repaircount === item.Repairs.items.length
                    ) {
                      if (repairData.length > 0) {
                        const sort = repairData.sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        );
                        console.log(repairData);
                        console.log(sort);
                        setAssetDemo(sort);
                        setFilter(sort);
                        setAssets(cloneDeep(sort.slice(0, countPerPage)));
                        //---
                        const spUserIds = new Set();

                        sort.forEach((item) => {
                          spUserIds.add(item.technician);
                        });

                        const areSpUserIdsUnique =
                          spUserIds.size === sort.length;

                        if (areSpUserIdsUnique) {
                          console.log(spUserIds);
                          console.log("All spuserid values are unique.");
                          //--
                          const filterdata = sort.filter((item) => {
                            return spdata.some(
                              (spuser) =>
                                spuser.id === item.technician &&
                                spuser.SPGroup === "admin"
                            );
                          });
                          console.log(filterdata);
                          if (filterdata.length > 0) {
                            fetchspusercomp(filterdata[0].technician);
                          } else {
                            const myArray = Array.from(spUserIds);
                            const myValue = myArray[0];
                            console.log(myValue);
                            fetchspusercompAdmin(myValue);
                          }
                        } else {
                          console.log(spUserIds);
                          console.log("Some spuserid values are not unique.");
                          if (spUserIds.size === 1) {
                            const myArray = Array.from(spUserIds);
                            const myValue = myArray[0];
                            console.log(myValue);
                            fetchspusercompAdmin(myValue);
                          } else {
                            const filterdata = sort.filter((item) => {
                              return spdata.some(
                                (spuser) =>
                                  spuser.id === item.technician &&
                                  spuser.SPGroup === "admin"
                              );
                            });
                            console.log(filterdata);
                            if (filterdata.length > 0) {
                              fetchspusercomp(filterdata[0].technician);
                            } else {
                              const myArray = Array.from(spUserIds);
                              const myValue = myArray[0];
                              console.log(myValue);
                              fetchspusercompAdmin(myValue);
                            }
                          }
                        }
                        setLoading(false);
                      } else {
                        setAssetDemo([]);
                        setAssets([]);
                        setFilter([]);
                        setLoading(false);
                      }
                    }
                  });
                }
                if (
                  assetFilter.length === counter &&
                  repaircount === item.Repairs.items.length
                ) {
                  setAsset(assetOptions);
                  if (repairData.length > 0) {
                    const sort = repairData.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    );
                    console.log(repairData);
                    console.log(sort);
                    setAssetDemo(sort);
                    setFilter(sort);
                    setAssets(cloneDeep(sort.slice(0, countPerPage)));
                    //---
                    const spUserIds = new Set();

                    sort.forEach((item) => {
                      spUserIds.add(item.technician);
                    });

                    const areSpUserIdsUnique = spUserIds.size === sort.length;

                    if (areSpUserIdsUnique) {
                      console.log(spUserIds);
                      console.log("All spuserid values are unique.");
                      //--
                      const filterdata = sort.filter((item) => {
                        return spdata.some(
                          (spuser) =>
                            spuser.id === item.technician &&
                            spuser.SPGroup === "admin"
                        );
                      });
                      console.log(filterdata);
                      if (filterdata.length > 0) {
                        fetchspusercomp(filterdata[0].technician);
                      } else {
                        const myArray = Array.from(spUserIds);
                        const myValue = myArray[0];
                        console.log(myValue);
                        fetchspusercompAdmin(myValue);
                      }
                    } else {
                      console.log(spUserIds);
                      console.log("Some spuserid values are not unique.");
                      if (spUserIds.size === 1) {
                        const myArray = Array.from(spUserIds);
                        const myValue = myArray[0];
                        console.log(myValue);
                        fetchspusercompAdmin(myValue);
                      } else {
                        const filterdata = sort.filter((item) => {
                          return spdata.some(
                            (spuser) =>
                              spuser.id === item.technician &&
                              spuser.SPGroup === "admin"
                          );
                        });
                        console.log(filterdata);
                        if (filterdata.length > 0) {
                          fetchspusercomp(filterdata[0].technician);
                        } else {
                          const myArray = Array.from(spUserIds);
                          const myValue = myArray[0];
                          console.log(myValue);
                          fetchspusercompAdmin(myValue);
                        }
                      }
                    }
                    setLoading(false);
                  } else {
                    setAssetDemo([]);
                    setAssets([]);
                    setFilter([]);
                    setLoading(false);
                  }
                }
              });
            } else {
              setAssetDemo([]);
              setAssets([]);
              setFilter([]);
              setLoading(false);
            }
          } else {
            setAssetDemo([]);
            setAssets([]);
            setFilter([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      setAssetDemo([]);
      setAssets([]);
      setFilter([]);
      setLoading(false);
    }
  }

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setAssets(cloneDeep(assetDemo.slice(from, to)));
  };

  // maintenance  contractor
  async function getUserdetails() {
    //--
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listUserDetails,
        variables: {
          filter: {
            id: {
              eq: LoginUserDetails.id,
            },
          },
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.listUserDetails.items;
        nextToken = result.data.listUserDetails.nextToken;
        console.log(
          "first token count",
          result.data.listUserDetails.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listUserDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listUserDetails,
              variables: {
                limit: 2000000,
                filter: {
                  id: {
                    eq: LoginUserDetails.id,
                  },
                },
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listUserDetails.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.listUserDetails.items.length);
              if (results.data.listUserDetails.items.length > 0) {
                assets.push(results.data.listUserDetails.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //--- Repaired By ----
  async function fetchSP() {
    //--
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listSPUserDetails,
        variables: {
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.listSPUserDetails.items;
        nextToken = result.data.listSPUserDetails.nextToken;
        if (result.data.listSPUserDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listSPUserDetails,
              variables: {
                limit: 2000000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listSPUserDetails.nextToken;
              if (results.data.listSPUserDetails.items.length > 0) {
                assets.push(results.data.listSPUserDetails.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setRepairedBy(mergeAssets);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getTechnician(id) {
    const technicianName = repairedBy.find((item) => item.id === id);
    return technicianName
      ? (technicianName.fname ? technicianName.fname : "-") +
          " " +
          (technicianName.lname ? technicianName.lname : "")
      : "";
  }

  function getLicense(technicianId) {
    const technicianName = repairedBy.find((item) => item.id === technicianId);
    return technicianName
      ? technicianName.license
        ? technicianName.license
        : "-"
      : "";
  }

  //---pdf ---
  const generatePDF = () => {
    try {
      setLoading(true);
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3, or A4
      const orientation = "portrait"; // portrait or landscape

      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(12);

      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth =
        doc.getStringUnitWidth("Repair Report") * doc.internal.getFontSize();
      const textOffset = (pageWidth - textWidth) / 2;

      // Add heading
      const headingY = 20;
      doc.text("Repair Report", textOffset, headingY);
      // Add rectangle box
      const boxWidth = 570;
      const boxHeight = 180;
      const boxX = (pageWidth - boxWidth) / 2.5;
      const boxY = headingY + 10;

      doc.rect(boxX, boxY, boxWidth, boxHeight);

      const dividingLineX = boxX + boxWidth / 2.5;
      const dividingLineStartY = boxY;
      const dividingLineEndY = boxY + boxHeight;

      doc.line(
        dividingLineX,
        dividingLineStartY,
        dividingLineX,
        dividingLineEndY
      );

      const spCompanyYCoordinate = boxY + 15;
      if (downloadedFile !== null) {
        doc.addImage(
          downloadedFile,
          "JPEG",
          boxX + 20,
          spCompanyYCoordinate,
          120,
          100
        );
      }
      const spcompanycordinateNew = spCompanyYCoordinate + 120;

      const aof = ao && ao.firstName ? ao.firstName : "N/A";
      const aol = ao && ao.lastName ? ao.lastName : "N/A";
      const aom = ao && ao.mobile ? ao.mobile : "N/A";
      const aoe = ao && ao.email ? ao.email : "N/A";

      const spCompanyInformation = ["", "Category: " + data.categoryName, ""];

      doc.setFontSize(7);
      doc.setFont("normal");
      spCompanyInformation.forEach((info, index) => {
        doc.text(info, boxX + 10, spcompanycordinateNew + index * 15 + 7.5);
      });

      const siteName = sites && sites.siteName ? sites.siteName : "All Sites";
      const unitNumber = sites && sites.unitNumber ? sites.unitNumber : "-";
      const companyName =
        company && company.companyName ? company.companyName : "-";
      const maxCharactersPerLinename = 30;
      const firstLineName = companyName.substring(0, maxCharactersPerLinename);
      const secondLineName = companyName.substring(maxCharactersPerLinename);
      const companyLicence =
        company && company.companyLicense ? company.companyLicense : "-";
      const companyBN = company && company.companyBN ? company.companyBN : "-";
      const companyAddress =
        company && company.companyAddress ? company.companyAddress : "-";
      const maxCharactersPerLine = 30;
      const firstLine = companyAddress.substring(0, maxCharactersPerLine);
      const secondLine = companyAddress.substring(maxCharactersPerLine);

      const spf = mc && mc.fname ? mc.fname : " ";
      const spl = mc && mc.lname ? mc.lname : "";
      const electricianLicence = mc && mc.license ? mc.license : "-";

      const siteInformation = [
        "",
        "Site:" + " " + siteName,
        "Site Address: " + unitNumber,
        " ",
        "Company Name:" + " " + companyName,
        "Company Electrical Licence #: " + companyLicence,
        "Company BN#:" + " " + companyBN,
        "Company Address:" + " " + companyAddress,
      ];
      doc.setFont("normal");
      const siteInfoXCoordinate = dividingLineX + 10;
      const siteInfoYCoordinate = boxY + 15;
      siteInformation.forEach((info, index) => {
        doc.text(info, siteInfoXCoordinate, siteInfoYCoordinate + index * 15);
      });

      const tableStartY = dividingLineEndY - 150;
      const tableData = [];

      doc.setFontSize(7);
      doc.setFont("normal");

      const tableHeader = [
        "Fitting Number",
        "Repaired By",
        "License",
        "Repair Date & Time",
        "Warranty Upto",
        "Distribution Board",
        "Additional Info",
      ];
      tableData.push(tableHeader);

      assetDemo.forEach((item) => {
        const repairDateTime =
          moment(item.RepairDate).format("DD/MM/YYYY") + " " + item.RepairTime;
        const rowData = [
          item.asset,
          getTechnician(item.technician),
          getLicense(item.technician),
          repairDateTime,
          item.warrantyUpto,
          getDB1(item.distributionBox),
          item.remarks,
        ];
        tableData.push(rowData);
      });

      const yCoordinate = tableStartY;

      const tableConfig = {
        startY: yCoordinate + siteInformation.length * 15 + 40,
        headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
        margin: { top: 20 },
        tableWidth: 570,
        styles: { fontSize: 7 },
      };

      doc.autoTable({
        head: [tableHeader],
        body: tableData.slice(1),
        startY: tableStartY,
        ...tableConfig,
        margin: { left: 10 },
      });
      doc.save("RepairReport.pdf");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const filterAssets = (assetValue) => {
    console.log(assetValue);
    try {
      setAssetVal(assetValue);
      if (assetValue !== null && assetValue.value !== null) {
        var filterAssets = filterdemo.filter((item) => {
          return item.assetId === assetValue.value;
        });
        if (dateRange[0] !== null) {
          const [newStartDate, newEndDate] = dateRange;
          var filterAssets1 = filterAssets.filter((item) => {
            return (
              moment(item.RepairDate).format("DD/MM/YYYY") >=
                moment(newStartDate).format("DD/MM/YYYY") &&
              moment(item.RepairDate).format("DD/MM/YYYY") <=
                moment(newEndDate).format("DD/MM/YYYY")
            );
          });
          const sort = filterAssets1.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else {
          const sort = filterAssets.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        }
      } else {
        if (dateRange[0] !== null) {
          const [newStartDate, newEndDate] = dateRange;
          var filterAssets = filterdemo.filter((item) => {
            return (
              moment(item.RepairDate).format("DD/MM/YYYY") >=
                moment(newStartDate).format("DD/MM/YYYY") &&
              moment(item.RepairDate).format("DD/MM/YYYY") <=
                moment(newEndDate).format("DD/MM/YYYY")
            );
          });
          const sort = filterAssets.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else {
          setAssetDemo(filterdemo);
          setAssets(cloneDeep(filterdemo.slice(0, countPerPage)));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterDateRage = (value, value1) => {
    try {
      if (value !== null && value1 !== null) {
        var filterAssets = filterdemo.filter((item) => {
          return (
            moment(item.RepairDate).format("DD/MM/YYYY") >=
              moment(value).format("DD/MM/YYYY") &&
            moment(item.RepairDate).format("DD/MM/YYYY") <=
              moment(value1).format("DD/MM/YYYY")
          );
        });
        if (assetVal !== null && assetVal !== "" && assetVal.value !== null) {
          var filterAssets1 = filterAssets.filter((item) => {
            return item.assetId === assetVal.value;
          });
          const sort = filterAssets1.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else {
          const sort = filterAssets.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        }
      } else {
        if (assetVal !== null && assetVal !== "" && assetVal.value !== null) {
          var filterAssets = filterdemo.filter((item) => {
            return item.id === assetVal.value;
          });
          const sort = filterAssets.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else {
          setAssetDemo(filterdemo);
          setAssets(cloneDeep(filterdemo.slice(0, countPerPage)));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //-----------------------------------------Display------------------------------------------
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Row>
            <Col md="12" className="project-list">
              <Card className="custom-card">
                <Row>
                  <Col md="6">
                    <H4>Repair Report</H4>
                  </Col>
                  {assets.length > 0 &&
                    userPermission.includes("Download Reports") && (
                      <Col md="6" className="text-end">
                        <Btn
                          attrBtn={{ color: "primary", onClick: generatePDF }}
                        >
                          Download PDF
                        </Btn>
                      </Col>
                    )}
                </Row>
                <Row>
                  <H4>Filters</H4>
                  <Col md="4">
                    <Select
                      value={assetVal}
                      onChange={filterAssets}
                      options={asset}
                      isSearchable={true}
                      hideSelectedOptions={false}
                      placeholder="Choose Asset"
                      isClearable={true}
                      menuPlacement="auto"
                      menuPosition="fixed"
                    />
                  </Col>
                  <Col md="4">
                    <DatePicker
                      className="form-control"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(d) => {
                        setDateRange(d);
                        const [newStartDate, newEndDate] = d;
                        filterDateRage(newStartDate, newEndDate);
                      }}
                      isClearable={true}
                      placeholderText="Choose Date Range"
                      dateFormat="dd-MM-yyyy"
                    />
                  </Col>
                </Row>
                <Row>
                  <div className="card-block row">
                    <Col sm="12" lg="12" xl="12">
                      <div className="table-responsive">
                        <Table className="table-striped  table-de">
                          <thead className="custom-table-head">
                            <tr>
                              <th>Fitting Number</th>
                              <th>Repaired By</th>
                              <th>License</th>
                              <th>Repair Date & Time</th>
                              <th>Warranty Upto</th>
                              <th>Asset Warranty Upto</th>
                              <th>Distribution Board</th>
                              <th>Additional Info</th>
                            </tr>
                          </thead>
                          <tbody className="trhover">
                            {assets.map((item) => (
                              <tr key={item.id}>
                                <td>{item.asset}</td>
                                <td>{getTechnician(item.technician)}</td>
                                <td>{getLicense(item.technician)}</td>
                                <td>
                                  {moment(item.RepairDate).format("DD/MM/YYYY")}{" "}
                                  & {item.RepairTime}
                                </td>
                                <td>
                                  {item.warrantyUpto
                                    ? moment(item.warrantyUpto).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>
                                  {item.assetWarranty
                                    ? moment(item.assetWarranty).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{getDB1(item.distributionBox)}</td>
                                <td>{item.remarks}</td>
                              </tr>
                            ))}
                            {assets.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan="12">
                                  No Reports Found for the selected Site and
                                  Distribution Board
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </div>
                </Row>
                <CardFooter className="row">
                  <Pagination
                    className="p-t-10"
                    pageSize={countPerPage}
                    onChange={updatePage}
                    current={currentPage}
                    total={assetDemo.length}
                    showTotal={(total, range) =>
                      `${range[0]} - ${range[1]} of ${total} items`
                    }
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default GenerateRepairReports;
