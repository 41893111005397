import React, { Fragment, useState } from "react";
import {
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Col,
} from "reactstrap";
import { Btn, Image, H2 } from "../../../AbstractElements";
import { Password, SignIn } from "../../../Constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import FormPassword from "./FormPassword";
import { useForm } from "react-hook-form";
import SignInWith from "./SignInWith";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import AWS from "aws-sdk";
import {
  signIn,
  getCurrentUser,
  confirmSignIn,
  signOut,
  fetchAuthSession,
} from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
//var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
//var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
const cognito = new AWS.CognitoIdentityServiceProvider({
  region: "ap-southeast-2",
});

const LoginTab = ({ selected }) => {
  const [aoactive, setAoactive] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const menueRedirect = {
    site_owner: "dashboardsao",
    electrician: "dashboard/default",
    site_user: "dashboard/default",
    admin: "dashboards",
    spao: "dashboardsao",
  };
  const [step, setStep] = useState(1);
  const [loginUserDetail, setLoginUserDetail] = useState([]);
  async function onSubmit(data) {
    setLoading(true);
    console.log(data);
    try {
      const loginCredentials = {
        username: data.username,
        password: data.password,
      };
      const { isSignedIn, nextStep } = await signIn(loginCredentials);
      console.log(isSignedIn, nextStep);
      if (isSignedIn && nextStep.signInStep === "DONE") {
        const { username, userId, signInDetails } = await getCurrentUser();
        const session = await fetchAuthSession();
        const userDetail = await client.graphql({
          query: queries.getUserDetails,
          variables: {
            id: userId,
          },
          authMode: "API_KEY",
        });
        localStorage.setItem(
          "userDetails",
          JSON.stringify(userDetail.data.getUserDetails)
        );
        const aodata = userDetail.data.getUserDetails;
        console.log("session ", session);
        localStorage.setItem(
          "groups",
          session.tokens.idToken.payload["cognito:groups"][0]
        );
        localStorage.setItem("auth0_profile", JSON.stringify(signInDetails));
        if (
          session.tokens.idToken.payload["cognito:groups"][0] === "electrician"
        ) {
          const result1 = await client.graphql({
            query: queries.getSPUserDetails,
            variables: {
              id: userId,
            },
            authMode: "API_KEY",
          });
          const sp = result1.data.getSPUserDetails;
          if (sp.SPGroup === "admin") {
            localStorage.setItem(
              "groups",
              session.tokens.idToken.payload["cognito:groups"][0]
            );
            localStorage.setItem(
              "auth0_profile",
              JSON.stringify(signInDetails)
            );
            localStorage.setItem("authenticated", true);
            toast.success("Successfully Logged In...", {
              position: toast.POSITION.TOP_CENTER,
            });
            history(
              `${process.env.PUBLIC_URL}/${menueRedirect[
              session.tokens.idToken.payload["cognito:groups"][0]
              ]
              }`
            );
          } else {
            console.log("sp group value is not admin");
            toast.error("User don't have the access to login", {
              position: toast.POSITION.TOP_CENTER,
            });
            setLoading(false);
          }
        } else if (
          session.tokens.idToken.payload["cognito:groups"][0] === "spao"
        ) {
          if (aodata && aodata.paymentObject && aodata.paymentObject !== null) {
            const paymentStatus = JSON.parse(aodata.paymentObject);
            if (paymentStatus.payment_status !== "unpaid") {
              localStorage.setItem("authenticated", true);
              toast.success("Successfully Logged In...", {
                position: toast.POSITION.TOP_CENTER,
              });
              history(
                `${process.env.PUBLIC_URL}/${menueRedirect[
                session.tokens.idToken.payload["cognito:groups"][0]
                ]
                }`
              );
            } else {
              navigate(`${process.env.PUBLIC_URL}/paymentstatus`, {
                state: {
                  site: aodata,
                },
              });
            }
          } else {
            navigate(`${process.env.PUBLIC_URL}/paymentstatus`, {
              state: {
                site: aodata,
              },
            });
          }
        } else if (
          session.tokens.idToken.payload["cognito:groups"][0] === "site_owner"
        ) {
          if (aodata.status === "Active") {
            if (
              session.tokens.idToken.payload["cognito:groups"][0] ===
              "site_owner"
            ) {
              if (
                aodata &&
                aodata.paymentObject &&
                aodata.paymentObject !== null
              ) {
                const paymentStatus = JSON.parse(aodata.paymentObject);
                if (paymentStatus.payment_status !== "unpaid") {
                  localStorage.setItem("authenticated", true);
                  toast.success("Successfully Logged In...", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  history(
                    `${process.env.PUBLIC_URL}/${menueRedirect[
                    session.tokens.idToken.payload["cognito:groups"][0]
                    ]
                    }`
                  );
                } else {
                  navigate(`${process.env.PUBLIC_URL}/paymentstatus`, {
                    state: {
                      site: aodata,
                    },
                  });
                }
              } else {
                navigate(`${process.env.PUBLIC_URL}/paymentstatus`, {
                  state: {
                    site: aodata,
                  },
                });
              }
            } else {
              const getuserinviteData = await client.graphql({
                query: queries.getSPUserDetails,
                variables: {
                  id: userId,
                },
                authMode: "API_KEY",
              });
              const invitedBy =
                getuserinviteData.data.getSPUserDetails.invitedBy;
              const invitedByUserData = await client.graphql({
                query: queries.getUserDetails,
                variables: {
                  id: invitedBy,
                },
                authMode: "API_KEY",
              });
              var params = {
                UserPoolId: "ap-southeast-2_Y8sY9qqTz",
                Username: invitedByUserData.data.getUserDetails.username,
              };
              cognito.adminGetUser(params, function (err, data) {
                if (err) console.log(err, err.stack); // an error occurred
                else {
                  console.log(data.UserAttributes[0].Value);
                  localStorage.setItem(
                    "spaoUserCheckForSUInvite",
                    data.UserAttributes[0].Value
                  );
                } // successful response
              });
              localStorage.setItem("authenticated", true);
              toast.success("Successfully Logged In...", {
                position: toast.POSITION.TOP_CENTER,
              });
              history(
                `${process.env.PUBLIC_URL}/${menueRedirect[
                session.tokens.idToken.payload["cognito:groups"][0]
                ]
                }`
              );
            }
          } else {
            console.log("Inactive AssetOwner ...");
            setLoading(false);
            setAoactive(true);
          }
        } else if (
          session.tokens.idToken.payload["cognito:groups"][0] === "site_user"
        ) {
          const getuserinviteData = await client.graphql({
            query: queries.getSPUserDetails,
            variables: {
              id: userId,
            },
            authMode: "API_KEY",
          });
          const invitedBy = getuserinviteData.data.getSPUserDetails.invitedBy;
          const invitedByUserData = await client.graphql({
            query: queries.getUserDetails,
            variables: {
              id: invitedBy,
              limit: 10000,
            },
            authMode: "API_KEY",
          });
          var params = {
            UserPoolId: "ap-southeast-2_Y8sY9qqTz",
            Username: invitedByUserData.data.getUserDetails.username,
          };
          cognito.adminGetUser(params, function (err, data) {
            if (err) console.log(err, err.stack); // an error occurred
            else {
              console.log(data.UserAttributes[0].Value);
              localStorage.setItem(
                "spaoUserCheckForSUInvite",
                data.UserAttributes[0].Value
              );
            } // successful response
          });
          localStorage.setItem("authenticated", true);
          toast.success("Successfully Logged In...", {
            position: toast.POSITION.TOP_CENTER,
          });
          history(
            `${process.env.PUBLIC_URL}/${menueRedirect[session.tokens.idToken.payload["cognito:groups"][0]]
            }`
          );
        } else {
          localStorage.setItem("authenticated", true);
          toast.success("Successfully Logged In...", {
            position: toast.POSITION.TOP_CENTER,
          });
          history(
            `${process.env.PUBLIC_URL}/${menueRedirect[session.tokens.idToken.payload["cognito:groups"][0]]
            }`
          );
        }
      }
      else if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
        setStep(2);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  }

  async function changePassword(data) {
    try {
      setLoading(true);
      // console.log(loginUserDetail);
      // const { requiredAttributes } = loginUserDetail.challengeParam;
      console.log("formData", data);
      const loginCredentials = {
        username: data.username,
        password: data.newPassword,
      };
      const attributes = {
        name: data.username,
      };
      const newPassword2 = data.newPassword;
      const result2 = await confirmSignIn({
        challengeResponse: newPassword2,
        options: {
          userAttributes: attributes,
        },
      });
      console.log(result2);
      if (result2.isSignedIn) {


        // Auth.completeNewPassword(
        //   loginUserDetail, // the Cognito User Object
        //   data.newPassword,
        //   requiredAttributes
        // )
        //   .then(async (user) => {
        //     console.log(user);
        await client
          .graphql({
            query: queries.listUserDetails,
            variables: {
              filter: {
                username: {
                  eq: data.username,
                },
              },
              limit: 200000,
            },
            authMode: "API_KEY",
          })
          .then(async (userDetail) => {
            var userId = userDetail.data.listUserDetails.items;
            console.log(userId);
            await client.graphql({
              query: mutations.updateUserDetails,
              variables: {
                input: {
                  id: userId[0].id,
                  status: "Active",
                },
              },
              authMode: "API_KEY",
            });
            if (userId[0].companyDetailsId !== null) {
              await client.graphql({
                query: mutations.updateSPUserDetails,
                variables: {
                  input: {
                    id: userId[0].id,
                    status: "Active",
                  },
                },
                authMode: "API_KEY",
              });
            }
            setLoading(false);
            toast.success("Password Changed Successfully..", {
              position: toast.POSITION.TOP_CENTER,
            });
            window.location.href = `${process.env.PUBLIC_URL}/login`;
          })
          .catch((err) => {
            console.log(err);
          });
        // })
        // .catch((e) => {
        //   console.log(e);
        //   toast.error(e.message, {
        //     position: toast.POSITION.TOP_CENTER,
        //   });
        //   setLoading(false);
        // });

      }
    } catch (err) {
      toast.error("Error Changing The Password: " + err.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  }

  return (
    <Fragment>
      {step === 1 && (
        <Form
          className="theme-form login-form needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormHeader selected={selected} />
          <FormGroup>
            <Label>Username</Label>
            <InputGroup>
              <InputGroupText>
                <i className="icon-user"></i>
              </InputGroupText>
              <input
                className="form-control"
                type="text"
                id="username"
                name="username"
                {...register("username", { required: true })}
                placeholder="Username"
              />
            </InputGroup>
            <span className="invalid-span">
              {errors.username && "Please enter Username"}
            </span>
            <div className="invalid-feedback">{"Please enter Username"}</div>
          </FormGroup>
          <FormGroup>
            <Label>{Password}</Label>
            <InputGroup>
              <InputGroupText>
                <i className="icon-lock"></i>
              </InputGroupText>
              <input
                className="form-control"
                type={togglePassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                {...register("password", { required: true })}
              />
              <div
                className="show-hide"
                onClick={() => setTogglePassword(!togglePassword)}
              >
                <span className={togglePassword ? "" : "show"}></span>
              </div>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn-block",
                disabled: loading ? loading : loading,
              }}
            >
              {loading ? "LOADING..." : SignIn}
            </Btn>
          </FormGroup>
          <FormPassword />
          <SignInWith />
        </Form>
      )}
      {step === 2 && (
        <Form
          className="theme-form login-form needs-validation"
          noValidate=""
          onSubmit={handleSubmit(changePassword)}
        >
          <H2 attrH2={{ className: "mb-3 text-center" }}>Change Password</H2>
          <FormGroup>
            <Label>Username</Label>
            <div className="input-group">
              <span className="input-group-text">
                <i className="icon-lock"></i>
              </span>
              <input
                className="form-control"
                type="text"
                name="username"
                {...register("username", { required: true })}
                placeholder="Confirmation username"
              />
            </div>
            <span className="invalid-span">
              {errors.username && "Please enter username"}
            </span>
            <div className="invalid-feedback">{"Please enter username"}</div>
          </FormGroup>
          <FormGroup>
            <Label>New Password</Label>
            <InputGroup>
              <InputGroupText>
                <i className="icon-lock"></i>
              </InputGroupText>
              <input
                className="form-control"
                type={togglePassword ? "text" : "password"}
                placeholder="New Password"
                name="newPassword"
                {...register("newPassword", { required: true })}
              />
              <div
                className="show-hide"
                onClick={() => setTogglePassword(!togglePassword)}
              >
                <span className={togglePassword ? "" : "show"}></span>
              </div>
            </InputGroup>
            <span className="invalid-span">
              {errors.newPassword && "Please enter New Password"}
            </span>
            <div className="invalid-feedback">
              {"Please enter New Password"}
            </div>
            <p>
              Password should atleast have 1 Capital Letter, 1 Number, 1 Special
              character and minimum 8 characters length
            </p>
          </FormGroup>
          <FormGroup>
            <Btn
              attrBtn={{
                color: "primary",
                disabled: loading ? loading : loading,
              }}
            >
              {loading ? "LOADING..." : "Change Password"}
            </Btn>
          </FormGroup>
        </Form>
      )}
      {aoactive && (
        <Form
          className="theme-form login-form needs-validation"
          noValidate=""
        //onSubmit={handleSubmit(submitMFA)}
        >
          <Row>
            <Col sm="12">
              <p className="errorSubscription">
                Sorry!.. Your Account has been suspended please contact admin
                "admin@knowyourasset.com.au".
              </p>
            </Col>
          </Row>
        </Form>
      )}
    </Fragment>
  );
};

export default LoginTab;
