import { Btn, H4, p, UL, LI } from "../../AbstractElements";
import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Container,
  Col,
  Form,
  CardHeader,
  CardFooter,
  Label,
  Card,
  Table,
  Row,
  Modal,
  Input,
  ModalBody,
  ModalHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { useForm } from "react-hook-form";
import awsExports from "../../aws-exports";
import uuid from "react-native-uuid";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  S3_BUCKET,
  REGION,
  ASSET_OWNER_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
  USERPOOLID,
  limit,
} from "../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const AddAssetOwnerContain = (props) => {
  const navigate = useNavigate();
  const { view } = useParams();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [ProfilePic, setProfilePic] = useState([]);
  const [companyNames, setcompanyNames] = useState([]);
  const [sites, setsites] = useState([]);
  const [selectedSites, setselectedSites] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [AssetOwnerDetailsList, setAssetOwnerDetailsList] = useState([]);
  const [downloadedFile, setdownloadedFile] = useState(null);
  const [logo, setlogo] = useState([]);
  const [selectedImg, setselectedImg] = useState(null);
  const [CompanyId, setCompanyId] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [siteSelected, setsiteSelected] = useState([]);
  const [getSites, setgetSites] = useState([]);
  const [filteredSites, setfilteredSites] = useState([]);
  const siteArray = [];
  const [sitesAO, setsitesAO] = useState([]);
  //const [limit, setLimit] = useState(100000);
  let selectedSitesData = [];

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const ref = useRef();

  //----------------------------------------------------

  const handleChange = (e) => {
    console.log("company Selected!!");
    console.log(e.target.value);
    setCompanyId(e.target.value);
    fetchcompanyNames();
    const companyNames1 = companyNames.filter((item) => {
      return item.id === e.target.value;
    });
    setCompanyName(companyNames1[0].companyName);
    // setselectedSites([]);
    fetchSites(e.target.value);
  };

  //---------------------------------------------------------

  async function fetchSites(compid) {
    //--
    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.listSiteDetails,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listSiteDetails.items.length > 0) {
      let assetDatas = result.data.listSiteDetails.items;
      nextToken = result.data.listSiteDetails.nextToken;
      console.log(
        "first token count",
        result.data.listSiteDetails.items.length
      );
      console.log("nextToken 1", nextToken);
      if (result.data.listSiteDetails.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.listSiteDetails,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listSiteDetails.items.length > 0) {
          nextToken = results.data.listSiteDetails.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listSiteDetails.items.length);
          if (results.data.listSiteDetails.items.length > 0) {
            assets.push(results.data.listSiteDetails.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        const sites = mergeAssets;
        console.log(sites);
        setsites(
          sites.filter((item) => {
            return item.companydetailsID === compid;
          })
        );
        if (id) {
          setselectedSites(selectedSitesData);
        }
        console.log("site filter.....");
        console.log(sites);
      }
    }
  }

  useEffect(() => {
    if (id) {
      async function fetchSitesAO() {
        const dataDetails = await client.graphql({
          query: queries.listSiteDetails,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (dataDetails.data.listSiteDetails.items.length > 0) {
          const sites = dataDetails.data.listSiteDetails.items;
          console.log(sites);
          setsitesAO(
            sites.filter((item) => {
              return item.assetownerID === id;
            })
          );
          console.log(id);
          const filterSites = sites.filter((item) => {
            return item.assetownerID === id;
          });
          console.log(filterSites);
        }
      }
      fetchSitesAO();
    }
  }, [id]);
  //---------------------------------------------------------------------
  const getCompanyName = (id) => {
    const companyNames1 = companyNames.filter((item) => {
      return item.id === id;
    });
    if (companyNames1.length > 0) {
      return companyNames1[0].companyName;
    } else {
      return "";
    }
  };
  //------------------------------------------------------------------------
  const getImage = (filename) => {
    var params = {
      Bucket: S3_BUCKET,
      Key: `public/${ASSET_OWNER_FOLDER}/${filename}`,
    };
    var promise = myBucket.getSignedUrlPromise("getObject", params);
    promise.then(
      function (url) {
        setdownloadedFile(url);
        setselectedImg(url);
      },
      function (err) {
        console.log(err);
      }
    );
  };
  //-------------------------------------------------------------------------
  function getfilename(path) {
    try {
      const files = JSON.parse(path);
      if (files.length > 0) {
        var newfilename = files[0].fileName;
        getImage(newfilename);
      }
    } catch (error) {
      console.log("Error parsing JSON input:", error);
    }
  }
  //---------------------------------------------------------------------------------

  const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
    apiVersion: "2022-10-03",
    region: REGION,
  });

  async function onSubmit(formData, e) {
    console.log(ProfilePic);
    console.log(AssetOwnerDetailsList.profilePicture);
    if (id) {
      await client
        .graphql({
          query: mutations.updateAssetOwner,
          variables: {
            limit: limit,
            input: {
              id: id,
              companyName: CompanyName
                ? CompanyName
                : AssetOwnerDetailsList.companyName,
              email: formData.email,
              firstName: formData.firstName,
              lastName: formData.lastName,
              mobile: formData.mobile,
              sites: siteSelected ? siteSelected : AssetOwnerDetailsList.sites,
              profilePicture:
                ProfilePic !== null && ProfilePic.length > 0
                  ? ProfilePic
                  : AssetOwnerDetailsList.profilePicture,
              companydetailsID: CompanyId
                ? CompanyId
                : AssetOwnerDetailsList.companydetailsID,
            },
          },
          authMode: "API_KEY",
        })
        .then((response) => {
          console.log(response);
          e.target.reset();
          toast.success(" Updated Succesfully!..", {
            position: toast.POSITION.TOP_CENTER,
          });
          navigate(`${process.env.PUBLIC_URL}/assetOwner`);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error while Updating ", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      //create cognito user
      let userSub = "";
      let userEmail = "";
      try {
        const cognitoParams = {
          UserPoolId: USERPOOLID,
          Username: formData.firstName,
          UserAttributes: [
            {
              Name: "email",
              Value: formData.email,
            },
          ],
          DesiredDeliveryMediums: ["EMAIL"],
          TemporaryPassword: formData.firstName + "Z@123456789",
          ForceAliasCreation: false,
        };
        console.log(cognitoParams);
        let response = await COGNITO_CLIENT.adminCreateUser(
          cognitoParams
        ).promise();
        console.log(JSON.stringify(response));
        const usersDetails = response.User.Attributes;
        usersDetails.forEach((item) => {
          if (item.Name === "sub") {
            userSub = item.Value;
          }
          if (item.Name === "email") {
            userEmail = item.Value;
          }
        });
        client.graphql({
          query: mutations.createUserDetails,
          variables: {
            input: {
              id: userSub,
              username: formData.firstName.toLowerCase(),
              email: userEmail,
              status: "Active",
            },
          },
          authMode: "API_KEY",
        });
        // Verify the user's email
        const emailVerificationParams = {
          UserPoolId: USERPOOLID,
          Username: formData.firstName,
          UserAttributes: [
            {
              Name: "email_verified",
              Value: "true",
            },
          ],
        };

        let emailVerificationResponse =
          await COGNITO_CLIENT.adminUpdateUserAttributes(
            emailVerificationParams
          ).promise();
        console.log(emailVerificationResponse);
        var params = {
          GroupName: "site_owner" /* required */,
          UserPoolId: USERPOOLID /* required */,
          Username: formData.firstName /* required */,
        };
        let groupResponse = await COGNITO_CLIENT.adminAddUserToGroup(
          params
        ).promise();
        console.log(groupResponse);

        await client
          .graphql({
            query: mutations.createAssetOwner,
            variables: {
              limit: limit,
              input: {
                id: userSub,
                companyName: CompanyName,
                email: formData.email,
                firstName: formData.firstName,
                lastName: formData.lastName,
                mobile: formData.mobile,
                sites: siteSelected,
                sites: formData.sitesoftheAO,
                profilePicture: ProfilePic,
                status: "Active",
                companydetailsID: CompanyId,
              },
            },
            authMode: "API_KEY",
          })
          .then((response) => {
            e.target.reset();
            toast.success("Asset Owner Added Succesfully!..", {
              position: toast.POSITION.TOP_CENTER,
            });
            console.log(CompanyId);
            const aoId = response.data.createAssetOwner.id;
            console.log(aoId);
            navigate(`${process.env.PUBLIC_URL}/site/addSite`, {
              state: {
                companyId: CompanyId,
                assetOwnerId: aoId,
                page: "assetOwner",
              },
            });
          })
          .catch((error) => {
            console.log(error);
            toast.error("Error while Adding.. Asset Owner", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      } catch (err) {
        console.log(err);
        toast.error(err.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  }

  //----------------------------------------------------------------------------
  const onSelectFile = async (e) => {
    const file = e.target.files[0];
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `public/${ASSET_OWNER_FOLDER}/${file.name}`,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(evt);
        const filedetails = [];
        filedetails.push({ fileName: file.name, type: file.type });
        setProfilePic(JSON.stringify(filedetails));
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };

  async function fetchcompanyNames() {
    const dataDetails = await client.graphql({
      query: queries.listCompanyDetails,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (dataDetails.data.listCompanyDetails.items.length > 0) {
      const companyNames = dataDetails.data.listCompanyDetails.items;
      console.log("non sp company  details...");
      console.log(companyNames);
      let filteredArray = [];
      filteredArray = companyNames.filter(function (item, pos) {
        return companyNames.indexOf(item) == pos;
      });
      const dataDetailsSP = await client.graphql({
        query: queries.listSPCompanyDetails,
        variables: {
          limit: limit,
        },
        authMode: "API_KEY",
      });
      if (dataDetailsSP.data.listSPCompanyDetails.items.length > 0) {
        const companyNamesSP = dataDetailsSP.data.listSPCompanyDetails.items;
        console.log("SP company  details...");
        console.log(companyNamesSP);
        let filteredArraySP = [];
        filteredArraySP = companyNamesSP.filter(function (item, pos) {
          return companyNamesSP.indexOf(item) == pos;
        });
        let CompanyNamesList = [];
        // CompanyNamesList.push(...filteredArraySP);
        CompanyNamesList.push(...filteredArray);
        // setcompanyNames(CompanyNamesList);
        setcompanyNames(
          CompanyNamesList.filter(function (item, pos) {
            return CompanyNamesList.indexOf(item) == pos;
          })
        );
        if (id) {
          fetchSites(AssetOwnerDetailsList.companydetailsID);
        }
      }
    }
  }

  //-----------------------------------------------------------------------------------------------
  useEffect(() => {
    fetchcompanyNames();

    if (id) {
      async function fetchAssetOwnerDetailsData() {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const assetOwnerId = userDetails.assetOwnerId;
        const dataDetails = await client.graphql({
          query: queries.listAssetOwners,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (dataDetails.data.listAssetOwners.items.length > 0) {
          const assetOwnerDetails =
            localStorage.getItem("groups") === "site_owner"
              ? dataDetails.data.listAssetOwners.items.filter(
                  (item) => item.id === assetOwnerId && !item._deleted
                )
              : dataDetails.data.listAssetOwners.items.filter(
                  (item) => !item._deleted && item.id === id
                );
          console.log(assetOwnerDetails);
          setAssetOwnerDetailsList({ ...assetOwnerDetails[0] });
          reset({ ...assetOwnerDetails[0] });

          fetchSites(assetOwnerDetails[0].companyName);
          console.log(sites);
          setPermissions(JSON.parse(assetOwnerDetails[0].permission));
          getfilename(assetOwnerDetails[0].profilePicture);
          selectedSitesData =
            assetOwnerDetails[0].sites && assetOwnerDetails[0].sites.length > 0
              ? JSON.parse(assetOwnerDetails[0].sites)
              : [];
          console.log(selectedSitesData);
        }
      }
      fetchAssetOwnerDetailsData();
    }
  }, [id, reset]);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <Row>
                <CardHeader>
                  <H4>
                    {" "}
                    {view
                      ? "View Asset Owner"
                      : id
                      ? " Edit Asset Owner Details"
                      : "Add Asset Owner"}
                  </H4>
                  <Row>
                    <Col md="6">
                      {view && (
                        <Link float="right" to={`/viewAssetOwnerdetails/${id}`}>
                          <i className="fa fa-edit "></i> Edit{" "}
                        </Link>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col md="8 mb-3">
                      <Label>Company</Label>
                      {view ? (
                        <p>{AssetOwnerDetailsList?.companyName}</p>
                      ) : (
                        <select
                          className="form-control"
                          name="companyName"
                          placeholder="Choose Company"
                          {...register("companyName", { required: true })}
                          onChange={handleChange}
                        >
                          <option value="">Choose Company Name</option>
                          <option value={AssetOwnerDetailsList.companyName}>
                            {AssetOwnerDetailsList.companyName}
                          </option>
                          {companyNames.map((item) => (
                            <option value={item.id}>{item.companyName}</option>
                          ))}
                        </select>
                      )}
                      <span style={{ color: "red" }}>
                        {errors.companyName && "Company is required"}{" "}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label>First Name</Label>
                      {view ? (
                        <p>{AssetOwnerDetailsList?.firstName}</p>
                      ) : (
                        <input
                          className="form-control"
                          name="firstName"
                          type="text"
                          placeholder="First Name"
                          {...register("firstName", { required: true })}
                        />
                      )}
                      <span>
                        {errors.firstName && "First name is required"}
                      </span>
                      <div className="invalid-feedback">
                        {"First name is required."}
                      </div>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Last Name</Label>
                      {view ? (
                        <p>{AssetOwnerDetailsList?.lastName}</p>
                      ) : (
                        <input
                          className="form-control"
                          name="lastName"
                          type="text"
                          placeholder="Last Name"
                          {...register("lastName", { required: true })}
                        />
                      )}
                      <span>{errors.lastName && "Last name is required"}</span>
                      <div className="invalid-feedback">
                        {"Last name is required."}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Email</Label>
                      {view ? (
                        <p>{AssetOwnerDetailsList?.email}</p>
                      ) : (
                        <input
                          className="form-control"
                          name="email"
                          type="email"
                          placeholder="Email"
                          {...register("email", { required: true })}
                        />
                      )}
                      <span>
                        {errors.email && "Please provide a valid email."}
                      </span>
                      <div className="invalid-feedback">
                        {"Please provide a valid email."}
                      </div>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Mobile</Label>
                      {view ? (
                        <p>{AssetOwnerDetailsList?.mobile}</p>
                      ) : (
                        <input
                          className="form-control"
                          name="mobile"
                          type="text"
                          maxLength="10"
                          placeholder="Mobile"
                          {...register("mobile", { required: true })}
                        />
                      )}
                      <span>
                        {errors.mobile && "Please provide a valid mobile."}
                      </span>
                      <div className="invalid-feedback">
                        {"Please provide a valid mobile."}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col md="6 mb-3">
                      <Label>Sites</Label>
                      {view ? (
                        <UL>
                          {selectedSites?.map((item) => (
                            <LI>{item.unitNumber}</LI>
                          ))}
                        </UL>
                      ) : id ? (
                        <Typeahead
                          id="basic-typeahead"
                          labelKey="unitNumber"
                          multiple
                          options={sites}
                          placeholder="Choose Sites"
                          selected={
                            selectedSites
                              ? selectedSites.map((site) => site.unitNumber)
                              : []
                          }
                          onChange={(d) => setsiteSelected(JSON.stringify(d))}
                        />
                      ) : (
                        <Typeahead
                          id="basic-typeahead"
                          labelKey="unitNumber"
                          multiple
                          options={sites}
                          placeholder="Choose Sites"
                          //defaultSelected= {selectedSites}
                          onChange={(d) => setsiteSelected(JSON.stringify(d))}
                        />
                      )}
                    </Col> */}

                    {/* <Row>

                              <>
                                  <Typeahead {...register('sitesoftheAO', { required: false })}
                                    defaultSelected={sites.slice(0, 4)}
                                    id="public-methods-example"
                                    name="sitesoftheAO"
                                    labelKey="unitNumber"
                                    multiple
                                    options={sites}
                                    placeholder="Choose a state..."
                                    ref={ref}
                                  />
                                  <ButtonToolbar style={{ marginTop: '10px' }}>
                                    <Button onClick={() => ref.current?.clear()}>Clear</Button>
                                    <Button onClick={() => ref.current?.focus()}>Focus</Button>
                                    <Button
                                      onClick={() => {
                                        ref.current?.focus();
                                        setTimeout(() => ref.current?.blur(), 1000);
                                      }}>
                                      Focus, then blur after 1 second
                                    </Button>
                                    <Button onClick={() => ref.current?.toggleMenu()}>Toggle Menu</Button>
                                  </ButtonToolbar>
                                </>
                                </Row> 

                    <Col md="6 mb-3">
                      <Label>Permissions</Label>

                      <UL>
                        {permissions?.map((item) => (
                          <LI>{item}</LI>
                        ))}
                      </UL>
                    </Col>
                    */}
                    <Col md="6" mb-3>
                      {(view || id) && (
                        <>
                          <Label>Site</Label>
                          <UL>
                            {sitesAO?.map((item) => (
                              <LI>{item.siteName}</LI>
                            ))}
                          </UL>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Profile Picture</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      {!view && (
                        <Input
                          type="file"
                          className="form-control"
                          name="profilePic"
                          onChange={onSelectFile}
                        />
                      )}
                    </Col>{" "}
                  </Row>
                  <br></br>
                  <Row>
                    <Col md="6 mb-3">
                      {/* { getfilename(AssetOwnerDetailsList.profilePicture) } */}
                      {downloadedFile ? (
                        <img src={downloadedFile} width="50px" height="50px" />
                      ) : (
                        "No Profile Pic"
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      {!view && (
                        <Btn attrBtn={{ color: "primary" }}>
                          {" "}
                          {!id ? "Submit" : "Update"}
                        </Btn>
                      )}{" "}
                    </Col>
                    <Col md="4">
                      <Link to={`/assetOwner`}>
                        <Button variant="danger">
                          {view ? "Close" : "Cancel"}
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                  <Row></Row>
                </Form>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddAssetOwnerContain;
