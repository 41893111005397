import React, { Fragment, useEffect, useState, useContext } from "react";
import { LogIn } from "react-feather";
import { Media } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Image, LI, UL } from "../../AbstractElements";
import UserImg from "../../assets/images/avtar/man.png";
import { toast } from "react-toastify";
import { LogOut } from "../../Constant";
import * as queries from "../../graphql/queries";
import { SiteContext } from "../../App";
import { aocontext } from "../../App";
import { SiteDetailsContext } from "../../App";
import * as mutations from "../../graphql/mutations";
import Select from "react-select";
import { signOut } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const HeaderContain = () => {
  // eslint-disable-next-line
  // const { user, signOut } = useAuthenticator([window.user]);
  const [siteDetails, setSiteDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const history = useNavigate();
  const [profile, setProfile] = useState("");
  const [admin, setAdmin] = useState("");
  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const { selectedAssetOwnerGlobal, handleAssetOwner } = useContext(aocontext);
  const { siteDetailsGlobal, handleSiteDetailsChange } =
    useContext(SiteDetailsContext);
  //const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserGroup = localStorage.getItem("groups");
  const [subscriptionsplansData, setSubscriptionsplansData] = useState([]);
  const [assetsCountOftheAO, setAssetsCountOftheAO] = useState(0);
  const [siteCountOftheAO, setsiteCountOftheAO] = useState(0);
  const [siteUserCountOftheAO, setsiteUserCountOftheAO] = useState(0);
  const [spUserCountOftheAO, setspUserCountOftheAO] = useState(0);

  const [siteListVersion, setSiteListVersion] = useState(0);
  const [assetOwner, setAssetOwner] = useState([]);

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL") || UserImg);
    let user = JSON.parse(localStorage.getItem("userDetails"));
    client
      .graphql({
        query: queries.getSPUserDetails,
        variables: {
          id: user.id,
        },
        authMode: "API_KEY",
      })
      .then((result) => {
        let dataDetails = result.data.getSPUserDetails;
        console.log(dataDetails);
        setAdmin(dataDetails.SPGroup);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleSiteFilterChange = (event) => {
    const siteId = event.value;
    console.log("handleSiteChange", siteId);
    handleSiteChange(siteId);
  };

  const handleAssetOwnerFilter = (event) => {
    const siteId = event.value;
    //console.log(siteId)
    handleAssetOwner(siteId);
  };

  //const history = useNavigate();
  const menueRedirect = {
    site_owner: "dashboards",
    electrician: "dashboard/default",
    site_user: "dashboard/default",
    admin: "dashboards",
  };

  async function signOut() {
    try {
      await signOut();
      localStorage.removeItem("categoryAssetCount");
      localStorage.removeItem("spuser");
      localStorage.removeItem("spCompany");
      localStorage.clear();
      localStorage.setItem("authenticated", false);
      window.location.reload();
      history(`${process.env.PUBLIC_URL}/login`);
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  // site filter ---Admin
  async function fetchSiteDetails() {
    await client
      .graphql({
        query: queries.listSiteDetails,
        variables: {
          limit: 10000,
        },
        authMode: "API_KEY",
      })
      .then((response1) => {
        setSiteDetails(
          response1.data.listSiteDetails.items.filter(
            (item) => item.status === "Active"
          )
        );
        handleSiteDetailsChange(
          response1.data.listSiteDetails.items.filter(
            (item) => item.status === "Active"
          )
        );
        setSiteListVersion((prevVersion) => prevVersion + 1);
      })
      .catch(() => {
        setErrorMessage("Unable to fetch the list");
      });
  }

  // --- asset owner sites ---
  async function fetchSiteDetailsAo(aoID) {
    await client
      .graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: aoID,
          limit: 10000,
        },
        authMode: "API_KEY",
      })
      .then((response1) => {
        //console.log(response1)
        setSiteDetails(
          response1.data.siteDetailsByAssetownerID.items.filter(
            (item) => item.status === "Active"
          )
        );
        handleSiteDetailsChange(
          response1.data.siteDetailsByAssetownerID.items.filter(
            (item) => item.status === "Active"
          )
        );
        setSiteListVersion((prevVersion) => prevVersion + 1);
      })
      .catch(() => {
        setErrorMessage("Unable to fetch the list");
      });
  }

  //---- site user sites ---
  async function fetchSiteDetailsSU() {
    await client
      .graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: userDetails.id,
          limit: 10000,
        },
      })
      .then((dataDetails) => {
        const siteDetails =
          dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
        if (siteDetails.length > 0) {
          client
            .graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: 10000,
              },
            })
            .then((result) => {
              const sites = result.data.listSiteDetails.items.filter((item) => {
                const _matchingAssetTemplate = siteDetails?.find(
                  (site) => site.sitedetailsID === item?.id
                );
                if (_matchingAssetTemplate) {
                  return item;
                }
              });
              let userFilterArray = [];

              if (sites.length > 0) {
                sites.map((item) => {
                  userFilterArray.push(item.assetownerID);
                });
                //console.log(userFilterArray);
                client
                  .graphql({
                    query: queries.listAssetOwners,
                    variables: {
                      limit: 10000,
                    },
                  })
                  .then((result1) => {
                    //console.log(result1);
                    const userao = result1.data.listAssetOwners.items.filter(
                      (item) => {
                        const _matchSites = userFilterArray?.find(
                          (id) => id === item?.id
                        );
                        if (_matchSites) {
                          return item;
                        }
                      }
                    );
                    //console.log(userao);
                    setAssetOwner(userao);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              setSiteDetails(sites.filter((item) => item.status === "Active"));
              handleSiteDetailsChange(
                sites.filter((item) => item.status === "Active")
              );
              setSiteListVersion((prevVersion) => prevVersion + 1);
            })
            .catch((error2) => {
              console.log(error2);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    //console.log(LoginUserGroup)
    try {
      if (localStorage.getItem("site") === null && siteDetails.length === 0) {
        LoginUserGroup === "admin"
          ? fetchSiteDetails()
          : LoginUserGroup === "site_user" || LoginUserGroup === "electrician"
          ? fetchSiteDetailsSU()
          : fetchSiteDetailsAo(userDetails.id);
      } else {
        let activeSites = JSON.parse(
          base64.decode(localStorage.getItem("site"))
        );
        setSiteDetails(activeSites.filter((item) => item.status === "Active"));
      }
    } catch (error) {
      console.log("header - ", error);
    }

    if (LoginUserGroup === "site_owner") {
      if (subscriptionsplansData.length === 0) fetchSubscriptionList();
      if (siteUserCountOftheAO === 0) fetchSiteUsersCountSiteUserDetails();
      if (spUserCountOftheAO === 0) fetchSiteUsersCountSPUserDetails();
      if (assetsCountOftheAO === 0) fetchSiteDetailsAoCount();
    }
  }, [siteListVersion]);

  // subcription ----
  async function fetchSubscriptionList() {
    await client
      .graphql({
        query: queries.listSubscriptionLimits,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
        },
      })
      .then((response) => {
        let setdata = [];
        let plansData = [];
        setdata = response.data.listSubscriptionLimits.items;
        // setsubscriptiondata(response.data.listSubscriptionLimits.items);
        setdata.map((item) => {
          plansData.push({
            id: item.subscriptionId,
            prize: "$" + item.Price,
            title: item.SubscriptionName,
            month: "/month",
            storage1: item.AssetLimit,
            props1: "Assets",
            // storage2: item.SiteLimit,
            storage2: "Unlimited",
            props2: "Sites",
            storage3: item.SiteUserLimit,
            props3: "Users",
            // storage4: item.SPUserLimit,
            // props4: 'SP Users'
            // storage5: '15',
            // props5: 'Domains',
          });
        });
        setSubscriptionsplansData(plansData);
        //console.log("data of the subscription list--");
        //console.log(setdata);
      })
      .catch(() => {
        console.log("Error i Fetching Subscription Details");
      });
  }

  function getPlanName(id) {
    const db = subscriptionsplansData.find((item) => item.id === id);
    return db ? db.title : "";
  }

  // --- assets count of Ao ---
  async function fetchSiteDetailsAoCount() {
    await client
      .graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: userDetails.id,
          limit: 10000,
        },
        authMode: "API_KEY",
      })
      .then((result) => {
        const sites = result.data.siteDetailsByAssetownerID.items;
        if (sites.length > 0) {
          const siteFilterArray = [];
          setSiteDetails(result.data.siteDetailsByAssetownerID.items);
          handleSiteDetailsChange(
            result.data.siteDetailsByAssetownerID.items.filter(
              (item) => item.status === "Active"
            )
          );
          setsiteCountOftheAO(
            result.data.siteDetailsByAssetownerID.items.length
          );
          sites.map(async (item) => {
            siteFilterArray.push({
              siteId: {
                eq: item.id,
              },
            });
          });
          client
            .graphql({
              query: queries.listAssets,
              variables: {
                filter: {
                  or: siteFilterArray,
                },
                limit: 10000,
              },
            })
            .then((result) => {
              //console.log(result);
              setAssetsCountOftheAO(result.data.listAssets.items.length);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setAssetsCountOftheAO(0);
        }
      });
  }

  async function fetchSiteUsersCountSiteUserDetails() {
    await client
      .graphql({
        query: queries.listSPUserDetails,
        variables: {
          filter: {
            invitedBy: {
              eq: userDetails.id,
            },
          },
          limit: 10000,
        },
        authMode: "API_KEY",
      })
      .then((result) => {
        let dataDetails = result.data.listSPUserDetails.items.filter(
          (item) => item.userGroup === "site_user"
        );
        setsiteUserCountOftheAO(dataDetails.length);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchSiteUsersCountSPUserDetails() {
    await client
      .graphql({
        query: queries.listSPUserDetails,
        variables: {
          filter: {
            invitedBy: {
              eq: userDetails.id,
            },
          },
          limit: 10000,
        },
        authMode: "API_KEY",
      })
      .then((result) => {
        let dataDetails = result.data.listSPUserDetails.items.filter(
          (item) => item.userGroup === "electrician"
        );
        setspUserCountOftheAO(dataDetails.length);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function onSubmit(data) {
    try {
      const bilId = data;
      console.log(data);
      const updateUser = await client.graphql({
        query: mutations.updateUserDetails,
        variables: { id: userDetails.id, paymentId: bilId },
      });
      console.log(updateUser);
      toast.success(
        "Plan " + getPlanName(data) + " has been choosen Succesfully!..",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      client
        .graphql({
          query: queries.getUserDetails,
          variables: {
            id: userDetails.id,
          },
        })
        .then((userDetail) => {
          localStorage.setItem(
            "userDetails",
            JSON.stringify(userDetail.data.getUserDetails)
          );
          history(
            `${process.env.PUBLIC_URL}/${
              menueRedirect[
                userDetail.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ][0]
              ]
            }`
          );
          userDetails = JSON.parse(localStorage.getItem("userDetails"));
          fetchSiteDetailsAoCount();
          fetchSiteUsersCountSPUserDetails();
          fetchSiteUsersCountSiteUserDetails();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  const options = [
    { value: "", label: "All Sites" },
    ...siteDetails.map((item) => ({
      value: item.id,
      label:
        item.siteName +
        " " +
        "(" +
        (item.unitNumber ? item.unitNumber : "") +
        ")",
    })),
  ];

  const assetowner = [
    { value: "", label: "Select Asset Owners" },
    ...assetOwner.map((item) => ({
      value: item.id,
      label: item.firstName + " " + item.lastName,
    })),
  ];

  return (
    <Fragment>
      <div className="nav-right col-10 col-sm-6 pull-right right-header p-0 dash-76">
        <UL attrUL={{ className: `simple-list flex-row nav-menus gap-3` }}>
          {/* <Notification /> */}
          {/*
          <select
            className="form-select me-2"
            value={selectedSiteGlobal}
            onChange={handleSiteFilterChange}
          >
            <option value="">All Sites</option>
            {siteDetails.map((site, index) => (
              <option value={site.id} key={index}>
                {(site?.siteName || "") + " (" + (site?.unitNumber || "") + ")"}
              </option>
            ))}
          </select>
          */}
          {LoginUserGroup === "electrician" && (
            <Select
              onChange={handleAssetOwnerFilter}
              options={assetowner}
              isSearchable={true}
              menuPlacement="auto"
              menuPosition="fixed"
            />
          )}
          <Select
            onChange={handleSiteFilterChange}
            options={options}
            isSearchable={true}
            menuPlacement="auto"
            menuPosition="fixed"
          />
          {LoginUserGroup !== "admin" &&
            LoginUserGroup !== "site_user" &&
            LoginUserGroup !== "electrician" && (
              <select
                className="form-select"
                onChange={(e) => onSubmit(e.target.value)}
              >
                <option value="">Subscription</option>
                {subscriptionsplansData.map((item, index) => (
                  <option
                    value={item.id}
                    key={index}
                    disabled={
                      assetsCountOftheAO >= parseInt(item.storage1) ||
                      siteCountOftheAO >= parseInt(item.storage2) ||
                      siteUserCountOftheAO >= parseInt(item.storage3) ||
                      spUserCountOftheAO >= parseInt(item.storage4)
                    }
                  >
                    {item.title}
                  </option>
                ))}
              </select>
            )}
          <LI
            attrLI={{
              className: "profile-nav onhover-dropdown pe-0 pt-0 me-0",
            }}
          >
            <Media className="profile-media">
              <Image
                attrImage={{
                  className: "rounded-circle",
                  src: `${authenticated ? profile : profile}`,
                  alt: "",
                }}
              />
              <Media body>
                <span>
                  {authenticated ? userDetails.username : ""}
                  {admin === "admin" ? "(admin)" : ""}
                </span>
              </Media>
            </Media>
            <UL
              attrUL={{
                className: `simple-list profile-dropdown onhover-show-div`,
              }}
            >
              {/* <LI><Link to={`${process.env.PUBLIC_URL}/users/userprofile`}><i><User /></i><span>{Account} </span></Link></LI>
              <LI><Link to={`${process.env.PUBLIC_URL}/email/mailbox`}><i><Mail /></i><span>{Inbox}</span></Link></LI>
              <LI><Link to={`${process.env.PUBLIC_URL}/kanbanboard`}><i><FileText /></i><span>{Taskboard}</span></Link></LI>
              <LI><Link to={`${process.env.PUBLIC_URL}/users/useredit`}><i><Settings /></i><span>Settings</span></Link></LI> */}
              <LI attrLI={{ onClick: signOut }}>
                <Link>
                  <LogIn />
                  <span>{LogOut}</span>
                </Link>
              </LI>
            </UL>
          </LI>
        </UL>
      </div>
      {/* <SiteListDetails selectedSite={selectedSite} /> */}
    </Fragment>
  );
};
export default HeaderContain;
