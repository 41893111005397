import React, { Fragment, useState, useEffect } from "react";
import { Btn, H5, H3, H6, LI, P, UL } from "../../AbstractElements";
import {
  Col,
  Container,
  Form,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  CardBody,
  Card,
  CardHeader,
  Media,
  Table,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  S3_BUCKET,
  REGION,
  CATEGORIES_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
} from "../../Config/Config";
import Papa from "papaparse";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import AWS from "aws-sdk";
import SampleTemplate from "./SampleTemplate";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const XLSX = require("xlsx");
AWS.config.update({ region: REGION });
const dynamoDb = new AWS.DynamoDB({
  region: REGION,
  accessKeyId: ACCESSKEYID,
  secretAccessKey: SECRETACCESSKEY,
  apiVersion: "2012-08-10",
});

const CategoryImport = (props) => {
  const [fileData, setFileData] = useState([]);
  const [fileHeader, setFileHeader] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableColumnLength, setTableColumnLength] = useState([]);
  const [serviceGroup, setSG] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleExport = (tblName) => {
    console.log(tblName);
    var elt = document.getElementById(tblName);
    var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
    return XLSX.writeFile(wb, tblName + ".xlsx");
  };

  useEffect(() => {
    const getServiceGroup = async () => {
      await client
        .graphql({
          query: queries.listServiceGroups,
          variables: {
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: 2000000,
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          setSG(result.data.listServiceGroups.items);
        })
        .catch((error) => {
          console.log(error);
          setSG([]);
        });
    };
    getServiceGroup();
  }, []);

  let categoryColumn = [
    "categoryName",
    "servicegroup",
    "description",
    "assetNumberPrefix",
  ];

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();

    if (extension === "csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          console.log(result);
          const rows = result.data.map((row) => Object.values(row));
          const headers = Object.keys(result.data[0]);
          var matchHeaderWithColumn = true;
          headers.map((item) => {
            if (!categoryColumn.includes(item)) {
              matchHeaderWithColumn = false;
            }
          });
          console.log(matchHeaderWithColumn);
          console.log(headers);
          console.log(rows);
          if (!matchHeaderWithColumn) {
            setFileData([]);
            setFileHeader([]);
            setTableColumnLength([]);
          } else {
            setFileData(rows);
            setFileHeader(headers);
            setTableColumnLength(headers);
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
    } else if (extension === "xls" || extension === "xlsx") {
      const reader = new FileReader();
      const jsonOpts = {
        header: 1,
        defval: null,
        raw: false,
        dateNF: "yyyy-m-d",
        blankrows: false,
      };
      reader.onload = (event) => {
        let data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        data = XLSX.utils.sheet_to_json(worksheet, jsonOpts);
        console.log(data);
        const headers = data.splice(0, 1)[0];
        var matchHeaderWithColumn = true;
        headers.map((item) => {
          if (!categoryColumn.includes(item)) {
            matchHeaderWithColumn = false;
          }
        });
        console.log(headers);
        console.log(matchHeaderWithColumn);
        if (!matchHeaderWithColumn) {
          setFileData([]);
          setFileHeader([]);
          setTableColumnLength([]);
          setErrorMessage(!errorMessage);
        } else {
          setFileData(data);
          setFileHeader(headers);
          setTableColumnLength(headers);
          if (errorMessage) {
            setErrorMessage(!errorMessage);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert("Unsupported file format");
    }
  };

  const handleSave = async () => {
    if (tableColumnLength.length === 0) {
      toast.error("Please choose to import the selected file.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const insetArray = [];
    tableColumnLength.map((val) => {
      insetArray.push({ col: val, data: val });
    });

    console.log(insetArray);

    if (fileData && fileData.length > 0) {
      let successCount = 0;
      let errorCount = 0;
      let dataCount = 0;
      fileData.map(async (row, i) => {
        dataCount = dataCount + 1;
        const columnDetails = {};
        await insetArray.forEach((itemData) => {
          if (itemData.col === "servicegroup") {
            var sg = serviceGroup.filter((item) => {
              return (
                item.serviceName === row[fileHeader.indexOf(itemData.data)]
              );
            });
            columnDetails["servicegroupID"] = sg[0].id;
          }
          if (itemData.col === "description") {
            columnDetails["formDescription"] =
              row[fileHeader.indexOf(itemData.data)];
          }
          columnDetails["status"] = "Active";
          columnDetails["formIds"] = "[]";
          if (
            itemData.col !== "servicegroup" &&
            itemData.col !== "description"
          ) {
            columnDetails[itemData.col] =
              row[fileHeader.indexOf(itemData.data)];
          }
        });
        console.log("columnDetails", columnDetails);
        await client
          .graphql({
            query: mutations.createCategories,
            variables: {
              input: columnDetails,
            },
            authMode: "API_KEY",
          })
          .then((result2) => {
            console.log("createCategories", result2);
            successCount = successCount + 1;
            if (fileData.length === successCount + errorCount) {
              if (errorCount > 0 && successCount > 0) {
                toast.error(
                  successCount +
                    " rows imported successfully, with " +
                    errorCount +
                    " rows failed to import.",
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
              }
              if (errorCount === 0 && successCount > 0) {
                toast.success(successCount + " rows imported successfully", {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
              if (errorCount > 0 && successCount === 0) {
                toast.error(
                  "Error while importing, please check the data and import again.",
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
              }
              setFileData([]);
              setFileHeader([]);
              setTableColumnLength([]);
              setTableColumns([]);
              props.nextStep();
            }
          })
          .catch((error) => {
            console.log(error);
            errorCount = errorCount + 1;
            if (fileData.length === successCount + errorCount) {
              if (errorCount > 0 && successCount > 0) {
                toast.error(
                  successCount +
                    " rows imported successfully, with " +
                    errorCount +
                    " rows failed to import.",
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
              }
              if (errorCount === 0 && successCount > 0) {
                toast.success(successCount + " rows imported successfully", {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
              if (errorCount > 0 && successCount === 0) {
                toast.error(
                  "Error while importing, please check the data and import again.",
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
              }
              setFileData([]);
              setFileHeader([]);
              setTableColumnLength([]);
              setTableColumns([]);
            }
          });
      });
    } else {
      toast.error("No Data To Save", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <CardHeader>
              <Row>
                <div className="u-pearl done col-3">
                  <span className="u-pearl-number">1</span>
                  <span className="u-pearl-title">Site Details Import</span>
                </div>
                <div className="u-pearl done col-3">
                  <span className="u-pearl-number">2</span>
                  <span className="u-pearl-title">
                    Distribution Board Import
                  </span>
                </div>
                <div className="u-pearl current col-3">
                  <span className="u-pearl-number">3</span>
                  <span className="u-pearl-title">Category Import</span>
                </div>
                <div className="u-pearl col-3">
                  <span className="u-pearl-number">4</span>
                  <span className="u-pearl-title">Logbooks Import</span>
                </div>
              </Row>
              <Row>
                <Col md="4">
                  <Btn
                    attrBtn={{
                      color: "info",
                      onClick: () => handleExport("categoryTable"),
                    }}
                  >
                    Download Category Import File Template
                  </Btn>
                  <Table
                    className="table-striped  table-de"
                    style={{ display: "none" }}
                    id="categoryTable"
                  >
                    <thead className="custom-table-head">
                      <tr>
                        <th>categoryName</th>
                        <th>servicegroup</th>
                        <th>description</th>
                        <th>assetNumberPrefix</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>test Cat</td>
                        <td>KYA SG</td>
                        <td>test cat import</td>
                        <td>tstCat</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <input
                    type="file"
                    className="form-control"
                    accept=".csv,.xls,.xlsx"
                    onChange={handleFileUpload}
                  />
                </Col>
                {fileData.length > 0 && (
                  <Col md="4">
                    <span>Total records in file - {fileData.length}.</span>
                  </Col>
                )}
                <Col md="4">
                  <Btn attrBtn={{ color: "primary", onClick: props.nextStep }}>
                    Skip
                  </Btn>
                  <Btn attrBtn={{ color: "primary", onClick: handleSave }}>
                    Import Category Details
                  </Btn>
                </Col>
              </Row>
              <Row>
                <Col>
                  {errorMessage && (
                    <span className="txt-danger">
                      Data Columns are not matching the sample import template.
                      Please check the sample import template by downloading.
                    </span>
                  )}
                </Col>
              </Row>
              {fileData.length > 0 && (
                <Row>
                  <Col md="12">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr key={"header"}>
                          {tableColumnLength.map((key) => (
                            <th>{key}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {fileData.map((item, i) => (
                          <tr key={i}>
                            {Object.values(item).map((val) => (
                              <td>{val}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default CategoryImport;
