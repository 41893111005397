import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Label, Card, CardBody } from "reactstrap";
import { Btn, H4 } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import { toast } from "react-toastify";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import SiteDBData from "../../../Data/DistributionBoard/index";
import SiteData from "../../../Data/Sites/index";
import { limit } from "../../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const AddEditSiteDetails = () => {
  const navigate = useNavigate();
  const state = useLocation();
  console.log(state);
  const { view } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();

  const [companyDetails, setcompanyDetails] = useState([]);
  const [assetOwner, setAssetOwner] = useState([]);
  const [assetOwnerFilter, setAssetOwnerFilter] = useState([]);
  const [siteAdressArray, setsiteAdressArray] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [siteDetail, setSiteDetail] = useState([]);
  const [distrubutionDetails, setDistributionDetails] = useState([]);
  const [address, setAddress] = useState([]);
  const [assetOwnerIds, setAssetOwnerId] = useState("");
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserGroup = localStorage.getItem("groups");
  const [siteLimit, setsiteLimit] = useState(0);
  const [siteLimitCountOftheAO, setsiteLimitCountOftheAO] = useState(0);
  const [AOSite, setAOSite] = useState([]);
  const [sitedupdata, setSitedupdata] = useState([]);
  const [edit, setEdit] = useState(false);
  const [sitesdup, setsitesdup] = useState(false);
  const [sitesdupdetails, setsitesdupdetails] = useState(false);
  //const [limit, setLimit] = useState(100000);

  const [catId, setCatId] = useState();
  const [maxNum, setmaxNum] = useState();

  async function fetchCompanies() {
    const result = await client.graphql({
      query: queries.listCompanyDetails,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listCompanyDetails.items.length > 0) {
      console.log(result);
      const companyDetails = result.data.listCompanyDetails.items;
      setcompanyDetails(companyDetails);
      console.log(companyDetails);
      const result1 = await client.graphql({
        query: queries.listAssetOwners,
        variables: {
          limit: limit,
        },
        authMode: "API_KEY",
      });
      if (result1.data.listAssetOwners.items.length > 0) {
        console.log(result1);
        const assetOwnerDetails = result1.data.listAssetOwners.items;
        setAssetOwner(assetOwnerDetails);
        setAssetOwnerFilter(assetOwnerDetails);
        if (state.state.page === "assetOwner") {
          const editDetails = {
            companyName: state.state.companyId,
            assetOwner: state.state.assetOwnerId,
          };
          reset({ ...editDetails });
        } else {
          fetchEditSiteDetail(state.state);
        }
      }
    }
  }
  async function fetchEditSiteDetail(siteId) {
    const result = await client.graphql({
      query: queries.getSiteDetails,
      variables: { id: siteId },
    });
    console.log(result);
    const siteDetails = result.data.getSiteDetails;
    console.log(siteDetails);
    if (siteDetails) {
      console.log("inside the loop");
      const result1 = await client.graphql({
        query: queries.distributionBoxesBySitedetailsID,
        variables: {
          sitedetailsID: siteId,
          limit: limit,
        },
      });
      console.log(result1);
      const dBDetails = result1.data.distributionBoxesBySitedetailsID.items;
      console.log(dBDetails);
      setDistributionDetails(dBDetails);
      setSiteDetail(siteDetails);
      setEditMode(true);
      const editDetails = {
        companyName: siteDetails.companydetailsID,
        assetOwner: siteDetails.assetownerID,
        siteAddress: siteDetails.unitNumber,
        siteName: siteDetails.siteName,
        // siteAddress:
        //   siteDetails.street +
        //   " " +
        //   siteDetails.suburb +
        //   " " +
        //   siteDetails.state +
        //   " " +
        //   siteDetails.country +
        //   " " +
        //   siteDetails.postCode,
        distributionBox:
          dBDetails && dBDetails.length > 0
            ? dBDetails[0].distributionBoxName
            : "",
        location:
          dBDetails && dBDetails.length > 0 ? dBDetails[0].location : "",
      };
      setAddress(siteDetails.unitNumber);
      reset({ ...editDetails });
    } else {
      console.log("outside the loop");
    }
  }
  useEffect(() => {
    SerialId();
    if (state.state !== null) {
      if (LoginUserGroup === "admin") {
        fetchCompanies();
      } else if (LoginUserGroup === "site_user") {
        fetchCompanyDetailsAOSu();
        fetchEditSiteDetail(state.state);
      } else {
        fetchCompanyDetailsAO();
        fetchEditSiteDetail(state.state);
      }
    } else {
      async function fetchCompanyDetails() {
        const dataDetails = await client.graphql({
          query: queries.listCompanyDetails,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (dataDetails.data.listCompanyDetails.items.length > 0) {
          const companyNames = dataDetails.data.listCompanyDetails.items;
          let filteredArray = [];
          filteredArray = companyNames.filter(function (item, pos) {
            return companyNames.indexOf(item) == pos && !item.deleted;
          });
          setcompanyDetails(
            filteredArray.filter(function (item, pos) {
              return filteredArray.indexOf(item) == pos;
            })
          );
        }
      }

      async function fetchAssetOwner() {
        const result = await client.graphql({
          query: queries.listAssetOwners,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (result.data.listAssetOwners.items.length > 0) {
          console.log(result);
          const assetOwnerDetails = result.data.listAssetOwners.items;
          setAssetOwner(assetOwnerDetails);
          setAssetOwnerFilter(assetOwnerDetails);
        }
      }

      if (LoginUserGroup === "admin") {
        fetchCompanyDetails();
        fetchAssetOwner();
      } else if (LoginUserGroup === "site_user") {
        fetchCompanyDetailsAOSu();
      } else {
        fetchCompanyDetailsAO();
      }
    }

    if (LoginUserGroup === "site_owner") {
      fetchSubscriptionLimit();
      fetchSiteOfAOLimit();
      async function fetchSubscriptionLimit() {
        const result = await client.graphql({
          query: queries.listSubscriptionLimits,
          variables: {
            filter: {
              subscriptionId: {
                eq: LoginUserDetails.paymentId,
              },
            },
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (result.data.listSubscriptionLimits.items.length > 0) {
          let siteLimitdata = result.data.listSubscriptionLimits.items;
          setsiteLimit(siteLimitdata[0].SiteLimit);
          console.log("asset limit of the AO in slimits");
          console.log(siteLimitdata);
          console.log(siteLimitdata[0]?.SiteLimit);
        }
      }

      async function fetchSiteOfAOLimit() {
        const result = await client.graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          setsiteLimitCountOftheAO(
            result.data.siteDetailsByAssetownerID.items.length
          );
        }
      }
    }

    async function SerialId() {
      const response = await client.graphql({
        query: queries.listDistributionBoxes,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      });
      if (response.data.listDistributionBoxes.items.length > 0) {
        const DistributionBoxesData = response.data.listDistributionBoxes.items;

        let idArry = [];
        let maxnumber = 0;

        DistributionBoxesData.map((item) => {
          if (
            item.distributionBoxNumber !== "" &&
            item.distributionBoxNumber !== null
          ) {
            if (
              isNaN(Number(item.distributionBoxNumber.replace(/^\D+/g, "")))
            ) {
              idArry.push(1);
            } else {
              idArry.push(
                Number(item.distributionBoxNumber.replace(/^\D+/g, ""))
              );
            }
          }
        });

        maxnumber = Math.max(...idArry);
        if (idArry.length > 0) {
          if (maxnumber !== 0 && maxnumber !== null) {
            const res = digits_count(Number(maxnumber + 1));
            if (res === 1) {
              setCatId("000");
              setmaxNum(maxnumber + 1);
            } else if (res === 2) {
              setCatId("00");
              setmaxNum(maxnumber + 1);
            } else if (res === 3) {
              setCatId("0");
              setmaxNum(maxnumber + 1);
            } else if (res === 4) {
              setCatId("");
              setmaxNum(maxnumber + 1);
            }
          } else {
            setCatId("000");
            setmaxNum(1);
          }
        } else {
          setCatId("000");
          setmaxNum(1);
        }
      }
    }
  }, []);

  const getCompanyName = (id) => {
    const company = companyDetails.filter((item) => {
      return item.id === id;
    });
    if (company.length > 0) {
      return company[0].companyName;
    } else {
      return "";
    }
  };

  const getAO = (id) => {
    const company = assetOwner.filter((item) => {
      return item.id === id;
    });
    if (company.length > 0) {
      return company[0].firstName + " " + company[0].lastName;
    } else {
      return "";
    }
  };

  const digits_count = (n) => {
    var count = 0;
    if (n >= 1) ++count;
    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }
    return count;
  };

  async function fetchCompanyDetailsAO() {
    const dataDetails = await client.graphql({
      query: queries.getAssetOwner,
      authMode: "API_KEY",
      variables: {
        id: LoginUserDetails.id,
        limit: limit,
      },
    });
    if (dataDetails.data.getAssetOwner !== null) {
      const AOcompanyNames = dataDetails.data.getAssetOwner;
      const company = [];
      company.push({
        id: AOcompanyNames.companydetailsID,
        companyName: AOcompanyNames.companyName,
      });
      console.log(company);
      setcompanyDetails(company);
      const assetOwner = [];
      assetOwner.push({
        id: AOcompanyNames.id,
        firstName: AOcompanyNames.firstName,
        lastName: AOcompanyNames.lastName,
        companydetailsID: AOcompanyNames.companydetailsID,
      });
      setAssetOwner(assetOwner);
      setAssetOwnerFilter(assetOwner);
    }
  }
  async function fetchCompanyDetailsAOSu() {
    const dataDetails = await client.graphql({
      query: queries.getAssetOwner,
      authMode: "API_KEY",
      variables: {
        id: LoginUserDetails.assetOwnerId,
        limit: limit,
      },
    });
    if (dataDetails.data.getAssetOwner !== null) {
      const AOcompanyNames = dataDetails.data.getAssetOwner;
      const company = [];
      company.push({
        id: AOcompanyNames.companydetailsID,
        companyName: AOcompanyNames.companyName,
      });
      console.log(company);
      setcompanyDetails(company);
      const assetOwner = [];
      assetOwner.push({
        id: AOcompanyNames.id,
        firstName: AOcompanyNames.firstName,
        lastName: AOcompanyNames.lastName,
        companydetailsID: AOcompanyNames.companydetailsID,
      });
      setAssetOwner(assetOwner);
      setAssetOwnerFilter(assetOwner);
    }
  }
  //---------------------------------------------------

  React.useEffect(() => {
    const companyName = watch("companyName");
    if (assetOwnerIds !== "") {
      setAssetOwnerFilter(
        assetOwner.filter((item) => {
          return item.companydetailsID === assetOwnerIds;
        })
      );
    } else if (companyName !== "") {
      const filteredAssetOwners = assetOwner.filter((item) => {
        return item.companydetailsID === watch("companyName");
      });
      setAssetOwnerFilter(filteredAssetOwners);
    } else {
      setAssetOwnerFilter(assetOwner);
    }
  }, [watch("companyName"), assetOwner, assetOwnerIds]);

  const onCancel = () => {
    navigate(`${process.env.PUBLIC_URL}/sites`);
  };

  const details = () => {
    //setsitesdupdetails(true);
    navigate(`${process.env.PUBLIC_URL}/sites`);
  };

  const onSubmit = async (data) => {
    console.log(data);
    console.log(state.state);
    try {
      if (state.state === null) {
        console.log("site creation", data);
        // const dataDetails = await client.graphql({
        //   query: queries.listSiteDetails,
        //   authMode: "API_KEY",
        //   variables: {
        //     filter:{
        //       companydetailsID:{
        //         eq: data.companyName
        //       },
        //       assetownerID: {
        //         eq: data.assetOwner
        //       }
        //     },
        //     limit: limit,
        //   },
        // });
        // if (dataDetails.data.listSiteDetails.items.length>0) {
        //   const siteslist = dataDetails.data.listSiteDetails.items
        // }
        let siteslist = JSON.parse(base64.decode(localStorage.getItem("site")));
        console.log(siteslist);
        const sitescompfilter = siteslist.filter(
          (item) => item.companydetailsID === data.companyName
        );
        console.log(sitescompfilter);
        const nameFilter = sitescompfilter.filter(
          (item) => item.siteName === data.siteName
        );
        setSitedupdata(nameFilter);
        if (nameFilter.length > 0) {
          // toast.success("Already Site exist: ", {
          //   position: toast.POSITION.TOP_CENTER,
          // });
          console.log("duplicate site for AO and company");
          setsitesdup(true);
        } else {
          await client
            .graphql({
              query: mutations.createSiteDetails,
              variables: {
                input: {
                  unitNumber: address,
                  siteName: data.siteName,
                  // street: siteAdressArray.hasOwnProperty("street")
                  //   ? siteAdressArray.street
                  //   : "",
                  // suburb: siteAdressArray.hasOwnProperty("suburb")
                  //   ? siteAdressArray.suburb
                  //   : "",
                  // state: siteAdressArray.hasOwnProperty("state")
                  //   ? siteAdressArray.state
                  //   : "",
                  // postCode: siteAdressArray.hasOwnProperty("postCode")
                  //   ? siteAdressArray.postCode
                  //   : "",
                  // country: siteAdressArray.hasOwnProperty("country")
                  //   ? siteAdressArray.country
                  //   : "",
                  companydetailsID: data.companyName,
                  assetownerID: data.assetOwner,
                  status: "Active",
                },
              },
              authMode: "API_KEY",
            })
            .then(async (result) => {
              console.log(result);
              const siteId = result.data.createSiteDetails.id;

              client
                .graphql({
                  query: mutations.createLogBook,
                  variables: {
                    input: {
                      siteId: siteId,
                      sitedetailsID: siteId,
                      status: "Active",
                      logbookName: data.siteName + " - Default Logbook",
                    },
                  },
                  authMode: "API_KEY",
                })
                .then((result1) => {
                  console.log(result1);
                });
              const addedsite = await client.graphql({
                query: queries.getSiteDetails,
                variables: {
                  id: result.data.createSiteDetails.id,
                },
                authMode: "API_KEY",
              });
              if (addedsite.data.getSiteDetails !== null) {
                if (localStorage.getItem("site") !== null) {
                  let activeSites = JSON.parse(
                    base64.decode(localStorage.getItem("site"))
                  );
                  console.log(activeSites);
                  activeSites.push(addedsite.data.getSiteDetails);
                  console.log(activeSites);
                  localStorage.setItem(
                    "site",
                    base64.encode(JSON.stringify(activeSites))
                  );
                }
              }
              //fetchSiteDetailsAO(LoginUserDetails.id);
              navigate(`${process.env.PUBLIC_URL}/sites`, {
                state: {
                  id: siteId,
                  unitNumber: address,
                  siteName: data.siteName,
                },
              });
            })
            .catch((error) => {
              console.log(error);
              toast.error("Error On Site Creation: " + error, {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        }
      } else if (state.state.page === "assetOwner") {
        console.log("AO Site creation");
        await client
          .graphql({
            query: mutations.createSiteDetails,
            variables: {
              input: {
                unitNumber: address,
                siteName: data.siteName,
                street: siteAdressArray.hasOwnProperty("street")
                  ? siteAdressArray.street
                  : "",
                suburb: siteAdressArray.hasOwnProperty("suburb")
                  ? siteAdressArray.suburb
                  : "",
                state: siteAdressArray.hasOwnProperty("state")
                  ? siteAdressArray.state
                  : "",
                postCode: siteAdressArray.hasOwnProperty("postCode")
                  ? siteAdressArray.postCode
                  : "",
                country: siteAdressArray.hasOwnProperty("country")
                  ? siteAdressArray.country
                  : "",
                companydetailsID: data.companyName,
                assetownerID: data.assetOwner,
                status: "Active",
              },
            },
            authMode: "API_KEY",
          })
          .then(async (result) => {
            console.log(result);
            const siteId = result.data.createSiteDetails.id;
            const addedsite = await client.graphql({
              query: queries.getSiteDetails,
              variables: {
                id: result.data.createSiteDetails.id,
              },
              authMode: "API_KEY",
            });
            if (addedsite.data.getSiteDetails !== null) {
              if (localStorage.getItem("site") !== null) {
                let activeSites = JSON.parse(
                  base64.decode(localStorage.getItem("site"))
                );
                console.log(activeSites);
                activeSites.push(addedsite.data.getSiteDetails);
                console.log(activeSites);
                localStorage.setItem(
                  "site",
                  base64.encode(JSON.stringify(activeSites))
                );
              }
            }
            fetchSiteDetailsAO(LoginUserDetails.id);
            navigate(`${process.env.PUBLIC_URL}/sites`, {
              state: {
                id: siteId,
                unitNumber: address,
                siteName: data.siteName,
                page: "ao",
              },
            });
          })
          .catch((error) => {
            console.log(error);
            toast.error("Error On Site Creation: " + error, {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      } else {
        console.log("updation");
        const result = await client.graphql({
          query: mutations.updateSiteDetails,
          variables: {
            input: {
              id: siteDetail.id,
              unitNumber: address,
              siteName: data.siteName,
              street: siteAdressArray.hasOwnProperty("street")
                ? siteAdressArray.street
                : "",
              suburb: siteAdressArray.hasOwnProperty("suburb")
                ? siteAdressArray.suburb
                : "",
              state: siteAdressArray.hasOwnProperty("state")
                ? siteAdressArray.state
                : "",
              postCode: siteAdressArray.hasOwnProperty("postCode")
                ? siteAdressArray.postCode
                : "",
              country: siteAdressArray.hasOwnProperty("country")
                ? siteAdressArray.country
                : "",
              companydetailsID: data.companyName,
              assetownerID: data.assetOwner,
            },
          },
        });
        if (result) {
          console.log(result);
          const addedsite = await client.graphql({
            query: queries.getSiteDetails,
            variables: {
              id: siteDetail.id,
              limit: 10000,
            },
            authMode: "API_KEY",
          });
          console.log(addedsite);
          if (addedsite.data.getSiteDetails !== null) {
            let activeAssets = [];
            if (localStorage.getItem("site") !== null) {
              activeAssets = JSON.parse(
                base64.decode(localStorage.getItem("site"))
              );
              console.log(activeAssets);
              const existingIndex = activeAssets.findIndex(
                (item) => item.id === addedsite.data.getSiteDetails.id
              );
              console.log(existingIndex);
              if (existingIndex > -1) {
                activeAssets[existingIndex] = addedsite.data.getSiteDetails;
              } else {
                activeAssets.push(addedsite.data.getSiteDetails);
              }
              console.log(activeAssets);
              localStorage.setItem(
                "site",
                base64.encode(JSON.stringify(activeAssets))
              );
            }
          }
          const DBDetail = distrubutionDetails;
          console.log(DBDetail);
          // if (DBDetail.length === 0) {
          //   const distributionBoxDataDetails = client.graphql({
          //     query: mutations.createDistributionBox,
          //     variables: {
          //       input: {
          //         distributionBoxName: data.distributionBox,
          //         location: siteLocationArray,
          //         sitedetailsID: siteDetail.id,
          //         status: "Active",
          //         distributionBoxNumber: "DB" + catId + maxNum,
          //       },
          //     },
          //     authMode: "API_KEY",
          //   });
          //   console.log(distributionBoxDataDetails);
          // } else {
          //   console.log(DBDetail[0].location);
          //   if (DBDetail[0].sitedetailsID === siteDetail.id) {
          //     client.graphql(
          //       {queries:mutations.updateDistributionBox, {
          //         input: {
          //           id: DBDetail[0].id,
          //           distributionBoxName: data.distributionBox,
          //           location:
          //             siteLocationArray.length > 0
          //               ? siteLocationArray
          //               : DBDetail[0].location,
          //           sitedetailsID: siteDetail.id,
          //         },
          //         authMode: "API_KEY",
          //       })
          //     ).then((result1) => {
          //       console.log(result1);
          //     });
          //   }
          // }
          toast.success("Site Detail Updated Successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          fetchSiteDetailsAO(LoginUserDetails.id);
          navigate(`${process.env.PUBLIC_URL}/sites`);
        } else {
          toast.error("Error On Site Updation: ", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    } catch (e) {
      toast.error("Error On Site Creation: " + e.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const onPlaceChanged = (detailAddress) => {
    let fullAddress1 = [];
    for (let i = 0; i < detailAddress.length; i++) {
      if (detailAddress[i].types.includes("postal_code")) {
        fullAddress1["postCode"] = detailAddress[i].long_name;
      }
      if (detailAddress[i].types.includes("locality")) {
        fullAddress1["suburb"] = detailAddress[i].long_name;
      }
      if (detailAddress[i].types.includes("administrative_area_level_1")) {
        fullAddress1["state"] = detailAddress[i].long_name;
      }
      if (detailAddress[i].types.includes("administrative_area_level_2")) {
        fullAddress1["street"] = detailAddress[i].long_name;
      }
      if (detailAddress[i].types.includes("country")) {
        fullAddress1["country"] = detailAddress[i].long_name;
      }
    }
    setsiteAdressArray(fullAddress1);
  };

  async function fetcAONew() {
    const result = await client.graphql({
      query: queries.listAssetOwners,
      authMode: "API_KEY",
      variables: {
        limit: limit,
      },
    });
    if (result.data.listAssetOwners.items.length > 0) {
      const AO = result.data.listAssetOwners.items;
      console.log(AO);
      setAOSite(AO);
    }
  }

  useEffect(() => {
    fetcAONew();
    setEdit(true);
  }, [state.state && state.state.page === "assetOwner"]);

  async function fetchSiteDetailsAO(assetOwnerId) {
    try {
      //setIsLoading(true);
      let nextToken = "";
      let sites = [];
      const result = await client.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: assetOwnerId,
          limit: limit,
        },
        authMode: "API_KEY",
      });
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          sites.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: assetOwnerId,
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            nextToken = results.data.siteDetailsByAssetownerID.nextToken;
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.push(results.data.siteDetailsByAssetownerID.items);
            }
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          SiteData.setSiteData(mergeSites);
          //setSiteDetails(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
          fetchlistDistributionBox(mergeSites);
          //fetchAssets(mergeSites, "All Time");
          //setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      //setIsLoading(false);
    }
  }

  async function fetchlistDistributionBox(siteData) {
    try {
      //setIsLoading(true);
      const promises = siteData.map((item) => {
        return client.graphql({
          query: queries.distributionBoxesBySitedetailsID,
          variables: {
            sitedetailsID: item.id,
            limit: limit,
          },
        });
      });
      Promise.all(promises)
        .then(async (results) => {
          console.log("results -> ", results);
          let SiteDB = [];
          results.map((items) => {
            if (items.data.distributionBoxesBySitedetailsID.items.length > 0) {
              SiteDB.push(items.data.distributionBoxesBySitedetailsID.items);
            }
          });
          if (SiteDB.length > 0) {
            SiteDBData.setSiteDBData(SiteDB.flat(1));
            console.log("SiteDB", SiteDB.flat(1));
            localStorage.setItem(
              "siteDB",
              base64.encode(JSON.stringify(SiteDB.flat(1)))
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Fragment>
      <br></br>
      <Card className="custom-card">
        <CardBody>
          <form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <H4>
              {state.state === null
                ? "Add Site"
                : state.state.page === "assetOwner"
                ? "Add Site For Asset Owner"
                : "Edit Site"}
            </H4>

            {siteLimitCountOftheAO !== 0 &&
            siteLimitCountOftheAO >= siteLimit ? (
              <>
                <Row>
                  <Col md="12">
                    <div>
                      <p className="errorSubscription">
                        Sorry!.. Your SP Users Limit is Exceeded, Please Upgrade
                        your Subscription
                      </p>
                    </div>
                    <Btn attrBtn={{ color: "warning", onClick: onCancel }}>
                      Close
                    </Btn>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col md="6 mb-3">
                    <Label>Company Details</Label>
                    <select
                      className="form-control"
                      name="companyName"
                      {...register("companyName", { required: true })}
                      onChange={(event) => setAssetOwnerId(event.target.value)}
                    >
                      <option value="">Choose Company Name</option>
                      {companyDetails
                        .filter((item) => !item.deleted)
                        .map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.companyName}
                          </option>
                        ))}
                    </select>
                  </Col>
                  <Col md="6 mb-3">
                    <Label>Asset Owner</Label>
                    <select
                      className="form-control"
                      name="assetOwner"
                      {...register("assetOwner", { required: true })}
                    >
                      <option value="">Choose Asset Owner</option>
                      {assetOwnerFilter.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.firstName} {item.lastName}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
                <Row>
                  {/* <Col md="6 mb-3">
                    <Label>Unit Number</Label>
                    <input
                      value={address}
                      type="text"
                      className="form-control"
                      placeholder="Unit Number"
                      name="unitNumber"
                      {...register("unitNumber", { required: false })}
                    />
                    <span>
                      {errors.unitNumber && "Please Enter Unit Number."}
                    </span>
                  </Col> */}
                  <Col md="6 mb-3">
                    <Label>Site Name</Label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Site Name"
                      name="siteName"
                      {...register("siteName", { required: true })}
                    />
                    <span>{errors.siteName && "Please Enter Site Name."}</span>
                  </Col>
                  <Col md="6 mb-3">
                    <Label>Site Address..</Label>
                    {/* <GooglePlacesAutocomplete
                      apiKey="AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4"
                      className="form-control"
                      onPlaceSelected={(place) => {
                        console.log(place);
                        const address = place.address_components;
                        setAddress(address);
                        onPlaceChanged(address);
                      }}
                      selectProps={{
                        fetchDetails:true,
                        onChange: ((data,details,place)=>{
                          setAddress(data.label);
                            console.log('details',details);
                        })
                      }}
                      options={{
                        types: ["places"],
                        componentRestrictions: { country: "AU" },
                      }}
                      name="siteAddress"
                      placeholder="Site Address"
                    />
                     <Autocomplete
                        selectProps={{
                          onChange: ((data,details,place)=>{
                              setAddress(data.label);
                          }),
                            placeholder: "Select...",
                            fetchDetails:true,
                            apiKey:"AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4",
                            // className:"form-control",
                            onPlaceSelected:((place) => {
                                  console.log(place);
                                  const address = place.address_components;
                                  onPlaceChanged(address);
                          }),
                          options:({
                            types: ["address"],
                            componentRestrictions: { country: "AU" },
                          }),
                        }}
                      /> */}

                    <Autocomplete
                      apiKey="AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4"
                      className="form-control"
                      onPlaceSelected={(place) => {
                        console.log(place);
                        setAddress(place.formatted_address);
                        const address = place.address_components;
                        onPlaceChanged(address);
                      }}
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "AU" },
                      }}
                      name="siteAddress"
                      placeholder="Site Address"
                      defaultValue={address}
                    />
                  </Col>
                </Row>
                {/* <Row>
                  <Col md="6 mb-3">
                    <Label>Distribution Board</Label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Distribution Board"
                      name="distributionBox"
                      {...register("distributionBox", { required: true })}
                    />
                    <span>
                      {errors.distributionBox &&
                        "Please Enter Distribution Board."}
                    </span>
                  </Col>
                  <Col md="6 mb-3">
                    <Label>Location</Label>
                    <Autocomplete
                      apiKey="AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4"
                      className="form-control"
                      onPlaceSelected={(place1) => {
                        console.log(place1);
                        const address1 = place1.formatted_address;
                        setsiteLocationArray(address1);
                      }}
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "AU" },
                      }}
                      placeholder="Location"
                      name="location"
                      defaultValue={address.location}
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Col className="text-end">
                    <Btn attrBtn={{ color: "primary" }}>
                      {!editMode ? "Save" : "Update"}
                    </Btn>
                    <Btn attrBtn={{ color: "danger", onClick: onCancel }}>
                      Cancel
                    </Btn>
                  </Col>
                </Row>
              </>
            )}
          </form>
          {sitesdup && (
            <form
              className="needs-validation"
              noValidate=""
              //onSubmit={handleSubmit(submitMFA)}
            >
              <Row>
                <Col sm="9">
                  <p className="errorSubscription">
                    Already site exist with a same SiteName for the selected
                    AssetOwner and Company.
                  </p>
                </Col>
                <Col sm="3">
                  {/* <Btn attrBtn={{ color: "primary", onClick: details }}>
                    Click here to check the Site Details
                  </Btn> */}
                  <Link to={`${process.env.PUBLIC_URL}/sites`}>
                    Click here to check the Site Details
                  </Link>
                </Col>
              </Row>
              <Row>
                {sitesdupdetails && (
                  <form
                    className="needs-validation"
                    noValidate=""
                    //onSubmit={handleSubmit(submitMFA)}
                  >
                    <Row>
                      <Col sm="4">
                        <Label>Site Name</Label>
                        <p>{sitedupdata[0].siteName}</p>
                      </Col>
                      <Col sm="4">
                        <Label>Asset Owner</Label>
                        <p>{getAO(sitedupdata[0].assetownerID)}</p>
                      </Col>
                      <Col sm="4">
                        <Label>Company Name</Label>
                        <p>{getCompanyName(sitedupdata[0].companydetailsID)}</p>
                      </Col>
                    </Row>
                  </form>
                )}
              </Row>
            </form>
          )}
          {/* <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onUpdateSubmit)}>
                  
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Company Details</Label>
                      <select className="form-control" placeholder="Company Details" name="companyName" name="companyName" {...register('companyName', {required: true})} onChange={filterAssetOwner} >
                        <option value="">Choose Company Name</option>
                        {
                          companyDetails.map((item) =>
                            <option value={item.id} key={item.id} >{item.companyName}</option>
                          )
                        }
                      </select>
                      <span>{errors.companyName && 'Please Select Company Name.'}</span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Asset Owner</Label>
                      <select className="form-control" placeholder="Asset Owner" name="assetOwner" name="assetOwner" {...register('assetOwner', {required: true})}  >
                        <option value="">Choose Asset Owner</option>
                        {
                          assetOwnerFilter.map((item) =>
                            <option value={item.id} key={item.id}>{item.firstName} {item.LastName}</option>
                          )
                        }
                      </select>
                      <span>{errors.assetOwner && 'Please Select Asset Owner.'}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Unit Number</Label>
                      <input type="text" className="form-control" placeholder="Unit Number" name="unitNumber" {...register('unitNumber', { required: true })} />
                      <span>{errors.unitNumber && 'Please Enter Unit Number.'}</span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Site Address</Label>
                      <Autocomplete
                        apiKey='AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4'
                        className="form-control"
                        onPlaceSelected={(place) => {
                          console.log(place);
                          const address = place.address_components;
                          onPlaceChanged(address);
                        }}
                        options={{
                            types: ["address"],
                            componentRestrictions: { country: "AU" },
                        }}
                        name='siteAddress'
                        placeholder="Site Address"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Distribution Board</Label>
                      <input type="text" className="form-control" placeholder="Distribution Board" name="distributionBox" {...register('distributionBox', { required: true })} />
                      <span>{errors.distributionBox && 'Please Enter Distribution Board.'}</span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Location</Label>
                      <Autocomplete
                        apiKey='AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4'
                        className="form-control"
                        onPlaceSelected={(place1) => {
                          console.log(place1);
                          const address1 = place1.formatted_address;
                          setsiteLocationArray(address1);
                        }}
                        options={{
                            types: ["address"],
                            componentRestrictions: { country: "AU" },
                        }}
                        placeholder="Location"
                        name="location"
                      /> 
                    </Col>
                  </Row>
                  <Btn attrBtn={{ color: 'primary' }}>Update</Btn> 
                  <Btn attrBtn={{ color: 'danger', onClick: onCancel }}>Cancel</Btn> 
                </form>
              ) */}

          {/* <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ul>
                    {
                      fields.map((item, index) => {
                        return (
                          <li key={index}>
                            <H4>Distribution Board {index + 1}</H4>
                            <Row>
                              <Col md="5 mb-3">
                                <input type="text" className="form-control" placeholder="Distribution Board" {...register(`siteDetails.${index}.distributionBox`, { required: true })} />
                              </Col>
                              <Col md="5 mb-3">                             
                                {/* <Autocomplete
                                  apiKey='AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4'
                                  className="form-control"
                                  onPlaceSelected={(place1) => {
                                    console.log(place1);
                                    const address1 = place1.formatted_address;
                                    setsiteLocationArray(siteLocationArray=>({
                                      ...siteLocationArray,
                                      [index]: address1
                                    }))
                                  }}
                                  options={{
                                      types: ["address"],
                                      componentRestrictions: { country: "AU" },
                                  }}
                                  placeholder="Location"
                                  
                                /> 
                                <input type="text" className="form-control" {...register(`siteDetails.${index}.location`, { required: true })} />
                              </Col>
                              <Col md="2 mb-3">
                                <Label></Label>
                                <i className="icofont icofont-trash" onClick={() => remove(index)}></i>
                              </Col>
                            </Row>
                          </li>
                        )
                      }
                    )}
                  </ul>
                  <button
                    type="button" className="btn btn-primary m-r-10"
                    onClick={() => {
                      append({ 
                      distributionBox: "", 
                      location: "" });
                    }}
                  > <i className="icofont icofont-plus"></i> Add Distribution Boxes </button>
                  <Btn attrBtn={{ color: 'primary' }}>Save</Btn> 
                  <br></br>
                </Form> */}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default AddEditSiteDetails;
