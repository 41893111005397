import { Btn, H6, H4 } from "../../AbstractElements";
import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Input,
  Col,
  Card,
  CardBody,
  InputGroup,
  Label,
  Media,
  Button,
} from "reactstrap";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import * as queries from "../../graphql/queries";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useForm } from "react-hook-form";
import awsExports from "../../aws-exports";
import { useNavigate, useLocation, Link } from "react-router-dom";
import AWS from "aws-sdk";
import {
  S3_BUCKET,
  REGION,
  CATEGORIES_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
  limit,
} from "../../Config/Config";
import { SiteContext } from "../../App";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const CategoryListContain = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [assets, setAssets] = useState([]);
  const [siteViewData, setSiteViewData] = useState([]);
  const [sites, setSites] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [categoryId, setCategoryId] = useState([]);
  const [serviceGroup, setServiceGroup] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [collection, setCollection] = useState([]);
  const [formDetails, setFormDetails] = useState([]);
  const [formIds, setFormIds] = useState([]);
  const LoginUserGroup = localStorage.getItem("groups");
  //const [limit, setLimit] = useState(100000);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  console.log(selectedSiteGlobal);

  const globalSearch = (searchValue) => {
    setCurrentPage(1);
    const filteredData = categoryDetails.filter((item) => {
      const serviceGroup = getServiceGroup(item.servicegroupID) || "";
      return (
        item.categoryName
          ?.toLowerCase()
          .includes(searchValue.trim().toLowerCase()) ||
        serviceGroup.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
        item.formDescription
          ?.toLowerCase()
          .includes(searchValue.trim().toLowerCase()) ||
        item.status?.toLowerCase().includes(searchValue.trim().toLowerCase())
      );
    });
    const configuredCollections = getConfiguredCollection(filteredData);
    setCollection(configuredCollections);
  };

  const getFormIds = (e) => {
    if (e.target.checked) {
      console.log(e.target.id);
      const fId = [...formIds];
      fId.push(e.target.id);
      setFormIds(fId);
    } else {
      console.log(formIds.indexOf(e.target.id));
    }
  };

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      globalSearch(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    const configuredCollections = getConfiguredCollection(categoryDetails);
    setCollection(configuredCollections);
  };

  //------------------------list------

  async function fetchCategoriesData() {
    setIsLoading(true);
    const response = await client.graphql({
      query: queries.listCategories,
      authMode: "API_KEY",
      variables: {
        limit: limit,
      },
    });
    if (response.data.listCategories.items.length > 0) {
      setIsLoading(false);
      const sortItems = response.data.listCategories.items.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      console.log(sortItems);
      const categoriesDetails = response.data.listCategories.items;
      console.log(categoriesDetails);
      setCategoryDetails(categoriesDetails);
      const configuredCollections = getConfiguredCollection(categoriesDetails);
      setCollection(configuredCollections);
      setCategoryId(categoriesDetails.map((category) => category.id));
    } else {
      setCategoryDetails([]);
      setCollection([]);
      setCategoryId([]);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (state.state) {
      console.log(state);
      setSiteViewData(state.state);
      console.log(siteViewData);
    }
    async function fetchFormDetailsData() {
      const result = await client.graphql({
        query: queries.listFormDetails,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      });
      if (result.data.listFormDetails.items.length > 0) {
        console.log(result);
        const formDetails = result.data.listFormDetails.items;
        console.log(formDetails);
        setFormDetails(formDetails);
      } else {
        setFormDetails([]);
      }
    }
    fetchFormDetailsData();
    fetchCategoriesData();
    if (LoginUserGroup === "site_user") {
      if (state.state !== null) {
        fetchSitesDetailsSites(state.state.id);
      } else {
        fetchSitesDetailsSU();
      }
    } else if (LoginUserGroup === "electrician") {
      console.log("electrician");
      if (state.state !== null) {
        fetchSitesDetailsSites(state.state.id);
      } else {
        console.log("asset count---");
        fetchSitesDetailsSU();
      }
    } else {
      if (state.state !== null) {
        fetchSitesDetailsSites(state.state.id);
      } else {
        fetchSitesDetails();
      }
    }
    async function fetchServiceGroups() {
      const result = await client.graphql({
        query: queries.listServiceGroups,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      });
      if (result.data.listServiceGroups.items.length > 0) {
        const serviceGroups = result.data.listServiceGroups.items;
        console.log(serviceGroups);
        setServiceGroup(serviceGroups);
      } else {
        setServiceGroup([]);
      }
    }
    fetchServiceGroups();
  }, [selectedSiteGlobal]);
  // toggle
  const activeInactive = () => {
    setIsActive(!isActive);
  };

  // assets count
  async function fetchSitesDetailsSites(siteId) {
    const collectArray = [];
    try {
      setIsLoading(true);
      const siteFilterArray = [];
      sites.forEach((item) => {
        siteFilterArray.push(item.id);
      });
      await client
        .graphql({
          query: queries.listCategories,
          authMode: "API_KEY",
          variables: { limit: limit },
        })
        .then(async (dataDetails) => {
          const catDetails = dataDetails.data.listCategories.items;
          console.log(catDetails);
          if (catDetails.length > 0) {
            const catFilterArray = [];
            catDetails.forEach((item) => {
              catFilterArray.push({ categoriesID: { eq: item.id } });
            });
            console.log(catFilterArray);
            await client
              .graphql({
                query: queries.listAssetTemplates,
                variables: {
                  filter: { or: catFilterArray },
                  limit: limit,
                },
                authMode: "API_KEY",
              })
              .then(async (catAssets) => {
                const assetTemp = catAssets.data.listAssetTemplates.items;
                console.log(assetTemp);
                if (assetTemp.length > 0) {
                  await client
                    .graphql({
                      query: queries.listAssets,
                      variables: {
                        limit: limit,
                      },
                    })
                    .then((result) => {
                      console.log(result);
                      const assetNew = result.data.listAssets.items;
                      const FilterAssets = assetNew.filter(
                        (item) => item.siteId === selectedSiteGlobal
                      );
                      const Assets = assetNew.filter(
                        (item) => item?.siteId === state.state.id
                      );
                      console.log(FilterAssets);
                      console.log(Assets);
                      const AssetFiltNew = selectedSiteGlobal
                        ? FilterAssets
                        : Assets;
                      console.log(AssetFiltNew);
                      console.log(Assets);
                      if (AssetFiltNew.length > 0) {
                        AssetFiltNew.map((site) => {
                          const categoriesId = assetTemp.find(
                            (temp) => temp.id === site.assettemplateID
                          )?.categoriesID;

                          collectArray.push({
                            id: site.id,
                            createdAt: site.createdAt,
                            categoriesId: categoriesId,
                          });
                        });
                        console.log(collectArray);
                        setAssets(collectArray);
                        setErrorMessage("");
                      } else {
                        setAssets([]);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      setIsLoading(false);
                    });
                } else {
                  setAssets([]);
                }
              })
              .catch((error) => {
                console.log(error);
                setIsLoading(false);
              });
          } else {
            setSites([]);
            setErrorMessage("No categories available");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } finally {
      setIsLoading(false);
    }
  }
  // assets count
  async function fetchSitesDetails() {
    const collectArray = [];
    try {
      setIsLoading(true);
      const result = await client.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: limit,
        },
        authMode: "API_KEY",
      });
      const sites = result.data.siteDetailsByAssetownerID.items;
      console.log(sites);
      if (sites.length > 0) {
        setSites(sites);
        const siteFilterArray = [];
        sites.forEach((item) => {
          siteFilterArray.push(item.id);
        });
        await client
          .graphql({
            query: queries.listCategories,
            authMode: "API_KEY",
            variables: { limit: limit },
          })
          .then(async (dataDetails) => {
            const catDetails = dataDetails.data.listCategories.items;
            console.log(catDetails);
            if (catDetails.length > 0) {
              const catFilterArray = [];
              catDetails.forEach((item) => {
                catFilterArray.push({ categoriesID: { eq: item.id } });
              });
              console.log(catFilterArray);
              await client
                .graphql({
                  query: queries.listAssetTemplates,
                  variables: {
                    filter: { or: catFilterArray },
                    limit: limit,
                  },
                  authMode: "API_KEY",
                })
                .then(async (catAssets) => {
                  const assetTemp = catAssets.data.listAssetTemplates.items;
                  console.log(assetTemp);
                  if (assetTemp.length > 0) {
                    await client
                      .graphql({
                        query: queries.listAssets,
                        variables: {
                          limit: 10000,
                        },
                      })
                      .then((result) => {
                        console.log(result);
                        const assetNew = result.data.listAssets.items;
                        const FilterAssets = assetNew.filter(
                          (item) => item.siteId === selectedSiteGlobal
                        );
                        const Assets = assetNew.filter((item) => {
                          const _matchingAssetTemplate = siteFilterArray?.find(
                            (assetTemp) => assetTemp === item?.siteId
                          );
                          if (_matchingAssetTemplate) {
                            return item;
                          }
                        });
                        console.log(FilterAssets);
                        console.log(Assets);
                        const AssetFiltNew = selectedSiteGlobal
                          ? FilterAssets
                          : Assets;
                        console.log(AssetFiltNew);
                        console.log(Assets);
                        if (AssetFiltNew.length > 0) {
                          AssetFiltNew.map((site) => {
                            const categoriesId = assetTemp.find(
                              (temp) => temp.id === site.assettemplateID
                            )?.categoriesID;

                            collectArray.push({
                              id: site.id,
                              createdAt: site.createdAt,
                              categoriesId: categoriesId,
                            });
                          });
                          console.log(collectArray);
                          setAssets(collectArray);
                          setErrorMessage("");
                        } else {
                          setAssets([]);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                      });
                  } else {
                    setAssets([]);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setIsLoading(false);
                });
            } else {
              setSites([]);
              setErrorMessage("No categories available");
              // setErrorMessage("No sites available");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } finally {
      setIsLoading(false);
    }
  }

  // Assets Count For Site User ----
  async function fetchSitesDetailsSU() {
    const collectArray = [];
    try {
      setIsLoading(true);
      await client
        .graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          variables: {
            userdetailsID: LoginUserDetails.id,
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          const sites = result.data.linkUsersAndSitesByUserdetailsID.items;
          console.log(sites);
          if (sites.length > 0) {
            //setSites(sites);
            const siteFilterArray = [];
            sites.forEach((item) => {
              siteFilterArray.push(item.sitedetailsID);
            });
            await client
              .graphql({
                query: queries.listCategories,
                authMode: "API_KEY",
                variables: { limit: limit },
              })
              .then(async (dataDetails) => {
                const catDetails = dataDetails.data.listCategories.items;
                console.log(catDetails);
                if (catDetails.length > 0) {
                  const catFilterArray = [];
                  catDetails.forEach((item) => {
                    catFilterArray.push({ categoriesID: { eq: item.id } });
                  });
                  console.log(catFilterArray);
                  await client
                    .graphql({
                      query: queries.listAssetTemplates,
                      variables: {
                        filter: { or: catFilterArray },
                        limit: limit,
                      },
                      authMode: "API_KEY",
                    })
                    .then(async (catAssets) => {
                      const assetTemp = catAssets.data.listAssetTemplates.items;
                      console.log(assetTemp);
                      if (assetTemp.length > 0) {
                        await client
                          .graphql({
                            query: queries.listAssets,
                            variables: {
                              limit: limit,
                            },
                          })
                          .then((result) => {
                            console.log(result);
                            const assetNew = result.data.listAssets.items;
                            const FilterAssets = assetNew.filter(
                              (item) => item.siteId === selectedSiteGlobal
                            );
                            const Assets = assetNew.filter((item) => {
                              const _matchingAssetTemplate =
                                siteFilterArray?.find(
                                  (assetTemp) => assetTemp === item?.siteId
                                );
                              if (_matchingAssetTemplate) {
                                return item;
                              }
                            });
                            console.log(FilterAssets);
                            console.log(selectedSiteGlobal);
                            const AssetFiltNew = selectedSiteGlobal
                              ? FilterAssets
                              : Assets;
                            console.log(AssetFiltNew);
                            console.log(Assets);
                            if (AssetFiltNew.length > 0) {
                              AssetFiltNew.map((site) => {
                                const categoriesId = assetTemp.find(
                                  (temp) => temp.id === site.assettemplateID
                                )?.categoriesID;

                                collectArray.push({
                                  id: site.id,
                                  createdAt: site.createdAt,
                                  categoriesId: categoriesId,
                                });
                              });
                              console.log(collectArray);
                              setAssets(collectArray);
                              setErrorMessage("");
                            } else {
                              setAssets([]);
                            }
                          })
                          .catch((error) => {
                            setIsLoading(false);
                          });
                      } else {
                        setAssets([]);
                      }
                    })
                    .catch((error) => {
                      setIsLoading(false);
                    });
                } else {
                  setSites([]);
                  setErrorMessage("No categories available");
                  // setErrorMessage("No sites available");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } finally {
      setIsLoading(false);
    }
  }

  function countAssetsForCategory(categoriesID) {
    const filteredAssets = assets.filter(
      (asset) => asset.categoriesId === categoriesID
    );
    return filteredAssets.length;
  }

  const getServiceGroup = (id) => {
    const groupName = serviceGroup.filter((item) => {
      return item.id === id;
    });
    if (groupName.length > 0) {
      return groupName[0].serviceName;
    } else {
      return "";
    }
  };

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/sites`, {
      state: {
        category: state.state.category,
        site: state.state.site,
      },
    });
  };

  const getConfiguredCollection = (filteredData) => {
    filteredData.forEach(async (data) => {
      console.log("imageURL", data?.imageURL);
      if (data?.imageURL?.length) {
        data["image"] = await getfilename(data?.imageURL);
      }
    });
    return filteredData;
  };

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const getImage = async (filename) => {
    var params = {
      Bucket: S3_BUCKET,
      Key: `public/${CATEGORIES_FOLDER}/${filename}`,
    };
    try {
      const url = await myBucket.getSignedUrlPromise("getObject", params);
      return url;
    } catch (err) {
      return null;
    }
  };

  const getfilename = async (path) => {
    if (!path || path.trim() === "") {
      return null;
    }
    const files = JSON.parse(path);
    if (files.length > 0) {
      var newfilename = files[0].fileName;
      const imageUrl = await getImage(newfilename);
      return imageUrl;
    } else {
      return null;
    }
  };

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <Row>
              <Col md="12">
                <div className="d-flex align-items-center justify-content-end h-100">
                  <p className={`mb-0 ${isActive && "fw-semibold"}`}>Active</p>
                  <Media body className="icon-stateCat switch-smcat px-2">
                    <Label className="switchCat mb-0">
                      <input type="checkbox" onClick={activeInactive} />
                      <span
                        className={
                          isActive
                            ? "switch-state bg-success"
                            : "switch-state bg-danger"
                        }
                      ></span>
                    </Label>
                  </Media>
                  <p className={`mb-0 ${!isActive && "fw-semibold"}`}>
                    Inactive
                  </p>
                </div>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col md="12" className="project-list">
              <Card className="custom-card">
                <Row>
                  {isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {collection.filter((item) =>
                        isActive
                          ? item.status === "Active"
                          : item.status === "Inactive"
                      ).length > 0 ? (
                        <>
                          {collection &&
                            collection
                              .filter((catItem) =>
                                isActive
                                  ? catItem.status === "Active"
                                  : catItem.status === "Inactive"
                              )
                              .map((catItem) => (
                                <Col md="3" key={catItem.id}>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/reports`}
                                    state={{ category: catItem }}
                                  >
                                    <Card className="category-list rounded-2">
                                      <img
                                        className="card-img"
                                        src={catItem.image}
                                        alt={catItem.categoryName}
                                      ></img>
                                      <div className="card-img-overlay text-white p-0 overlay-dark">
                                        <CardBody className="d-flex flex-column p-0 h-100">
                                          <div className="d-flex justify-content-end">
                                            <div
                                              className={
                                                catItem.status === "Active"
                                                  ? "ribbon ribbon-clip-right ribbon-right ribbon-success"
                                                  : "ribbon ribbon-clip-right ribbon-right ribbon-danger"
                                              }
                                            >
                                              {catItem.status}
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content-between flex-column h-100">
                                            <p className="activestatus text-white pt-4 pb-5">
                                              {catItem.categoryName}
                                            </p>
                                            <Media className="static-widget">
                                              <Media body>
                                                <H4
                                                  id="status"
                                                  attrH6={{
                                                    className: "font-roboto",
                                                  }}
                                                >
                                                  {" "}
                                                  {getServiceGroup(
                                                    catItem.servicegroupID
                                                  )}{" "}
                                                </H4>
                                              </Media>
                                            </Media>
                                          </div>
                                        </CardBody>
                                      </div>
                                    </Card>
                                  </Link>
                                </Col>
                              ))}
                        </>
                      ) : (
                        <p>No Category Found</p>
                      )}
                    </>
                  )}
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Container>
    </Fragment>
  );
  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default CategoryListContain;
