import React, { Fragment, useState, useEffect, createRef, useRef } from "react";
import { Btn, H3 } from "../../AbstractElements";
import {
  Label,
  Row,
  Col,
  Container,
  Card,
  Table,
  CardFooter,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  CardBody,
} from "reactstrap";
import * as queries from "../../graphql/queries";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import cloneDeep from "lodash/cloneDeep";
import { toast } from "react-toastify";
import * as mutations from "../../graphql/mutations";
import * as mergedQueries from "../../graphql/mergedQueries";
import { useForm } from "react-hook-form";
import SiteData from "../../Data/Sites/index";
import { limit } from "../../Config/Config";
import useInfiniteLoadingAssets from "./assetsInfiniteloop";
import CategoryData from "../../Data/Category/index";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const AssetLogbook = () => {
  const navigate = useNavigate();
  const state = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    e,
    ...form
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [assetsCountBySite, setAssetsCountBySite] = useState([]);
  console.log("assets count", assetsCountBySite);
  const [sites, setSites] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [assetsLogbook, setAssetsLogbook] = useState([]);
  const LoginUserGroup = localStorage.getItem("groups");
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const countPerPage = 10;
  const [distri, setDistri] = useState([]);
  const [countassets, setAssetscount] = useState([]);
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [logbookName, setLogbookName] = useState("");
  const [Viewdbid, setViewdbid] = useState(false);
  const { items, loadMoreItems, hasMore } = useInfiniteLoadingAssets({ state });
  const containerRef = useRef(null);
  const handleScroll = () => {
    console.log(items);
    if (!containerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollHeight - scrollTop <= clientHeight + 50 && hasMore) {
      loadMoreItems();
    }
  };

  const ViewDistributionBoxModal = () => {
    setViewdbid(!Viewdbid);
  };

  const ViewdbidModaltoggle = () => setViewdbid(!Viewdbid);
  useEffect(() => {
    if (
      localStorage.getItem("categoryAssetCount") === null &&
      assetsCountBySite.length === 0
    ) {
      LoginUserGroup === "spao" || LoginUserGroup === "site_owner"
        ? fetchSiteDetailsAO()
        : LoginUserGroup === "site_user"
          ? fetchSiteDetailsSU()
          : fetchSiteDetailsSP(LoginUserDetails.id);
    } else {
      let assetsCount = JSON.parse(
        base64.decode(localStorage.getItem("categoryAssetCount"))
      );
      const matchingItems = assetsCount.filter(
        (item) => item.sitedetailsID === state.state.item.sitedetailsID
      );
      console.log(matchingItems);
      const totalAssetCount = matchingItems.reduce(
        (total, item) => total + item.assetCount,
        0
      );
      setAssetscount(totalAssetCount);
    }
    // if (assetsCountBySite && assetsCountBySite.length > 0) {
    //   const matchingItems = assetsCountBySite.filter(item => item.sitedetailsID === state.state.item.sitedetailsID);
    //   console.log(matchingItems);
    //   const totalAssetCount = matchingItems.reduce((total, item) => total + item.assetCount, 0);
    //   setAssetscount(totalAssetCount);
    // }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (localStorage.getItem("site") === null && sites.length === 0) {
      // LoginUserGroup === "site_owner"
      //   ? fetchSiteDetailsAO()
      //   : fetchSiteDetailsSPSU(LoginUserDetails.id);
      LoginUserGroup === "spao" || LoginUserGroup === "site_owner"
        ? fetchSiteDetailsAO()
        : LoginUserGroup === "site_user"
          ? fetchSiteDetailsSU()
          : fetchSiteDetailsSP(LoginUserDetails.id);
    } else {
      let activeSites = JSON.parse(base64.decode(localStorage.getItem("site")));
      setSites(activeSites.filter((item) => item.status === "Active"));
    }
    console.log(state);
    if (state.state) {
      fetchListLogBookAssetDetails(state.state.item.siteId);
      fetchDistributionboards(state.state.item.siteId);
    } else {
      console.log("no state..");
    }
  }, []);

  async function fetchSiteDetailsAO() {
    try {
      setIsLoading(true);
      let nextToken = "";
      let sites = [];
      const result = await client.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      });
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          sites.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            nextToken = results.data.siteDetailsByAssetownerID.nextToken;
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.push(results.data.siteDetailsByAssetownerID.items);
            }
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          setSites(mergeSites.filter((item) => item.status === "Active"));
          SiteData.setSiteData(mergeSites);
          const promises1 = mergeSites.map(async (item) => {
            return client.graphql({
              query: queries.assetCountsBySitedetailsID,
              variables: {
                sitedetailsID: item.id,
                limit: 10000,
              },
            });
          });
          Promise.all(promises1).then(async (results) => {
            console.log(results);
            let assetCount = [];
            if (results.length > 0) {
              results.forEach((result1) => {
                let counts = result1.data.assetCountsBySitedetailsID;
                if (counts !== null) {
                  if (counts.items.length > 0) {
                    counts.items.map((item) => {
                      assetCount.push(item);
                    });
                  }
                }
              });
              if (assetCount.length > 0) {
                localStorage.setItem(
                  "categoryAssetCount",
                  base64.encode(JSON.stringify(assetCount))
                );
                //--
                const matchingItems = assetCount.filter(
                  (item) =>
                    item.sitedetailsID === state.state.item.sitedetailsID
                );
                console.log(matchingItems);
                const totalAssetCount = matchingItems.reduce(
                  (total, item) => total + item.assetCount,
                  0
                );
                setAssetscount(totalAssetCount);
              } else {
                localStorage.setItem(
                  "categoryAssetCount",
                  base64.encode(JSON.stringify(assetCount))
                );
              }
            }
          });
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        } else {
          setSites([]);
          setIsLoading(false);
        }
      } else {
        setSites([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setSites([]);
      setIsLoading(false);
    }
  }
  async function fetchSiteDetailsSU() {
    try {
      //setIsLoading(true);
      const response = await client.graphql({
        query: mergedQueries.getSiteUsersFromLink,
        authMode: "API_KEY",
        variables: {
          //nextToken: '',
          assetownerID: LoginUserDetails.assetOwnerId,
          userdetailsID: LoginUserDetails.id,
        },
      });
      console.log(response);
      let nextToken = "";
      let sitess = [];
      if (response.data.siteDetailsByAssetownerID.items.length > 0) {
        let sites = response.data.siteDetailsByAssetownerID.items;
        nextToken = response.data.siteDetailsByAssetownerID.nextToken;
        for (let site of sites) {
          console.log(site);
          console.log(site.LinkUsersAndSites.items.length);
          if (site.LinkUsersAndSites.items.length > 0) {
            sitess.push(site);
          }
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: mergedQueries.getSiteUsersFromLink,
            variables: {
              nextToken: nextToken,
              assetownerID: LoginUserDetails.assetOwnerId,
              userdetailsID: LoginUserDetails.id,
            },
            authMode: "API_KEY",
          });
          nextToken = results.data.siteDetailsByAssetownerID.nextToken;
          sites = results.data.siteDetailsByAssetownerID.items;
          for (let site of sites) {
            console.log(site);
            console.log(site.LinkUsersAndSites.items.length);
            if (site.LinkUsersAndSites.items.length > 0) {
              sitess.push(site);
            }
          }
        }
        console.log(sitess);
        if (sitess.length > 0) {
          const mergeSites = sitess.flat(1);
          setSites(mergeSites.filter((item) => item.status === "Active"));
          SiteData.setSiteData(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        }
      }
    } catch (error) {
      console.log(error);
      setSites([]);
      setIsLoading(false);
    }
  }
  //--sites for sp/su
  async function fetchSiteDetailsSP(assetOwnerId) {
    //setIsLoading(true);
    try {
      let nextToken = "";
      let sites = [];
      const result = await client.graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: assetOwnerId,
          limit: limit,
        },
      });
      if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
        let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
        nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
        sites.push(assetDatas);
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.linkUsersAndSitesByUserdetailsID,
            authMode: "API_KEY",
            variables: {
              userdetailsID: assetOwnerId,
              limit: limit,
              nextToken: nextToken,
            },
          });
          nextToken = results.data.linkUsersAndSitesByUserdetailsID.nextToken;
          if (results.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
            sites.push(results.data.linkUsersAndSitesByUserdetailsID.items);
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergesites = sites.flat(1);
          let sitesforUsers = [];
          const promises = mergesites.map(async (item) => {
            return await client.graphql({
              query: queries.getSiteDetails,
              variables: {
                id: item.sitedetailsID,
              },
              authMode: "API_KEY",
            });
          });
          Promise.all(promises).then((sitesData) => {
            sitesData.forEach((sites) => {
              if (sites.data.getSiteDetails !== null) {
                sitesforUsers.push(sites.data.getSiteDetails);
              }
            });
            setSites(sitesforUsers.filter((item) => item.status === "Active"));
            localStorage.setItem(
              "site",
              base64.encode(JSON.stringify(sitesforUsers))
            );
            const promises1 = sitesforUsers.map(async (item) => {
              return client.graphql({
                query: queries.assetCountsBySitedetailsID,
                variables: {
                  sitedetailsID: item.id,
                  limit: 10000,
                },
              });
            });
            Promise.all(promises1).then(async (results) => {
              console.log(results);
              let assetCount = [];
              if (results.length > 0) {
                results.forEach((result1) => {
                  let counts = result1.data.assetCountsBySitedetailsID;
                  if (counts !== null) {
                    if (counts.items.length > 0) {
                      counts.items.map((item) => {
                        assetCount.push(item);
                      });
                    }
                  }
                });
                if (assetCount.length > 0) {
                  localStorage.setItem(
                    "categoryAssetCount",
                    base64.encode(JSON.stringify(assetCount))
                  );
                  //--
                  const matchingItems = assetCount.filter(
                    (item) =>
                      item.sitedetailsID === state.state.item.sitedetailsID
                  );
                  console.log(matchingItems);
                  const totalAssetCount = matchingItems.reduce(
                    (total, item) => total + item.assetCount,
                    0
                  );
                  setAssetscount(totalAssetCount);
                } else {
                  localStorage.setItem(
                    "categoryAssetCount",
                    base64.encode(JSON.stringify(assetCount))
                  );
                }
              }
            });
            //SiteData.setSiteData(sitesforUsers);
          });
        }
      }
    }
    catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function fetchListLogBookAssetDetails(site) {
    //--
    try {
      setIsLoading(true);
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.assetsBySitedetailsID,
        variables: {
          sitedetailsID: site,
          filter: {
            status: {
              ne: "delete",
            },
          },
          limit: limit,
        },
        authMode: "API_KEY",
      });
      if (result.data.assetsBySitedetailsID.items.length > 0) {
        let assetDatas = result.data.assetsBySitedetailsID.items;
        nextToken = result.data.assetsBySitedetailsID.nextToken;
        console.log(
          "first token count",
          result.data.assetsBySitedetailsID.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.assetsBySitedetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.assetsBySitedetailsID,
            variables: {
              sitedetailsID: site,
              filter: {
                status: {
                  ne: "delete",
                },
              },
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.assetsBySitedetailsID.items.length > 0) {
            nextToken = results.data.assetsBySitedetailsID.nextToken;
            console.log("nextToken", nextToken);
            console.log(results.data.assetsBySitedetailsID.items.length);
            if (results.data.assetsBySitedetailsID.items.length > 0) {
              assets.push(results.data.assetsBySitedetailsID.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          const data = mergeAssets.filter(
            (item) => item.logbookId === state.state.item.id
          );
          const sort = data.sort((a, b) => a.fittingNumber - b.fittingNumber);
          console.log(sort);
          setAssetsLogbook(sort);
          setIsLoading(false);
        } else {
          setAssetsLogbook([]);
          setIsLoading(false);
        }
      }
    }
    catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/generatereportfasset`);
  };

  async function fetchDistributionboards(siteId) {
    //--
    try {
      const dboards = await client.graphql({
        query: queries.distributionBoxesBySitedetailsID,
        variables: {
          sitedetailsID: siteId,
          limit: limit,
        },
        authMode: "API_KEY",
      });
      if (dboards.data.distributionBoxesBySitedetailsID.items.length > 0) {
        setDistri(dboards.data.distributionBoxesBySitedetailsID.items);
      } else {
        setDistri([]);
      }
    }
    catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const onSubmit = async () => {
    try {
      const data = getValues();
      const datalist = await client.graphql({
        query: mutations.updateLogBook,
        variables: {
          limit: limit,
          input: {
            id: state.state.item.id,
            additionalInformation: additionalInformation,
            logbookName: logbookName,
          },
        },
        authMode: "API_KEY",
      });
      if (datalist) {
        console.log(datalist);
        reset();
        toast.success(" Updated Succesfully!..", {
          position: toast.POSITION.TOP_CENTER,
        });
        setAdditionalInformation("");
        setLogbookName("");
        navigate(`${process.env.PUBLIC_URL}/generatereportfasset`);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  // -----comment retrive
  useEffect(() => {
    if (state.state) {
      async function listLogbook() {
        try {
          const response = await client.graphql({
            query: queries.listLogBooks,
            variables: {
              limit: limit,
              filter: {
                id: {
                  eq: state.state.item.id,
                },
              },
            },
            authMode: "API_KEY",
          });
          if (response.data.listLogBooks.items.length > 0) {
            console.log(response);
            const logbook = response.data.listLogBooks.items;
            console.log(logbook);
            setAdditionalInformation(logbook[0].additionalInformation);
            setLogbookName(logbook[0].logbookName);
            reset({ ...logbook[0] });
            setIsLoading(false);
          } else {
            setAdditionalInformation([]);
            setLogbookName([]);
            setIsLoading(false);
          }
        }
        catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }
      listLogbook();
    }
  }, [state.state, reset]);

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <Row>
                <H3>Assets For Logbook</H3>
                <Col md="6">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ marginRight: "10px" }}>
                      <p> Site : </p>
                      <p> Address : </p>
                      <p>Distribution Boards :</p>
                    </div>
                    <div>
                      {state.state &&
                        sites
                          .filter((item) => item.id === state.state.item.siteId)
                          .map((filteredItem) => (
                            <>
                              <p>{filteredItem.siteName}</p>
                              <p>{filteredItem.unitNumber}</p>
                            </>
                          ))}
                      <button
                        type="button"
                        className="customDBcss"
                        onClick={() => {
                          ViewDistributionBoxModal();
                        }}
                      >
                        {distri.length}
                      </button>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="12">
                      <Label>Logbook Name</Label>
                      <input
                        className="form-control"
                        name="logbookName"
                        type="text"
                        placeholder="Logbook Name"
                        value={logbookName}
                        onChange={(e) => setLogbookName(e.target.value)}
                        readOnly={state.state && state.state.purpose !== "edit"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Label>Comment</Label>
                      <input
                        className="form-control"
                        name="additionalInformation"
                        type="text"
                        placeholder="Comment"
                        value={additionalInformation}
                        onChange={(e) =>
                          setAdditionalInformation(e.target.value)
                        }
                        readOnly={state.state && state.state.purpose !== "edit"}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br></br>
              <br></br>
              <CardBody>
                <div className="card-block row">
                  <Col sm="12" lg="12" xl="12">
                    {items && (
                      <div>
                        Loaded {items.length} of {countassets} Assets
                      </div>
                    )}
                    <div
                      ref={containerRef}
                      onScroll={handleScroll}
                      style={{ height: "400px", overflow: "auto" }}
                    >
                      <div style={{ position: "relative" }}>
                        <Table
                          className="table-striped table-de"
                          style={{ marginBottom: "0" }}
                        >
                          <thead
                            className="custom-table-head"
                            style={{
                              position: "sticky",
                              top: "0",
                              backgroundColor: "#fff",
                              zIndex: "1",
                            }}
                          >
                            <tr>
                              <th>Added On</th>
                              <th>Fitting Number</th>
                              <th>Asset Model</th>
                              <th>Asset Make</th>
                              <th>Asset Description</th>
                              <th>Asset Serial Number</th>
                              <th>Warranty Start Date</th>
                              <th>Warranty End Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          {items.length > 0 ? (
                            <tbody className="trhover">
                              {items
                                .sort(
                                  (a, b) => a.fittingNumber - b.fittingNumber
                                )
                                .map((item, i) => (
                                  <tr key={i}>
                                    <td>
                                      {moment(item.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>{item.fittingNumber}</td>
                                    <td>{item.assetModel}</td>
                                    <td>{item.assetMake}</td>
                                    <td>{item.assetDescription}</td>
                                    <td>
                                      {item.assetSerialNumber
                                        ? item.assetSerialNumber
                                        : "-"}
                                    </td>
                                    <td>
                                      {moment(item.assetWarrantyStart).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {moment(item.assetWarrantyEnd).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>{item.status}</td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan="12" className="text-center">
                                  No data available.
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </Table>
                        {hasMore && <div>Loading more items...</div>}
                      </div>
                    </div>
                  </Col>
                </div>
              </CardBody>
              {/*
              <CardFooter className="row">
                <Pagination
                  className="p-t-10"
                  pageSize={countPerPage}
                  onChange={updatePage}
                  current={currentPage}
                  total={assetsLogbook.length}
                  showTotal={(total, range) =>
                    `${range[0]} - ${range[1]} of ${total} items`
                  }
                />
              </CardFooter>
              */}
              <br />
              <Row>
                <Col md="6"></Col>
                <Col md="3" className="text-end">
                  {state.state && state.state.purpose === "edit" && (
                    <Btn attrBtn={{ color: "primary", onClick: onSubmit }}>
                      Update
                    </Btn>
                  )}
                </Col>
                <Col md="3" className="text-end">
                  <Btn attrBtn={{ color: "danger", onClick: cancelForm }}>
                    Cancel
                  </Btn>
                </Col>
              </Row>
            </Card>
          </Col>
        </Form>
        <Modal
          isOpen={Viewdbid}
          toggle={ViewdbidModaltoggle}
          size="lg"
          centered
        >
          <ModalHeader toggle={ViewdbidModaltoggle}>
            Distribution Board List
          </ModalHeader>
          <ModalBody className="modal-body">
            <Container fluid={true}>
              <br></br>
              <Form className="needs-validation" noValidate="">
                <Row>
                  <Col md="9">
                    <ul>
                      {distri.map((item, index) => {
                        return (
                          <li key={index}>
                            <Label>{item.distributionBoxName}</Label>
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </Row>
              </Form>
            </Container>
          </ModalBody>
        </Modal>
      </Container>
    </Fragment>
  );
  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default AssetLogbook;
