import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Form, Label, Input } from "reactstrap";
import { FirstName, LastName } from "../../../Constant";
import { H4, H6, LI, UL, Image, Btn } from "../../../AbstractElements";
import paypal from "../../../assets/images/checkout/paypal.png";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import awsExports from "../../../aws-exports";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
const stripePromise =
  "pk_test_51NM5Y7IeIZdDV3cdUR4Yc4gzYtz6cClrUIrrcWS4GYh5n4pZAcYZeX11Xnf1CRnoF4WYe5lVghUisTG57QcUJAFr00ZuGGFHBE";

const PaymentOption = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const subscriptionsPlan = props.values.paymentData;
  const [clientSecretVal, setClientSecret] = useState("");
  const [stripe, setstripe] = useState();
  const [options, setoptions] = useState();
  const history = useNavigate();
  async function onSubmit(data) {
    window.location.href = "https://buy.stripe.com/test_bIYbMn0nU3zhcus000";
    // if (data !== "") {
    //   toast.success("Asset Owner Regitered Succesfully!..", {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    //   // props.nextStep();
    // } else {
    //   errors.showMessages();
    // }
  }
  const appearance = {
    theme: "night",
  };
  useEffect(() => {
    // const paymentIntent = await stripe.paymentIntents.create({
    //   amount: 10,
    //   currency: "aud",
    //   payment_method_types: ["card"],
    // });
    // setClientSecret(paymentIntent.client_secret);
    // const option = {
    //   // passing the client secret obtained from the server
    //   clientSecret: `{{${paymentIntent.client_secret}}}`,
    //   //"{{sk_test_51NM5Y7IeIZdDV3cdGLgYDOfyM9wSa9Tj7X0i5l603HD4XZUwkFPVDbsvKM02fs3LLqAb5PdG3C9JkH1rexdlABPO00MKHRoZ46}}",
    // };
    // setoptions(option);
  }, [stripe]);
  useEffect(() => {
    // const stripe = await getStripe();
    // setstripe(stripe);
  }, []);

  return (
    <Fragment>
      <H4>Payment</H4>
      <Row>
        <Col sm="12">
          {/* <Elements stripe={stripe} options={options}>
            <CheckoutForm />
          </Elements> */}
          {
            /* <UL>
            <LI>{subscriptionsPlan.title}</LI>
            <LI>
              {subscriptionsPlan.prize} / {subscriptionsPlan.month}
            </LI>
            <LI>
              <H6>
                {subscriptionsPlan.storage1}
                <span>{subscriptionsPlan.props1}</span>
              </H6>
            </LI>
            <LI>
              <H6>
                {subscriptionsPlan.storage2}
                <span>{subscriptionsPlan.props2}</span>
              </H6>
            </LI>
            <LI>
              <H6>
                {subscriptionsPlan.storage3}
                <span>{subscriptionsPlan.props3}</span>
              </H6>
            </LI>
            <LI>
              <H6>
                {subscriptionsPlan.storage4}
                <span>{subscriptionsPlan.props4}</span>
              </H6>
            </LI>
          </UL>*/
            <Btn attrBtn={{ color: "primary", onClick: () => onSubmit() }}>
              Pay
            </Btn>
          }
          {/* <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md="12 mb-3">
                <div className="animate-chk">
                  <Row>
                    <Col>
                      <Label className="d-block" htmlFor="edo-ani1">
                        <Input
                          className="radio_animated"
                          id="p-4"
                          type="radio"
                          name="paymentType"
                          value="Bank Account Transfer"
                          data-original-title=""
                          title=""
                          {...register("paymentType", { required: false })}
                        />
                        Bank Account Transfer
                      </Label>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Btn style="float:right" attrBtn={{ color: "primary" }}>
              Submit form
            </Btn>
          </Form> */}
        </Col>
      </Row>
    </Fragment>
  );
};
export default PaymentOption;
