import React, { useEffect, Fragment, useState } from "react";
import { Col, CardBody, Row, Card, Form, Label } from "reactstrap";
import { Btn, H2, H3, H6, H4, LI, P, UL } from "../../../AbstractElements";
import { Subscribe } from "../../../Constant";
// import { StandardData } from '../../../Data/Ecommerce';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import awsExports from "../../../aws-exports";
import uuid from "react-native-uuid";
import { useLocation } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const BillingPlan = (props) => {
  const [subscriptionsplansData, setSubscriptionsplansData] = useState([]);

  const { state } = useLocation();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  async function onSubmit(data) {
    try {
      console.log(data);
      const bilId = data.id;
      const updateUser = client.graphql({
        query: mutations.updateUserDetails,
        variables: {
          input: { id: props.values.UserId, paymentId: bilId },
        },
      });
      console.log(updateUser);
      toast.success("Plan " + data.title + " has been choosen Succesfully!..", {
        position: toast.POSITION.TOP_CENTER,
      });
      props.nextStep();
    } catch (err) {
      toast.error(err.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
  //----------------------------------
  useEffect(() => {
    fetchSubscriptionList();
    async function fetchSubscriptionList() {
      await client
        .graphql({
          query: queries.listSubscriptionLimits,
          authMode: "API_KEY",
          variables: {
            limit: 10000,
          },
        })
        .then((response) => {
          let setdata = [];
          let plansData = [];
          setdata = response.data.listSubscriptionLimits.items;
          // setsubscriptiondata(response.data.listSubscriptionLimits.items);
          setdata.map((item) => {
            plansData.push({
              id: item.subscriptionId,
              prize: "$" + item.Price,
              title: item.SubscriptionName,
              month: "/month",
              storage1: item.AssetLimit,
              props1: "Assets",
              // storage2: item.SiteLimit,
              storage2: "Unlimited",
              props2: "Sites",
              storage3: item.SiteUserLimit,
              props3: "Users",
              // storage4: item.SPUserLimit,
              // props4: 'SP User Limit'
              // storage5: '15',
              // props5: 'Domains',
            });
          });
          setSubscriptionsplansData(plansData);
          console.log("data of the subscription list--");
          console.log(setdata);
        })
        .catch(() => {
          console.log("Error i Fetching Subscription Details");
        });
    }
  }, []);
  //----------------------------------
  return (
    <Fragment>
      <H4>Choose Your Subscription Plan</H4>
      <CardBody className="pricing-content pricing-col">
        <Row>
          {subscriptionsplansData.map((item) => {
            return (
              <Col sm="6" xl="3" className="xl-50 box-col-6" key={item.id}>
                <Card className="pricing-block text-center">
                  <Form className="needs-validation" noValidate="">
                    <div className="pricing-header">
                      <H2>{item.title}</H2>
                      <div className="price-box">
                        <div>
                          <H3>{item.prize}</H3>
                          <P>{item.month}</P>
                        </div>
                      </div>
                    </div>
                    <div className="pricing-list">
                      <UL attrUL={{ className: "simple-list pricing-inner" }}>
                        <LI>
                          <H6>
                            {item.storage1}
                            <span>{item.props1}</span>
                          </H6>
                        </LI>
                        <LI>
                          <H6>
                            {item.storage2}
                            <span>{item.props2}</span>
                          </H6>
                        </LI>
                        <LI>
                          <H6>
                            {item.storage3}
                            <span>{item.props3}</span>
                          </H6>
                        </LI>
                        <LI>
                          <H6>
                            {item.storage4}
                            <span>{item.props4}</span>
                          </H6>
                        </LI>
                      </UL>
                      <Btn
                        attrBtn={{
                          color: "primary",
                          size: "lg",
                          onClick: () => onSubmit(item),
                        }}
                      >
                        {Subscribe}
                      </Btn>
                    </div>
                  </Form>
                </Card>
              </Col>
            );
          })}
        </Row>
      </CardBody>
    </Fragment>
  );
};
export default BillingPlan;
