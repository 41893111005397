import * as queries from "../../graphql/queries";
import AssetTemplate from "../../Data/AssetTemplate/index";

import { limit } from "../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");
var AssetTemplatesData = (function () {
  async function fetchAssetTemplatesData() {
    try {
      let nextToken = "";
      let assetTemplates = [];
      await client
        .graphql({
          query: queries.listAssetTemplates,
          authMode: "API_KEY",
          variables: {
            limit: limit,
          },
        })
        .then(async (dataDetails) => {
          let assetDatas = dataDetails.data.listAssetTemplates.items;
          nextToken = dataDetails.data.listAssetTemplates.nextToken;
          if (dataDetails.data.listAssetTemplates.items.length > 0) {
            assetTemplates.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listAssetTemplates,
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((dataDetailss) => {
                nextToken = dataDetailss.data.listAssetTemplates.nextToken;
                if (dataDetailss.data.listAssetTemplates.items.length > 0) {
                  assetTemplates.push(
                    dataDetailss.data.listAssetTemplates.items
                  );
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assetTemplates);
          if (assetTemplates.length > 0) {
            const mergeAssetTemplates = assetTemplates.flat(1);
            localStorage.setItem(
              "assetTemp",
              base64.encode(JSON.stringify(mergeAssetTemplates))
            );
            AssetTemplate.setAssetTemplate(mergeAssetTemplates);
            return mergeAssetTemplates;
          }
        })
        .catch((error) => {
          console.log(error);
          AssetTemplate.setAssetTemplate([]);
          return "";
        });
    } catch (error) {
      console.log(error);
      AssetTemplate.setAssetTemplate([]);
      return "";
    }
  }
  return {
    fetchAssetTemplate: fetchAssetTemplatesData,
  };
})();

export default AssetTemplatesData;
