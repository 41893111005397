import { useForm, useFieldArray } from "react-hook-form";
import { Btn, H4 } from "../../../AbstractElements";
import React, { Fragment, useEffect } from "react";
import { useState, useCallback } from "react";
import { Col, Label, Row } from "reactstrap";
import Form from "react-bootstrap/Form";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import CompanyDetails from "./CompanyDetails";
import AssetOwner from "./AssetOwner";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { result } from "lodash";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
const placesLibrary = ["places"];

const SiteDetails = (props) => {
  const [catId, setCatId] = useState();
  const [maxNum, setmaxNum] = useState();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4",
    libraries: placesLibrary,
  });
  const [locations, setLocations] = useState([]);
  const [siteAdress, setsiteAdress] = useState();
  const [siteAdressArray, setsiteAdressArray] = useState([]);
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      siteDetails: [
        {
          siteName: "",
          siteAddress: "",
          distributionBox: "",
          location: "",
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "siteDetails",
  });

  useEffect(() => {
    SerialId();
    async function SerialId() {
      await client
        .graphql({
          query: queries.listDistributionBoxes,
          authMode: "API_KEY",
          variables: {
            limit: 10000,
          },
        })
        .then((response) => {
          const DistributionBoxesData =
            response.data.listDistributionBoxes.items;

          let idArry = [];
          let maxnumber = 0;

          DistributionBoxesData.map((item) => {
            if (
              item.distributionBoxNumber !== "" &&
              item.distributionBoxNumber !== null
            ) {
              if (
                isNaN(Number(item.distributionBoxNumber.replace(/^\D+/g, "")))
              ) {
                idArry.push(1);
              } else {
                idArry.push(
                  Number(item.distributionBoxNumber.replace(/^\D+/g, ""))
                );
              }
            }
          });

          maxnumber = Math.max(...idArry);
          if (idArry.length > 0) {
            if (maxnumber !== 0 && maxnumber !== null) {
              const res = digits_count(Number(maxnumber + 1));
              if (res === 1) {
                setCatId("000");
                setmaxNum(maxnumber + 1);
              } else if (res === 2) {
                setCatId("00");
                setmaxNum(maxnumber + 1);
              } else if (res === 3) {
                setCatId("0");
                setmaxNum(maxnumber + 1);
              } else if (res === 4) {
                setCatId("");
                setmaxNum(maxnumber + 1);
              }
            } else {
              setCatId("000");
              setmaxNum(1);
            }
          } else {
            setCatId("000");
            setmaxNum(1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  //--------------------------------------------------------------------------------------

  const digits_count = (n) => {
    var count = 0;
    if (n >= 1) ++count;
    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }
    return count;
  };

  const onSubmit = async (data) => {
    try {
      console.log(props);
      const inputArray = data.siteDetails;
      console.log("key", siteAdressArray);
      const siteDetailsId = [];
      const distributionId = [];
      const siteDetails1 = [];
      const siteName = [];
      const unitNumber = [];
      const distributionBox = [];
      const distriLocation = [];
      inputArray.forEach(function (d, idx) {
        //  let detailedAddress = siteAdressArray[idx];
        //  console.log(detailedAddress);
        // client.graphql({
        //   query: mutations.createSiteDetails,
        //  variables: {
        //  input: {
        //  unitNumber: d.siteAddress,
        // siteName: d.siteName,
        // ------------start-----
        // street: detailedAddress.hasOwnProperty("street")
        //   ? detailedAddress.street
        //   : "",
        // suburb: detailedAddress.hasOwnProperty("suburb")
        //   ? detailedAddress.suburb
        //   : "",
        // state: detailedAddress.hasOwnProperty("state")
        //   ? detailedAddress.state
        //   : "",
        // postCode: detailedAddress.hasOwnProperty("postCode")
        //   ? detailedAddress.postCode
        //   : "",
        // country: detailedAddress.hasOwnProperty("country")
        //   ? detailedAddress.country
        //   : "",
        //   --- previous commented lines-----
        //  assetownerID: props.values.UserId,
        //  companydetailsID: props.values.CompanyId,
        //   status: "Active",
        //  },
        // },
        //  authMode: "API_KEY",
        // })
        // .then((result) => {
        // siteDetailsId.push(result.data.createSiteDetails.id);
        siteName.push(d.siteName);
        unitNumber.push(d.siteAddress);
        //  client.graphql({
        //  query: mutations.createDistributionBox,
        //  variables: {
        //   input: {
        //     distributionBoxName: d.distributionBox,
        //     location: d.location,
        //     sitedetailsID: result.data.createSiteDetails.id,
        //      status: "Active",
        //      distributionBoxNumber: "DB" + catId + maxNum,
        //    },
        //   },
        //   authMode: "API_KEY",
        // }).then((result2) => {
        //  console.log(result2);
        //  distributionId.push(result2.data.createDistributionBox.id);
        distributionBox.push(d.distributionBox);
        distriLocation.push(d.location);
        console.log(siteDetailsId);
        console.log(distributionId);
        console.log(distributionBox);
        console.log(distriLocation);
        console.log(siteName);
        console.log(unitNumber);
        siteDetails1.push({
          siteName: d.siteName,
          SiteAddress: d.siteAddress,
          DbName: d.distributionBox,
          location: d.location,
        });

        props.values.siteDetails = siteDetails1;
        // client.graphql({
        //   query: mutations.createLogBook,
        //   variables: {
        //     limit: 10000,
        //     input: {
        //       siteId: result.data.createSiteDetails.id,
        //       sitedetailsID: result.data.createSiteDetails.id,
        //      distributionBox: result2.data.createDistributionBox.id,
        //       status: "Active",
        //       logbookName: d.siteName + " - Default Logbook",
        //     },
        //   },
        //   authMode: "API_KEY",
        // }).then((result1) => {
        //   console.log(result1);
        //  });
        //  });
        //  client.graphql({
        //   query: mutations.createLinkUsersAndSite,
        //  variables: {
        //    input: {
        //      sitedetailsID: result.data.createSiteDetails.id,
        //       userdetailsID: props.values.UserId,
        //      status: "Active",
        //     },
        //   },
        //    authMode: "API_KEY",
        //  });
        //   console.log(result);
        //   })
        //  .catch((error) => {
        //    console.log(error);
        //   });
        // });

        // --------previous ---- commented  start ----
        // await inputArray.map(async function(d, idx){
        //   let detailedAddress = siteAdressArray[idx];

        //     const updateUser = await client.graphql({queries:mutations.updateUserDetails, { input: { id: state.userId, siteDetailsId: siteDetailsId, _version: getUserDetail[0]._version}}));
        //     console.log(updateUser);
        //     console.log(dataDetails);

        // });
        // --------previous ---- commented  last ----
        // client.graphql({
        //  query: mutations.updateUserDetails,
        //  variables: {
        ////   input: {
        //     id: props.values.UserId,
        //     siteDetailsId: siteDetailsId,
        //   },
        // },
        // authMode: "API_KEY",
        // })
        // .then((userUpdate) => {
        //   console.log(userUpdate);
        //   toast.success("Site Details Saved Succesfully!..", {
        //     position: toast.POSITION.TOP_CENTER,
        //   });
        props.values.SiteIds = siteDetailsId;
        props.values.siteName = siteName;
        props.values.UnitNumber = unitNumber;
        props.values.distributionBoxName = distributionBox;
        props.values.distributionBoxLocation = distriLocation;
        props.nextStep();
        //  })
        // .catch((error) => {
        //   console.log(error);
        //  toast.error("Error while Creating ", {
        //    position: toast.POSITION.TOP_CENTER,
        //  });
      });
    } catch (err) {
      toast.error(err.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  //------------------------------------------------------------------------------

  // const onSubmit = useCallback(async (data) => {
  //   const userDetails = await client.graphql({
  //     query: queries.listUserDetails,
  //     authMode: 'API_KEY'
  //   });
  //   const getUserDetail = userDetails.data.listUserDetails.items.filter(item => item.id === state?.userId);
  //   const companyId = getUserDetail[0]?.companyDetailsId;
  //   const inputArray=data.siteDetails;
  //   const siteDetailsId = [];
  //   await inputArray.map(async function(d, idx){
  //     const detailedAddress = siteAdressArray[idx];
  //     await client.graphql({
  //       query: mutations.createSiteDetails,
  //       variables: {input: {
  //         unitNumber:d.unitNumber,
  //         street:detailedAddress.hasOwnProperty('street') ? detailedAddress.street : '',
  //         suburb:detailedAddress.hasOwnProperty('suburb') ? detailedAddress.suburb : '',
  //         state:detailedAddress.hasOwnProperty('state') ? detailedAddress.state : '',
  //         postCode:detailedAddress.hasOwnProperty('postCode') ? detailedAddress.postCode : '',
  //         country:detailedAddress.hasOwnProperty('country') ? detailedAddress.country : '',
  //         assetownerID:state.userId,
  //         companydetailsID:companyId,
  //         status:'Active'
  //       }},
  //       authMode: 'API_KEY'
  //     }).then(result =>{
  //       console.log(result);
  //       siteDetailsId.push(result.data.createSiteDetails.id);
  //       client.graphql({
  //         query: mutations.createLinkUsersAndSite,
  //         variables:{
  //           input:{
  //             sitedetailsID: result.data.createSiteDetails.id,
  //             userdetailsID: state.userId,
  //             status:'Active'
  //           }
  //         },
  //         authMode: 'API_KEY'
  //       });
  //      }).catch(error => {
  //       console.log(error);
  //       toast.error("Error While Creating!..",{
  //         position: toast.POSITION.TOP_CENTER
  //       });
  //     });
  //   });
  //   const updateUser =  client.graphql({queries:mutations.updateUserDetails, { input: { id: state.userId, siteDetailsId: siteDetailsId, _version: getUserDetail[0]._version}})).then(result => {
  //     toast.success("Site Details Saved Succesfully!..",{
  //       position: toast.POSITION.TOP_CENTER
  //     });
  //   });
  // });

  //-------------------------------------------------------------------------------------------------

  const onLoad = (autocomplete) => {
    setsiteAdress(autocomplete);
  };

  const onPlaceChanged = (index) => {
    if (siteAdress !== null) {
      let fullAddress = siteAdress.getPlace();
      let fullAddress1 = [];
      let detailAddress = fullAddress.address_components;
      for (let i = 0; i < detailAddress.length; i++) {
        if (detailAddress[i].types.includes("postal_code")) {
          fullAddress1["postCode"] = detailAddress[i].long_name;
        }
        if (detailAddress[i].types.includes("locality")) {
          fullAddress1["suburb"] = detailAddress[i].long_name;
        }
        if (detailAddress[i].types.includes("administrative_area_level_1")) {
          fullAddress1["state"] = detailAddress[i].long_name;
        }
        if (detailAddress[i].types.includes("administrative_area_level_2")) {
          fullAddress1["street"] = detailAddress[i].long_name;
        }
        if (detailAddress[i].types.includes("country")) {
          fullAddress1["country"] = detailAddress[i].long_name;
        }
      }
      setsiteAdressArray((siteAdressArray) => ({
        ...siteAdressArray,
        [index]: fullAddress1,
      }));
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };
  return (
    <Fragment>
      <H4>Enter Site Details</H4>
      <br></br>
      <Form
        className="needs-validation"
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <ul>
          {fields.map((item, index) => {
            return (
              <li key={item.id}>
                <Row>
                  <Col md="5 mb-3">
                    <Label>Site Name</Label>
                    <input
                      className="form-control"
                      placeholder="Site Name"
                      {...register(`siteDetails.${index}.siteName`, {
                        required: true,
                      })}
                    />
                    <span>{errors.siteName && "Site Name is required"}</span>
                  </Col>
                  {/* <Col md="5 mb-3">
                    <Label>Unit Number</Label>
                    <input
                      className="form-control"
                      placeholder="Unit Number"
                      {...register(`siteDetails.${index}.unitNumber`, {
                        required: true,
                      })}
                    />
                    <span>
                      {errors.unitNumber && "Unit Number is required"}
                    </span>
                  </Col> */}
                  <Col md="5 mb-3">
                    <Label>Site Address</Label>
                    <Autocomplete
                      onPlaceChanged={(place) => {
                        console.log(place);
                        // onPlaceChanged(`${index}`);
                      }}
                      onLoad={onLoad}
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "AU" },
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Site Address"
                        className="form-control"
                        {...register(`siteDetails.${index}.siteAddress`, {
                          required: true,
                        })}
                      />
                    </Autocomplete>
                  </Col>
                  <Col md="2 mb-3">
                    <Label className="m-t-50"></Label>
                    <i
                      className="icofont icofont-trash"
                      onClick={() => remove(index)}
                    ></i>
                  </Col>
                </Row>

                <Row>
                  {/*
                  <Col md="5 mb-3">
                    <Label>Distribution Board</Label>
                    <input
                      className="form-control"
                      placeholder="Distribution Board"
                      {...register(`siteDetails.${index}.distributionBox`, {
                        required: true,
                      })}
                    />
                    <span>
                      {errors.distributionBox &&
                        "Distribution Box Name is required"}
                    </span>
                  </Col>
                  <Col md="5 mb-3">
                    <Label>Location</Label>

                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={(place) => {
                        setLocations((locations) => ({
                          ...locations,
                          [index]: place,
                        }));
                      }}
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "AU" },
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Distribution Board Location"
                        {...register(
                          `siteDetails.${index}.location`,
                          { required: true }
                        )}
                      />
                    </Autocomplete>
                    //-- previous
                     <GooglePlacesAutocomplete
                      selectProps={{
                        fetchDetails: true,
                        onChange: (data, details, place) => {
                          setLocations((locations) => ({
                            ...locations,
                            [index]: data.label,
                          }));
                          console.log("details", details);
                        },
                      }}
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "AU" },
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Location"
                        className="form-control"
                        {...register(`siteDetails.${index}.location`, {
                          required: true,
                        })}
                      />
                    </GooglePlacesAutocomplete> 
                    //--- previous commented---                    
                  </Col>
                  */}
                </Row>
              </li>
            );
          })}
        </ul>
        <button
          type="button"
          className="btn btn-primary m-r-10"
          onClick={() => {
            append({
              unitNumber: "",
              siteAddress: "",
              distributionBox: "",
              location: "",
            });
          }}
        >
          {" "}
          <i className="icofont icofont-plus"></i> Add Another Site{" "}
        </button>
        <Btn attrBtn={{ color: "primary" }}>Save</Btn>
        <br></br>
      </Form>
      <br></br>
    </Fragment>
  );
};
export default SiteDetails;
