import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Input,
  Form,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import { allData } from "../../../Constant";
import { PlusCircle } from "react-feather";
import { LargeModal } from "../../../Constant/index";
import { Btn } from "../../../AbstractElements";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const AssetsTemplateDetails = () => {
  const navigate = useNavigate();
  const [assetTemplate, setAssetTemplate] = useState([]);
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [value, setValue] = useState("");
  const [collection, setCollection] = React.useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [viewData, setViewData] = useState([]);
  const [ViewLarge, setViewLarge] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filteredData, setFilteredData] = React.useState([]);
  const LargeModaltoggle = () =>
    navigate(`${process.env.PUBLIC_URL}/assettemplates/add`, {
      state: { assetTemplates: assetTemplate },
    });

  const ViewLargeModal = async (siteData) => {
    console.log(siteData.formId);
    await client
      .graphql({
        query: queries.getFormDetails,
        variables: {
          id: siteData.formId,
        },
        authMode: "API_KEY",
      })
      .then((result) => {
        console.log(result);
        setViewData(JSON.parse(result.data.getFormDetails.formFields));
        ViewLargeModaltoggle();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const ViewLargeModaltoggle = () => setViewLarge(!ViewLarge);

  const handelAction = (action, data) => {
    action === "View"
      ? ViewLargeModal(data)
      : action === "Edit"
      ? navigate(`${process.env.PUBLIC_URL}/assettemplates/add`, {
          state: data,
        })
      : setViewLarge(!ViewLarge);
  };

  useEffect(() => {
    setIsLoading(true);
    async function fetchAssetTemplate() {
      await client
        .graphql({
          query: queries.listAssetTemplates,
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          const assetTemplateData = result.data.listAssetTemplates.items;
          if (assetTemplateData.length > 0) {
            const sortedItems = result.data.listAssetTemplates.items.sort(
              (a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              }
            );
            console.log(sortedItems);
            const assetTempArray = [];
            sortedItems.map((item) => {
              client
                .graphql({
                  query: queries.listServiceGroups,
                  variables: {
                    filter: {
                      id: {
                        eq: item.servicegroupID,
                      },
                    },
                    limit: 10000,
                  },
                  authMode: "API_KEY",
                })
                .then((serviceGroupResult) => {
                  console.log(serviceGroupResult);
                  const serviceGroupData =
                    serviceGroupResult.data.listServiceGroups.items;
                  assetTempArray.push({
                    id: item.id,
                    templatename: item.templatename,
                    servicegroup:
                      serviceGroupData.length > 0
                        ? serviceGroupData[0].serviceName
                        : "",
                    createdAt: item.createdAt,
                    formId: item.formId,
                    description: item.description,
                    status: item.status,
                    categoriesID: item.categoriesID,
                    servicegroupID: item.servicegroupID,
                  });
                  const sortedItems = assetTempArray.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  });
                  console.log(sortedItems);
                  setAssetTemplate(sortedItems);
                  setCollection(cloneDeep(sortedItems.slice(0, countPerPage)));
                  setIsLoading(false);
                })
                .catch((serviceGroupError) => {
                  console.log(serviceGroupError);
                  setIsLoading(false);
                  setErrorMessage("Unable to fetch list");
                });
            });
          } else {
            setCollection([]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          setErrorMessage("Unable to fetch list");
        });
    }
    fetchAssetTemplate();
  }, []);

  const globalSearch = (searchValue) => {
    let filteredData = assetTemplate.filter((row) => {
      return (
        row.templatename.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.servicegroup.toLowerCase().includes(searchValue.toLowerCase()) ||
        moment(row.createdAt)
          .format("DD/MM/YYYY")
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    });
    return cloneDeep(filteredData);
  };

  React.useEffect(() => {
    if (!value) {
      setCollection(cloneDeep(assetTemplate.slice(0, countPerPage)));
      setFilteredData([]);
    } else {
      const filteredData = globalSearch(value);
      setFilteredData(filteredData);
      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    if (!value) {
      setCollection(cloneDeep(assetTemplate.slice(from, to)));
    } else {
      const filteredData = globalSearch(value);
      setCollection(cloneDeep(filteredData.slice(from, to)));
    }
  };

  const formInputs = Object.keys(viewData).map((e) => {
    const { label, type, mandatory, errorMessage, options } = viewData[e];
    return (
      <Col md="6 mb-3" key={e}>
        <label>{label}</label>
        {type !== "dropdown" && (
          <input
            placeholder={label}
            className="form-control disabled"
            type={type}
            {...register(e, { required: mandatory })}
          />
        )}
        {type === "dropdown" && (
          <select
            placeholder={label}
            className="form-control disabled"
            {...register(e, { required: mandatory })}
          >
            <option>Choose {label}...</option>
            {options.map((item) => (
              <option key={item}>{item}</option>
            ))}
          </select>
        )}
        {errors[e] && <p className="invalid-feedback">{errorMessage}</p>}
      </Col>
    );
  });

  const [primarycolorTab, setprimarycolorTab] = useState("1");

  const renderList = (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <CardHeader>
                <Row>
                  <Col md="6">
                    <InputGroup>
                      <span className="input-group-text">
                        <i className="icon-search"></i>
                      </span>
                      <Input
                        type="search"
                        placeholder="Search Asset Templates.."
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <Btn
                      attrBtn={{ color: "primary", onClick: LargeModaltoggle }}
                    >
                      <PlusCircle />
                      Add Template
                    </Btn>
                  </Col>
                </Row>
              </CardHeader>
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">
                  <div className="table-responsive">
                    {isLoading ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        <Table className="table-striped  table-de">
                          <thead className="custom-table-head">
                            <tr>
                              <th>Template Name</th>
                              <th>Service Group</th>
                              <th>Added On</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="trhover">
                            {collection.length > 0 ? (
                              collection.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.templatename}</td>
                                  <td>{item.servicegroup}</td>
                                  <td>
                                    {moment(item.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {/* <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'danger', title: 'Delete',  onClick: () => handelAction('Delete', item)}} ><i className="fa fa-trash-o"></i></Btn> */}
                                    <Btn
                                      attrBtn={{
                                        className: "btn-outline-* btn-xs m-r-5",
                                        color: "info",
                                        title: "Edit",
                                        onClick: () =>
                                          handelAction("Edit", item),
                                      }}
                                    >
                                      <i className="fa fa-edit "></i>
                                    </Btn>
                                    <Btn
                                      attrBtn={{
                                        className: "btn-outline-* btn-xs m-r-5",
                                        color: "primary",
                                        title: "View",
                                        onClick: () =>
                                          handelAction("View", item),
                                      }}
                                    >
                                      <i className="fa fa-eye"></i>{" "}
                                    </Btn>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan="5" className="text-center">
                                    No Asset Template Available.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </tbody>
                        </Table>
                      </>
                    )}
                  </div>
                </Col>
              </div>
              <CardFooter className="row">
                <Pagination
                  className="p-t-10"
                  pageSize={countPerPage}
                  onChange={updatePage}
                  current={currentPage}
                  total={value ? filteredData.length : assetTemplate.length}
                  showTotal={(total, range) =>
                    `${range[0]} - ${range[1]} of ${total} items`
                  }
                />
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={ViewLarge}
          toggle={ViewLargeModaltoggle}
          size="lg"
          centered
        >
          <ModalHeader toggle={ViewLargeModaltoggle}>Preview Form</ModalHeader>
          <ModalBody className="modal-body">
            <Container fluid={true}>
              <Row>
                <Col md="12">
                  <Nav className="nav-primary mb-1" tabs>
                    <NavItem>
                      <NavLink
                        className={primarycolorTab === "1" ? "active" : ""}
                        onClick={() => setprimarycolorTab("1")}
                      >
                        Web View
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={primarycolorTab === "2" ? "active" : ""}
                        onClick={() => setprimarycolorTab("2")}
                      >
                        Mobile View
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={primarycolorTab}>
                    <TabPane className="fade show" tabId="1">
                      <Row>{formInputs}</Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <div class="wrapperCss">
                        <div class="iphone12max">
                          <div class="iphone12max__screen">
                            <div class="iphone12max__viewport">
                              <div class="dummyWebsite">
                                <Row>{formInputs}</Row>
                              </div>
                            </div>

                            <div class="iphone12max__topIcons"></div>
                            <div class="iphone12max__bottomIcons"></div>
                            <div class="iphone12max__bottomBar"></div>
                          </div>

                          <div class="iphone12max__notch"></div>
                          <div class="iphone12max__leftButtons"></div>
                          <div class="iphone12max__rightButton"></div>
                          <div class="iphone12max__sideAntennas"></div>
                          <div class="iphone12max__topBottomAntennas"></div>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </Container>
    </Fragment>
  );
  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default AssetsTemplateDetails;
