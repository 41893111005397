import React, { Fragment, useState, useEffect } from "react";
import * as mergedQueries from "../../graphql/mergedQueries";
import * as queries from "../../graphql/queries";
import { toast } from "react-toastify";
import { CardBody, CardHeader, Card, Row, Col, Media } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { H4 } from "../../AbstractElements";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import moment from "moment";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const CategoryAssetsContain = () => {
  const [assets, setAssets] = useState([]);
  const [assetTemplate, setAssetTemplate] = useState([]);
  const [catId, setCatId] = useState("");
  const [catName, setCatName] = useState("");
  const [serviceGroupId, setServiceGroupId] = useState("");
  const [siteDetails, setSiteDetails] = useState([]);
  const state = useLocation();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserGroup = localStorage.getItem("groups");

  useEffect(() => {
    console.log(state);
    if (state.state) {
      // setCatId(state.state.id);
      // getCategories(state.state.id)
      getAssetTemplates(state.state.id);
      getSiteUsers();
    }
  }, [state.state]);

  async function getSiteUsers() {
    await client
      .graphql({
        query: queries.getUserDetails,
        variables: {
          id: userDetails.id,
        },
      })
      .then((result) => {
        const sites = result.data.getUserDetails.LinkUsersAndSites.items;
        if (sites.length > 0) {
          getSite(sites);
        }
      })
      .catch((error) => {
        toast.error("error loading getUserDetails -> " + error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  async function getSite(sites) {
    const siteFilterArray = [];
    sites.map(async (item) => {
      siteFilterArray.push({
        id: {
          eq: item.sitedetailsID,
        },
      });
    });
    console.log(siteFilterArray);
    await client
      .graphql({
        query: queries.listSiteDetails,
        variables: {
          filter: {
            or: siteFilterArray,
          },
          limit: 10000,
        },
      })
      .then((result) => {
        console.log(result);
        setSiteDetails(result.data.listSiteDetails.items);
      })
      .catch((error) => {
        toast.error("error loading Site Details -> " + error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  async function getAssetTemplates(id) {
    console.log(id);
    await client
      .graphql({
        query: mergedQueries.getAssetsFromCategory,
        variables: {
          filter: {
            categoriesID: {
              eq: id,
            },
          },
          limit: 10000,
        },
      })
      .then((assetDetails) => {
        console.log(
          "Asset Template Details",
          assetDetails.data.listAssetTemplates.items
        );
        const assetTemplates = assetDetails.data.listAssetTemplates.items;
        if (assetTemplates.length > 0) {
          const assets = [];
          assetTemplates.map((item) => {
            if (item.Assets.items.length > 0) {
              item.Assets.items.map((asset) => {
                assets.push(asset);
              });
            }
          });
          setAssets(assets);
        }
      })
      .catch((error) => {
        console.log("error in Asset Template Details", error);
        toast.error("error loading AssetTemplate -> " + error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  async function getCategories(dbId) {
    await client
      .graphql({
        query: queries.getCategories,
        variables: {
          id: dbId,
        },
      })
      .then((result) => {
        const category = result.data.getCategories;
        setCatName(category.categoryName);
      })
      .catch((error) => {
        console.log("error loading Category", error);
      });
  }

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <Row>
            <Col md="8"></Col>
            <Col md="4" className="text-end">
              <select className="form-control" placeholder="Choose Site...">
                <option>Choose Site...</option>
                {siteDetails.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.unitNumber}
                  </option>
                ))}
              </select>
              <br></br>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            {assets.map((item) => (
              <Col md="3" key={item.id}>
                <Card className="o-hidden">
                  {item.status === "Active" ? (
                    <p className="activestatus">{item.assetSerialNumber}</p>
                  ) : (
                    <p className="inactivestatus">{item.assetSerialNumber}</p>
                  )}
                  <CardBody>
                    <Media className="static-widget">
                      <br></br>
                      <Media body>
                        <Link
                          to={`/formsLists`}
                          state={{
                            catId: `${catId}`,
                            assetId: `${item.assetId}`,
                          }}
                        >
                          <H4 id="status" attrH6={{ className: "font-roboto" }}>
                            {item.assetModel}
                          </H4>
                        </Link>
                        <br></br>
                        <b>Service Group</b>
                        <br></br>
                        {item.serviceGroup}
                        <br></br> <br></br>
                        <b>Distribution Board</b>
                        <br></br>
                        {item.distributionbox} <br></br>
                        {moment(item.installedOn).format("YYYY-MM-DD")}
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </CardBody>
        <CardHeader></CardHeader>
      </Card>
    </Fragment>
  );
};
export default CategoryAssetsContain;
