import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  CardBody,
  Media,
  Label,
  ModalBody,
  ModalHeader,
  Modal,
  Form,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import { Btn, H4, H1, Badges, H6 } from "../../AbstractElements";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { toast } from "react-toastify";
import { useNavigate, useLocation, Link } from "react-router-dom";
import moment from "moment";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { SiteContext } from "../../App";
import { Grid } from "react-feather";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const AssetsCount = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const [assets, setAssets] = useState([]);
  const [fullAssets, setFullAssets] = useState([]);
  const [value, setValue] = useState("");
  const [collection, setCollection] = React.useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedSiteData, setSelectedSiteData] = useState("");
  const [selectedSearchSiteData, setSelectedSearchSiteData] = useState("");
  const [selectedSearchDBData, setSelectedSearchDBData] = useState("");

  const [sites, setSites] = useState([]);
  const [category, setCategory] = useState([]);
  const [assetTemplate, setAssetTemplate] = useState([]);
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));

  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
    LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];
  const [isLoading, setIsLoading] = useState(false);
  const [firstSearch, setFirstSearch] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [distributionBoxList, setDistributionBoxList] = useState([]);
  const [distributionBoxFilter, setDistributionBoxfilter] = useState([]);
  const [selectedBox, setSelectedBox] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [assetView, setAssetView] = useState("list");
  const LoginUserGroup = localStorage.getItem("groups");
  const countPerPageCard = 8;
  const [currentPageCard, setCurrentPageCard] = useState(1);
  const [assetDemo, setAssetsDemo] = React.useState([]);
  const [assetDemoCard, setAssetsDemoCard] = React.useState([]);
  const [collectionCard, setCollectionCard] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const countPerPage = 10;
  const [filteredData, setFilteredData] = React.useState([]);
  const [assetTemplatData, setAssetTemplatData] = React.useState([]);
  const [activedata, setactiveData] = React.useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [deleteAsset, setDelete] = useState(false);
  const [selectedItemsAsset, setSelectedItemsAsset] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const [assetsCount, setAssetsCount] = useState([]);
  const [subscriptionPlan, setsubscriptionplan] = useState([]);
  const [addOn, setAddOn] = useState([]);
  const [sitesdetails, setsitesdetails] = useState([]);

  async function fetchSitesDetails() {
    let siteOptions = [];
    await client
      .graphql({
        query: queries.listSiteDetails,
        variables: {},
        authMode: "API_KEY",
      })
      .then(async (result) => {
        const sites = result.data.listSiteDetails.items;
        let nextToken = result.data.listSiteDetails.nextToken;
        let siteData = [];
        if (sites.length > 0) {
          siteData.push(sites);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listSiteDetails,
              variables: {
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((result) => {
              nextToken = result.data.listSiteDetails.nextToken;
              if (result.data.listSiteDetails.items.length > 0) {
                siteData.push(result.data.listSiteDetails.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        if (siteData.length > 0) {
          let mergeSites = siteData.flat(1);
          console.log(mergeSites);
          setsitesdetails(mergeSites);
          mergeSites.map(async (item) => {
            siteOptions.push({
              value: item.id,
              label:
                item.siteName +
                " " +
                "(" +
                (item.unitNumber ? item.unitNumber : " ") +
                ")",
            });
          });
          setSites(siteOptions);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setErrorMessage("No Assets to fetch");
        }
      });
  }

  async function fetchCategories() {
    let siteOptions = [];
    await client
      .graphql({
        query: queries.listCategories,
        variables: {},
        authMode: "API_KEY",
      })
      .then(async (result) => {
        const sites = result.data.listCategories.items;
        let nextToken = result.data.listCategories.nextToken;
        let siteData = [];
        if (sites.length > 0) {
          siteData.push(sites);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listCategories,
              variables: {
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((result) => {
              nextToken = result.data.listCategories.nextToken;
              if (result.data.listCategories.items.length > 0) {
                siteData.push(result.data.listCategories.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        if (siteData.length > 0) {
          let mergeSites = siteData.flat(1);
          console.log(mergeSites);
          mergeSites.map(async (item) => {
            siteOptions.push({
              value: item.id,
              label: item.categoryName,
            });
          });
          setCategory(siteOptions);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setErrorMessage("No Assets to fetch");
        }
      });
  }

  async function fetchAssetTemp() {
    await client
      .graphql({
        query: queries.listAssetTemplates,
        variables: {},
        authMode: "API_KEY",
      })
      .then(async (result) => {
        const sites = result.data.listAssetTemplates.items;
        let nextToken = result.data.listAssetTemplates.nextToken;
        let siteData = [];
        if (sites.length > 0) {
          siteData.push(sites);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listAssetTemplates,
              variables: {
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((result) => {
              nextToken = result.data.listAssetTemplates.nextToken;
              if (result.data.listAssetTemplates.items.length > 0) {
                siteData.push(result.data.listAssetTemplates.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        if (siteData.length > 0) {
          let mergeSites = siteData.flat(1);
          console.log(mergeSites);
          setAssetTemplate(mergeSites);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setErrorMessage("No Assets to fetch");
        }
      });
  }

  useEffect(() => {
    fetchCategories();
    fetchSitesDetails();
    fetchAssetTemp();
  }, []);

  async function getAssetsByTemplates(assetTemplates, siteData) {
    try {
      setIsLoading(true);
      console.log(siteData);
      let nextToken = "";
      let assets = [];
      let count = 0;
      assetTemplates.map(async (assetTemplate) => {
        count = count + 1;
        let tempSiteId = assetTemplate.id + "_" + siteData;
        await client
          .graphql({
            query: queries.assetsByAssettemplateSiteIDAndAssetNum,
            variables: {
              assettemplateSiteID: tempSiteId,
            },
            authMode: "API_KEY",
          })
          .then(async (result) => {
            let assetDatas =
              result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
            nextToken =
              result.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
            if (
              result.data.assetsByAssettemplateSiteIDAndAssetNum.items.length >
              0
            ) {
              assets.push(assetDatas);
            }
            while (nextToken !== null) {
              await client
                .graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: tempSiteId,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                })
                .then((results) => {
                  nextToken =
                    results.data.assetsByAssettemplateSiteIDAndAssetNum
                      .nextToken;
                  if (
                    results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                      .length > 0
                  ) {
                    assets.push(
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                    );
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setFullAssets([]);
                  setIsLoading(false);
                });
            }
            console.log(assets);
            if (count === assetTemplates.length) {
              const mergeAssets = assets.flat(1);
              console.log(mergeAssets);
              setCollection(mergeAssets);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  const handleSelectedSiteSearch = () => {
    setDistributionBoxfilter(
      distributionBoxList.filter(
        (item) => item.sitedetailsID === state.state.site.id
      )
    );
  };

  const cancelForm = () => {
    if (state.state.category && state.state.site) {
      navigate(`${process.env.PUBLIC_URL}/sites/listsubcat`, {
        state: {
          category: state.state.category,
          site: state.state.site,
        },
      });
    } else {
      navigate(`${process.env.PUBLIC_URL}/catassetlist`);
    }
  };

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption.value);
    setSelectedCat(selectedOption);
    console.log(selectedOption.value);
    console.log(assetTemplate);
    const filterdata = assetTemplate.filter(
      (item) => item.categoriesID === selectedOption.value
    );
    console.log(filterdata);
    setAssetTemplatData(filterdata);
    if (selectedSite) {
      getAssetsByTemplates(filterdata, selectedSite);
    }
  };

  const handleSiteDDChange = (selectedOption) => {
    setSelectedSite(selectedOption.value);
    setSelectedSearchSiteData(selectedOption);
    console.log(assetTemplatData);
    if (selectedCat) {
      getAssetsByTemplates(assetTemplatData, selectedOption.value);
    }
  };

  const handleUpdate = () => {
    try {
      client
        .graphql({
          query: queries.listAssetCounts,
          variables: {
            filter: {
              sitedetailsID: {
                eq: selectedSite,
              },
              categoriesID: {
                eq: selectedCategory,
              },
            },
            limit: 10000,
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result);
          const assetcount = result.data.listAssetCounts.items;
          if (assetcount.length > 0) {
            client
              .graphql({
                query: mutations.updateAssetCounts,
                variables: {
                  limit: 10000,
                  input: {
                    id: assetcount[0].id,
                    assetCount: assetcount[0].assetCount + 1,
                  },
                },
                authMode: "API_KEY",
              })
              .then((response) => {
                console.log(response);
                toast.success("Successfully Updated AssetCount.", {
                  position: toast.POSITION.TOP_CENTER,
                });
              })
              .catch((error) => {
                console.log(error);
                toast.error("Error While Updating" + error, {
                  position: toast.POSITION.TOP_CENTER,
                });
              });
          } else {
            const assetowner = sitesdetails.filter(
              (item) => item.id === selectedSite
            );
            client
              .graphql({
                query: mutations.createAssetCounts,
                variables: {
                  input: {
                    assetCount: 1,
                    categoriesID: selectedCategory,
                    sitedetailsID: selectedSite,
                    assetownerID: assetowner[0].assetownerID,
                  },
                },
                authMode: "API_KEY",
              })
              .then((result) => {
                console.log(result);
                toast.success("Successfully Added AssetCount.", {
                  position: toast.POSITION.TOP_CENTER,
                });
              })
              .catch((error) => {
                console.log(error);
                toast.error("Error While Adding: " + error, {
                  position: toast.POSITION.TOP_CENTER,
                });
              });
          }
        })
        .catch((serviceGroupError) => {
          setErrorMessage("Unable to fetch list");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const renderList = (
    <Fragment>
      <Container fluid={true} className="p-0 project-list">
        <Card className="custom-card">
          <Row>
            <Col md="3">
              <Select
                value={selectedCat}
                onChange={handleCategoryChange}
                options={category}
                isSearchable={true}
                hideSelectedOptions={false}
                placeholder="Select Category"
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </Col>
            <Col md="3">
              <Select
                value={selectedSearchSiteData}
                onChange={handleSiteDDChange}
                options={sites}
                isSearchable={true}
                hideSelectedOptions={false}
                placeholder="Select Site"
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <H1>Assets Count : {collection.length}</H1>
          </Row>
          <Row>
            <Col md="3">
              <Btn attrBtn={{ color: "primary", onClick: handleUpdate }}>
                Update
              </Btn>
            </Col>
          </Row>
          {/* </CardHeader> */}
        </Card>
        <Row></Row>
      </Container>
    </Fragment>
  );

  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default AssetsCount;
