import React, { Fragment, useEffect, useCallback } from "react";
import { H5, H4, H6, P } from "../../../AbstractElements";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Btn } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { FormGroup, Label, InputGroupAddon, InputGroupText } from "reactstrap";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import { toast } from "react-toastify";
import awsExports from "../../../aws-exports";
import uuid from "react-native-uuid";
import { useState, CSSProperties } from "react";
// import ClipLoader from "react-spinners/ClipLoader";

// const override: CSSProperties = {

//   display: "block",
//   margin: "0 auto",
//   borderColor: "red",
// };

const Certifications = () => {
  // let [loading, setLoading] = useState(true);
  // let [color, setColor] = useState("#ffffff");

  return (
    <Fragment>
      <Container fluid={true}>
        {/* <div className="sweet-loading">
      <button onClick={() => setLoading(!loading)}>Toggle Loader</button>
      <input value={color} onChange={(input) => setColor(input.target.value)} placeholder="Color of the loader" />

      <ClipLoader
        color={color}
        loading={loading}
        // cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div> */}
      </Container>
    </Fragment>
  );
};

export default Certifications;
