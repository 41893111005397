import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Col,
  Card,
  CardBody,
  Nav,
  Input,
  InputGroup,
  NavItem,
} from "reactstrap";
import { PlusCircle } from "react-feather";
import { Views, Tags } from "../../Constant";
import { taskData, tagData } from "../../Data/Task/index";
import NewTaskClass from "./NewTask";
import HeaderProfile from "./HeaderProfile";
import CreateTag from "./CreateTag";
import { LI } from "../../AbstractElements";
import { useNavigate, useLocation, Link } from "react-router-dom";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { limit } from "../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

const NavClass = (props) => {
  const state = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const [IsOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const [assetsFilter, setAssetsFilter] = useState([]);
  //const [limit, setLimit] = useState(100000);

  useEffect(() => {
    fetchATbyCat();
    async function fetchATbyCat() {
      try {
        const catAssets = await client.graphql({
          query: queries.assetTemplatesByCategoriesID,
          variables: {
            categoriesID: state.state.category.id,
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (catAssets.data.assetTemplatesByCategoriesID.items.length > 0) {
          console.log(catAssets);
          const assetTemp = catAssets.data.assetTemplatesByCategoriesID.items;
          if (assetTemp.length > 0) {
            //--
            let nextToken = "";
            let assets = [];
            const result = await client.graphql({
              query: queries.assetsBySitedetailsID,
              variables: {
                sitedetailsID: state.state.site.id,
                filter: {
                  status: {
                    ne: "delete",
                  },
                },
                limit: limit,
              },
              authMode: "API_KEY",
            });
            if (result.data.assetsBySitedetailsID.items.length > 0) {
              let assetDatas = result.data.assetsBySitedetailsID.items;
              nextToken = result.data.assetsBySitedetailsID.nextToken;
              console.log(
                "first token count",
                result.data.assetsBySitedetailsID.items.length
              );
              console.log("nextToken 1", nextToken);
              if (result.data.assetsBySitedetailsID.items.length > 0) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                const results = await client.graphql({
                  query: queries.assetsBySitedetailsID,
                  variables: {
                    sitedetailsID: state.state.site.id,
                    filter: {
                      status: {
                        ne: "delete",
                      },
                    },
                    limit: limit,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                });
                if (results.data.assetsBySitedetailsID.items.length > 0) {
                  nextToken = results.data.assetsBySitedetailsID.nextToken;
                  console.log("nextToken", nextToken);
                  console.log(results.data.assetsBySitedetailsID.items.length);
                  if (results.data.assetsBySitedetailsID.items.length > 0) {
                    assets.push(results.data.assetsBySitedetailsID.items);
                  }
                }
              }
              console.log(assets);
              if (assets.length > 0) {
                const mergeAssets = assets.flat(1);
                console.log(mergeAssets);
                //--
                const filterAssets = mergeAssets;
                console.log(filterAssets);
                const assetData = filterAssets.filter((item) => {
                  const _matchingAssetTemplate = assetTemp?.find(
                    (temp) => temp.id === item?.assettemplateID
                  );
                  if (_matchingAssetTemplate) {
                    return item;
                  }
                });
                if (assetData.length > 0) {
                  const sort = assetData.sort(
                    (a, b) => a.fittingNumber - b.fittingNumber
                  );
                  console.log(sort);
                  setAssetsFilter(sort);
                  setActiveTab(sort[0].id);
                } else {
                  setAssetsFilter([]);
                  setActiveTab("");
                }
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  useEffect(() => {
    if (!value) {
      setAssetsFilter(props.assets);
    } else {
      const filteredData = globalSearch(value);
      setAssetsFilter(filteredData);
    }
  }, [value]);

  const globalSearch = (searchValue) => {
    let filteredData = props.assets.filter((item) => {
      return item.fittingNumber
        ?.toLowerCase()
        .includes(searchValue.toLowerCase());
    });
    return filteredData;
  };

  return (
    <Fragment>
      <Col xl="3" className="box-col-3 xl-30">
        <div className="md-sidebar">
          <div className={`md-sidebar-aside open`}>
            <Card className="scrollView">
              <CardBody>
                <div className="email-app-sidebar left-bookmark">
                  <Nav className="main-menu" role="tablist">
                    <NavItem>
                      <span className="main-title">
                        Assets For {state.state && state.state.site.siteName}
                      </span>
                    </NavItem>
                    <NavItem>
                      <hr />
                    </NavItem>
                    <NavItem>
                      <InputGroup>
                        <span className="input-group-text">
                          <i className="icon-search"></i>
                        </span>
                        <Input
                          type="search"
                          placeholder="Search Assets.."
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                      </InputGroup>
                    </NavItem>
                    {assetsFilter.length > 0
                      ? assetsFilter.map((item) => (
                          <>
                            <NavItem key={item.id}>
                              <a
                                className={`${
                                  activeTab === item.id ? "active" : ""
                                } ${
                                  item.status === "Inactive"
                                    ? "text-danger"
                                    : "text-success"
                                }`}
                                onClick={() => {
                                  setActiveTab(item.id);
                                  props.activeToggle(item);
                                }}
                              >
                                <span className="title">
                                  {" "}
                                  {item.fittingNumber +
                                    " " +
                                    "(" +
                                    item.assetNumber +
                                    ")"}
                                </span>
                              </a>
                            </NavItem>
                          </>
                        ))
                      : "No Assets"}
                  </Nav>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default NavClass;
