import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const config = require("./app_config.json");

// Firebase
export const firebase_app = firebase.initializeApp({
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  databaseURL: config.firebase.databaseURL,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
});

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const githubProvider = new firebase.auth.GithubAuthProvider();
export const db = firebase.firestore();

// Auth0
export const auth0 = {
  domain: config.auth0.domain,
  clientId: config.auth0.clientID,
  redirectUri: config.auth0.redirectUri,
};

// Jwt da2-ixz6j3rw75emzcekjiarnyvfgm
export const Jwt_token = config.jwt_token;

export const S3_BUCKET = "kyauploadimages133428-prod";
export const REGION = "ap-southeast-2";
export const LOGBOOK_FOLDER = "logbook";
export const REPAIR_FOLDER = "repair";
export const TESTING_FOLDER = "testing";
export const ASSET_OWNER_FOLDER = "assetOwner";
export const CATEGORIES_FOLDER = "categories";
export const COMPANY_FOLDER = "company";
export const User_FOLDER = "userDocs";
export const FUNCTIONNAME = "sendEmailAssetWarranty";
export const USERPOOLID = "ap-southeast-2_Y8sY9qqTz";
export const GOOGLEAPIKEY = "AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4";
export const CERTIFICATES_FOLDER = "certificates";
export const ASSETS = "assets";
export const Reports = "reports";
export const IMPORT = "import";
export const FAILED = "failed";
export const FILES = "files";
export const limit = 100000;
export const ACCESSKEYID = "AKIAYJIQJGGWAFZAGZ53";
export const SECRETACCESSKEY = "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";
export const ASSETIMPORT_S3_BUCKET = "uploadassetsfromwebprod";
export const EMAIL_API =
  "https://7rbmhfcqy5.execute-api.ap-southeast-2.amazonaws.com/prod/sendemailskya";
export const DELETED_ASSETS = "deletedAssets";
