import React, { Fragment } from "react";
import { useCallback, useState, state, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { H5, H4, H6, P } from "../../../AbstractElements";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useParams } from "react-router-dom";
import Certifications from "./certifications";
import Repairs from "./repairs";
import Testing from "./testing";
import Logbooks from "./logbooks";

import * as queries from "../../../graphql/queries";
import awsExports from "../../../aws-exports";
import Form from "react-bootstrap/Form";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
const SiteListDetails = () => {
  const { category } = useParams();

  const [siteList, setsiteList] = useState([]);

  useEffect(() => {
    async function listSiteDetails() {
      const dataDetails = await client.graphql({
        query: queries.listSiteDetails,
        authMode: "API_KEY",
      });
      const siteDetails = dataDetails.data.listSiteDetails.items;
      setsiteList(siteDetails);
    }
    listSiteDetails();
  }, []);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="3 mb-3">
            <H4>{category}</H4>
          </Col>
          <Col md="3 mb-3"></Col>
          <Col md="3 mb-3"></Col>
          <Col md="3 mb-3">
            <Form.Select name="site">
              <option value="1">Site</option>
              {siteList.map((item) => (
                <option key={item.id} value={item.unitNumber}>
                  {item.unitNumber + "-" + item.country + "-" + item.state}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Tabs
            defaultActiveKey="Logbooks"
            transition={false}
            id="Logbooks"
            className="mb-3"
          >
            <Tab eventKey="Logbooks" title="Logbooks" id="Logbooks">
              <Logbooks />
            </Tab>
            <Tab eventKey="Testing" title="Testing">
              <Testing />
            </Tab>
            <Tab eventKey="Repairs" title="Repairs">
              <Repairs />
            </Tab>
            <Tab eventKey="Certifications" title="Certifications">
              <Certifications />
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SiteListDetails;
